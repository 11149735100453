import { setAsWeekForDate, setAsMonthForDate } from "../../../State/dateRange";
import fetchData from '../../../Services/fetchData';

export default (value) => async (dispatch, getState) => {

  const date = value[0]
  
  const { mode } = getState().screens.Board;

  if (mode === 'WEEK') dispatch(setAsWeekForDate(date));
  if (mode === 'MONTH') dispatch(setAsMonthForDate(date));

  dispatch(fetchData());

}