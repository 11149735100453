import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

const setDateRange = createAction("SCREENS/BOARD/SET_DATE_RANGE");
const setAsThisWeek = createAction("SCREENS/BOARD/SET_AS_THIS_WEEK");
const setAsThisMonth = createAction("SCREENS/BOARD/SET_AS_THIS_MONTH");
const nextWeek = createAction("SCREENS/BOARD/NEXT_WEEK");
const prevWeek = createAction("SCREENS/BOARD/PREV_WEEK");
const nextMonth = createAction("SCREENS/BOARD/NEXT_MONTH");
const prevMonth = createAction("SCREENS/BOARD/PREV_MONTH");
const setAsWeekForDate = createAction("SCREENS/BOARD/SET_AS_WEEK_FOR_DATE");
const setAsMonthForDate = createAction("SCREENS/BOARD/SET_AS_MONTH_FOR_DATE");

const thisMonth = [
  moment().startOf('month').format('YYYY-MM-DD'),
  moment().endOf('month').format('YYYY-MM-DD')
];

const thisWeek = [
  moment().startOf('isoWeek').format('YYYY-MM-DD'),
  moment().endOf('isoWeek').format('YYYY-MM-DD')
];

const defaultState = thisWeek;

const reducer = handleActions(
  {

    [setDateRange]: (state, action) => action.payload,

    [setAsThisWeek]: () => thisWeek,

    [setAsThisMonth]: () => thisMonth,

    [nextWeek]: (state) => ([
      moment(state[0]).startOf('isoWeek').add(1, 'week').format('YYYY-MM-DD'),
      moment(state[1]).endOf('isoWeek').add(1, 'week').format('YYYY-MM-DD')
    ]),

    [prevWeek]: (state) => ([
      moment(state[0]).startOf('isoWeek').subtract(1, 'week').format('YYYY-MM-DD'),
      moment(state[1]).endOf('isoWeek').subtract(1, 'week').format('YYYY-MM-DD')
    ]),

    [nextMonth]: (state) => ([
      moment(state[0]).startOf('month').add(1, 'month').format('YYYY-MM-DD'),
      moment(state[1]).startOf('month').add(1, 'month').endOf('month').format('YYYY-MM-DD')
    ]),

    [prevMonth]: (state) => ([
      moment(state[0]).startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
      moment(state[1]).startOf('month').subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    ]),

    [setAsWeekForDate]: (state, action) => ([
      moment(action.payload).startOf('isoWeek').format('YYYY-MM-DD'),
      moment(action.payload).endOf('isoWeek').format('YYYY-MM-DD'),
    ]),

    [setAsMonthForDate]: (state, action) => ([
      moment(action.payload).startOf('month').format('YYYY-MM-DD'),
      moment(action.payload).endOf('month').format('YYYY-MM-DD'),
    ])

  },
  defaultState
);

export { setDateRange, setAsThisWeek, setAsThisMonth, nextWeek, prevWeek, nextMonth, prevMonth, setAsWeekForDate, setAsMonthForDate, reducer };
