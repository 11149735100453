import { createAction, handleActions } from 'redux-actions';

const setSelectedLeftoverId = createAction("SCREENS/LEFTOVERS/SET_SELECTED_LEFTOVER_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedLeftoverId]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedLeftoverId, reducer };
