import { combineReducers } from "redux"

import { reducer as jobs } from "./State/jobs"
import { reducer as fetching } from "./State/fetching"
import { reducer as selectedRowKeys } from "./State/selectedRowKeys"
import { reducer as delay } from "./State/delay"

export default combineReducers({
  jobs,
  fetching,
  selectedRowKeys,
  delay,
})
