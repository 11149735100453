import { createSelector } from 'reselect'
import { get } from 'lodash'
import float from '../../../../../Utils/float'

const selectOrder = state => state.screens.CostSheet.order

export default createSelector(
  selectOrder,
  (order) => {

    const { cost_sheet: costSheet } = order

    const materials = costSheet.cost_sheet_materials
    const expenses = costSheet.cost_sheet_expenses
    const quotes = costSheet.cost_sheet_quotes

    const sale = quotes.filter(quote => get(quote, 'area.type') === 'Sale')
    const contractWorks = quotes.filter(quote => get(quote, 'area.type') === 'C/W')
    const customerExtra = quotes.filter(quote => get(quote, 'area.type') === 'C/E')

    const materialsTotal = materials.reduce((carry, material) => carry + (material.cost || 0), 0)
    const expensesTotal = expenses.reduce((carry, expense) => carry + (expense.cost || 0), 0)
    const quotesTotal = quotes.reduce((carry, quote) => carry + (quote.price || 0), 0)

    const saleTotal = sale.reduce((carry, quote) => carry + (quote.price || 0), 0)
    const contractWorksTotal = contractWorks.reduce((carry, quote) => carry + (quote.price || 0), 0)
    const customerExtraTotal = customerExtra.reduce((carry, quote) => carry + (quote.price || 0), 0)

    const otherTotal = (costSheet.anc_cost || 0) + (costSheet.rebate_cost || 0) + (costSheet.travel_cost || 0)

    const cost = materialsTotal + expensesTotal + otherTotal
    const profit = quotesTotal - cost
    const markup = cost > 0 ? (profit / cost) * 100 : 0

    // This will pick up quotable material types (e.g. matwell)
    const materialTypes = quotes
      .filter(quote => !!quote.material_type_id && quote.price > 0)
      .map(quote => ({
        key: quote.guid,
        label: quote.material_type.name,
        value: float(quote.price),
      }))

    return {
      materialTypes,
      sale: saleTotal > 0 ? float(saleTotal) : null,
      contractWorks: contractWorksTotal > 0 ? float(contractWorksTotal) : null,
      customerExtra: customerExtraTotal > 0 ? float(customerExtraTotal) : null,
      cost: float(cost),
      profit: float(profit),
      markup: float(markup),
    }

  },
)
