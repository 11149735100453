import axios from 'axios';

export const getOrders = ({ params } = {}) => axios.get(`orders`, { params });
export const getOrder = ({id, params }) => axios.get(`orders/${id}`, { params });
export const createOrder = ({ data }) => axios.post('orders', data);
export const updateOrder = ({ id, data }) => axios.put(`orders/${id}`, data);

export const updateOrderOnHoldReason = ({ id, data }) => axios.put(`orders/${id}/update_on_hold_reason`, data);

export const markOrderAsCalledOff = ({ id }) => axios.put(`orders/${id}/mark_as_called_off`);
export const markOrderAsFitted = ({ id }) => axios.put(`orders/${id}/mark_as_fitted`);
export const markOrderAsInvoiced = ({ id }) => axios.put(`orders/${id}/mark_as_invoiced`);
export const markOrderAsClosed = ({ id }) => axios.put(`orders/${id}/mark_as_closed`);
export const markOrderAsCancelled = ({ id }) => axios.put(`orders/${id}/mark_as_cancelled`);

export const unmarkOrderAsCalledOff = ({ id }) => axios.put(`orders/${id}/unmark_as_called_off`);
export const unmarkOrderAsFitted = ({ id }) => axios.put(`orders/${id}/unmark_as_fitted`);
export const unmarkOrderAsInvoiced = ({ id }) => axios.put(`orders/${id}/unmark_as_invoiced`);
export const unmarkOrderAsClosed = ({ id }) => axios.put(`orders/${id}/unmark_as_closed`);
export const unmarkOrderAsCancelled = ({ id }) => axios.put(`orders/${id}/unmark_as_cancelled`);

export const updateOrderSpecifications = ({ id, data }) => axios.put(`orders/${id}/specifications`, data);
export const updateOrderMaterials = ({ id, data }) => axios.put(`orders/${id}/materials`, data);
export const updateLeftovers = ({ id, data }) => axios.put(`orders/${id}/leftovers`, data);

export const bulkUpdateOrderStatuses = ({ data }) => axios.put(`orders/bulk_update_statuses`, data);

export const getOrderNumbers = () => axios.get(`orders/numbers`);

export const duplicateOrder = ({ id, data }) => axios.put(`orders/${id}/duplicate`, data);

export const sendRemedialQuestionnaire = ({ id, data }) => axios.put(`orders/${id}/send_questionnaire`, data);
