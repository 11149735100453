import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Tooltip } from 'antd';

import styles from './Component.css';

export default class extends React.PureComponent{

  static propTypes = {
    searchTerm: PropTypes.string.isRequired,
    showImportButton: PropTypes.bool.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    onSubmitSearch: PropTypes.func.isRequired,
    onClickNew: PropTypes.func.isRequired,
    onClickImport: PropTypes.func.isRequired,
  }

  render () {
    const {
      searchTerm,
      showImportButton,
      onSubmitSearch,
      onChangeSearch,
      onClickNew,
      onClickImport,
    } = this.props;

    return (

      <div className={styles.stack}>

        <h1 className={styles.heading}>
          Orders
        </h1>

        {showImportButton && (
          <Tooltip title="Import Files">
            <Button type="default" icon="upload" onClick={onClickImport} />
          </Tooltip>
        )}

        <div style={{ width: 10 }} />

        <Input.Search
          value={searchTerm}
          onChange={onChangeSearch}
          onSearch={onSubmitSearch}
          placeholder="Search"
          style={{ width: 250 }}
        />

        <div style={{ width: 10 }} />

        <Button type="primary" onClick={onClickNew}>
          New Order
        </Button>

      </div>

    )
  }
}