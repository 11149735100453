import axios from 'axios';

export const getJobs = ({ params } = {}) => axios.get(`jobs`, { params });
export const getJob = ({ id, params }) => axios.get(`jobs/${id}`, { params });
export const createJob = ({ data }) => axios.post('jobs', data);
export const updateJob = ({ id, data }) => axios.put(`jobs/${id}`, data);
export const deleteJob = ({ id }) => axios.delete(`jobs/${id}`);
export const markJobApproved = ({ id, data }) => axios.put(`jobs/${id}/mark_approved`, data);
export const completeJobs = ({ data }) => axios.put(`jobs/complete`, data);
export const searchPeriodsWithJobs = ({ params } = {}) => axios.get(`jobs/search_periods`, { params });
export const getJobStats = ({ params } = {}) => axios.get(`jobs/stats`, { params });
export const getJobPiggybacked = ({ id }) => axios.get(`jobs/${id}/piggybacked`);
