import { getJobs } from '../../../HttpRequests/jobs'
import { setFetching } from '../State/fetching'
import { loadJobs } from '../State/jobs'
import { setSelectedRowKeys } from '../State/selectedRowKeys'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  try {

    const params = {
      'filter[for_completion]': 1,
      'filter[manager_id]': getState().user.id,

      include: [
        'fitter',
        'order.site'
      ].join()
    }

    dispatch(setFetching(true))

    const response = await getJobs({ params })

    const jobs = response.data.data

    dispatch(loadJobs(jobs))
  
    const initialSelection = getState().modals.CompletedJobs.jobs.slice(0,10).map(job => job.id)
  
    dispatch(setSelectedRowKeys(initialSelection))

    dispatch(setFetching(false))

  } catch (error) {

    dispatch(setFetching(false))

    handleException(error)

  }

}