import { get } from 'lodash'
import moment from 'moment'
import screenProps from '../screenProps'
import { getOrder } from '../../../HttpRequests/orders'
import { setOrder } from '../State/order'
import { setOriginal } from '../State/original'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {
    include: [
      'builder',
      'site.manager',
      'site.site_rates.material_ranges',
      'site.site_rates.range_variants',
      'site.site_rates.area_range_quotes',
      'site.site_material_ranges',
      'plot_type.areas.area_material_types',
      'plot_type.area_groups.areas',
      'plot_type.area_groups.area_material_types',

      'cost_sheet.cost_sheet_areas.area',
      'cost_sheet.cost_sheet_areas.material_type',
      'cost_sheet.cost_sheet_areas.material_range',
      'cost_sheet.cost_sheet_areas.range_variant',

      'cost_sheet.cost_sheet_materials.material_type',
      'cost_sheet.cost_sheet_materials.material_range',
      'cost_sheet.cost_sheet_materials.range_variant',
      'cost_sheet.cost_sheet_materials.supplier',

      'cost_sheet.cost_sheet_expenses.fitting_rate.material_type',
      'cost_sheet.cost_sheet_expenses.fitting_rate.material_types',
      'cost_sheet.cost_sheet_expenses.fitting_rate.material_ranges',

      'cost_sheet.cost_sheet_quotes.area',
      'cost_sheet.cost_sheet_quotes.material_type',

      'jobs',
    ].join(','),
    append: 'cheat_sheets',
  }

  try {

    const response = await getOrder({ id, params })

    const order = {
      ...response.data.data,
      cost_sheet: {
        ...response.data.data.cost_sheet,
        ordered_date: response.data.data.ordered_date ? moment(response.data.data.ordered_date) : null, // binding order data here for ease of use
        delivery_date: response.data.data.delivery_date ? moment(response.data.data.delivery_date) : null, // binding order data here for ease of use
        cost_sheet_areas: get(response.data.data, 'cost_sheet.cost_sheet_areas', []),
        cost_sheet_materials: get(response.data.data, 'cost_sheet.cost_sheet_materials', []),
        cost_sheet_expenses: get(response.data.data, 'cost_sheet.cost_sheet_expenses', []),
        cost_sheet_quotes: get(response.data.data, 'cost_sheet.cost_sheet_quotes', []),
      },
    }

    dispatch(setOrder(order))

    dispatch(setOriginal(order.cost_sheet))

  } catch (error) {

    handleException(error)

  }

}
