import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './AppBarRight.css'

const AppBarRight = (props) => {

  const {
    children,
    className,
    style,
  } = props

  return (

    <div
      className={classNames(styles.right, className)}
      style={style}
    >
      {children}
    </div>

  )
}

AppBarRight.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

AppBarRight.defaultProps = {
  className: undefined,
  style: undefined,
}

export default AppBarRight