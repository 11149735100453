import { createAction, handleActions } from 'redux-actions';

const setOrderSearchTerm = createAction("MODALS/JOB/SET_ORDER_SEARCH_TERM");

const defaultState = "";

const reducer = handleActions(
  {
    [setOrderSearchTerm]: (state, action) => action.payload
  },
  defaultState
);

export { setOrderSearchTerm, reducer };
