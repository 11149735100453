import { connect } from 'react-redux'
import AreaGroups from './AreaGroups'

import onEditAreaGroup from './Handlers/onEditAreaGroup'
import onAddAreaMaterialType from './Handlers/onAddAreaMaterialType'
import onClickDimensions from './Handlers/onClickDimensions'
import onEditAreaMaterialType from './Handlers/onEditAreaMaterialType'
import onChangeSelectedRows from './Handlers/onChangeSelectedRows'

const mapStateToProps = (state) => ({
  areaGroups: state.screens.PlotType.AreaGroups.areaGroups,
  selectedRows: state.screens.PlotType.AreaGroups.selectedRows,
})

const actionCreators = {
  onEditAreaGroup,
  onAddAreaMaterialType,
  onClickDimensions,
  onEditAreaMaterialType,
  onChangeSelectedRows,
}

export default connect(mapStateToProps, actionCreators)(AreaGroups)
