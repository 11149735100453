import { closeSupplierRateModal } from '../State/supplierRateModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeSupplierRateModal())

  if (reload) dispatch(fetch())

}
