import { connect } from 'react-redux'
import PlotType from './PlotType'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onChangeActiveTab from './Handlers/onChangeActiveTab'
import onEditPlotType from './Handlers/onEditPlotType'
import onDeletePlotType from './Handlers/onDeletePlotType'
import onUpdatePlotType from './Handlers/onUpdatePlotType'
import onClosePlotTypeModal from './Handlers/onClosePlotTypeModal'
import onAddArea from './Handlers/onAddArea'
import onGroupAreas from './Handlers/onGroupAreas'
import onDeleteAreas from './Handlers/onDeleteAreas'
import onAddAreaGroup from './Handlers/onAddAreaGroup'
import onDeleteAreaMaterialTypes from './Handlers/onDeleteAreaMaterialTypes'
import onCloseAddAreaModal from './Handlers/onCloseAddAreaModal'
import onCloseGroupAreasModal from './Handlers/onCloseGroupAreasModal'
import onCloseAreaGroupModal from './Handlers/onCloseAreaGroupModal'
import onCloseAddAreaMaterialTypeModal from './Handlers/onCloseAddAreaMaterialTypeModal'

const mapStateToProps = (state) => ({
  plotType: state.screens.PlotType.plotType,
  activeTab: state.screens.PlotType.activeTab,
  plotTypeModal: state.screens.PlotType.plotTypeModal,
  addAreaModal: state.screens.PlotType.addAreaModal,
  groupAreasModal: state.screens.PlotType.groupAreasModal,
  dimensionsModal: state.screens.PlotType.dimensionsModal,
  areaGroupModal: state.screens.PlotType.areaGroupModal,
  addAreaMaterialTypeModal: state.screens.PlotType.addAreaMaterialTypeModal,
  areas: state.screens.PlotType.Areas.areas,
  selectedAreas: state.screens.PlotType.Areas.selectedRows,
  selectedAreaMaterialTypes: state.screens.PlotType.AreaGroups.selectedRows,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onChangeActiveTab,
  onEditPlotType,
  onDeletePlotType,
  onUpdatePlotType,
  onClosePlotTypeModal,
  onAddArea,
  onGroupAreas,
  onDeleteAreas,
  onAddAreaGroup,
  onDeleteAreaMaterialTypes,
  onCloseAddAreaModal,
  onCloseGroupAreasModal,
  onCloseAreaGroupModal,
  onCloseAddAreaMaterialTypeModal,
}

export default connect(mapStateToProps, actionCreators)(PlotType)
