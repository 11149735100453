import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Checkbox } from 'antd'
import { orderBy } from 'lodash'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    materialRange: PropTypes.object.isRequired,
    selectedRangeVariantIds: PropTypes.array.isRequired,
    saving: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onToggleRangeVariant: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      materialRange,
      selectedRangeVariantIds,
      saving,
      onToggleRangeVariant,
      onClickSave,
      onClose,
    } = this.props

    return (
        
      <Modal
        title={`${materialRange.name} Variants`}
        visible
        width={450}
        okText='Save'
        okButtonProps={{ loading: saving }}
        onOk={() => onClickSave()}
        onCancel={() => onClose()}
      >
        
        <div className={styles.rangeVariants}>
          <Checkbox.Group
            value={selectedRangeVariantIds}
            onChange={value => onToggleRangeVariant(value)}
            options={orderBy(materialRange.range_variants, 'name').map(({ id, name, code, design }) => ({ label: [name, code, design].filter(item => !!item).join(' • '), value: id }))}
          />
        </div>

      </Modal>
      
    )

  }

}
