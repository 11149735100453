import { closeBuilderRateRangeVariantsModal } from '../State/builderRateRangeVariantsModal'
import fetch from '../Services/fetch'

export default () => (dispatch) => {

  dispatch(closeBuilderRateRangeVariantsModal())

  dispatch(fetch())

}
