import { createAction, handleActions } from 'redux-actions';

const setOtherPeriodsWithResults = createAction("SCREENS/BOARD/SET_OTHER_PERIODS_WITH_RESULTS");
const resetOtherPeriodsWithResults = createAction("SCREENS/BOARD/RESET_OTHER_PERIODS_WITH_RESULTS");

const defaultState = [];

const reducer = handleActions(
  {
    [setOtherPeriodsWithResults]: (state, action) => action.payload,
    [resetOtherPeriodsWithResults]: () => defaultState,
  },
  defaultState
);

export { setOtherPeriodsWithResults, resetOtherPeriodsWithResults, reducer };
