import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { find } from 'lodash'
import { FORM_NAME } from '../Form';

const selectMaterials = state => state.core.materials;
const selectMaterialIds = state => formValueSelector(FORM_NAME)(state, 'material');

export default createSelector(
  selectMaterials,
  selectMaterialIds,
  (materials, materialIds) => {

    if (!materialIds || !Array.isArray(materialIds) || materialIds.length === 0) return null;

    const material = find(materials, { id: materialIds[1] });
    if (!material) return null;

    return material.material_unit;
  
  }
)