import { find, get } from 'lodash'
import selectAreas from '../../../Selectors/selectAreas'

export default (costSheetArea, state) => {
  
  const areas = selectAreas(state)

  const $costSheetArea = { ...costSheetArea }

  if (!$costSheetArea.area_id) return $costSheetArea

  if (get($costSheetArea, 'material_type.usage_key') !== 'dimensions') return $costSheetArea

  if ($costSheetArea.dimensions) return $costSheetArea
  
  const area = find(areas, row => row.id === $costSheetArea.area_id)

  const areaMaterialType = find(area.area_material_types, row => row.material_type_id === $costSheetArea.material_type_id)

  if (!areaMaterialType) return $costSheetArea

  $costSheetArea.dimensions = areaMaterialType.dimensions

  return $costSheetArea

}
