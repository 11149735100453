import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createSiteMaterialRange, updateSiteMaterialRange } from '../../../../../HttpRequests/siteMaterialRanges'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { siteMaterialRange, siteId } = modalProps()

  const data = {
    material_type_id: values.material_type_id,
    material_range_id: values.material_range_id,
  }

  try {

    if (siteMaterialRange) {
      await updateSiteMaterialRange({ id: siteMaterialRange.id, data })
      message.success('Updated Material')
    } else {
      data.site_id = siteId
      await createSiteMaterialRange({ data })
      message.success('Created Material')
    }

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

    throw error

  }

}
