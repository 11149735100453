import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { Screen } from 'evolve-portal'

import Manufacturers from './Components/Manufacturers'
import Suppliers from './Components/Suppliers'
import MaterialTypes from './Components/MaterialTypes'
import MaterialRanges from './Components/MaterialRanges'
import ManufacturerModal from '../../Modals/Manufacturer'
import SupplierModal from '../../Modals/Supplier'
import MaterialTypeModal from '../../Modals/MaterialType'
import MaterialRangeModal from '../../Modals/MaterialRange'

export default class extends React.PureComponent {

  static propTypes = {
    materialTypes: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    manufacturerModal: PropTypes.object.isRequired,
    supplierModal: PropTypes.object.isRequired,
    materialTypeModal: PropTypes.object.isRequired,
    materialRangeModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onCloseManufacturerModal: PropTypes.func.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
    onCloseMaterialTypeModal: PropTypes.func.isRequired,
    onCloseMaterialRangeModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render() {

    const {
      materialTypes,
      activeTab,
      manufacturerModal,
      supplierModal,
      materialTypeModal,
      materialRangeModal,
      onChangeActiveTab,
      onCloseManufacturerModal,
      onCloseSupplierModal,
      onCloseMaterialTypeModal,
      onCloseMaterialRangeModal,
    } = this.props

    return (

      <Screen>

        <h1>Materials</h1>

        <Tabs
          activeKey={activeTab}
          type='card'
          onChange={onChangeActiveTab}
          style={{ height: '40px' }}
        >
          <Tabs.TabPane key='manufacturers' tab='Manufacturers' />
          <Tabs.TabPane key='suppliers' tab='Suppliers' />
          <Tabs.TabPane key='material-types' tab='Types' />
          {materialTypes.map(materialType => <Tabs.TabPane key={materialType.id} tab={materialType.name} />)}
        </Tabs>

        {activeTab === 'manufacturers' && <Manufacturers />}

        {activeTab === 'suppliers' && <Suppliers />}

        {activeTab === 'material-types' && <MaterialTypes />}

        {activeTab !== 'manufacturers' && activeTab !== 'suppliers' && activeTab !== 'material-types' && <MaterialRanges />}

        {manufacturerModal.show && (

          <ManufacturerModal
            {...manufacturerModal}
            onClose={onCloseManufacturerModal}
          />

        )}

        {supplierModal.show && (

          <SupplierModal
            {...supplierModal}
            onClose={onCloseSupplierModal}
          />

        )}

        {materialTypeModal.show && (

          <MaterialTypeModal
            {...materialTypeModal}
            onClose={onCloseMaterialTypeModal}
          />

        )}

        {materialRangeModal.show && (

          <MaterialRangeModal
            {...materialRangeModal}
            onClose={onCloseMaterialRangeModal}
          />

        )}

      </Screen>

    )

  }
  
}
