import { createAction, handleActions } from 'redux-actions';

const setShouldCreateAnother = createAction("MODALS/ORDER_CREATE/SET_SHOULD_CREATE_ANOTHER");

const defaultState = false;

const reducer = handleActions(
  {
    [setShouldCreateAnother]: (state, action) => action.payload
  },
  defaultState
);

export { setShouldCreateAnother, reducer };
