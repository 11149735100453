import modalProps from '../../../modalProps'
import { createAreaRangeQuote, updateAreaRangeQuote } from '../../../../../HttpRequests/areaRangeQuotes'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { id, siteRate, type, areaOrGroupId } = modalProps()

  const data = {
    square_metres: values.square_metres,
    rate: values.rate || null,
    price_net: values.price_net,
    vat_perc: values.vat_perc,
  }

  let response

  try {

    if (id) {

      response = await updateAreaRangeQuote({ id, data })

    } else {

      data.site_rate_id = siteRate.id
      
      if (type === 'AreaGroup') {
        data.area_group_id = areaOrGroupId
      }

      if (type === 'Area') {
        data.area_id = areaOrGroupId
      }

      response = await createAreaRangeQuote({ data })

    }
    
    modalProps().onClose(response.data.data)

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
