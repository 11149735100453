import { createSelector } from "reselect";
import { find, findIndex, slice } from 'lodash';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectOrderNumbers = state => state.core.orderNumbers;
const selectOrderSearchTerm = state => state.screens.OrderView.orderSearchTerm;
const selectOrderId = state => formValueSelector(FORM_NAME)(state, 'original_order_id');

export default createSelector(
  selectOrderNumbers,
  selectOrderSearchTerm,
  selectOrderId,
  (orderNumbers, orderSearchTerm, orderId) => {

    let array = [];

    if (orderSearchTerm.length > 3) {

      const position = findIndex(orderNumbers, item => String(item.order_number || item.legacy_order_number).startsWith(orderSearchTerm));

      if (position > -1) {
        array = slice(orderNumbers, position, position+100)
      }
    }

    if (!orderSearchTerm && orderId) {
      
      const orderNumber = find(orderNumbers, item => item.id === orderId);

      if (orderNumber) {
        array = [ orderNumber ];
      }
    }

    return array.map(item => ({ value: item.id, label: String(item.order_number || item.legacy_order_number) }));
    
  }
)