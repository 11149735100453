import handleException from '../../../Core/Helpers/handleException';
import { getInspectors } from '../../../HttpRequests/inspectors';
import { setFetching } from '../State/fetching';
import { loadInspectors } from '../State/inspectors';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm } = getState().screens.Fitters;

  try {

    const params = {};
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (searchTerm) {
      params.search = searchTerm;
    }

    params.sort = 'name';

    dispatch(setFetching(true));

    const response = await getInspectors({ params });

    dispatch(loadInspectors(response.data.data));
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}