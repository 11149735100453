import { connect } from 'react-redux'
import Notes from './Notes'

import onEditNotes from './Handlers/onEditNotes'

const mapStateToProps = (state) => ({
  order: state.screens.CostSheet.order,
})

const actionCreators = {
  onEditNotes,
}

export default connect(mapStateToProps, actionCreators)(Notes)
