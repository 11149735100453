import { createAction, handleActions } from 'redux-actions';

const setSelectedInspectorId = createAction("SCREENS/INSPECTORS/SET_SELECTED_INSPECTOR_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedInspectorId]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedInspectorId, reducer };
