import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Select, Checkbox } from 'antd'
import { isEmpty } from 'lodash'
import HeightSpacer from '../../../HeightSpacer'
import styles from './styles.css'

const Label = props => <div className={styles.label}>{props.text}</div>

const validate = fields => {
  const errors = {}
  if (!fields.materialTypeId) errors.materialTypeId = true
  return errors
}

const AddModal = props => {

  const {
    materialTypes,
    materialRanges,
    rangeVariants,
    areas,
    fields,
    componentDidMount,
    componentWillUnmount,
    onChangeField,
    onSubmit,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  const [submitted, setSubmitted] = useState(false)

  const errors = validate(fields)

  return (

    <Modal
      title='Add Areas'
      visible
      width={360}
      okText='Apply'
      onOk={() => {
        setSubmitted(true)
        if (isEmpty(errors)) onSubmit()
      }}
      onCancel={onClose}
      maskClosable={false}
    >

      <Label text='Material' />
      <HeightSpacer type='label' />
      <Form.Item
        validateStatus={(submitted && errors.materialTypeId) ? 'error' : undefined}
      >
        <Select
          value={fields.materialTypeId}
          onChange={value => {
            onChangeField('materialTypeId', value)
            onChangeField('materialRangeId', null)
            onChangeField('rangeVariantId', null)
          }}
          allowClear
          showSearch
          optionFilterProp='children'
          style={{ width: '100%' }}
        >
          {materialTypes.map(materialType => (
            <Select.Option key={materialType.id} value={materialType.id}>
              {materialType.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <HeightSpacer type='field' />

      <Label text='Range' />
      <HeightSpacer type='label' />
      <Form.Item
        validateStatus={(submitted && errors.materialRangeId) ? 'error' : undefined}
      >
        <Select
          value={fields.materialRangeId}
          onChange={value => {
            onChangeField('materialRangeId', value)
            onChangeField('rangeVariantId', null)
          }}
          allowClear
          showSearch
          optionFilterProp='children'
          style={{ width: '100%' }}
        >
          {materialRanges.map(materialRange => (
            <Select.Option key={materialRange.id} value={materialRange.id}>
              {materialRange.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <HeightSpacer type='field' />

      <Label text='Variant' />
      <HeightSpacer type='label' />
      <Form.Item
        validateStatus={(submitted && errors.rangeVariantId) ? 'error' : undefined}
      >
        <Select
          value={fields.rangeVariantId}
          onChange={value => onChangeField('rangeVariantId', value)}
          allowClear
          showSearch
          optionFilterProp='children'
          style={{ width: '100%' }}
        >
          {rangeVariants.map(rangeVariant => (
            <Select.Option key={rangeVariant.id} value={rangeVariant.id}>
              {rangeVariant.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <HeightSpacer type='field' />

      <Label text='Areas' />
      <HeightSpacer type='label' />
      <div className={styles.areas}>
        <Checkbox.Group
          value={fields.areaIds}
          onChange={value => onChangeField('areaIds', value)}
          options={areas}
        />
      </div>

    </Modal>

  )

}

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

AddModal.propTypes = {
  materialTypes: PropTypes.array.isRequired,
  materialRanges: PropTypes.array.isRequired,
  rangeVariants: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default AddModal
