import { createAction, handleActions } from 'redux-actions'

const loadFittingRates = createAction('CORE/FITTING_RATES/LOAD_FITTING_RATES')
const clearFittingRates = createAction('CORE/FITTING_RATES/CLEAR_FITTING_RATES')

const defaultState = []

const reducer = handleActions(
  {
    [loadFittingRates]: (state, action) => action.payload,
    [clearFittingRates]: () => [],
  },
  defaultState,
)

export { loadFittingRates, clearFittingRates, reducer }
