import { createSelector } from "reselect";
import moment from "moment";
import { flatten } from 'lodash';
import selectDates from './dates';

const selectInspectorVisits = state => state.screens.Board.inspectorVisits;

export default createSelector(
  selectInspectorVisits,
  selectDates,
  (inspectorVisits, dates) => {

    const data = flatten(
      dates.map(week => week.map(date => date.date))
    ).reduce(
      (object, date) => Object.assign(object, { [date]: [] }),
      {}
    );

    inspectorVisits.forEach(inspectorVisit => {

      const key = moment(inspectorVisit.date).format('YYYY-MM-DD');

      if (data[key]) data[key].push(inspectorVisit);
    
    });
    
    return data;
  
  }
)