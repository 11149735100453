import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { NavLink } from 'react-router-dom';

export default class extends React.PureComponent {

  static propTypes = {
    suppliers: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    onChangeTable: PropTypes.func.isRequired
  }

  render () {

    const {
      suppliers,
      pagination,
      fetching,
      onChangeTable
    } = this.props;
    
    const columns = [

      { 
        title: 'Name', 
        dataIndex: 'name',
        width: 350,
        render: (text, record) => (
          <NavLink key={record.id} to={`/portal/suppliers/${record.id}`}>
            {record.name}
          </NavLink>
        ),
      }

    ]
    
    return (

      <Table
        dataSource={suppliers}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />

    )
  }
}