import * as React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Icon } from 'antd';
import { orderBy } from 'lodash';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    sites: PropTypes.array.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired
  }

  renderHeader = () => (

    <div className={styles.header}>

      <div className={styles.title}>
        Sites
      </div>

      <Button onClick={this.props.onClickAdd} type="primary">
        <Icon type="plus-circle" />
        Add Site
      </Button>

    </div>

  )
  
  render () {

    const {
      sites,
      onClickItem
    } = this.props;

    const columns = [
      
      {
        title: 'Name',
        dataIndex: 'name',
        width: 300,
        render: (text, record) => (
          <div onClick={() => onClickItem(record.id)} role="presentation" className={styles.link}>
            {record.name}
          </div>
        )
      },

      {
        title: 'Manager',
        dataIndex: 'manager',
        width: 200,
        render: (text) => (
          <span>
            {text.first_name}
            &nbsp;
            {text.last_name}
          </span>
        )
      },
      
      {
        title: 'Town',
        dataIndex: 'town',
        width: 200
      },
      
      {
        title: 'Account Ref',
        dataIndex: 'account_ref',
        width: 200
      }
      
    ];

    return (

      <Table
        dataSource={orderBy(sites.filter(site => !site.archived_at), 'name')}
        columns={columns} 
        rowKey={record => record.id}
        pagination={false}
        bordered
        title={this.renderHeader}
        locale={{ emptyText: 'There are no archived sites for this builder yet' }}
      />

    )
  }
}