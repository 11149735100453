import { closeGroupAreasModal } from '../State/groupAreasModal'
import fetch from '../Components/AreaGroups/Services/fetch'
import { resetSelectedRows } from '../Components/Areas/State/selectedRows'

export default (reload = false) => (dispatch) => {

  dispatch(closeGroupAreasModal())

  if (reload) {

    dispatch(fetch())

    dispatch(resetSelectedRows())
    
  }

}
