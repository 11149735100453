import screenProps from '../screenProps'
import fetch from '../Services/fetch'
import fetchMaterialTypes from '../../../Core/Services/fetchMaterialTypes'
import fetchAreas from '../Components/Areas/Services/fetch'
import fetchAreaGroups from '../Components/AreaGroups/Services/fetch'

export default (props) => async (dispatch) => {

  screenProps(props)

  dispatch(fetchMaterialTypes())

  await dispatch(fetch())

  dispatch(fetchAreas())

  dispatch(fetchAreaGroups())

}
