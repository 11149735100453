import { message } from 'antd';
import { updateLeftover } from '../../../HttpRequests/leftovers';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {
  
  try {

    const response = await updateLeftover({ id: data.id, data });
    
    message.success("Leftover Updated");

    return response.data.data;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}