import { createAction, handleActions } from 'redux-actions'

const setSuppliers = createAction('MODALS/SUPPLIERS/SET_SUPPLIERS')
const resetSuppliers = createAction('MODALS/SUPPLIERS/RESET_SUPPLIERS')

const defaultState = []

const reducer = handleActions(
  {
    [setSuppliers]: (state, { payload }) => payload,
    [resetSuppliers]: () => defaultState,
  },
  defaultState,
)

export { setSuppliers, resetSuppliers, reducer }
