import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, FormSpy, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Modal, Form, Row, Col, InputNumber, Button } from 'antd'
import { reduce } from 'lodash'
import numeral from 'numeral'

const Dimensions = props => {

  const {
    isNew,
    dimensions,
    onSave,
    onClose,
    validate,
    handleSave,
    handleClose,
  } = props

  let formService = null

  const [dimensionQty, setDimensionQty] = useState(dimensions.length)

  const [squareMetres, setSquareMetres] = useState(0)

  const close = (reload = false) => handleClose(onClose, reload)

  const add = () => {
    formService.mutators.push('dimensions', { length: null, width: null })
  }

  const remove = (index) => {
    formService.mutators.remove('dimensions', index)
  }

  const save = async (values) => {
    await handleSave(onSave, values.dimensions)
    close(true)
  }

  const getFooter = () => {

    const footer = []

    footer.push([
      <Button key='add' type='link' onClick={add} style={{ float: 'left' }}>Add</Button>,
      <span key='m2' style={{ fontWeight: 500, marginRight: 10 }}>{`M2: ${numeral(squareMetres).format('0,0.[00]')}`}</span>,
    ])

    footer.push(<Button key='cancel' onClick={() => close()}>Cancel</Button>)

    if (!isNew || dimensionQty > 0) {
      footer.push(<Button key='save' type='primary' onClick={() => formService.submit()}>Save</Button>)
    }

    return footer

  }

  const onFormChange = ({ values }) => {
    setDimensionQty(values.dimensions.length)
    setSquareMetres(reduce(values.dimensions, (sum, dimension) => sum + (dimension.length * dimension.width), 0))
  }

  return (

    <Modal
      visible
      title='Dimensions'
      width={350}
      footer={getFooter()}
      onCancel={() => close()}
    >

      <div style={{ maxHeight: '225px', overflow: 'auto', overflowX: 'hidden' }}>

        <FormProvider
          initialValues={{ dimensions }}
          mutators={{ ...arrayMutators }}
          onSubmit={save}
          validate={validate}
        >

          {({ handleSubmit, form }) => {

            formService = form

            return (

              <Form
                onSubmit={handleSubmit}
                autoComplete='off'
                layout='vertical'
              >

                <FormSpy
                  subscription={{ values: true }}
                  onChange={onFormChange}
                />

                <FieldArray name='dimensions'>

                  {({ fields }) => (

                    <div>

                      {fields.map((field, index) => (

                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={index} gutter={8}>

                          <Col span={2}>

                            <div
                              style={{
                                marginTop: index === 0 ? '29px' : null,
                                height: '32px',
                                lineHeight: '32px',
                              }}
                            >
                              {index + 1}
                            </div>

                          </Col>

                          <Col span={9}>

                            <Field name={`${field}.length`}>
                              {({ input, meta }) => (
                                <Form.Item
                                  label={index === 0 ? 'Length' : undefined}
                                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                                >
                                  <InputNumber
                                    {...input}
                                    precision={2}
                                    min={0}
                                    autoFocus={index === 0}
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              )}
                            </Field>

                          </Col>

                          <Col span={9}>

                            <Field name={`${field}.width`}>
                              {({ input, meta }) => (
                                <Form.Item
                                  label={index === 0 ? 'Width' : undefined}
                                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                                >
                                  <InputNumber
                                    {...input}
                                    precision={2}
                                    min={0}
                                    style={{ width: '100%' }}
                                  />
                                </Form.Item>
                              )}
                            </Field>

                          </Col>

                          <Col span={2}>

                            <Button
                              type='danger'
                              icon='delete'
                              onClick={() => remove(index)}
                              style={{ marginTop: index === 0 ? '29px' : null }}
                            />

                          </Col>

                        </Row>

                      ))}

                    </div>

                  )}

                </FieldArray>

              </Form>

            )

          }}

        </FormProvider>

      </div>

    </Modal>

  )

}

Dimensions.propTypes = {
  isNew: PropTypes.bool.isRequired,
  dimensions: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

Dimensions.defaultProps = {
  dimensions: [],
}

export default Dimensions
