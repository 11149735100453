import { connect } from 'react-redux'
import MaterialRanges from './MaterialRanges'

import onClickRow from './Handlers/onClickRow'
import onRemove from './Handlers/onRemove'

const mapStateToProps = (state) => ({
  siteRate: state.modals.SiteRate.siteRate,
})

const actionCreators = {
  onClickRow,
  onRemove,
}

export default connect(mapStateToProps, actionCreators)(MaterialRanges)
