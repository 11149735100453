import { connect } from 'react-redux'
import AddAreaMaterialType from './AddAreaMaterialType'

import componentDidMount from './Handlers/componentDidMount'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  areas: state.screens.PlotType.Areas.areas,
})

const actionCreators = {
  componentDidMount,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(AddAreaMaterialType)
