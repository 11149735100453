import { message } from 'antd'
import modalProps from '../modalProps'
import { addMaterialRangeToBuilderRate } from '../../../../../HttpRequests/builderRates'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch, getState) => {

  const { builderRate: { id } } = getState().modals.BuilderRate

  try {

    const response = await addMaterialRangeToBuilderRate({ id, materialRangeId: values.material_range_id })

    modalProps().onClose(response.data.data)

    message.success('Range Added')

  } catch (error) {

    handleException(error)

    throw error

  }

}
