import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { SelectField } from 'redux-form-antd-fields'

const labelColSpan = 6
const wrapperColSpan = 15

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    orderFileTypes: PropTypes.array.isRequired
  }

  render () {

    const {
      submitting,
      orderFileTypes
    } = this.props

    const orderFileTypeOptions = orderFileTypes.map(item => ({
      value: item.key,
      label: item.name
    }))

    return (
      
      <React.Fragment>
        
        <Field
          name="order_file_type_key"
          label="Type"
          disabled={submitting}
          component={SelectField}
          options={orderFileTypeOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

      </React.Fragment>

    )
  }
}