import { createAction, handleActions } from 'redux-actions'

const startFetching = createAction('SCREENS/SITE_VIEW/SITE_RATES/START_FETCHING')
const finishFetching = createAction('SCREENS/SITE_VIEW/SITE_RATES/FINISH_FETCHING')
const resetFetching = createAction('SCREENS/SITE_VIEW/SITE_RATES/RESET_FETCHING')

const defaultState = false

const reducer = handleActions(
  {
    [startFetching]: () => true,
    [finishFetching]: () => false,
    [resetFetching]: () => false,
  },
  defaultState,
)

export { startFetching, finishFetching, resetFetching, reducer }
