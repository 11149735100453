import * as React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

export default class extends React.PureComponent {

  static propTypes = {
    onClickApprove: PropTypes.func.isRequired,
  }

  render () {

    const {
      onClickApprove,
    } = this.props;

    return (
        
      <Alert
        type="info"
        description="The details of this job have been amended"
        showIcon
        closable
        closeText="Approve Changes"
        onClose={onClickApprove}
        banner
        style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
      />

    )
  }
}