import { connect } from 'react-redux'
import AddModal from './AddModal'

import selectMaterialTypes from './Selectors/selectMaterialTypes'
import selectMaterialRanges from './Selectors/selectMaterialRanges'
import selectRangeVariants from './Selectors/selectRangeVariants'
import selectAreas from './Selectors/selectAreas'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onChangeField from './Handlers/onChangeField'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  materialTypes: selectMaterialTypes(state),
  materialRanges: selectMaterialRanges(state),
  rangeVariants: selectRangeVariants(state),
  areas: selectAreas(state),
  fields: state.screens.CostSheet.Areas.AddModal.fields,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onChangeField,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(AddModal)
