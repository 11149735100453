import { connect } from 'react-redux'
import AddMaterialRangeModal from './AddMaterialRangeModal'

import componentDidMount from './Handlers/componentDidMount'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  materialTypeId: state.modals.SiteRate.siteRate.material_type_id,
  materialRanges: state.core.materialRanges,
})

const actionCreators = {
  componentDidMount,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(AddMaterialRangeModal)
