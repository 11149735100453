import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Cascader, Dropdown, Menu, Icon } from 'antd'
import classNames from 'classnames'
import styles from './styles.css'

const Item = props => {

  const {
    fittingRates,
    item,
    onEditField,
    onClickAction,
  } = props

  const [cost, setCost] = useState(undefined)

  const onBlurNumber = (field, value) => {
    const $value = (value || value === '0') ? parseFloat(value.replace(',', '')) : null
    onEditField(item.guid, field, $value)
  }

  const actions = (
    <Menu>
      <Menu.Item key='add' onClick={() => onClickAction(item.guid, 'add')}>
        <Icon type='plus' />
        Add
      </Menu.Item>
      <Menu.Item key='duplicate' onClick={() => onClickAction(item.guid, 'duplicate')}>
        <Icon type='copy' />
        Duplicate
      </Menu.Item>
      <Menu.Item key='remove' onClick={() => onClickAction(item.guid, 'remove')}>
        <Icon type='delete' />
        Remove
      </Menu.Item>
    </Menu>
  )

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.fittingRate)}>
        <Cascader
          options={fittingRates}
          onChange={value => onEditField(item.guid, 'fitting_rate', value)}
          expandTrigger='hover'
        >
          <div className={styles.content}>{item.fitting_rate}</div>
        </Cascader>
      </div>

      <div className={classNames(styles.cell, styles.cost)}>
        <input
          value={cost !== undefined ? cost : item.cost}
          onChange={e => setCost(e.target.value)}
          onBlur={e => {
            onBlurNumber('cost', e.target.value)
            setCost(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
        <div className={styles.actions}>
          <Dropdown
            trigger={['click']}
            overlay={actions}
          >
            <Icon type='caret-down' />
          </Dropdown>
        </div>
      </div>

    </div>
    
  )

}

Item.propTypes = {
  fittingRates: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  onEditField: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
}

export default Item
