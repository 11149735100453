import { connect } from 'react-redux'
import Table from './Table'

import onClickRow from './Handlers/onClickRow'
import onSort from './Handlers/onSort'

const mapStateToProps = (state) => ({
  data: state.screens.BuilderView.BuilderMaterialRanges.data,
  isFetching: state.screens.BuilderView.BuilderMaterialRanges.isFetching,
})

const actionCreators = {
  onClickRow,
  onSort,
}

export default connect(mapStateToProps, actionCreators)(Table)
