import { setShowFitterModal } from "../../../State/showFitterModal";
import { setShowInspectorModal } from "../../../State/showInspectorModal";

export default () => async (dispatch, getState) => {

  const { activeTab } = getState().screens.Fitters

  if (activeTab === 'fitters') {
    dispatch(setShowFitterModal(true));
  }

  if (activeTab === 'inspectors') {
    dispatch(setShowInspectorModal(true));
  }
  
}