import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteSupplierRate } from '../../../HttpRequests/supplierRates'
import fetchSupplierRates from '../../../Core/Services/fetchSupplierRates'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { supplierRate } = modalProps()

  try {

    await deleteSupplierRate({ id: supplierRate.id })

    message.success('Deleted Supplier Rate')

    modalProps().onClose(true)

    dispatch(fetchSupplierRates({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
