import { connect } from 'react-redux';

import Component from './Component';

import selectCanViewInspections from './Selectors/selectCanViewInspections'
import selectCanViewJobBoardStats from './Selectors/selectCanViewJobBoardStats'

import onChangeSearch from './Handlers/onChangeSearch';
import onChangeSource from './Handlers/onChangeSource';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onClickNextButton from './Handlers/onClickNextButton';
import onClickPrevButton from './Handlers/onClickPrevButton';
import onChangeDate from './Handlers/onChangeDate';
import onClickAddButton from './Handlers/onClickAddButton';
import onSelectOtherPeriod from './Handlers/onSelectOtherPeriod'
import onClickCompleted from './Handlers/onClickCompleted'
import onClickMetresSummary from './Handlers/onClickMetresSummary'
import onClickRebate from './Handlers/onClickRebate'
import onClickWip from './Handlers/onClickWip'
import onClickRemedialDates from './Handlers/onClickRemedialDates'

const mapStateToProps = (state) => ({
  source: state.screens.Board.source,
  mode: state.screens.Board.mode,
  searchTerm: state.screens.Board.searchTerm,
  dateRange: state.screens.Board.dateRange,
  otherPeriodsWithResults: state.screens.Board.otherPeriodsWithResults,
  canViewInspections: selectCanViewInspections(state),
  canViewJobBoardStats: selectCanViewJobBoardStats(state),
});

const actionCreators = {
  onChangeSearch,
  onChangeSource,
  onSubmitSearch,
  onClickNextButton,
  onClickPrevButton,
  onChangeDate,
  onClickAddButton,
  onSelectOtherPeriod,
  onClickCompleted,
  onClickMetresSummary,
  onClickRebate,
  onClickWip,
  onClickRemedialDates,
};

export default connect(mapStateToProps, actionCreators)(Component);
