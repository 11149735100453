import { getFitters } from '../../../HttpRequests/fitters'
import _props from '../props'
import { setFetching } from '../State/fetching'
import { loadFitters } from '../State/fitters'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  try {

    const { tag, dates } = _props.get()

    const params = {
      'filter[tag]': tag,
      'filter[available_between]': dates,
      'filter[without_archived]': 1,
      'sort': 'name',
    }

    dispatch(setFetching(true))

    const response = await getFitters({ params })

    const fitters = response.data.data

    dispatch(loadFitters(fitters))

    dispatch(setFetching(false))

  } catch (error) {

    dispatch(setFetching(false))

    handleException(error)

  }

}