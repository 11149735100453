import validate from './validate';
import onSubmit from './onSubmit';
import onSubmitFail from './onSubmitFail';

export const FORM_NAME = 'Order';

export const formConfig = {
  form: FORM_NAME,
  validate,
  onSubmit,
  onSubmitFail,
  destroyOnUnmount: false,
  initialValues: {
    sage_export_skip: false,
  }
}