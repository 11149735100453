import { formValueSelector, change, untouch } from 'redux-form';
import { FORM_NAME } from '../../../Form';
import resetPlotType from '../Services/resetPlotType';

export default (event, value) => (dispatch, getState) => {

  const valueBefore = formValueSelector(FORM_NAME)(getState(), 'flags') || [];

  const credit = value.includes('credit') ? 1 : 0;
  const creditBefore = valueBefore.includes('credit') ? 1 : 0;
  const isPrivate = value.includes('private') ? 1 : 0;
  const customEntry = value.includes('custom_entry') ? 1 : 0;
  const customEntryBefore = valueBefore.includes('custom_entry') ? 1 : 0;

  dispatch(change(FORM_NAME, 'credit', credit));
  dispatch(change(FORM_NAME, 'private', isPrivate));
  dispatch(change(FORM_NAME, 'custom_entry', customEntry));

  if (credit !== creditBefore) {

    dispatch(change(FORM_NAME, 'credit_reference', null));
    dispatch(change(FORM_NAME, 'credit_status', null));

  }

  if (customEntry !== customEntryBefore) {

    if (customEntry) {

      dispatch(change(FORM_NAME, 'original_order_id', null));
      dispatch(change(FORM_NAME, 'order_category_id', null));
      dispatch(change(FORM_NAME, 'builder_id', null));
      dispatch(change(FORM_NAME, 'site_id', null));
      dispatch(change(FORM_NAME, 'plot_type_id', null));
      dispatch(change(FORM_NAME, 'cost_sheet', null));

      dispatch(untouch(FORM_NAME, 'original_order_id', 'order_category_id', 'builder_id', 'site_id', 'plot_type_id'));

      dispatch(resetPlotType());

    } else {

      dispatch(change(FORM_NAME, 'order_category_custom', null));
      dispatch(change(FORM_NAME, 'builder_custom', null));
      dispatch(change(FORM_NAME, 'site_custom', null));
      dispatch(change(FORM_NAME, 'town_custom', null));

      dispatch(untouch(FORM_NAME, 'order_category_custom', 'builder_custom', 'site_custom', 'town_custom'));

    }

  }
  
}