import { getAreaGroups } from '../../../../../HttpRequests/areaGroups'
import { startFetching, finishFetching } from '../State/isFetching'
import { setAreaGroups } from '../State/areaGroups'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { plotType: { id: plotTypeId } } = getState().screens.PlotType

  const params = {
    'filter[plot_type_id]': plotTypeId,
    include: 'material_type,area_material_types.area',
  }

  dispatch(startFetching())

  try {

    const response = await getAreaGroups({ params })

    dispatch(setAreaGroups(response.data.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
