import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import Table from "./Components/Table";
import LeftoverModal from "../../Modals/Leftover";

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    showLeftoverModal: PropTypes.bool.isRequired,
    selectedLeftoverId: PropTypes.number,
    onCloseLeftoverModal: PropTypes.func.isRequired,
    didCreateLeftover: PropTypes.func.isRequired,
    didUpdateLeftover: PropTypes.func.isRequired,
    didDeleteLeftover: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedLeftoverId: null
  };

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      showLeftoverModal,
      selectedLeftoverId,
      onCloseLeftoverModal,
      didCreateLeftover,
      didUpdateLeftover,
      didDeleteLeftover
    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header />

        <Table />

        {showLeftoverModal && (
          <LeftoverModal
            leftoverId={selectedLeftoverId}
            onClose={onCloseLeftoverModal}
            didCreate={didCreateLeftover}
            didUpdate={didUpdateLeftover}
            didDelete={didDeleteLeftover}
          />
        )}

      </Screen>

    )
  }
}
