import { createAction, handleActions } from 'redux-actions'

const setFetching = createAction('MODALS/RANGE_VARIANTS/SET_FETCHING')
const resetFetching = createAction('MODALS/RANGE_VARIANTS/RESET_FETCHING')

const defaultState = []

const reducer = handleActions(
  {
    [setFetching]: (state, { payload }) => payload,
    [resetFetching]: () => defaultState,
  },
  defaultState,
)

export { setFetching, resetFetching, reducer }
