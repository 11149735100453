import React from 'react';
import { Icon, Modal } from 'antd';
import unarchiveSite from '../../../Services/unarchive';

export default () => (dispatch) => {

  Modal.confirm({
    title: `Unarchive Site?`,
    content: 'Are you sure you want to unarchive this site?',
    icon: <Icon type="eye" />,
    okText: 'Unarchive',
    onOk: () => dispatch(unarchiveSite())
  })

}