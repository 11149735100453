import { createAction, handleActions } from 'redux-actions'

const setSiteRate = createAction('MODALS/SITE_RATE/SET_SITE_RATE')
const resetSiteRate = createAction('MODALS/SITE_RATE/RESET_SITE_RATE')

const defaultState = null

const reducer = handleActions(
  {
    [setSiteRate]: (state, action) => action.payload,
    [resetSiteRate]: () => defaultState,
  },
  defaultState,
)

export { setSiteRate, resetSiteRate, reducer }
