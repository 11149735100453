import { defer } from 'lodash'
import logFitterChange from '../../../Services/logFitterChange'
import checkFitterAvailability from '../../../Services/checkFitterAvailability'

export default () => (dispatch) => {

  dispatch(logFitterChange())
  
  defer(() => dispatch(checkFitterAvailability()))
  
}