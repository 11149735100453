import { resetOrder } from '../State/order'
import { resetOriginal } from '../State/original'
import { resetEditing } from '../State/editing'

export default () => (dispatch) => {

  dispatch(resetOrder())
  dispatch(resetOriginal())
  dispatch(resetEditing())

}
