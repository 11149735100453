import guessCost from '../Utils/guessCost'
import { setCostSheetField } from '../../../../../State/order'

export default () => (dispatch, getState) => {

  const state = getState()

  const { cost_sheet_expenses: costSheetExpenses } = state.screens.CostSheet.order.cost_sheet

  const $costSheetExpenses = []

  costSheetExpenses.forEach(costSheetExpense => {
    $costSheetExpenses.push(guessCost(costSheetExpense, state))
  })

  dispatch(setCostSheetField({ field: 'cost_sheet_expenses', value: $costSheetExpenses }))

}
