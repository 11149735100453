import { createSelector } from 'reselect'
import selectAreaSummary from '../../../../../Selectors/selectAreaSummary'
import float from '../../../../../Utils/float'

export default createSelector(
  selectAreaSummary,
  (areaSummary) => {

    const { materialTypes } = areaSummary

    if (materialTypes.length === 1) return [{
      key: materialTypes[0].materialTypeId,
      total: float(materialTypes[0].areaSquareMetres),
    }]
    
    return materialTypes.map(({ areaSquareMetres, materialType }) => ({
      key: materialType.id,
      total: `${materialType.name[0]}: ${float(areaSquareMetres)}`,
    }))

  },
)
