import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class extends React.PureComponent {

  static propTypes = {
    id: PropTypes.number,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    id: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      id,
      onSubmit,
      onDelete,
      onClose,
    } = this.props

    const footer = []

    if (id) {
      footer.push(
        <Button key='delete' type='danger' onClick={onDelete} style={{ float: 'left' }}>
          Delete
        </Button>,
      )
    }

    footer.push(
      <Button key='cancel' onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' onClick={onSubmit}>
        {id ? 'Update' : 'Create'}
      </Button>,
    )

    return (
        
      <Modal
        visible  
        title={id ? 'Quote' : 'New Quote'}
        width={550}
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

      </Modal>
      
    )

  }

}
