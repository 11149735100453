import { connect } from 'react-redux'
import SiteRate from './SiteRate'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'
import onAddMaterialRange from './Handlers/onAddMaterialRange'
import onCloseAddMaterialRangeModal from './Handlers/onCloseAddMaterialRangeModal'
import onCloseSiteRateRangeVariantsModal from './Handlers/onCloseSiteRateRangeVariantsModal'

const mapStateToProps = (state, props) => ({
  isNew: !props.id,
  siteRate: state.modals.SiteRate.siteRate,
  addMaterialRangeModal: state.modals.SiteRate.addMaterialRangeModal,
  siteRateRangeVariantsModal: state.modals.SiteRate.siteRateRangeVariantsModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onSubmit,
  onDelete,
  onAddMaterialRange,
  onCloseAddMaterialRangeModal,
  onCloseSiteRateRangeVariantsModal,
}

export default connect(mapStateToProps, actionCreators)(SiteRate)
