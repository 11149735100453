import { connect } from "react-redux";
import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import componentWillUnmount from "./Handlers/componentWillUnmount";
import onCloseBuilderModal from "./Handlers/onCloseBuilderModal";
import onCloseSiteModal from "./Handlers/onCloseSiteModal";
import didUpdateBuilder from "./Handlers/didUpdateBuilder";
import didDeleteBuilder from "./Handlers/didDeleteBuilder";
import didCreateSite from "./Handlers/didCreateSite";
import onCloseBuilderRateModal from "./Handlers/onCloseBuilderRateModal";
import onCloseBuilderMaterialRangeModal from "./Handlers/onCloseBuilderMaterialRangeModal";

const mapStateToProps = state => ({
  builder: state.screens.BuilderView.builder,
  activeTab: state.screens.BuilderView.activeTab,
  showBuilderModal: state.screens.BuilderView.showBuilderModal,
  showSiteModal: state.screens.BuilderView.showSiteModal,
  builderRateModal: state.screens.BuilderView.builderRateModal,
  builderMaterialRangeModal: state.screens.BuilderView.builderMaterialRangeModal,
});

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseBuilderModal,
  onCloseSiteModal,
  didUpdateBuilder,
  didDeleteBuilder,
  didCreateSite,
  onCloseBuilderRateModal,
  onCloseBuilderMaterialRangeModal,
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
