import { closeDimensionsModal } from '../../../State/dimensionsModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeDimensionsModal())

  if (reload) {
    dispatch(fetch())
  }

}
