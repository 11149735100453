import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as supplierRates } from './State/supplierRates'
import { reducer as fetching } from './State/fetching'
import { reducer as supplierRateModal } from './State/supplierRateModal'

export default combineReducers({
  controls,
  supplierRates,
  fetching,
  supplierRateModal,
})
