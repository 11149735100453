import { connect } from 'react-redux'
import Manufacturers from './Manufacturers'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseManufacturerModal from './Handlers/onCloseManufacturerModal'
import onCloseMaterialRangesModal from './Handlers/onCloseMaterialRangesModal'

const mapStateToProps = (state) => ({
  manufacturerModal: state.modals.Manufacturers.manufacturerModal,
  materialRangesModal: state.modals.Manufacturers.materialRangesModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseManufacturerModal,
  onCloseMaterialRangesModal,
}

export default connect(mapStateToProps, actionCreators)(Manufacturers)
