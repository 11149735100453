import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteSiteRate } from '../../../HttpRequests/siteRates'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  const { id } = modalProps()

  try {

    await deleteSiteRate({ id })

    message.success('Deleted Rate')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
