import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as materialTypes } from './State/materialTypes'
import { reducer as fetching } from './State/fetching'
import { reducer as materialTypeModal } from './State/materialTypeModal'

export default combineReducers({
  controls,
  materialTypes,
  fetching,
  materialTypeModal,
})
