import { setAsWeekForDate, setAsMonthForDate } from "../../../State/dateRange";
import fetchData from '../../../Services/fetchData';

export default (date) => async (dispatch, getState) => {
  const { mode } = getState().screens.Board;

  if (mode === 'WEEK') dispatch(setAsWeekForDate(date));
  if (mode === 'MONTH') dispatch(setAsMonthForDate(date));

  dispatch(fetchData());

}