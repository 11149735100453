import { createAction, handleActions } from 'redux-actions';

const setShowInspectorModal = createAction("SCREENS/INSPECTORS/SET_SHOW_INSPECTOR_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowInspectorModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowInspectorModal, reducer };