import { connect } from 'react-redux'
import JobStatsTable from './JobStatsTable'

import selectTableRows from './Selectors/selectTableRows'

const mapStateToProps = (state) => ({
  tags: state.screens.Board.jobStats.tags,
  tableRows: selectTableRows(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(JobStatsTable)
