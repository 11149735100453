import { createAction, handleActions } from 'redux-actions';

const showSpinner = createAction("SCREENS/BOARD/SHOW_SPINNER");
const hideSpinner = createAction("SCREENS/BOARD/HIDE_SPINNER");

const defaultState = false;

const reducer = handleActions(
  {
    [showSpinner]: () => true,
    [hideSpinner]: () => false
  },
  defaultState
);

export { showSpinner, hideSpinner, reducer };
