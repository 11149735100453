import { message } from 'antd';
import { getProps } from '../props';
import { deletePlotType } from '../../../HttpRequests/plotTypes';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { plotTypeId, onClose, didDelete } = getProps();

    await deletePlotType({ id: plotTypeId });
    
    message.success("Plot Type Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}