import { connect } from 'react-redux'
import Controls from './Controls'

import onClickAdd from './Handlers/onClickAdd'
import onClickReset from './Handlers/onClickReset'

const mapStateToProps = (state) => ({
  order: state.screens.CostSheet.order,
  editing: state.screens.CostSheet.editing,
})

const actionCreators = {
  onClickAdd,
  onClickReset,
}

export default connect(mapStateToProps, actionCreators)(Controls)
