export default {
  width: {
    'block': 24,
    'button': 12,
    'button-small': 8,
  },
  height: {
    'block': 24,
    'controls': 12,
    'field': 12,
    'label': 6,
  },
}
