import React from 'react'
import PropTypes from 'prop-types'
import { Input, Radio, Button } from 'antd'
import styles from './styles.css'

const Controls = props => {

  const {
    controls,
    onChangeControl,
    onSearch,
    onClickNew,
  } = props

  return (

    <div className={styles.wrapper}>

      <div className={styles.left}>

        <Radio.Group
          value={controls.activeTab}
          onChange={e => onChangeControl('activeTab', e.target.value)}
        >
          <Radio.Button value='active'>Active</Radio.Button>
          <Radio.Button value='archived'>Archived</Radio.Button>
        </Radio.Group>

        <Input.Search
          value={controls.search}
          onChange={e => onChangeControl('search', e.target.value)}
          onSearch={onSearch}
          placeholder='Search...'
          autoFocus
          style={{ width: 250 }}
        />

      </div>

      <div className={styles.right}>
        
        <Button type='primary' icon='plus' onClick={onClickNew}>New Range</Button>

      </div>

    </div>

  )

}

Controls.propTypes = {
  controls: PropTypes.object.isRequired,
  onChangeControl: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickNew: PropTypes.func.isRequired,
}

export default Controls
