import { connect } from 'react-redux';
import Component from './Component';

import onChangeTable from './Handlers/onChangeTable';
import onChangeRowSelection from './Handlers/onChangeRowSelection';
import onClickBulkUpdateAction from './Handlers/onClickBulkUpdateAction';
import onClickExport from './Handlers/onClickExport';

const mapStateToProps = (state) => ({
  orders: state.screens.Orders.orders,
  pagination: state.screens.Orders.pagination,
  selectedRowKeys: state.screens.Orders.selectedRowKeys,
  fetching: state.screens.Orders.fetching,
  type: state.screens.Orders.filters.type,
});

const actionCreators = {
  onChangeTable,
  onChangeRowSelection,
  onClickBulkUpdateAction,
  onClickExport,
};

export default connect(mapStateToProps, actionCreators)(Component);
