import handleException from '../../../Core/Helpers/handleException';
import { getLeftovers } from '../../../HttpRequests/leftovers';
import { setFetching } from '../State/fetching';
import { loadLeftovers } from '../State/leftovers';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm } = getState().screens.Leftovers;

  try {

    const params = {};

    params.include = [
      'from_order',
      'to_order',
      'material_type',
      'material',
      'material_range'
    ].join();

    params.append = [
      'measurement'
    ].join();
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (searchTerm) {
      params.search = searchTerm;
    }

    params.sort = '-id';

    dispatch(setFetching(true));

    const response = await getLeftovers({ params });
    const leftovers = response.data;

    dispatch(loadLeftovers(leftovers.data));
    
    dispatch(setPagination({
      total: leftovers.pagination.total,
      pageSize: leftovers.pagination.per_page,
      current: leftovers.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}