import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import MaterialRangeModal from '../MaterialRange'
import RangeVariantsModal from '../RangeVariants'

import Controls from './Components/Controls'
import Table from './Components/Table'

const Spacer = () => <div style={{ height: 12 }} />

const MaterialRanges = props => {

  const {
    manufacturer,
    materialRangeModal,
    rangeVariantsModal,
    componentDidMount,
    componentWillUnmount,
    onCloseMaterialRangeModal,
    onCloseRangeVariantsModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Modal
      title={manufacturer ? `${manufacturer.name} Ranges` : 'Ranges'}
      visible
      width={700}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >

      <Controls />
      
      <Spacer />

      <Table />

      {materialRangeModal.show && (
        <MaterialRangeModal
          {...materialRangeModal}
          onClose={onCloseMaterialRangeModal}
        />
      )}

      {rangeVariantsModal.show && (
        <RangeVariantsModal
          {...rangeVariantsModal}
          onClose={onCloseRangeVariantsModal}
        />
      )}

    </Modal>

  )

}

MaterialRanges.propTypes = {
  manufacturer: PropTypes.object,
  materialRangeModal: PropTypes.object.isRequired,
  rangeVariantsModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseMaterialRangeModal: PropTypes.func.isRequired,
  onCloseRangeVariantsModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

MaterialRanges.defaultProps = {
  manufacturer: null,
}

export default MaterialRanges
