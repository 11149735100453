import { connect } from "react-redux";

import Component from "./Component";

import onChangeFilter from "./Handlers/onChangeFilter";

const mapStateToProps = state => ({
  filters: state.screens.Orders.filters,
  users: state.core.users,
  onHoldReasons: state.core.onHoldReasons,
});

const actionCreators = {
  onChangeFilter
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
