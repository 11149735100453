import { openMaterialRangeModal, closeMaterialRangeModal } from '../State/materialRangeModal'
import fetch from '../Components/MaterialRanges/Services/fetch'

export default (reload = false, newMaterialRange = undefined) => (dispatch, getState) => {

  const { materialType } = getState().screens.Materials.MaterialRanges

  if (newMaterialRange) {
    dispatch(openMaterialRangeModal({ id: newMaterialRange.id, materialType }))
  } else {
    dispatch(closeMaterialRangeModal())
  }

  if (reload) {
    dispatch(fetch())
  }

}
