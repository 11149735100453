import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Radio, Select, Checkbox, Tag } from 'antd'
import { isEmpty } from 'lodash'
import HeightSpacer from '../../../HeightSpacer'
import styles from './styles.css'

const Label = props => <div className={styles.label}>{props.text}</div>

const validate = fields => {
  const errors = {}
  if (fields.type === 'cheat-sheet') {
    if (!fields.cheatSheetId) errors.cheatSheetId = true
    if (!fields.materialRangeId) errors.materialRangeId = true
    if (!fields.rangeVariantId) errors.rangeVariantId = true
  }
  if (fields.type === 'areas') {
    if (!fields.materialTypeId) errors.materialTypeId = true
  }
  if (fields.type === 'area-group') {
    if (!fields.areaGroupId) errors.areaGroupId = true
    if (!fields.materialRangeId) errors.materialRangeId = true
    if (!fields.rangeVariantId) errors.rangeVariantId = true
  }
  return errors
}

const SetupModal = props => {

  const {
    cheatSheets,
    materialTypes,
    areaGroups,
    areaGroup,
    materialRanges,
    rangeVariants,
    areas,
    fields,
    componentDidMount,
    componentWillUnmount,
    onChangeField,
    onSubmit,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  const [submitted, setSubmitted] = useState(false)

  const errors = validate(fields)

  return (

    <Modal
      title='Setup'
      visible
      width={360}
      okText='Generate'
      onOk={() => {
        setSubmitted(true)
        if (isEmpty(errors)) onSubmit()
      }}
      onCancel={onClose}
      maskClosable={false}
    >

      <Label text='Type' />
      <HeightSpacer type='label' />
      <Form.Item>
        <Radio.Group
          value={fields.type}
          onChange={e => {
            onChangeField('type', e.target.value)
            onChangeField('cheatSheetId', null)
            onChangeField('materialTypeId', null)
            onChangeField('areaGroupId', null)
            onChangeField('materialRangeId', null)
            onChangeField('rangeVariantId', null)
            onChangeField('areaIds', [])
            onChangeField('quoteAreas', false)
          }}
          style={{ width: '100%' }}
        >
          <Radio.Button value='cheat-sheet' className={styles.radioButton}>Cheat</Radio.Button>
          <Radio.Button value='areas' className={styles.radioButton}>Individual</Radio.Button>
          <Radio.Button value='area-group' className={styles.radioButton}>Throughout</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <HeightSpacer type='field' />

      {fields.type === 'cheat-sheet' && (

        <React.Fragment>

          <Label text='Sheet' />
          <HeightSpacer type='label' />
          <Form.Item
            validateStatus={(submitted && errors.cheatSheetId) ? 'error' : undefined}
          >
            <Select
              value={fields.cheatSheetId}
              onChange={value => {
                onChangeField('cheatSheetId', value)
                onChangeField('materialRangeId', null)
                onChangeField('rangeVariantId', null)
              }}
              allowClear
              showSearch
              optionFilterProp='children'
              dropdownMatchSelectWidth={false}
              style={{ width: '100%' }}
            >
              {cheatSheets.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

        </React.Fragment>

      )}

      {fields.type === 'areas' && (

        <React.Fragment>

          <Label text='Material' />
          <HeightSpacer type='label' />
          <Form.Item
            validateStatus={(submitted && errors.materialTypeId) ? 'error' : undefined}
          >
            <Select
              value={fields.materialTypeId}
              onChange={value => {
                onChangeField('materialTypeId', value)
                onChangeField('materialRangeId', null)
                onChangeField('rangeVariantId', null)
              }}
              allowClear
              showSearch
              optionFilterProp='children'
              style={{ width: '100%' }}
            >
              {materialTypes.map(materialType => (
                <Select.Option key={materialType.id} value={materialType.id}>
                  {materialType.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

        </React.Fragment>

      )}

      {fields.type === 'area-group' && (

        <React.Fragment>

          <Label text='Group' />
          <HeightSpacer type='label' />
          <Form.Item
            validateStatus={(submitted && errors.areaGroupId) ? 'error' : undefined}
          >
            <Select
              value={fields.areaGroupId}
              onChange={value => {
                onChangeField('areaGroupId', value)
                onChangeField('materialRangeId', null)
                onChangeField('rangeVariantId', null)
                onChangeField('quoteAreas', false)
              }}
              allowClear
              showSearch
              optionFilterProp='children'
              style={{ width: '100%' }}
            >
              {areaGroups.map($areaGroup => (
                <Select.Option key={$areaGroup.id} value={$areaGroup.id}>
                  {$areaGroup.name}
                  &nbsp;
                  {!!$areaGroup.is_custom && <Tag color='blue'>Custom</Tag>}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

        </React.Fragment>

      )}

      <HeightSpacer type='field' />

      <Label text='Range' />
      <HeightSpacer type='label' />
      <Form.Item
        validateStatus={(submitted && errors.materialRangeId) ? 'error' : undefined}
      >
        <Select
          value={fields.materialRangeId}
          onChange={value => {
            onChangeField('materialRangeId', value)
            onChangeField('rangeVariantId', null)
          }}
          allowClear
          showSearch
          optionFilterProp='children'
          style={{ width: '100%' }}
        >
          {materialRanges.map(materialRange => (
            <Select.Option key={materialRange.id} value={materialRange.id}>
              {materialRange.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <HeightSpacer type='field' />

      <Label text='Variant' />
      <HeightSpacer type='label' />
      <Form.Item
        validateStatus={(submitted && errors.rangeVariantId) ? 'error' : undefined}
      >
        <Select
          value={fields.rangeVariantId}
          onChange={value => onChangeField('rangeVariantId', value)}
          allowClear
          showSearch
          optionFilterProp='children'
          style={{ width: '100%' }}
        >
          {rangeVariants.map(rangeVariant => (
            <Select.Option key={rangeVariant.id} value={rangeVariant.id}>
              {rangeVariant.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {fields.type === 'areas' && (

        <React.Fragment>

          <HeightSpacer type='field' />

          <Label text='Areas' />
          <HeightSpacer type='label' />
          <div className={styles.areas}>
            <Checkbox.Group
              value={fields.areaIds}
              onChange={value => onChangeField('areaIds', value)}
              options={areas}
            />
          </div>

        </React.Fragment>

      )}

      {areaGroup && !areaGroup.is_custom && (

        <React.Fragment>

          <HeightSpacer type='field' />

          <Label text='Quote' />
          <HeightSpacer type='label' />
          <Checkbox
            value={fields.quoteAreas}
            onChange={e => onChangeField('quoteAreas', e.target.checked)}
          />
          &nbsp;
          {'Use room-by-room prices'}

        </React.Fragment>

      )}

    </Modal>

  )

}

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

SetupModal.propTypes = {
  cheatSheets: PropTypes.array.isRequired,
  materialTypes: PropTypes.array.isRequired,
  areaGroups: PropTypes.array.isRequired,
  areaGroup: PropTypes.object,
  materialRanges: PropTypes.array.isRequired,
  rangeVariants: PropTypes.array.isRequired,
  areas: PropTypes.array.isRequired,
  fields: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

SetupModal.defaultProps = {
  areaGroup: null,
}

export default SetupModal
