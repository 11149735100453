import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

const MaterialRange = props => {

  const {
    materialRange,
    submitting,
    componentDidMount,
    componentWillUnmount,
    onSubmit,
    onDelete,
    onArchive,
    onUnarchive,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  const footer = []

  if (materialRange) footer.push(
    <Button key='delete' type='danger' onClick={onDelete} style={{ float: 'left' }}>
      Delete
    </Button>,
  )

  if (materialRange && !materialRange.archived_at) footer.push(
    <Button key='archive' type='dashed' onClick={onArchive} style={{ float: 'left' }}>
      Archive
    </Button>,
  )

  if (materialRange && !!materialRange.archived_at) footer.push(
    <Button key='unarchive' type='dashed' onClick={onUnarchive} style={{ float: 'left' }}>
      Unarchive
    </Button>,
  )

  footer.push(
    <Button key='cancel' onClick={() => onClose()}>
      Cancel
    </Button>,
    <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
      {materialRange ? 'Update' : 'Create'}
    </Button>,
  )

  return (

    <Modal
      visible
      title={materialRange ? 'Range' : 'New Range'}
      width={500}
      onCancel={() => onClose()}
      maskClosable={false}
      footer={footer}
    >

      <Form />

    </Modal>

  )

}

MaterialRange.propTypes = {
  materialRange: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onUnarchive: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

MaterialRange.defaultProps = {
  materialRange: null,
}

export default MaterialRange
