import { message } from 'antd'
import { pick } from 'lodash'
import { updateInspectorVisit } from '../../../HttpRequests/inspectorVisits';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {
  
  try {

    const { id } = values

    const data = pick(values, [
      'inspector_id',
      'site_id',
      'plots',
      'date',
      'notes',
    ])

    await updateInspectorVisit({ id, data });
    
    message.success("Inspection Updated");

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}