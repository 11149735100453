import modalProps from '../modalProps'
import { resetControls } from '../State/controls'
import { resetMaterialRanges } from '../State/materialRanges'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetControls())
  dispatch(resetMaterialRanges())

}
