import { openDimensionsModal } from '../../../State/dimensionsModal'
import onSaveDimensions from './onSaveDimensions'
import onCloseDimensionsModal from './onCloseDimensionsModal'

export default (id, areaId, materialTypeId, dimensions) => (dispatch) => {

  dispatch(openDimensionsModal({
    id,
    areaId,
    materialTypeId,
    dimensions,
    onSave: onSaveDimensions,
    onClose: onCloseDimensionsModal,
  }))

}
