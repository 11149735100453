import { initialize } from 'redux-form'
import { setProps } from '../props'
import { FORM_NAME } from '../Form'

export default (props) => (dispatch) => {

  setProps(props)

  dispatch(initialize(FORM_NAME, {
    notes: props.notes,
  }))

}