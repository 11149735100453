import React from 'react'
import PropTypes from 'prop-types'
import { List, Avatar, Divider, Tooltip, Button, Input, Typography } from 'antd'
import { find, first, orderBy } from 'lodash'
import moment from 'moment'
import Panel from '../Panel'
import styles from './styles.css'

const getSentStatus = order => {
  const {
    questionnaire_sent_at: questionnaireSentAt,
    questionnaire_sent_by_user: questionnaireSentByUser,
  } = order
  if (!questionnaireSentAt) return 'Not Sent'
  return `Sent by ${questionnaireSentByUser ? questionnaireSentByUser.name : 'N/A'} ${moment(questionnaireSentAt).fromNow()}`
}

const selectRemedialQuestionnaire = order => first(orderBy(order.remedial_questionnaires, 'created_at', 'desc'))

const getReceivedStatus = order => {
  if (!order.questionnaire_received_at) return 'Awaiting Response'
  return `Answered ${moment(order.questionnaire_received_at).fromNow()}`
}

const parseContent = remedialQuestionnaire => {
  if (!remedialQuestionnaire) return []
  const { form_response: formResponse } = remedialQuestionnaire.data
  const { definition: { fields }, answers } = formResponse
  if (!fields) return []
  return fields.map((field, index) => {
    const { id, title } = field
    const answer = find(answers, row => row.field.id === id)
    return { key: index, question: title, answer: answer ? answer[answer.type] : null }
  })
}

export default class Questionnaire extends React.PureComponent {

  static propTypes = {
    order: PropTypes.object.isRequired,
    onChangeAction: PropTypes.func.isRequired,
  }

  state = {
    showActionInput: false,
  }

  render() {

    const {
      order,
      onChangeAction,
    } = this.props

    if (!order) return false

    const getActionedStatus = () => {
      if (!order.questionnaire_action) return (
        <div>
          {!this.state.showActionInput && (
            <Button
              type='danger'
              size='small'
              onClick={() => this.setState({ showActionInput: true })}
            >
              Awaiting Action
            </Button>
          )}
          {this.state.showActionInput && (
            <Input.TextArea
              rows={2}
              onBlur={e => {
                onChangeAction(e.target.value)
                this.setState({ showActionInput: false })
              }}
            />
          )}
        </div>
      )
      return (
        <Typography.Text
          editable={{ onChange: value => value !== order.questionnaire_action && onChangeAction(value) }}
        >
          {order.questionnaire_action}
        </Typography.Text>
      )
    }

    const remedialQuestionnaire = selectRemedialQuestionnaire(order)
  
    const uri = remedialQuestionnaire && `https://admin.typeform.com/form/${remedialQuestionnaire.data.form_response.form_id}/results#responses`

    return (

      <Panel title='Questionnaire'>

        <div className={styles.wrapper}>

          <div className={styles.info}>
            <div className={styles.label}>Order ID</div>
            <div className={styles.value}>
              <Tooltip title='This is the number used in Typeform for this order'>
                <b>{order.id}</b>
              </Tooltip>
            </div>
          </div>

          <div className={styles.info}>
            <div className={styles.label}>Customer Email</div>
            <div className={styles.value}>{order.customer_email}</div>
          </div>

          <div className={styles.info}>
            <div className={styles.label}>Customer Phone</div>
            <div className={styles.value}>{order.customer_phone_number}</div>
          </div>

          <div className={styles.info}>
            <div className={styles.label}>Sent</div>
            <div className={styles.value}>{getSentStatus(order)}</div>
          </div>

          {!!order.questionnaire_sent_at && (

            <React.Fragment>

              <div className={styles.info}>
                <div className={styles.label}>Received</div>
                <div className={styles.value}>{getReceivedStatus(order)}</div>
              </div>

              {remedialQuestionnaire && (

                <React.Fragment>

                  <div className={styles.info}>
                    <div className={styles.label}>Actioned</div>
                    <div className={styles.value}>{getActionedStatus(order)}</div>
                  </div>

                  <div className={styles.info}>
                    <div className={styles.label}>All Responses</div>
                    <div className={styles.value}><a href={uri} target='_blank' rel='noopener noreferrer'>{uri}</a></div>
                  </div>
                  
                  <Divider style={{ marginBottom: 5 }} />

                  <List
                    dataSource={parseContent(remedialQuestionnaire)}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar>{index + 1}</Avatar>}
                          title={item.question}
                          description={item.answer}
                        />
                      </List.Item>
                    )}
                  />

                </React.Fragment>

              )}

            </React.Fragment>
            
          )}

        </div>

      </Panel>

    )

  }

}
