export default (values) => () => {

  const errors = {}

  if (!values.title) errors.title = true

  if (!values.rate) errors.rate = true

  return errors

}
