import { message } from 'antd';

import { createInspector } from '../../../HttpRequests/inspectors';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {

  try {

    const response = await createInspector({ data });

    message.success('Inspector Created');

    return response.data.data;
    
  }  catch(error) {
    
    handleException(error);

    return false;

  }
}
