import { connect } from 'react-redux'
import Custom from './Custom'

import onEditField from './Handlers/onEditField'
import onClickAction from './Handlers/onClickAction'
import onClickSupplySheetNotes from './Handlers/onClickSupplySheetNotes'

const mapStateToProps = (state) => ({
})

const actionCreators = {
  onEditField,
  onClickAction,
  onClickSupplySheetNotes,
}

export default connect(mapStateToProps, actionCreators)(Custom)
