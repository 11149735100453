import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Section.css'

const Section = (props) => {

  const {
    className,
    style,
    headerClassName,
    headerStyle,
    title,
    titleClassName,
    titleStyle,
    renderHeaderLeft,
    renderHeaderRight,
    contentClassName,
    contentStyle,
    children,
    borderTop,
    borderBottom,
  } = props
  
  return (

    <div className={classNames(styles.section, {[styles.borderTop]: borderTop, [styles.borderBottom]: borderBottom}, className)} style={style}>

      {(title || renderHeaderLeft || renderHeaderRight) && (

        <div className={classNames(styles.header, headerClassName)} style={headerStyle}>

          {title && (
            <div className={classNames(styles.title, titleClassName)} style={titleStyle}>
              {title}
            </div>
          )}

          {renderHeaderLeft && (
            <div className={styles.headerLeft}>
              {renderHeaderLeft()}
            </div>
          )}

          {renderHeaderRight && (
            <div className={styles.headerRight}>
              {renderHeaderRight()}
            </div>
          )}
        
        </div>

      )}

      <div className={classNames(styles.content, contentClassName)} style={contentStyle}>

        {typeof children === 'function' && children()}

        {typeof children === 'object' && children}

      </div>

    </div>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  headerClassName: PropTypes.string,
  headerStyle: PropTypes.object,
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  titleClassName: PropTypes.string,
  titleStyle: PropTypes.object,
  renderHeaderLeft: PropTypes.func,
  renderHeaderRight: PropTypes.func,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.object,
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]),
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
}

Section.defaultProps = {
  className: undefined,
  style: undefined,
  headerClassName: undefined,
  headerStyle: undefined,
  title: undefined,
  titleClassName: undefined,
  titleStyle: undefined,
  renderHeaderLeft: undefined,
  renderHeaderRight: undefined,
  contentClassName: undefined,
  contentStyle: undefined,
  children: undefined,
  borderTop: false,
  borderBottom: false,
}

export default Section