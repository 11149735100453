export default (values) => () => {

  const errors = {}

  if (!values.name) errors.name = 'Name is required'

  if (!values.type) errors.type = 'Type is required'
  
  return errors

}
