import { createSelector } from 'reselect'
import selectMaterialRanges from '../../../../../Selectors/selectMaterialRanges'

const selectFields = state => state.screens.CostSheet.Areas.AddModal.fields

export default createSelector(
  selectMaterialRanges,
  selectFields,
  (materialRanges, fields) => {

    if (!fields.materialTypeId) return []

    return materialRanges.filter(row => row.material_type_id === fields.materialTypeId)

  },
)
