import { combineReducers } from 'redux'

import { reducer as areaGroups } from './State/areaGroups'
import { reducer as isFetching } from './State/isFetching'
import { reducer as selectedRows } from './State/selectedRows'

export default combineReducers({
  areaGroups,
  isFetching,
  selectedRows,
})
