import { setAreaGroups } from '../State/areaGroups'
import saveAreaMaterialType from '../../../Services/saveAreaMaterialType'

export default (id, column, value) => (dispatch, getState) => {
  
  const { areaGroups: prevAreaGroups } = getState().screens.PlotType.AreaGroups

  const newAreaGroups = prevAreaGroups.map(prevAreaGroup => {

    const newAreaGroup = { ...prevAreaGroup }

    newAreaGroup.area_material_types = prevAreaGroup.area_material_types.map(prevAreaMaterialType => {

      if (prevAreaMaterialType.id !== id) return prevAreaMaterialType

      const newAreaMaterialType = { ...prevAreaMaterialType }

      if (column === 'notes') {
        newAreaMaterialType.notes = value
      }

      return newAreaMaterialType

    })

    return newAreaGroup

  })

  dispatch(setAreaGroups(newAreaGroups))

  dispatch(saveAreaMaterialType(id, { [column]: value }))

}
