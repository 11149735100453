import { connect } from 'react-redux'
import Placeholder from './Placeholder'

import onClickGenerate from './Handlers/onClickGenerate'

const mapStateToProps = (state) => ({
  editing: state.screens.CostSheet.editing,
})

const actionCreators = {
  onClickGenerate,
}

export default connect(mapStateToProps, actionCreators)(Placeholder)
