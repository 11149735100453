import { connect } from 'react-redux'
import BuilderRate from './BuilderRate'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'
import onAddMaterialRange from './Handlers/onAddMaterialRange'
import onCloseAddMaterialRangeModal from './Handlers/onCloseAddMaterialRangeModal'
import onCloseBuilderRateRangeVariantsModal from './Handlers/onCloseBuilderRateRangeVariantsModal'

const mapStateToProps = (state, props) => ({
  isNew: !props.id,
  builderRate: state.modals.BuilderRate.builderRate,
  addMaterialRangeModal: state.modals.BuilderRate.addMaterialRangeModal,
  builderRateRangeVariantsModal: state.modals.BuilderRate.builderRateRangeVariantsModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onSubmit,
  onDelete,
  onAddMaterialRange,
  onCloseAddMaterialRangeModal,
  onCloseBuilderRateRangeVariantsModal,
}

export default connect(mapStateToProps, actionCreators)(BuilderRate)
