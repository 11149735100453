import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Detail.css'

const Detail = (props) => {

  const {
    horizontal,
    label,
    labelWidth,
    children,
    className,
    style,
    labelClassName,
    labelStyle,
    contentClassName,
    contentStyle,
  } = props

  return (

    <div
      className={classNames(styles[horizontal ? 'horizontal' : 'vertical'], className)}
      style={style}
    >

      <div
        className={classNames(styles.label, labelClassName)}
        style={{ width: (horizontal ? labelWidth : undefined), ...labelStyle }}
      >
        {label}
      </div>

      <div
        className={classNames(styles.content, contentClassName)}
        style={contentStyle}
      >
        {children}
      </div>

    </div>

  )
}

Detail.propTypes = {
  horizontal: PropTypes.bool,
  label: PropTypes.node,
  labelWidth: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  labelClassName: PropTypes.string,
  labelStyle: PropTypes.object,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.object,
}

Detail.defaultProps = {
  horizontal: false,
  label: undefined,
  labelWidth: 80,
  children: undefined,
  className: undefined,
  style: undefined,
  labelClassName: undefined,
  labelStyle: undefined,
  contentClassName: undefined,
  contentStyle: undefined,
}

export default Detail