import { createAction, handleActions } from 'redux-actions'

const setOriginal = createAction('SCREENS/COST_SHEET/SET_ORIGINAL')
const resetOriginal = createAction('SCREENS/COST_SHEET/RESET_ORIGINAL')

const defaultState = null

const reducer = handleActions(
  {
    [setOriginal]: (state, { payload }) => payload,
    [resetOriginal]: () => defaultState,
  },
  defaultState,
)

export { setOriginal, resetOriginal, reducer }
