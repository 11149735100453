import { message } from 'antd';

import { updateBuilder } from '../../../HttpRequests/builders';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {

  const data = {
    name: values.name,
    protection: values.protection,
  }
  
  try {

    const response = await updateBuilder({ id: values.id, data });
    
    message.success("Builder Updated");

    return response.data.data;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}