import * as React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import moment from 'moment'
import classnames from 'classnames'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    tags: PropTypes.array.isRequired,
    tableRows: PropTypes.array.isRequired,
  }

  render() {

    const {
      tags,
      tableRows,
    } = this.props

    const columns = [
      {
        key: 'date',
        title: 'Date',
        width: 200,
        fixed: 'left',
        render: (text, record) => record.type === 'day' ? moment(record.date).format('ddd Do MMM YYYY') : 'Totals',
      },
      {
        key: 'jobs',
        title: 'Jobs',
        width: 200,
        fixed: 'left',
        className: styles.groupHeader,
        children: [
          {
            key: 'jobs_total',
            title: 'Total',
            width: 100,
            className: styles.numericColumn,
            onCell: () => ({
              className: classnames(styles.numericColumn, styles.boldColumn),
            }),
            render: (text, record) => record.jobs_total,
          },
          {
            key: 'jobs_started',
            title: 'Started',
            width: 100,
            className: styles.numericColumn,
            render: (text, record) => record.jobs_started,
          },
        ],
      },
    ]

    tags.forEach((tag) => {
      columns.push({
        key: tag,
        title: tag,
        width: 200,
        className: styles.groupHeader,
        children: [
          {
            key: `${tag}-total`,
            title: 'Total',
            width: 100,
            className: styles.numericColumn,
            onCell: () => ({
              className: classnames(styles.numericColumn, styles.boldColumn),
            }),
            render: (text, record) => record.job_stats_by_tag[tag].jobs_total,
          },
          {
            key: `${tag}-started`,
            title: 'Started',
            width: 100,
            className: styles.numericColumn,
            render: (text, record) => record.job_stats_by_tag[tag].jobs_started,
          },
        ],
      })
    })

    return (

      <div className={styles.wrapper}>

        <Table
          dataSource={tableRows}
          columns={columns}
          rowKey='key'
          pagination={false}
          bordered
          scroll={{
            x: columns.reduce((total, column) => total + column.width, 0),
            y: 625,
          }}
        />

      </div>

    )

  }

}
