import { createAction, handleActions } from 'redux-actions'

const setAreaGroups = createAction('SCREENS/PLOT_TYPE/AREA_GROUPS/SET_AREA_GROUPS')
const resetAreaGroups = createAction('SCREENS/PLOT_TYPE/AREA_GROUPS/RESET_AREA_GROUPS')

const defaultState = []

const reducer = handleActions(
  {
    [setAreaGroups]: (state, action) => action.payload,
    [resetAreaGroups]: () => defaultState,
  },
  defaultState,
)

export { setAreaGroups, resetAreaGroups, reducer }
