import modalProps from '../modalProps'
import fetchBuilders from '../../../Core/Services/fetchBuilders'
import fetchMaterialTypes from '../../../Core/Services/fetchMaterialTypes'
import fetchMaterialRanges from '../../../Core/Services/fetchMaterialRanges'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchBuilders())
  dispatch(fetchMaterialTypes())
  dispatch(fetchMaterialRanges())
  
  dispatch(initializeForm())
  
}
