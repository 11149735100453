import updateItem from '../Services/updateItem'
import { closeDimensionsModal } from '../State/dimensionsModal'

export default (dimensions) => (dispatch, getState) => {

  if (dimensions !== undefined) {

    const { guid } = getState().screens.CostSheet.Materials.dimensionsModal

    dispatch(updateItem(guid, 'dimensions', dimensions))

  }

  dispatch(closeDimensionsModal())

}
