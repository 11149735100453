import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Select, InputNumber, Checkbox, Divider } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      initialValues,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    const options = [
      { label: 'Length X Width', value: 'dimensions' },
      { label: 'M2', value: 'square-metres' },
      { label: 'Each', value: 'item' },
    ]

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >

        {({ handleSubmit, values, form }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name='name'>
              {({ input, meta }) => (
                <Form.Item
                  label='Name'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='usage_key'>
              {({ input, meta }) => (
                <Form.Item
                  label='Unit'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    {options.map(({ label, value }) => (
                      <Select.Option key={value} value={value}>{label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='is_flooring' type='checkbox'>
              {({ input }) => (
                <Form.Item
                  label='Flooring'
                >
                  <Checkbox
                    {...input}
                    onChange={value => {
                      input.onChange(value)
                      form.change('is_quotable', false)
                      form.change('vat_perc', null)
                      form.change('rebate_rate', null)
                    }}
                  />
                </Form.Item>
              )}
            </Field>

            {!values.is_flooring && (

              <Field name='is_quotable' type='checkbox'>
                {({ input }) => (
                  <Form.Item
                    label='Quotable'
                  >
                    <Checkbox
                      {...input}
                    />
                  </Form.Item>
                )}
              </Field>

            )}

            {!!values.is_flooring && (

              <React.Fragment>

                <Field name='vat_perc'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='VAT'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='rebate_rate'>
                  {({ input }) => (
                    <Form.Item
                      label='Rebate'
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Divider />

                <Field name='anc_coverage'>
                  {({ input }) => (
                    <Form.Item
                      label='Anc Coverage'
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='anc_rate'>
                  {({ input }) => (
                    <Form.Item
                      label='Anc Rate'
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='anc_min_amount'>
                  {({ input }) => (
                    <Form.Item
                      label='Anc Min'
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </React.Fragment>

            )}

          </Form>

        )}

      </FormProvider>

    )

  }

}
