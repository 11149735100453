import { getAreas } from '../../../../../HttpRequests/areas'
import { startFetching, finishFetching } from '../State/isFetching'
import { setAreas } from '../State/areas'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { plotType: { id: plotTypeId } } = getState().screens.PlotType

  const params = {
    'filter[plot_type_id]': plotTypeId,
    sort: 'order_no',
    include: 'area_material_types,area_site_rates',
  }

  dispatch(startFetching())

  try {

    const response = await getAreas({ params })

    dispatch(setAreas(response.data.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
