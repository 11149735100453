import React from 'react'
import PropTypes from 'prop-types'
import DefaultSplashscreen from './Splashscreen'

export default class App extends React.PureComponent {

  static propTypes = {
    children: PropTypes.node.isRequired,
    Splashscreen: PropTypes.func,
    showApp: PropTypes.bool,
    showSplashscreen: PropTypes.bool,
  }
  

  static defaultProps = {
    Splashscreen: DefaultSplashscreen,
    showApp: true,
    showSplashscreen: false,
  }

  render () {

    const {
      children,
      Splashscreen,
      showApp,
      showSplashscreen,
    } = this.props

    return (

      <React.Fragment>

        {showApp && children}

        {(Splashscreen && showSplashscreen) && <Splashscreen />}

      </React.Fragment>

    )
  }
}