import { combineReducers } from 'redux';

import { reducer as activeTab } from './State/activeTab';
import { reducer as orderSearchTerm } from './State/orderSearchTerm';
import { reducer as leftovers } from './State/leftovers';
import { reducer as openedFitterInvoiceId } from './State/openedFitterInvoiceId';
import { reducer as openedJobId } from './State/openedJobId';
import { reducer as openedLeftoverId } from './State/openedLeftoverId';
import { reducer as order } from './State/order';
import { reducer as showFitterInvoiceModal } from './State/showFitterInvoiceModal';
import { reducer as showJobModal } from './State/showJobModal';
import { reducer as showLeftoverModal } from './State/showLeftoverModal';
import { reducer as showOrderModal } from './State/showOrderModal';
import { reducer as editFileModal } from './State/editFileModal';
import { reducer as showDropzone } from './State/showDropzone';
import { reducer as areaSelectorModal } from './State/areaSelectorModal';
import { reducer as dimensionsModal } from './State/dimensionsModal';

import PlotNotes from './Components/PlotNotes/reducer'
import OrderNotes from './Components/OrderNotes/reducer'

export default combineReducers({
  activeTab,
  orderSearchTerm,
  leftovers,
  openedFitterInvoiceId,
  openedJobId,
  openedLeftoverId,
  order,
  showFitterInvoiceModal,
  showJobModal,
  showLeftoverModal,
  showOrderModal,
  editFileModal,
  showDropzone,
  areaSelectorModal,
  dimensionsModal,

  PlotNotes,
  OrderNotes,
})
