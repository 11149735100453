import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './Component.css';

import Table from './Components/Table';
import EditFile from '../../../../Modals/EditFile';

export default class _Files_ extends React.PureComponent {

  static propTypes = {
    orderId: PropTypes.number,
    editFileModal: PropTypes.object.isRequired,
    onCloseEditFileModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    orderId: null,
  }

  render () {

    const {
      orderId,
      editFileModal,
      onCloseEditFileModal,
    } = this.props;
    
    return (

      <div>
        
        <br />

        <Table />
        
        {editFileModal.show && (
          <EditFile
            orderId={orderId}
            onClose={onCloseEditFileModal}
          />
        )}

      </div>
      
    )
  }
}
