import { openBuilderRateModal, closeBuilderRateModal } from '../State/builderRateModal'
import fetch from '../Components/BuilderRates/Services/fetch'

export default (reload = false, builderRate = undefined) => (dispatch) => {

  if (builderRate) {
    dispatch(openBuilderRateModal({ id: builderRate.id }))
  } else {
    dispatch(closeBuilderRateModal())
  }

  if (reload) {
    dispatch(fetch())
  }

}
