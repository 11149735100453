import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from "./Screens/Home";
import Logout from './Screens/Logout';

import Board from './Screens/Board';

import Builders from './Screens/Builders';
import BuilderView from './Screens/BuilderView'
import SiteView from './Screens/SiteView';
import PlotType from './Screens/PlotType';

import Materials from './Screens/Materials';

import Suppliers from './Screens/Suppliers';

import Orders from './Screens/_Orders';
import OrderView from './Screens/OrderView';
import CostSheet from './Screens/CostSheet';

import Fitters from './Screens/Fitters';
import Leftovers from './Screens/Leftovers';

export default () => (
  <Switch>

    {/* Home */}
    <Route path="/portal/" exact component={Home} />
    <Route path="/portal/logout" exact component={Logout} />

    {/* Router for Board */}
    <Route path="/portal/board" exact component={Board} />

    {/* Router for Builders */}
    <Route path="/portal/builders" exact component={Builders} />
    <Route path="/portal/builders/:id" exact component={BuilderView} />
    <Route path="/portal/builders/:builderId/sites/:id" exact component={SiteView} />
    <Route path="/portal/builders/:builderId/sites/:siteId/plot_types/:id" exact component={PlotType} />

    {/* Router for Suppliers */}
    <Route path="/portal/suppliers" exact component={Suppliers} />

    {/* Router for Orders */}
    <Route path="/portal/orders" exact component={Orders} />
    <Route path="/portal/orders/:id" exact component={OrderView} />
    <Route path="/portal/cost_sheets/:id" exact component={CostSheet} />

    {/* Router for Materials */}
    <Route path="/portal/materials" exact component={Materials} />

    {/* Leftover */}
    <Route path="/portal/leftovers" exact component={Leftovers} />

    {/* Fitters */}
    <Route path="/portal/fitters" exact component={Fitters} />

  </Switch>
);