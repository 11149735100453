import { push } from 'connected-react-router';
import { getProps } from '../../../props';

export default (plotTypeId) => (dispatch) => {
    
  const { builderId, id: siteId } = getProps().match.params;
  
  dispatch(push(`/portal/builders/${builderId}/sites/${siteId}/plot_types/${plotTypeId}`));

}
