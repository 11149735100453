import { connect } from 'react-redux'
import Areas from './Areas'

import selectTableRows from './Selectors/selectTableRows'
import selectTotals from './Selectors/selectTotals'

import onEdit from './Handlers/onEdit'
import onClickDimensions from './Handlers/onClickDimensions'
import onToggleSiteRate from './Handlers/onToggleSiteRate'
import onChangeSelectedRows from './Handlers/onChangeSelectedRows'
import onSort from './Handlers/onSort'

const mapStateToProps = (state) => ({
  materialTypes: state.core.materialTypes.filter(materialType => !!materialType.is_flooring),
  siteRates: state.screens.PlotType.plotType.site.site_rates.filter(siteRate => siteRate.material_type.usage_key === 'square-metres'),
  tableRows: selectTableRows(state),
  isFetching: state.screens.PlotType.Areas.isFetching,
  selectedRows: state.screens.PlotType.Areas.selectedRows,
  totals: selectTotals(state),
})

const actionCreators = {
  onEdit,
  onClickDimensions,
  onToggleSiteRate,
  onChangeSelectedRows,
  onSort,
}

export default connect(mapStateToProps, actionCreators)(Areas)
