import { connect } from 'react-redux'
import Controls from './Controls'

import onChangeControl from './Handlers/onChangeControl'
import onSearch from './Handlers/onSearch'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = (state) => ({
  controls: state.modals.Manufacturers.controls,
})

const actionCreators = {
  onChangeControl,
  onSearch,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(Controls)
