import { find, get, reduce } from 'lodash'
import selectAreaSummary from '../../../../../Selectors/selectAreaSummary'
import { setCostSheetField } from '../../../../../State/order'
import addItem from './addItem'

const getFittingRate = (fittingRates, order) => {

  let $fittingRates = [...fittingRates]

  // Can we narrow down by whether it's a remedial or not?
  if ($fittingRates.length > 1) {
    $fittingRates = $fittingRates.filter(row => row.remedial === order.remedial)
  }

  // No? Then can we narrow down by matching order category ID?
  if ($fittingRates.length > 1) {
    $fittingRates = $fittingRates.filter(row => row.order_category_id === order.order_category_id)
  }

  if ($fittingRates.length !== 1) return null

  return $fittingRates[0]

}

export default () => (dispatch, getState) => {

  const state = getState()

  const { fittingRates } = state.core

  const { order } = state.screens.CostSheet
  
  const { materialTypes, rangeVariants } = selectAreaSummary(state)

  dispatch(setCostSheetField({ field: 'travel_cost', value: get(order, 'site.travel_rate', null) }))

  materialTypes.forEach(({ materialTypeId, squareMetres }) => {

    // Since this data is coming from the area summary, each material type should
    // be flooring, meaning its usage key will either be dimensions or square
    // metres. In which case, it's safe to assume that we'll be multiplying the
    // fitting rate by the given square metres value (i.e. no 'item' types)

    const $fittingRates = fittingRates.filter(row => {
      if (row.material_type_id !== materialTypeId) return false
      if (row.material_types.length > 0) return false
      if (row.material_ranges.length > 0) return false
      return true
    })

    const fittingRate = getFittingRate($fittingRates, order)

    if (!fittingRate) return

    dispatch(addItem({
      fitting_rate_id: fittingRate.id,
      cost: squareMetres * fittingRate.rate,
      fitting_rate: fittingRate,
    }))
    
  })

  fittingRates.forEach(fittingRate => {

    const materialRangeIds = fittingRate.material_ranges.map(row => row.id)

    const $rangeVariants = rangeVariants.filter(({ materialRangeId }) => materialRangeIds.includes(materialRangeId))

    if ($rangeVariants.length === 0) return

    const squareMetres = reduce($rangeVariants, (sum, row) => sum + (row.squareMetres || 0), 0)

    dispatch(addItem({
      fitting_rate_id: fittingRate.id,
      cost: squareMetres * fittingRate.rate,
      fitting_rate: fittingRate,
    }))

  })

  fittingRates.forEach(fittingRate => {

    // We want these to appear underneath the fitting rates for flooring

    const materialTypeIds = fittingRate.material_types.map(row => row.id)

    const $materialTypes = materialTypes.filter(({ materialTypeId }) => materialTypeIds.includes(materialTypeId))

    if ($materialTypes.length === 0) return

    const squareMetres = reduce($materialTypes, (sum, row) => sum + (row.squareMetres || 0), 0)

    if (fittingRate.material_type.usage_key === 'dimensions' || fittingRate.material_type.usage_key === 'square-metres') {
      dispatch(addItem({
        fitting_rate_id: fittingRate.id,
        cost: (squareMetres * fittingRate.tar_value) * fittingRate.rate,
        fitting_rate: fittingRate,
      }))
    }

    if (fittingRate.material_type.usage_key === 'item') {
      dispatch(addItem({
        fitting_rate_id: fittingRate.id,
        cost: Math.ceil(squareMetres / fittingRate.tar_value) * fittingRate.rate,
        fitting_rate: fittingRate,
      }))
    }

  })

  /* ---------- Hacky ---------- */

  // Town house stair charge

  const formats = [
    'townhouse',
    'town-house',
    'town house',
    'th',
    't/h',
  ]

  if (order.cost_sheet.material_type_id === 1 && formats.includes(order.plot_type.format.trim().toLowerCase())) {

    const fittingRate = find(fittingRates, row => row.title === 'Town House Stair Charge')

    if (fittingRate) {
      dispatch(addItem({
        fitting_rate_id: fittingRate.id,
        cost: fittingRate.rate,
        fitting_rate: fittingRate,
      }))
    }

  }

  // Ply

  const quantity = order.cost_sheet.cost_sheet_materials.reduce((carry, costSheetMaterial) => {
    if (!costSheetMaterial.material_range) return carry
    if (!costSheetMaterial.material_range.name.includes('Ply')) return carry
    return carry + (costSheetMaterial.quantity || 0)
  }, 0)

  if (quantity) {

    const fittingRate = find(fittingRates, row => row.title === 'Ply')

    if (fittingRate) {
      dispatch(addItem({
        fitting_rate_id: fittingRate.id,
        cost: fittingRate.rate * quantity,
        fitting_rate: fittingRate,
      }))
    }
    
  }

}
