import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { manufacturer } = modalProps()

  if (!manufacturer) return

  const initialValues = {
    ...manufacturer,
  }

  dispatch(setFormInitialValues(initialValues))

}
