import { createAction, handleActions } from 'redux-actions';

const setShowInspectorVisitModal = createAction("SCREENS/BOARD/SET_SHOW_INSPECTOR_VISIT_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowInspectorVisitModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowInspectorVisitModal, reducer };
