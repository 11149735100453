import { closeManufacturerModal } from '../State/manufacturerModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeManufacturerModal())

  if (reload) dispatch(fetch())

}
