import { createAction, handleActions } from 'redux-actions';

const loadBuilders = createAction("CORE/BUILDERS/LOAD_BUILDERS");
const clearBuilders = createAction("CORE/BUILDERS/CLEAR_BUILDERS");

const defaultState = []

const reducer = handleActions(
  {
    [loadBuilders]: (state, action) => action.payload,
    [clearBuilders]: () => []
  },
  defaultState
);

export { loadBuilders, clearBuilders, reducer };