import { combineReducers } from 'redux';

import { reducer as fromOrderOpen } from './State/fromOrderOpen';
import { reducer as fromOrderSearchTerm } from './State/fromOrderSearchTerm';
import { reducer as toOrderOpen } from './State/toOrderOpen';
import { reducer as toOrderSearchTerm } from './State/toOrderSearchTerm';

export default combineReducers({
  fromOrderOpen,
  fromOrderSearchTerm,
  toOrderOpen,
  toOrderSearchTerm
})
