import { closeSiteRateRangeVariantsModal } from '../State/siteRateRangeVariantsModal'
import fetch from '../Services/fetch'

export default () => (dispatch) => {

  dispatch(closeSiteRateRangeVariantsModal())

  dispatch(fetch())

}
