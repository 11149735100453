import { createAction, handleActions } from 'redux-actions';

const loadSite = createAction("SCREENS/SITE_VIEW/LOAD_SITE");
const clearSite = createAction("SCREENS/SITE_VIEW/CLEAR_SITE");

const defaultState = null;

const reducer = handleActions(
  {
    [loadSite]: (state, action) => action.payload,
    [clearSite]: () => defaultState
  },
  defaultState
);

export { loadSite, clearSite, reducer };