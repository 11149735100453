import { connect } from 'react-redux'

import Component from './Component'

const mapStateToProps = (state) => ({
  orderFileTypes: state.core.orderFileTypes
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Component)