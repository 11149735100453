import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import styles from './styles.css'

export default class extends React.PureComponent {
  
  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClickRow: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
      onChange,
      onClickRow,
    } = this.props

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        onChange={onChange}
        className={styles.table}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id),
        })}
      >

        <Table.Column
          key='name'
          title='Name'
          sorter
          render={(text, record) => record.name}
        />

      </Table>

    )

  }

}
