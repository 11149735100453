import { submit } from 'redux-form';
import { FORM_NAME } from '../../../Form';
import { setShouldCreateAnother } from '../../../State/shouldCreateAnother'

export default (shouldCreateAnother = false) => (dispatch) => {
  
  dispatch(setShouldCreateAnother(shouldCreateAnother))

  dispatch(submit(FORM_NAME))

}