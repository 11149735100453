import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'

export default () => () => {

  const { orderNote } = modalProps()

  if (!orderNote) return

  const initialValues = {
    note: orderNote.note,
  }

  defer(() => formService().initialize(initialValues))

}
