import { defer } from 'lodash'
import formService from '../formService'

export default () => (dispatch, getState) => {

  const { areaGroup } = getState().modals.AreaGroup

  let initialValues

  if (areaGroup) {
    initialValues = {
      name: areaGroup.name,
      material_type_id: areaGroup.material_type_id,
      is_custom: !!areaGroup.is_custom,
    }
  } else {
    initialValues = {
      is_custom: false,
    }
  }

  defer(() => formService().initialize(initialValues))

}
