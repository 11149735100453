import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'antd'
import styles from './Empty.css'

const Empty = (props) => {

  const {
    className,
    style,
    icon,
    title,
    text,
  } = props
  
  return (

    <div className={classNames(styles.wrapper, className)} {...{ style }}>

      <span className={styles.icon}>

        {typeof icon === 'string' && <Icon type={icon} />}

        {typeof icon === 'object' && icon}

      </span>

      <div className={styles.title}>
        {title}
      </div>

      <div className={styles.text}>
        {text}
      </div>

    </div>
  )
}

Empty.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
}

Empty.defaultProps = {
  className: undefined,
  style: undefined,
  icon: 'inbox',
  title: undefined,
  text: undefined,
}

export default Empty