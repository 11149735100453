import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { CascaderField, InputNumberField, SelectField } from 'redux-form-antd-fields';

const labelColSpan = 7;
const wrapperColSpan = 15;
const numberWrapperColSpan = 7;

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    fromOrderOptions: PropTypes.array.isRequired,
    toOrderOptions: PropTypes.array.isRequired,
    materialOptions: PropTypes.array.isRequired,
    materialUnit: PropTypes.object,
    onSearchFromOrder: PropTypes.func.isRequired,
    onSearchToOrder: PropTypes.func.isRequired,
    onToggleFromOrder: PropTypes.func.isRequired,
    onToggleToOrder: PropTypes.func.isRequired
  }

  static defaultProps = {
    materialUnit: null
  }

  render () {

    const {
      submitting,
      materialOptions,
      materialUnit,
      fromOrderOptions,
      toOrderOptions,
      onSearchFromOrder,
      onSearchToOrder,
      onToggleFromOrder,
      onToggleToOrder
    } = this.props;

    return (
      
      <React.Fragment>

        <Field
          name="from_order_id"
          placeholder="Enter Order No"
          label="From Order"
          disabled={submitting}
          options={fromOrderOptions}
          component={SelectField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
          onSearch={onSearchFromOrder}
          onDropdownVisibleChange={onToggleFromOrder}
        />

        <Field
          name="material"
          label="Material"
          component={CascaderField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          options={materialOptions}
          displayRender={labels => labels.join(' / ')}
          style={{ width: '100%' }}
          required
        />

        {(materialUnit && Array.isArray(materialUnit.required)) && (

          <React.Fragment>

            {(materialUnit.required.includes('length_in_metres')) && (
              <Field
                name="length_in_metres"
                placeholder="0.00"
                label="Length in Metres"
                disabled={submitting}
                formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
                component={InputNumberField}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: numberWrapperColSpan }}
                required
              />
            )}

            {(materialUnit.required.includes('width_in_metres')) && (
              <Field
                name="width_in_metres"
                placeholder="0.00"
                label="Width in Metres"
                disabled={submitting}
                formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
                component={InputNumberField}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: numberWrapperColSpan }}
                required
              />
            )}
            
            {(materialUnit.required.includes('square_metres')) && (
              <Field
                name="square_metres"
                placeholder="0.00"
                label="Square Metres"
                disabled={submitting}
                formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
                component={InputNumberField}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: numberWrapperColSpan }}
                required
              />
            )}

            {(materialUnit.required.includes('linear_metres')) && (
              <Field
                name="linear_metres"
                placeholder="0.00"
                label="Linear Metres"
                disabled={submitting}
                formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
                component={InputNumberField}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: numberWrapperColSpan }}
                required
              />
            )}

            {(materialUnit.required.includes('weight_in_kilograms')) && (
              <Field
                name="weight_in_kilograms"
                placeholder="0.00"
                label="Weight in Kilograms"
                disabled={submitting}
                formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
                component={InputNumberField}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: numberWrapperColSpan }}
                required
              />
            )}

            {(materialUnit.required.includes('volume_in_litres')) && (
              <Field
                name="volume_in_litres"
                placeholder="0.00"
                label="Volume in Litres"
                disabled={submitting}
                formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
                component={InputNumberField}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: numberWrapperColSpan }}
                required
              />
            )}

          </React.Fragment>

        )}

        <br />

        <Field
          name="to_order_id"
          placeholder="Enter Order No"
          label="Allocate to Order"
          disabled={submitting}
          options={toOrderOptions}
          component={SelectField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          onSearch={onSearchToOrder}
          onDropdownVisibleChange={onToggleToOrder}
        />

      </React.Fragment>

    )
  }
}