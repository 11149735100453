import * as React from "react";
import PropTypes from "prop-types";
import { Affix, Input, Button, Icon, Select, Dropdown, Menu } from 'antd';
import { startCase } from 'lodash';
import { Screen } from "evolve-portal";
import styles from './styles.css'

import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import PlotTypes from "./Components/PlotTypes";
import SiteRates from "./Components/SiteRates";
import SiteMaterialRanges from "./Components/SiteMaterialRanges";
import CostMatrix from "./Components/CostMatrix";

import SiteModal from "../../Modals/Site";
import PlotTypeModal from "../../Modals/PlotType";
import SiteRate from "../../Modals/SiteRate";
import SiteMaterialRange from "../../Modals/SiteMaterialRange";
import AreaRangeQuote from "../../Modals/AreaRangeQuote";

const getFormats = site => {
  const formats = []
  site.plot_types.forEach(plotType => {
    if (!plotType.format) return
    const format = startCase(plotType.format)
    if (formats.includes(format)) return
    formats.push(format)
  })
  return formats
}

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    site: PropTypes.object,
    activeTab: PropTypes.string.isRequired,
    showSiteModal: PropTypes.bool.isRequired,
    showPlotTypeModal: PropTypes.bool.isRequired,
    siteRateModal: PropTypes.object.isRequired,
    siteMaterialRangeModal: PropTypes.object.isRequired,
    areaRangeQuoteModal: PropTypes.object.isRequired,
    costMatrixSearch: PropTypes.string.isRequired,
    costMatrixFormat: PropTypes.string.isRequired,
    onCloseSiteModal: PropTypes.func.isRequired,
    onClosePlotTypeModal: PropTypes.func.isRequired,
    didUpdateSite: PropTypes.func.isRequired,
    didDeleteSite: PropTypes.func.isRequired,
    didCreatePlotType: PropTypes.func.isRequired,
    onCloseSiteRateModal: PropTypes.func.isRequired,
    onCloseSiteMaterialRangeModal: PropTypes.func.isRequired,
    onCloseAreaRangeQuoteModal: PropTypes.func.isRequired,
    onChangeCostMatrixSearch: PropTypes.func.isRequired,
    onSearchCostMatrix: PropTypes.func.isRequired,
    onChangeCostMatrixFormat: PropTypes.func.isRequired,
    onClickRegenerate: PropTypes.func.isRequired,
    onClickExport: PropTypes.func.isRequired,
  };

  static defaultProps = {
    site: null,
  };

  componentDidMount = () => this.props.componentDidMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      site,
      activeTab,
      showSiteModal,
      showPlotTypeModal,
      siteRateModal,
      siteMaterialRangeModal,
      areaRangeQuoteModal,
      costMatrixSearch,
      costMatrixFormat,
      onCloseSiteModal,
      onClosePlotTypeModal,
      didUpdateSite,
      didDeleteSite,
      didCreatePlotType,
      onCloseSiteRateModal,
      onCloseSiteMaterialRangeModal,
      onCloseAreaRangeQuoteModal,
      onChangeCostMatrixSearch,
      onSearchCostMatrix,
      onChangeCostMatrixFormat,
      onClickRegenerate,
      onClickExport,
    } = this.props;

    if (!site) return null;

    return (

      <Screen>

        <Breadcrumbs site={site} />

        <Header />

        {activeTab === "PLOT_TYPES" && <PlotTypes />}

        {activeTab === "SITE_RATES" && <SiteRates />}

        {activeTab === "SITE_MATERIAL_RANGES" && <SiteMaterialRanges />}

        {activeTab === "COST_MATRIX" && <CostMatrix />}

        {showSiteModal && (
          <SiteModal
            siteId={site.id}
            onClose={onCloseSiteModal}
            didUpdate={didUpdateSite}
            didDelete={didDeleteSite}
          />
        )}

        {showPlotTypeModal && (
          <PlotTypeModal
            siteId={site.id}
            onClose={onClosePlotTypeModal}
            didCreate={didCreatePlotType}
          />
        )}

        {siteRateModal.show && (

          <SiteRate
            {...siteRateModal}
            onClose={onCloseSiteRateModal}
          />

        )}

        {siteMaterialRangeModal.show && (

          <SiteMaterialRange
            {...siteMaterialRangeModal}
            onClose={onCloseSiteMaterialRangeModal}
          />

        )}

        {areaRangeQuoteModal.show && (

          <AreaRangeQuote
            {...areaRangeQuoteModal}
            onClose={onCloseAreaRangeQuoteModal}
          />

        )}

        {activeTab === 'COST_MATRIX' && (

          <Affix offsetBottom>
            <div className={styles.costMatrixControls}>
              <div key='spacer-1' style={{ width: 10 }} />
              <Input.Search
                value={costMatrixSearch}
                onChange={onChangeCostMatrixSearch}
                onSearch={onSearchCostMatrix}
                placeholder='Search'
                style={{ width: 200, boxShadow: '4px 4px #DDD' }}
              />
              <div key='spacer-2' style={{ width: 10 }} />
              <Select
                value={costMatrixFormat}
                onChange={onChangeCostMatrixFormat}
                getPopupContainer={triggerNode => triggerNode.parentElement}
                style={{ width: 120, boxShadow: '4px 4px #DDD' }}
              >
                <Select.OptGroup label='Format'>
                  <Select.Option key='All' value='All'>All</Select.Option>
                  {getFormats(site).map(format => (
                    <Select.Option key={format} value={format}>{format}</Select.Option>
                  ))}
                </Select.OptGroup>
              </Select>
              <div key='spacer-3' style={{ width: 10 }} />
              <Button key='regenerate' type='primary' onClick={onClickRegenerate} style={{ boxShadow: '4px 4px #DDD' }}>
                <Icon type='reload' />
                Regenerate All
              </Button>
              <div key='spacer-4' style={{ width: 10 }} />
              <Dropdown
                trigger={['click']}
                placement='topCenter'
                getPopupContainer={triggerNode => triggerNode.parentElement}
                overlay={(
                  <Menu>
                    <Menu.Item key='with' onClick={() => onClickExport(true)}>With Materials & Labour</Menu.Item>
                    <Menu.Item key='without' onClick={() => onClickExport(false)}>Without Materials & Labour</Menu.Item>
                  </Menu>
                )}
              >
                <Button key='export' style={{ boxShadow: '4px 4px #DDD' }}>
                  <Icon type='export' />
                  Export
                </Button>
              </Dropdown>
            </div>
          </Affix>

        )}

      </Screen>

    )
  }
}
