import { createSelector } from 'reselect'
import selectMaterialType from './selectMaterialType'

export default createSelector(
  selectMaterialType,
  (materialType) => {
    if (!materialType) return null
    if (materialType.usage_key === 'dimensions') return 'Per M2'
    if (materialType.usage_key === 'square-metres') return 'Per M2'
    if (materialType.usage_key === 'item') return 'Each'
    return null
  },
)
