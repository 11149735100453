import { change, formValueSelector } from 'redux-form';
import moment from 'moment'
import { getOrder } from '../../../HttpRequests/orders';
import { FORM_NAME } from '../Form';

const selectValue = formValueSelector(FORM_NAME);

export default () => async (dispatch, getState) => {

  const id = selectValue(getState(), 'order_id');

  if (id) {

    const params = {
      include: 'site',
      'fields[site]': 'id,name'
    }

    const response = await getOrder({ id, params });

    const order = response.data.data;

    dispatch(change(FORM_NAME, 'plot_number', order.plot_number));
    if (order.site) {
      dispatch(change(FORM_NAME, 'site', `${order.site.name} @ ${order.site.town}`));
    } else if (order.custom_entry) {
      dispatch(change(FORM_NAME, 'site', `${order.site_custom} @ ${order.town_custom}`));
    }
    dispatch(change(FORM_NAME, 'delivery_date', order.delivery_date ? moment(order.delivery_date).format('DD/MM/YYYY') : null));

  } else {

    dispatch(change(FORM_NAME, 'plot_number', null));
    dispatch(change(FORM_NAME, 'site', null));
    dispatch(change(FORM_NAME, 'delivery_date', null));

  }
}
