import { find, get } from 'lodash'
import guessSupplier from '../Utils/guessSupplier'
import guessSquareMetres from '../Utils/guessSquareMetres'
import guessQuantity from '../Utils/guessQuantity'
import guessCost from '../Utils/guessCost'
import { setCostSheetField } from '../../../State/order'

const modValues = (costSheetMaterial, field, value, state) => {

  let $costSheetMaterial = { ...costSheetMaterial, [field]: value }

  if (field === 'material') {
    const { materialTypes, materialRanges } = state.core
    const materialType = find(materialTypes, row => row.id === value[0])
    const materialRange = find(materialRanges, row => row.id === value[2])
    const rangeVariant = find(materialRange.range_variants, row => row.id === value[3])
    $costSheetMaterial.material_type_id = materialType.id
    $costSheetMaterial.material_range_id = materialRange.id
    $costSheetMaterial.range_variant_id = get(rangeVariant, 'id')
    $costSheetMaterial.material_type = materialType
    $costSheetMaterial.material_range = materialRange
    $costSheetMaterial.range_variant = rangeVariant
    $costSheetMaterial = guessSupplier($costSheetMaterial, state)
    $costSheetMaterial = guessSquareMetres($costSheetMaterial)
    $costSheetMaterial = guessQuantity($costSheetMaterial)
    $costSheetMaterial = guessCost($costSheetMaterial, costSheetMaterial, state)
  }

  if (field === 'supplier') {
    const { suppliers } = state.core
    const supplier = find(suppliers, row => row.id === value[0])
    $costSheetMaterial.supplier_id = supplier.id
    $costSheetMaterial.supplier = supplier
    $costSheetMaterial = guessSquareMetres($costSheetMaterial)
    $costSheetMaterial = guessQuantity($costSheetMaterial)
    $costSheetMaterial = guessCost($costSheetMaterial, costSheetMaterial, state)
  }

  if (field === 'dimensions') {
    $costSheetMaterial = guessSquareMetres($costSheetMaterial)
    $costSheetMaterial = guessQuantity($costSheetMaterial)
    $costSheetMaterial = guessCost($costSheetMaterial, costSheetMaterial, state)
  }

  if (field === 'square_metres') {
    $costSheetMaterial = guessQuantity($costSheetMaterial)
    $costSheetMaterial = guessCost($costSheetMaterial, costSheetMaterial, state)
  }

  if (field === 'quantity') {
    $costSheetMaterial = guessCost($costSheetMaterial, costSheetMaterial, state)
  }

  return $costSheetMaterial

}

export default (guid, field, value) => (dispatch, getState) => {

  const state = getState()

  const { cost_sheet_materials: costSheetMaterials } = state.screens.CostSheet.order.cost_sheet

  const $costSheetMaterials = []

  costSheetMaterials.forEach(costSheetMaterial => {
    if (costSheetMaterial.guid !== guid) {
      $costSheetMaterials.push({ ...costSheetMaterial })
    } else {
      const $costSheetMaterial = modValues(costSheetMaterial, field, value, state)
      $costSheetMaterials.push($costSheetMaterial)
    }
  })

  dispatch(setCostSheetField({ field: 'cost_sheet_materials', value: $costSheetMaterials }))

}
