import modalProps from '../modalProps'
import { resetFormState } from '../Components/Form/State/formState'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetFormState())
  
}
