import { createAction, handleActions } from 'redux-actions';

const loadInspectorVisits = createAction("SCREENS/BOARD/LOAD_INSPECTOR_VISITS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadInspectorVisits]: (state, action) => action.payload
  },
  defaultState
);

export { loadInspectorVisits, reducer };
