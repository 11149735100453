import { connect } from 'react-redux'
import CostSheet from './CostSheet'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onClickBack from './Handlers/onClickBack'
import onCloseMaterialTypesModal from './Handlers/onCloseMaterialTypesModal'
import onCloseManufacturersModal from './Handlers/onCloseManufacturersModal'
import onCloseMaterialRangesModal from './Handlers/onCloseMaterialRangesModal'
import onCloseSuppliersModal from './Handlers/onCloseSuppliersModal'
import onCloseFittingRatesModal from './Handlers/onCloseFittingRatesModal'

const mapStateToProps = (state) => ({
  order: state.screens.CostSheet.order,
  editing: state.screens.CostSheet.editing,
  materialTypesModal: state.screens.CostSheet.materialTypesModal,
  manufacturersModal: state.screens.CostSheet.manufacturersModal,
  materialRangesModal: state.screens.CostSheet.materialRangesModal,
  suppliersModal: state.screens.CostSheet.suppliersModal,
  fittingRatesModal: state.screens.CostSheet.fittingRatesModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onClickBack,
  onCloseMaterialTypesModal,
  onCloseManufacturersModal,
  onCloseMaterialRangesModal,
  onCloseSuppliersModal,
  onCloseFittingRatesModal,
}

export default connect(mapStateToProps, actionCreators)(CostSheet)
