import { message } from 'antd'
import { deleteCostSheet } from '../../../HttpRequests/costSheets'
import fetch from './fetch'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { order } = getState().screens.CostSheet

  try {

    await deleteCostSheet({ orderId: order.id })

    message.success('Deleted Cost Sheet')

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }

}
