import { setCostSheetField } from '../State/order'

export default () => (dispatch) => {

  dispatch(setCostSheetField({ field: 'material_type_id', value: null }))
  dispatch(setCostSheetField({ field: 'area_group_id', value: null }))
  dispatch(setCostSheetField({ field: 'anc_cost', value: null }))
  dispatch(setCostSheetField({ field: 'rebate_cost', value: null }))
  dispatch(setCostSheetField({ field: 'travel_cost', value: null }))
  dispatch(setCostSheetField({ field: 'notes', value: null }))

  dispatch(setCostSheetField({ field: 'cost_sheet_areas', value: [] }))
  dispatch(setCostSheetField({ field: 'cost_sheet_materials', value: [] }))
  dispatch(setCostSheetField({ field: 'cost_sheet_expenses', value: [] }))
  dispatch(setCostSheetField({ field: 'cost_sheet_quotes', value: [] }))

}
