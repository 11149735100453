const createPropsHelper = () => {

  let props = {}

  const propsHelper = (newProps) => {
    
    if (newProps !== undefined) {
      props = newProps
    }

    return props
  }

  return propsHelper

}

export default createPropsHelper