import { createAction, handleActions } from 'redux-actions'

const openManufacturersModal = createAction('SCREENS/COST_SHEET/OPEN_MANUFACTURERS_MODAL')
const closeManufacturersModal = createAction('SCREENS/COST_SHEET/CLOSE_MANUFACTURERS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openManufacturersModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeManufacturersModal]: () => defaultState,
  },
  defaultState,
)

export { openManufacturersModal, closeManufacturersModal, reducer }
