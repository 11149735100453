import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { find } from 'lodash';
import { SelectField, DateRangePickerField, TextAreaField } from 'redux-form-antd-fields';
import moment from 'moment';
import styles from './Component.css';

const labelColSpan = 5;
const wrapperColSpan = 16;

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    fitters: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    values: PropTypes.object,
    onChangeFitter: PropTypes.func.isRequired,
    onChangeDates: PropTypes.func.isRequired,
  }

  static defaultProps = {
    values: {}
  }

  render () {

    const {
      submitting,
      fitters,
      users,
      values,
      onChangeFitter,
      onChangeDates,
    } = this.props;

    const fitterOptions = fitters.filter(fitter => !fitter.archived_at).map(item => ({ value: item.id, label: item.name }))

    let lastUpdatedByUser = null

    if (values) {
      lastUpdatedByUser = find(users, { 'id': values.updated_by_user_id })
    }

    return (

      <React.Fragment>
        
        <Field
          name="fitter_id"
          label="Fitter"
          component={SelectField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          options={fitterOptions}
          onChange={onChangeFitter}
        />
        
        <Field
          name="dates"
          label="Dates"
          component={DateRangePickerField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
          onChange={onChangeDates}
        />

        <Field
          name="notes"
          label="Notes"
          component={TextAreaField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ height: 130 }}
        />

        {values && lastUpdatedByUser && (
          <div className={styles.updateInfo}>
            {`Updated by ${lastUpdatedByUser.first_name} at ${moment(values.updated_at).format('HH:mm DD/MM/YYYY')}`}
          </div>
        )}

      </React.Fragment>

    )
  }
}