import { connect } from 'react-redux'
import Form from './Form'

import selectMaterialRanges from './Selectors/selectMaterialRanges'
import selectMaterialType from './Selectors/selectMaterialType'
import selectDimensions from './Selectors/selectDimensions'
import selectUnit from './Selectors/selectUnit'

import onChangeFormState from './Handlers/onChangeFormState'
import onClickDimensions from './Handlers/onClickDimensions'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  builders: state.core.builders,
  materialTypes: state.core.materialTypes,
  initialValues: state.modals.SupplierRate.Form.formState.initialValues,
  materialRanges: selectMaterialRanges(state),
  materialType: selectMaterialType(state),
  format: state.modals.SupplierRate.Form.formState.values.format,
  dimensions: selectDimensions(state),
  unit: selectUnit(state),
})

const actionCreators = {
  onChangeFormState,
  onClickDimensions,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
