import { createAction, handleActions } from 'redux-actions'

const openAddAreaModal = createAction('SCREENS/PLOT_TYPES/OPEN_ADD_AREA_MODAL')
const closeAddAreaModal = createAction('SCREENS/PLOT_TYPES/CLOSE_ADD_AREA_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openAddAreaModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeAddAreaModal]: () => defaultState,
  },
  defaultState,
)

export { openAddAreaModal, closeAddAreaModal, reducer }
