import modalProps from '../modalProps'
import { findBuilderRate } from '../../../HttpRequests/builderRates'
import { setBuilderRate } from '../State/builderRate'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = modalProps()

  if (!id) return

  const params = {
    include: 'material_type,material_ranges.range_variants,range_variants',
  }

  try {

    const response = await findBuilderRate({ id, params })

    dispatch(setBuilderRate(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
