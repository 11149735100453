import { connect } from 'react-redux'
import Dimensions from './Dimensions'

import validate from './Handlers/validate'
import handleSave from './Handlers/handleSave'
import handleClose from './Handlers/handleClose'

const mapStateToProps = () => ({
})

const actionCreators = {
  validate,
  handleSave,
  handleClose,
}

export default connect(mapStateToProps, actionCreators)(Dimensions)
