import { showSpinner, hideSpinner } from "../State/showSpinner";
import { getHolidays } from '../../../HttpRequests/holidays';
import { loadHolidays } from '../State/holidays';
import handleException from '../../../Core/Helpers/handleException';

export default ({ quiet } = { quiet: false }) => async (dispatch, getState) => {
  
  try {

    const { dateRange, searchTerm } = getState().screens.Board;

    const params = {};
    
    params.include = ['fitter'].join();

    params['filter[date_between]'] = dateRange.join();

    if (searchTerm) params.search = searchTerm;

    params.pagination = 0;

    if (!quiet) dispatch(showSpinner());
    
    const response = await getHolidays({ params });

    if (!quiet) dispatch(hideSpinner());

    const holidays = response.data.data;
    
    dispatch(loadHolidays(holidays));

  } catch (error) {
    
    handleException(error);

  }

}