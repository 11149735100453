import { connect } from 'react-redux'
import SiteRateRangeVariantsModal from './SiteRateRangeVariantsModal'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onToggleRangeVariant from './Handlers/onToggleRangeVariant'
import onClickSave from './Handlers/onClickSave'

const mapStateToProps = (state, { materialRange }) => ({
  materialRange,
  selectedRangeVariantIds: state.modals.SiteRate.SiteRateRangeVariantsModal.selectedRangeVariantIds,
  saving: state.modals.SiteRate.SiteRateRangeVariantsModal.saving,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onToggleRangeVariant,
  onClickSave,
}

export default connect(mapStateToProps, actionCreators)(SiteRateRangeVariantsModal)
