import { createAction, handleActions } from 'redux-actions'

const setSelectedRows = createAction('SCREENS/PLOT_TYPE/AREAS/SET_SELECTED_ROWS')
const resetSelectedRows = createAction('SCREENS/PLOT_TYPE/AREAS/RESET_SELECTED_ROWS')

const defaultState = []

const reducer = handleActions(
  {
    [setSelectedRows]: (state, action) => action.payload,
    [resetSelectedRows]: () => defaultState,
  },
  defaultState,
)

export { setSelectedRows, resetSelectedRows, reducer }
