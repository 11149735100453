import React from 'react'
import PropTypes from 'prop-types'
import { Table, Typography, Icon, Select, Checkbox } from 'antd'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import { find, get } from 'lodash'
import arrayMove from 'array-move'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './styles.css'

const DragHandle = sortableHandle(() => (
  <Icon type='menu' style={{ cursor: 'pointer', color: '#999' }} />
))

const SortableItem = sortableElement(props => <tr {...props} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)

const DraggableBodyRow = (rowProps, tableRows) => {
  const index = tableRows.findIndex(tableRow => tableRow.id === rowProps['data-row-key'])
  return <SortableItem index={index} {...rowProps} />
}

const onSortEnd = ({ oldIndex, newIndex }, tableRows, onSort) => {
  if (oldIndex !== newIndex) {
    const newTableRows = arrayMove([].concat(tableRows), oldIndex, newIndex).filter(el => !!el)
    onSort(newTableRows)
  }
}

const DraggableContainer = (wrapperProps, tableRows, onSort) => (
  <SortableContainer
    useDragHandle
    helperClass={styles.rowDragging}
    onSortEnd={onSortEndProps => onSortEnd(onSortEndProps, tableRows, onSort)}
    {...wrapperProps}
  />
)

const Areas = props => {

  const {
    materialTypes,
    siteRates,
    tableRows,
    isFetching,
    selectedRows,
    totals,
    onEdit,
    onClickDimensions,
    onToggleSiteRate,
    onChangeSelectedRows,
    onSort,
  } = props

  const findAreaMaterialType = (record, materialType) => find(record.areaMaterialTypes, row => row.material_type_id === materialType.id)

  return (

    <div>

      <Table
        dataSource={tableRows}
        rowKey='id'
        bordered
        className={styles.table}
        loading={!tableRows.length && isFetching}
        pagination={false}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: onChangeSelectedRows,
        }}
        components={{
          body: {
            wrapper: wrapperProps => DraggableContainer(wrapperProps, tableRows, onSort),
            row: rowProps => DraggableBodyRow(rowProps, tableRows),
          },
        }}
      >

        <Table.Column
          key='sort'
          title='Sort'
          width={30}
          className={styles.dragVisible}
          render={() => <DragHandle />}
        />

        <Table.Column
          key='area.name'
          title='Area'
          className={styles.dragVisible}
          render={(text, record) => (
            <Typography.Text
              editable={{ onChange: value => value !== record.area.name && onEdit(record.id, 'name', value) }}
            >
              {record.area.name}
            </Typography.Text>
          )}
        />

        <Table.Column
          key='area.type'
          title='Type'
          width={110}
          onCell={() => ({
            style: { padding: 0 },
          })}
          render={(text, record) => (
            <Select
              value={record.area.type}
              onChange={value => onEdit(record.id, 'type', value)}
              className={styles.antInput}
            >
              {/* <Select.Option key='Sale' value='Sale'>Sale</Select.Option> */}
              <Select.Option key='C/W' value='C/W'>C/W</Select.Option>
              <Select.Option key='C/E' value='C/E'>C/E</Select.Option>
            </Select>
          )}
        />

        {materialTypes.filter(row => row.usage_key === 'dimensions').map(materialType => (

          <Table.Column
            key={materialType.id}
            title={`${materialType.name} M2`}
            width={110}
            onHeaderCell={() => ({
              className: classNames(styles.numeric),
            })}
            onCell={record => ({
              className: classNames(styles.numeric, styles.clickable),
              onClick: () => {
                const areaMaterialType = findAreaMaterialType(record, materialType)
                onClickDimensions(get(areaMaterialType, 'id'), record.id, materialType.id, get(areaMaterialType, 'dimensions', []))
              }
            })}
            render={(text, record) => {
              const areaMaterialType = findAreaMaterialType(record, materialType)
              if (!areaMaterialType) return false
              return (
                <div style={{ width: '100%', height: '100%' }}>
                  {areaMaterialType.dimensions.map((dimension, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{`${dimension.length} x ${dimension.width}`}</div>
                  ))}
                </div>
              )
            }}
          />

        ))}

        <Table.Column
          key='area.square_metres'
          title='Net M2'
          width={110}
          className={styles.numeric}
          render={(text, record) => (
            <Typography.Text
              editable={{ onChange: value => value !== record.area.square_metres && onEdit(record.id, 'square_metres', value) }}
            >
              {`${record.area.square_metres ? numeral(record.area.square_metres).format('0,0.00') : ''}`}
            </Typography.Text>
          )}
        />

        <Table.Column
          key='area.wastage_perc'
          title='Waste %'
          width={110}
          className={styles.numeric}
          render={(text, record) => (
            <Typography.Text
              editable={{ onChange: value => value !== record.area.wastage_perc && onEdit(record.id, 'wastage_perc', value) }}
            >
              {`${record.area.wastage_perc ? numeral(record.area.wastage_perc).format('0,0.00') : ''}`}
            </Typography.Text>
          )}
        />

        <Table.Column
          key='area.square_metres_gross'
          title='Gross M2'
          width={110}
          className={styles.numeric}
          render={(text, record) => record.area.square_metres_gross ? numeral(record.area.square_metres_gross).format('0,0.00') : ''}
        />

        {siteRates.map(siteRate => (

          <Table.Column
            key={siteRate.id}
            title={`${siteRate.title}${siteRate.subtitle ? ` ${siteRate.subtitle}` : ''}`}
            width={100}
            className={styles.numeric}
            render={(text, record) => (
              <Checkbox
                checked={record.siteRateIds.indexOf(siteRate.id) !== -1}
                onChange={e => onToggleSiteRate(record.area, siteRate, e.target.checked)}
              />
            )}
          />

        ))}

      </Table>

      <div className={styles.totals}>
        {totals.map(materialType => (
          <div key={materialType.id} className={styles.total}>
            {numeral(materialType.total).format('0,0.00')}
          </div>
        ))}
        {siteRates.map(siteRate => (
          <div key={siteRate.id} style={{ width: 100 }} />
        ))}
      </div>

    </div>

  )
  
}

Areas.propTypes = {
  materialTypes: PropTypes.array.isRequired,
  siteRates: PropTypes.array.isRequired,
  tableRows: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  totals: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClickDimensions: PropTypes.func.isRequired,
  onToggleSiteRate: PropTypes.func.isRequired,
  onChangeSelectedRows: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
}

export default Areas
