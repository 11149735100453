import { createSelector } from "reselect";
import moment from "moment";
import selectDates from './dates';

const selectInspectorVisits = state => state.screens.Board.inspectorVisits;

export default createSelector(
  selectInspectorVisits,
  selectDates,
  (inspectorVisits, dates) => {

    const data = dates.map(() => []);

    inspectorVisits.forEach(inspectorVisit => {

      const key = moment(inspectorVisit.date).format('YYYY-MM-DD');

      if (dates.indexOf(key) !== -1) {
        data[dates.indexOf(key)].push(inspectorVisit);
      }
    
    });
    
    return data;
  
  }
)