import { setAreas } from '../State/areas'
import save from '../Services/save'

export default (id, column, value) => (dispatch, getState) => {
  
  const { areas: prevAreas } = getState().screens.PlotType.Areas

  if (column === 'name' && value === '') return

  // eslint-disable-next-line no-restricted-globals
  if (column === 'square_metres' && isNaN(value)) return

  const newAreas = prevAreas.map(prevArea => {

    if (prevArea.id !== id) return prevArea

    const newArea = { ...prevArea }

    newArea[column] = value

    return newArea

  })

  dispatch(setAreas(newAreas))

  dispatch(save(id, column, value))

}
