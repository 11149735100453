import { getProps } from '../props'

export default (withColumns) => async (dispatch, getState) => {

  const state = getState()

  const { id } = getProps().match.params

  const { token } = state

  const { costMatrixSearch: search, costMatrixFormat: format } = getState().screens.SiteView

  let path = `/api/sites/${id}/cost_matrix/export?token=${token}`

  const params = {
    search,
    format: format !== 'All' ? format.trim() : undefined,
    with_columns: withColumns === true ? 1 : 0,
  }

  Object.keys(params).forEach(key => {
    if (params[key] === undefined) return
    path = `${path}&${key}=${params[key]}`
  })

  window.open(path)

}
