import { message } from 'antd';

import { updateInspector } from '../../../HttpRequests/inspectors';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {
  
  try {

    const response = await updateInspector({ id: data.id, data });
    
    message.success("Inspector Updated");

    return response.data.data;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}