import { createAction, handleActions } from 'redux-actions'

const setAreaRangeQuote = createAction('MODALS/AREA_RANGE_QUOTE/SET_AREA_RANGE_QUOTE')
const resetAreaRangeQuote = createAction('MODALS/AREA_RANGE_QUOTE/RESET_AREA_RANGE_QUOTE')

const defaultState = null

const reducer = handleActions(
  {
    [setAreaRangeQuote]: (state, action) => action.payload,
    [resetAreaRangeQuote]: () => defaultState,
  },
  defaultState,
)

export { setAreaRangeQuote, resetAreaRangeQuote, reducer }
