import { combineReducers } from 'redux';

import { reducer as dateRange } from './State/dateRange';
import { reducer as hideAllocated } from './State/hideAllocated';
import { reducer as thetfordOnly } from './State/thetfordOnly';
import { reducer as hideOrdered } from './State/hideOrdered';
import { reducer as holidays } from './State/holidays';
import { reducer as inspectorVisits } from './State/inspectorVisits';
import { reducer as inspections } from './State/inspections';
import { reducer as jobs } from './State/jobs';
import { reducer as jobStats } from './State/jobStats';
import { reducer as otherPeriodsWithResults } from './State/otherPeriodsWithResults';
import { reducer as locked } from './State/locked';
import { reducer as manager } from './State/manager';
import { reducer as mode } from './State/mode';
import { reducer as openedHolidayId } from './State/openedHolidayId';
import { reducer as openedInspectorVisitId } from './State/openedInspectorVisitId';
import { reducer as openedJobId } from './State/openedJobId';
import { reducer as createLegacyJob } from './State/createLegacyJob';
import { reducer as searchTerm } from './State/searchTerm';
import { reducer as selectedTags } from './State/selectedTags';
import { reducer as showHolidayModal } from './State/showHolidayModal';
import { reducer as showInspectorVisitModal } from './State/showInspectorVisitModal';
import { reducer as showJobModal } from './State/showJobModal';
import { reducer as showSpinner } from './State/showSpinner';
import { reducer as showWeekends } from './State/showWeekends';
import { reducer as source } from './State/source';
import { reducer as completedJobsModal } from './State/completedJobsModal';

export default combineReducers({
  dateRange,
  hideAllocated,
  thetfordOnly,
  hideOrdered,
  holidays,
  inspectorVisits,
  inspections,
  jobs,
  jobStats,
  otherPeriodsWithResults,
  locked,
  manager,
  mode,
  openedHolidayId,
  openedInspectorVisitId,
  openedJobId,
  createLegacyJob,
  searchTerm,
  selectedTags,
  showHolidayModal,
  showInspectorVisitModal,
  showJobModal,
  showSpinner,
  showWeekends,
  source,
  completedJobsModal,
});
