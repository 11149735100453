import { find, get, orderBy } from 'lodash'
import modalProps from '../modalProps'
import selectAreas from '../../../../../Selectors/selectAreas'
import { setCostSheetField } from '../../../../../State/order'
import addItem from '../../../Services/addItem'

export default () => (dispatch, getState) => {

  const state = getState()

  const { materialTypes, materialRanges } = state.core

  const areas = selectAreas(state)

  const { fields } = state.screens.CostSheet.Areas.AddModal

  // Areas / group

  const materialRange = find(materialRanges, row => row.id === fields.materialRangeId)
  const rangeVariant = find(get(materialRange, 'range_variants', []), row => row.id === fields.rangeVariantId)

  const items = []

  const materialType = find(materialTypes, row => row.id === fields.materialTypeId)

  dispatch(setCostSheetField({ field: 'material_type_id', value: materialType.id }))

  fields.areaIds.forEach(areaId => {

    const area = find(areas, row => row.id === areaId)

    const item = {
      area_id: area.id,
      material_type_id: materialType.id,
      material_range_id: get(materialRange, 'id'),
      range_variant_id: get(rangeVariant, 'id'),
      area,
      material_type: materialType,
      material_range: materialRange,
      range_variant: rangeVariant,
    }

    if (materialType.usage_key === 'dimensions') {
      const areaMaterialType = find(area.area_material_types, row => row.material_type_id === materialType.id)
      item.dimensions = get(areaMaterialType, 'dimensions')
      item.square_metres = get(areaMaterialType, 'square_metres')
    }

    if (materialType.usage_key === 'square-metres' && materialRange) {
      item.square_metres = area.square_metres_gross
      if (materialRange.wastage_perc && area.square_metres) {
        item.square_metres = area.square_metres + ((area.square_metres / 100) * materialRange.wastage_perc)
      }
    }

    items.push(item)

  })

  orderBy(items, 'area.order_no').forEach(item => dispatch(addItem(item)))

  modalProps().onClose()

}
