export default (values, props) => {

  const errors = {};

  const { materialUnit } = props;

  if (!values.from_order_id) errors.from_order_id = true;

  if (!Array.isArray(values.material) || values.material.length === 0) errors.material = true;
  
  if (materialUnit && Array.isArray(materialUnit.leftover)) {
    
    if (materialUnit.leftover.includes('width_in_metres') && !values.width_in_metres) errors.width_in_metres = true;
    if (materialUnit.leftover.includes('length_in_metres') && !values.length_in_metres) errors.length_in_metres = true;
    if (materialUnit.leftover.includes('square_metres') && !values.square_metres) errors.square_metres = true;
    if (materialUnit.leftover.includes('linear_metres') && !values.linear_metres) errors.linear_metres = true;
    if (materialUnit.leftover.includes('weight_in_kilograms') && !values.weight_in_kilograms) errors.weight_in_kilograms = true;
    if (materialUnit.leftover.includes('volume_in_litres') && !values.volume_in_litres) errors.volume_in_litres = true;

  }

  return errors;
  
}