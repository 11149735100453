import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchMaterials from '../../../Core/Services/fetchMaterials'
import fetchOrderNumbers from '../../../Core/Services/fetchOrderNumbers'

export default (props) => (dispatch) => {

  setProps(props);
  
  dispatch(initialize());

  dispatch(fetchMaterials());
  dispatch(fetchOrderNumbers());
  
}