import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import Fields from './Components/Fields';
import Footer from './Components/Footer';

export default class extends React.PureComponent{

  static propTypes = {
    componentWillMount: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    fitterInvoiceId: PropTypes.number,
    onClickCancel: PropTypes.func.isRequired
  }

  static defaultProps = {
    fitterInvoiceId: null
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      submitting,
      fitterInvoiceId,
      onClickCancel
    } = this.props;

    const exists = !!fitterInvoiceId;

    return (
        
      <Modal
        visible  
        title={`${exists ? 'Fitter Invoice': 'New Fitter Invoice'}`}        
        onCancel={onClickCancel}
        footer={<Footer submitting={submitting} exists={exists} />}
      >
        
        <Fields submitting={submitting} />

      </Modal>
    )
  }
}