import { connect } from 'react-redux'
import SiteMaterialRange from './SiteMaterialRange'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'

const mapStateToProps = (state) => ({
  submitting: state.modals.SiteMaterialRange.Form.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onSubmit,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(SiteMaterialRange)
