import chance from 'chance'
import { setCostSheetField } from '../../../State/order'

export default (guid, action) => (dispatch, getState) => {

  const { cost_sheet_materials: costSheetMaterials } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetMaterials = []

  costSheetMaterials.forEach(costSheetMaterial => {

    if (costSheetMaterial.guid !== guid) {
      newCostSheetMaterials.push({ ...costSheetMaterial })
      return
    }

    if (action === 'add') {
      newCostSheetMaterials.push({ ...costSheetMaterial })
      newCostSheetMaterials.push({
        guid: chance().guid(),
      })
    }

    if (action === 'duplicate') {
      newCostSheetMaterials.push({ ...costSheetMaterial })
      newCostSheetMaterials.push({
        ...costSheetMaterial,
        id: undefined,
        guid: chance().guid(),
      })
    }

    if (action === 'remove') {
      // We just simply omit it
    }

  })

  dispatch(setCostSheetField({ field: 'cost_sheet_materials', value: newCostSheetMaterials }))

}
