import { getProps } from '../../../props'
import { updateOrder } from '../../../../../HttpRequests/orders'
import fetchOrder from '../../../Services/fetchOrder'
import handleException from '../../../../../Core/Helpers/handleException'

export default (action) => async (dispatch) => {

  const { id } = getProps().match.params

  const data = {
    questionnaire_action: action || null,
  }

  try {

    await updateOrder({ id, data })

    dispatch(fetchOrder())

  } catch (error) {

    handleException(error)

  }

}
