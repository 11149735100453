import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteMaterialType } from '../../../HttpRequests/materialTypes'
import fetchMaterialTypes from '../../../Core/Services/fetchMaterialTypes'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { materialType } = modalProps()

  try {

    await deleteMaterialType({ id: materialType.id })

    message.success('Deleted Material')

    modalProps().onClose(true)

    dispatch(fetchMaterialTypes({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
