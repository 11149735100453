import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openMaterialTypeModal = createAction('SCREENS/MATERIALS/OPEN_MATERIAL_TYPE_MODAL')
const closeMaterialTypeModal = createAction('SCREENS/MATERIALS/CLOSE_MATERIAL_TYPE_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openMaterialTypeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id'),
    }),
    [closeMaterialTypeModal]: () => defaultState,
  },
  defaultState,
)

export { openMaterialTypeModal, closeMaterialTypeModal, reducer }
