import { createSelector } from 'reselect'
import { get } from 'lodash'
import chance from 'chance'
import float from '../../../Utils/float'

const selectOrder = state => state.screens.CostSheet.order

const getMaterial = ({ material_range: materialRange, range_variant: rangeVariant }) => {
  if (materialRange && rangeVariant) {
    return `${materialRange.name} / ${[rangeVariant.name, rangeVariant.code].filter(item => !!item).join(' • ')}`
  }
  if (materialRange) return materialRange.name
  return ''
}

const getDimensions = ({ dimensions }) => dimensions ? dimensions.map(row => ({
  ...row,
  guid: chance().guid(),
})) : null

const getTotalSquareMetres = ({ square_metres: squareMetres, quantity }) => {
  if (!Number.isFinite(squareMetres) || !Number.isFinite(quantity)) {
    return ''
  }

  return float(squareMetres * quantity)
}

export default createSelector(
  selectOrder,
  (order) => order.cost_sheet.cost_sheet_materials.map(costSheetMaterial => ({
    ...costSheetMaterial,
    material: getMaterial(costSheetMaterial),
    supplier: get(costSheetMaterial, 'supplier.name', ''),
    dimensions: getDimensions(costSheetMaterial),
    square_metres: float(costSheetMaterial.square_metres),
    quantity: float(costSheetMaterial.quantity),
    total_square_metres: getTotalSquareMetres(costSheetMaterial),
    cost: float(costSheetMaterial.cost),
  })),
)
