import { createAction, handleActions } from 'redux-actions';

const setActiveTab = createAction('SCREENS/BUILDER_VIEW/SET_ACTIVE_TAB');

const defaultState = 'SITES';

const reducer = handleActions(
  {
    [setActiveTab]: (state, action) => action.payload
  },
  defaultState
);

export { setActiveTab, reducer };