import { find, uniq } from 'lodash'

export default (costSheetMaterial, state) => {

  const { suppliers, supplierRates } = state.core

  const $costSheetMaterial = { ...costSheetMaterial }

  if ($costSheetMaterial.supplier_id) return $costSheetMaterial

  let supplierIds = supplierRates.filter(row => row.material_range_id === $costSheetMaterial.material_range_id).map(row => row.supplier_id)

  supplierIds = uniq(supplierIds)

  if (supplierIds.length !== 1) return $costSheetMaterial

  const supplier = find(suppliers, row => row.id === supplierIds[0])

  $costSheetMaterial.supplier_id = supplier.id
  $costSheetMaterial.supplier = supplier

  return $costSheetMaterial

}
