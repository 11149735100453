import { createSelector } from 'reselect'
import float from '../../../../../Utils/float'

const selectOrder = state => state.screens.CostSheet.order

const getQuotable = ({ area, material_type: materialType }) => {
  if (area) return area.name
  if (materialType) return materialType.name
  return ''
}

export default createSelector(
  selectOrder,
  (order) => order.cost_sheet.cost_sheet_quotes.map(costSheetQuote => ({
    ...costSheetQuote,
    quotable: getQuotable(costSheetQuote),
    price: float(costSheetQuote.price),
  })),
)
