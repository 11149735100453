import { createStore, applyMiddleware, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'

export default (reducers = {}, persistConfig = {}) => {

  const history = createBrowserHistory();

  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers
  })

  const defaultPersistConfig = {
    key: 'root',
    storage,
  }

  const finalPersistConfig = Object.assign({}, defaultPersistConfig, persistConfig)

  const persistedReducer = persistReducer(finalPersistConfig, rootReducer)

  const middleware = applyMiddleware(
    routerMiddleware(history),
    thunkMiddleware,
  )

  const createStoreWidthDevTools = composeWithDevTools(middleware)(createStore)

  const store = createStoreWidthDevTools(persistedReducer)
  
  const persistor = persistStore(store)

  return { store, persistor, history }
  
}
