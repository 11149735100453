import { createAction, handleActions } from 'redux-actions'

const openMaterialRangeModal = createAction('MODALS/MATERIAL_RANGES/OPEN_MATERIAL_RANGE_MODAL')
const closeMaterialRangeModal = createAction('MODALS/MATERIAL_RANGES/CLOSE_MATERIAL_RANGE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openMaterialRangeModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeMaterialRangeModal]: () => defaultState,
  },
  defaultState,
)

export { openMaterialRangeModal, closeMaterialRangeModal, reducer }
