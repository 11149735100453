import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createSupplier, updateSupplier } from '../../../../../HttpRequests/suppliers'
import fetchSuppliers from '../../../../../Core/Services/fetchSuppliers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { supplier } = modalProps()

  const data = {
    name: values.name,
  }

  try {

    if (supplier) {
      await updateSupplier({ id: supplier.id, data })
      message.success('Updated Supplier')
    } else {
      await createSupplier({ data })
      message.success('Created Supplier')
    }

    modalProps().onClose(true)

    dispatch(fetchSuppliers({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
