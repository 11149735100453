import { setHideOrdered } from '../../../State/hideOrdered';
import fetchJobs from '../../../Services/fetchJobs';
import fetchInspections from '../../../Services/fetchInspections';

export default (value) => async (dispatch) => {

  dispatch(setHideOrdered(value));
  dispatch(fetchJobs());
  dispatch(fetchInspections());
  
}