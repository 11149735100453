import { combineReducers } from 'redux'

import { reducer as plotType } from './State/plotType'
import { reducer as isFetching } from './State/isFetching'
import { reducer as activeTab } from './State/activeTab'
import { reducer as plotTypeModal } from './State/plotTypeModal'
import { reducer as addAreaModal } from './State/addAreaModal'
import { reducer as groupAreasModal } from './State/groupAreasModal'
import { reducer as dimensionsModal } from './State/dimensionsModal'
import { reducer as areaGroupModal } from './State/areaGroupModal'
import { reducer as addAreaMaterialTypeModal } from './State/addAreaMaterialTypeModal'

import Areas from './Components/Areas/reducer'
import AreaGroups from './Components/AreaGroups/reducer'
import GroupAreas from './Components/GroupAreas/reducer'

export default combineReducers({
  plotType,
  isFetching,
  activeTab,
  plotTypeModal,
  addAreaModal,
  groupAreasModal,
  dimensionsModal,
  areaGroupModal,
  addAreaMaterialTypeModal,

  Areas,
  AreaGroups,
  GroupAreas,
})
