import { createSelector } from "reselect";
import { sortBy } from 'lodash';
import moment from "moment";
import selectDates from './dates';

const selectInspections = state => state.screens.Board.inspections;
const selectSelectedTags = state => state.screens.Board.selectedTags;

export default createSelector(
  selectInspections,
  selectDates,
  selectSelectedTags,
  (inspections, dates, selectedTags) => {

    let data = dates.map(() => []);

    inspections
      .filter(inspection => selectedTags.length === 0 || selectedTags.includes(inspection.tag))
      .forEach(inspection => {

        const key = moment(inspection.inspection_date).format('YYYY-MM-DD');

        if (dates.indexOf(key) !== -1) {
          data[dates.indexOf(key)].push(inspection);
        }

      });

    data = data.map(items => sortBy(items, item => {

      const { order } = item

      if (item.order) {
        return `${order.site ? order.site.name : order.site_custom} ${order.plot_number}`.toLowerCase();
      }

      return `${item.legacy_site} ${item.legacy_plot_number}`.toLowerCase()
      
    }));

    return data;
  
  }
)