import modalProps from '../modalProps'
import { resetAreaGroup } from '../State/areaGroup'
import { resetSelectedAreaIds } from '../State/selectedAreaIds'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetAreaGroup())
  dispatch(resetSelectedAreaIds())
  
}
