import { combineReducers } from "redux";

import { reducer as site } from "./State/site";
import { reducer as activeTab } from "./State/activeTab";
import { reducer as showSiteModal } from "./State/showSiteModal";
import { reducer as showPlotTypeModal } from "./State/showPlotTypeModal";
import { reducer as siteRateModal } from "./State/siteRateModal";
import { reducer as siteMaterialRangeModal } from "./State/siteMaterialRangeModal";
import { reducer as areaRangeQuoteModal } from "./State/areaRangeQuoteModal";
import { reducer as costMatrixSearch } from "./State/costMatrixSearch";
import { reducer as costMatrixFormat } from "./State/costMatrixFormat";

import SiteRates from './Components/SiteRates/reducer';
import SiteMaterialRanges from './Components/SiteMaterialRanges/reducer';
import CostMatrix from './Components/CostMatrix/reducer';

export default combineReducers({
  site,
  activeTab,
  showSiteModal,
  showPlotTypeModal,
  siteRateModal,
  siteMaterialRangeModal,
  areaRangeQuoteModal,
  costMatrixSearch,
  costMatrixFormat,

  SiteRates,
  SiteMaterialRanges,
  CostMatrix,
});
