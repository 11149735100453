import React from 'react'
import classNames from 'classnames'
import styles from './styles.css'

const Headings = () => (

  <div className={styles.wrapper}>

    <div className={classNames(styles.cell, styles.material)}>Material</div>

    <div className={classNames(styles.cell, styles.supplier)}>Supplier</div>

    <div className={classNames(styles.cell, styles.quantity)}>Qty</div>

    <div className={classNames(styles.cell, styles.dimensions)}>Sizes</div>

    <div className={classNames(styles.cell, styles.squareMetres)}>M2</div>

    <div className={classNames(styles.cell, styles.squareMetres)}>Total M2</div>

    <div className={classNames(styles.cell, styles.cost)}>Cost</div>

  </div>

)

export default Headings
