import saveAreaMaterialType from '../../../Services/saveAreaMaterialType'

export default (dimensions) => async (dispatch, getState) => {

  const { id, areaGroupId } = getState().screens.PlotType.dimensionsModal
  
  const values = {
    dimensions,
  }

  if (!id) {
    values.area_group_id = areaGroupId
  }

  await dispatch(saveAreaMaterialType(id, values))

}
