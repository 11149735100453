import { createAction, handleActions } from 'redux-actions'

const setSupplierRates = createAction('MODALS/SUPPLIER_RATES/SET_SUPPLIER_RATES')
const resetSupplierRates = createAction('MODALS/SUPPLIER_RATES/RESET_SUPPLIER_RATES')

const defaultState = []

const reducer = handleActions(
  {
    [setSupplierRates]: (state, { payload }) => payload,
    [resetSupplierRates]: () => defaultState,
  },
  defaultState,
)

export { setSupplierRates, resetSupplierRates, reducer }
