import { getSiteRates } from '../../../../../HttpRequests/siteRates'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/isFetching'
import { setPagination } from '../State/pagination'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { site: { id: siteId } } = getState().screens.SiteView

  const { searchTerm, pagination } = getState().screens.SiteView.SiteRates

  const params = {
    'filter[site_id]': siteId,
    sort: 'order_no',
  }

  if (searchTerm) params.search = searchTerm

  params.include = 'material_type'

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getSiteRates({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
