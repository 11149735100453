import { closeMaterialRangeModal } from '../State/materialRangeModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeMaterialRangeModal())

  if (reload) dispatch(fetch())

}
