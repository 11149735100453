import { createSelector } from "reselect";
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectFitterSetAt = state => formValueSelector(FORM_NAME)(state, 'fitter_set_at');
const selectFitterSetByUser = state => formValueSelector(FORM_NAME)(state, 'fitter_set_by_user');

export default createSelector(
  selectFitterSetAt,
  selectFitterSetByUser,
  (fitterSetAt, fitterSetByUser) => {

    if (fitterSetByUser && fitterSetAt) {
      return `Set by ${fitterSetByUser.first_name} at ${moment(fitterSetAt).format('HH:mm DD/MM/YYYY')}`
    }
    
    return null;
  }
)