import { createAction, handleActions } from 'redux-actions'

const loadMaterialRangesCascaded = createAction('CORE/MATERIAL_RANGES_CASCADED/LOAD_MATERIAL_RANGES_CASCADED')
const clearMaterialRangesCascaded = createAction('CORE/MATERIAL_RANGES_CASCADED/CLEAR_MATERIAL_RANGES_CASCADED')

const defaultState = []

const reducer = handleActions(
  {
    [loadMaterialRangesCascaded]: (state, action) => action.payload,
    [clearMaterialRangesCascaded]: () => [],
  },
  defaultState,
)

export { loadMaterialRangesCascaded, clearMaterialRangesCascaded, reducer }
