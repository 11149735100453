import { createSelector } from 'reselect'
import { orderBy } from 'lodash'
import fittingRateOptions from '../../../../../../../Utils/fittingRateOptions'

const selectFittingRates = state => state.core.fittingRates

export default createSelector(
  selectFittingRates,
  (fittingRates) => fittingRateOptions(orderBy(fittingRates, 'title')),
)
