import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Select, Checkbox } from 'antd'
import { ExposeFormToHelper } from '../../../../../packages/sota'
import modalProps from '../../modalProps'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    materialTypes: PropTypes.array.isRequired,
    selectedAreaIds: PropTypes.array.isRequired,
    onToggleIsCustom: PropTypes.func.isRequired,
    onToggleAreaId: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      materialTypes,
      selectedAreaIds,
      onToggleIsCustom,
      onToggleAreaId,
      onSubmit,
      validate,
    } = this.props

    const { areas } = modalProps()

    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
      >

        {({ handleSubmit, values }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            colon={false}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <Field name='name'>
              {({ input, meta }) => (
                <Form.Item
                  label='Name'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='material_type_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Type'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    {materialTypes.map(materialType => (
                      <Select.Option key={materialType.id} value={materialType.id}>
                        {materialType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='is_custom' type='checkbox'>
              {({ input }) => (
                <Form.Item
                  label='Custom'
                >
                  <Checkbox
                    {...input}
                    onChange={e => {
                      input.onChange(e)
                      onToggleIsCustom()
                    }}
                  />
                </Form.Item>
              )}
            </Field>

            {!!values.is_custom && (

              <Field name='quote'>
                {() => (
                  <Form.Item
                    label='Quote'
                  >
                    <div className={styles.areas}>
                      <Checkbox.Group
                        value={selectedAreaIds}
                        onChange={onToggleAreaId}
                        options={areas.map(area => ({
                          label: area.name,
                          value: area.id,
                        }))}
                      />
                    </div>
                  </Form.Item>
                )}
              </Field>

            )}

          </Form>

        )}

      </FormProvider>

    )

  }

}
