import { findBuilder } from '../../../HttpRequests/builders';
import { loadBuilder } from '../State/builder';
import handleException from '../../../Core/Helpers/handleException';
import { getProps } from '../props';

export default () => async (dispatch) => {

  try {
    
    const { id } = getProps().match.params;

    const params = {};

    params.include = [
      'sites',
      'sites.manager',
    ].join();

    const response = await findBuilder({ id, params })

    dispatch(loadBuilder(response.data.data ));

  } catch (error) {

    handleException(error);

  }
}