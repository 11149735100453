import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectField, InputNumberField, DatePickerField, TextAreaField } from 'redux-form-antd-fields';

const labelColSpan = 6;
const wrapperColSpan = 15;

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    fitters: PropTypes.array.isRequired
  }

  render () {

    const {
      submitting,
      fitters
    } = this.props;

    const fitterOptions = fitters.map(item => ({
      value: item.id,
      label: item.name
    }));

    return (
      
      <React.Fragment>
        
        <Field
          name="fitter_id"
          label="Fitter"
          disabled={submitting}
          component={SelectField}
          options={fitterOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="amount"
          label="Amount (£)"
          disabled={submitting}
          component={InputNumberField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="date"
          label="Date"
          disabled={submitting}
          component={DatePickerField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="notes"
          label="Notes"
          disabled={submitting}
          component={TextAreaField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

      </React.Fragment>

    )
  }
}