import modalProps from '../modalProps'
import { resetControls } from '../State/controls'
import { resetMaterialTypes } from '../State/materialTypes'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetControls())
  dispatch(resetMaterialTypes())

}
