import { createAction, handleActions } from 'redux-actions'

const loadMaterialRanges = createAction('CORE/MATERIAL_RANGES/LOAD_MATERIAL_RANGES')
const clearMaterialRanges = createAction('CORE/MATERIAL_RANGES/CLEAR_MATERIAL_RANGES')

const defaultState = []

const reducer = handleActions(
  {
    [loadMaterialRanges]: (state, action) => action.payload,
    [clearMaterialRanges]: () => [],
  },
  defaultState,
)

export { loadMaterialRanges, clearMaterialRanges, reducer }
