import { openBuilderRateRangeVariantsModal } from '../../../State/builderRateRangeVariantsModal'

export default (materialRange) => (dispatch, getState) => {

  const { builderRate } = getState().modals.BuilderRate

  const rangeVariantIds = materialRange.range_variants.map(row => row.id)

  const rangeVariants = builderRate.range_variants.filter(row => rangeVariantIds.includes(row.id))

  dispatch(openBuilderRateRangeVariantsModal({ materialRange, rangeVariants }))

}
