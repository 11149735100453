import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import styles from './UploadProgress.css'

const UploadProgress = (props) => {

  const {
    show,
    queueLength,
    progress,
    filename,
  } = props

  if (!show) return null

  return (

    <div className={styles.wrapper}>

      <div className={styles.bar}>

        <Icon type="sync" spin className={styles.icon} />

        <div className={styles.filename}>
          {`${progress === 100 ? 'Processing' : 'Uploading'} ${filename}`}
        </div>

        <div className={styles.filesLeft}>
          {`${queueLength} files left`}
        </div>

        <div className={styles.progressOuter}>
          <div className={styles.progressInner} style={{ width: `${progress}%`}} />
        </div>

      </div>

    </div>

  )
}

UploadProgress.propTypes = {
  show: PropTypes.bool.isRequired,
  queueLength: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
}

export default UploadProgress