import { connect } from "react-redux";
import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import onCloseOrderCreateModal from "./Handlers/onCloseOrderCreateModal";
import didCreateOrder from "./Handlers/didCreateOrder";

const mapStateToProps = state => ({
  showOrderCreateModal: state.screens.Orders.showOrderCreateModal
});

const actionCreators = {
  componentDidMount,
  onCloseOrderCreateModal,
  didCreateOrder
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
