import { message } from 'antd'
import { reduce } from 'lodash'
import { createAreaMaterialType, updateAreaMaterialType } from '../../../HttpRequests/areaMaterialTypes'
import { handleException } from '../../../../packages/sota'

export default (id, values) => async () => {

  const data = { ...values }

  data.square_metres = reduce(data.dimensions, (sum, dimension) => sum + (dimension.length * dimension.width), 0)

  try {

    if (id) {

      await updateAreaMaterialType({ id, data })

    } else {

      await createAreaMaterialType({ data })

    }

    message.success('Changes Saved')

  } catch (error) {

    handleException(error)

    throw error

  }

}
