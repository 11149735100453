import axios from 'axios'

export const createMaterialRange = ({ data }) => axios.post(`material_ranges`, data)
export const deleteMaterialRange = ({ id }) => axios.delete(`material_ranges/${id}`)
export const findMaterialRange = ({ id, params } = {}) => axios.get(`material_ranges/${id}`, { params })
export const getMaterialRanges = ({ params } = {}) => axios.get(`material_ranges`, { params })
export const updateMaterialRange = ({ id, data }) => axios.put(`material_ranges/${id}`, data)

export const archiveMaterialRange = ({ id, data }) => axios.put(`material_ranges/${id}/archive`, data)
export const unarchiveMaterialRange = ({ id, data }) => axios.put(`material_ranges/${id}/unarchive`, data)
