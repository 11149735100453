import { createAction, handleActions } from 'redux-actions'

const openBuilderMaterialRangeModal = createAction('SCREENS/BUILDER_VIEW/OPEN_BUILDER_MATERIAL_RANGE_MODAL')
const closeBuilderMaterialRangeModal = createAction('SCREENS/BUILDER_VIEW/CLOSE_BUILDER_MATERIAL_RANGE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openBuilderMaterialRangeModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeBuilderMaterialRangeModal]: () => defaultState,
  },
  defaultState,
)

export { openBuilderMaterialRangeModal, closeBuilderMaterialRangeModal, reducer }
