import React from 'react'
import PropTypes from 'prop-types'

const Spacer = (props) => {

  const {
    width,
    height,
    className,
    style,
  } = props

  return (

    <div
      className={className}
      style={Object.assign({ width, height } , style)}
    />

  )
}

Spacer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
}

Spacer.defaultProps = {
  width: undefined,
  height: undefined,
  className: undefined,
  style: undefined,
}

export default Spacer