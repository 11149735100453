import { combineReducers } from 'redux'

import { reducer as siteRate } from './State/siteRate'
import { reducer as addMaterialRangeModal } from './State/addMaterialRangeModal'
import { reducer as siteRateRangeVariantsModal } from './State/siteRateRangeVariantsModal'

import SiteRateRangeVariantsModal from './Components/SiteRateRangeVariantsModal/reducer'

export default combineReducers({
  siteRate,
  addMaterialRangeModal,
  siteRateRangeVariantsModal,

  SiteRateRangeVariantsModal,
})
