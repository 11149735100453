import { createAction, handleActions } from 'redux-actions'

const openRangeVariantModal = createAction('MODALS/RANGE_VARIANTS/OPEN_RANGE_VARIANT_MODAL')
const closeRangeVariantModal = createAction('MODALS/RANGE_VARIANTS/CLOSE_RANGE_VARIANT_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openRangeVariantModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeRangeVariantModal]: () => defaultState,
  },
  defaultState,
)

export { openRangeVariantModal, closeRangeVariantModal, reducer }
