import moment from 'moment'
import { getMaterialRanges } from '../../HttpRequests/materialRanges'
import { loadMaterialRanges } from '../State/materialRanges'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return

    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'name',
      include: 'manufacturer,categories,siblings,range_variants',
    }

    const response = await getMaterialRanges({ params })

    dispatch(loadMaterialRanges(response.data.data))

  } catch (error) {
    
    throw error

  }

}
