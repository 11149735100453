import { connect } from 'react-redux'
import Static from './Static'

import onEditField from './Handlers/onEditField'

const mapStateToProps = (state) => ({
})

const actionCreators = {
  onEditField,
}

export default connect(mapStateToProps, actionCreators)(Static)
