import { createSelector } from 'reselect'
import { find } from 'lodash'
import selectAreaGroups from '../../../../../Selectors/selectAreaGroups'

const selectFields = state => state.screens.CostSheet.Areas.SetupModal.fields

export default createSelector(
  selectAreaGroups,
  selectFields,
  (areaGroups, fields) => {
    if (!fields.areaGroupId) return null
    return find(areaGroups, row => row.id === fields.areaGroupId)
  },
)
