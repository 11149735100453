import { get } from 'lodash'
import * as Sentry from '@sentry/browser'

const setupSentry = ({ local = false } = {}) => {

  const {
    SENTRY_DSN,
    APP_ENV,
    RELEASE,
    BUILD
  } = get(window, 'env', {})

  if (SENTRY_DSN && (local || APP_ENV !== 'local')) {

    Sentry.init({
      dsn: SENTRY_DSN,
      release: RELEASE,
      environment: APP_ENV,
      tags: { build: BUILD },
    })

    Sentry.configureScope((scope) => {
      scope.setTag("build", BUILD)
    })

  }

}

export default setupSentry