import React from 'react'
import { Row, Col } from 'antd'
import spacing from '../../Utils/spacing'

import Expenses from './Components/Expenses'
import Quotes from './Components/Quotes'
import Summary from './Components/Summary'

const Footer = () => (

  <Row gutter={spacing.width.block}>

    <Col span={8}>

      <Expenses />

    </Col>

    <Col span={8}>

      <Quotes />

    </Col>

    <Col span={8}>

      <Summary />

    </Col>

  </Row>

)

export default Footer
