import { connect } from 'react-redux'
import Component from './Component'

import onSelectFitter from './Handlers/onSelectFitter'

const mapStateToProps = (state) => ({
  fitters: state.modals.AvailableFitters.fitters,
  fetching: state.modals.AvailableFitters.fetching,
})

const actionCreators = {
  onSelectFitter
}

export default connect(mapStateToProps, actionCreators)(Component)
