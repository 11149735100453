import React from 'react'
import { Modal, Icon } from 'antd'
import deleteMaterialRange from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: 'Delete Range',
    content: 'Are you sure you want to delete this range?',
    icon: <Icon type='delete' style={{ color: '#ff4d4f' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteMaterialRange()),
  })

}
