import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { rangeVariant } = modalProps()

  if (!rangeVariant) return

  const initialValues = {
    ...rangeVariant,
  }

  dispatch(setFormInitialValues(initialValues))

}
