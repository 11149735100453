import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import Table from "./Components/Table";
import SupplierModal from "../../Modals/Supplier";

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    showSupplierModal: PropTypes.bool.isRequired,
    onCloseSupplierModal: PropTypes.func.isRequired,
    didCreateSupplier: PropTypes.func.isRequired
  };

  static defaultProps = {

  };

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      showSupplierModal,
      onCloseSupplierModal,
      didCreateSupplier
    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header />

        <Table />

        {showSupplierModal && (
          <SupplierModal
            onClose={onCloseSupplierModal}
            didCreate={didCreateSupplier}
          />
        )}

      </Screen>

    )
  }
}
