import modalProps from '../modalProps'
import { findAreaRangeQuote } from '../../../HttpRequests/areaRangeQuotes'
import { setAreaRangeQuote } from '../State/areaRangeQuote'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = modalProps()

  if (!id) return

  try {

    const response = await findAreaRangeQuote({ id })

    dispatch(setAreaRangeQuote(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
