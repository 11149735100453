import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openOrderNoteModal = createAction('SCREENS/ORDER_VIEW/ORDER_NOTES/OPEN_ORDER_NOTE_MODAL')
const closeOrderNoteModal = createAction('SCREENS/ORDER_VIEW/ORDER_NOTES/CLOSE_ORDER_NOTE_MODAL')

const defaultState = {
  show: false,
  orderNote: null,
  orderId: null,
}

const reducer = handleActions(
  {
    [openOrderNoteModal]: (state, action) => ({
      ...state,
      show: true,
      orderNote: get(action.payload, 'orderNote'),
      orderId: get(action.payload, 'orderId'),
    }),
    [closeOrderNoteModal]: () => defaultState,
  },
  defaultState,
)

export { openOrderNoteModal, closeOrderNoteModal, reducer }
