import { createAction, handleActions } from 'redux-actions'

const openSiteRateRangeVariantsModal = createAction('MODALS/SITE_RATE/OPEN_SITE_RATE_RANGE_VARIANTS_MODAL')
const closeSiteRateRangeVariantsModal = createAction('MODALS/SITE_RATE/CLOSE_SITE_RATE_RANGE_VARIANTS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSiteRateRangeVariantsModal]: (state, { payload }) => Object.assign({}, state, {
      show: true,
      ...payload,
    }),
    [closeSiteRateRangeVariantsModal]: () => defaultState,
  },
  defaultState,
)

export { openSiteRateRangeVariantsModal, closeSiteRateRangeVariantsModal, reducer }
