import { createAction, handleActions } from 'redux-actions';

const loadInspectors = createAction('SCREENS/INSPECTORS/LOAD_INSPECTORS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadInspectors]: (state, action) => action.payload,
  },
  defaultState
);

export { loadInspectors, reducer };