import React from 'react'
import { Modal, Icon } from 'antd'
import regenerate from '../Services/regenerate'

export default () => (dispatch, getState) => {

  const { site } = getState().screens.SiteView

  Modal.confirm({
    title: 'Regenerate Site Cost Matrix?',
    content: 'This action will automatically regenerate the site\'s cost matrix, using the site\'s rates and each plot type\'s take-off.',
    icon: <Icon type="reload" style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Regenerate',
    onOk: () => dispatch(regenerate('Site', site.id)),
  })

}
