import { connect } from 'react-redux'
import FittingRates from './FittingRates'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseFittingRateModal from './Handlers/onCloseFittingRateModal'

const mapStateToProps = (state) => ({
  fittingRateModal: state.modals.FittingRates.fittingRateModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseFittingRateModal,
}

export default connect(mapStateToProps, actionCreators)(FittingRates)
