import { createAction, handleActions } from 'redux-actions';

const setPiggybackOrderSearchTerm = createAction("MODALS/JOB/SET_PIGGYBACK_ORDER_SEARCH_TERM");

const defaultState = "";

const reducer = handleActions(
  {
    [setPiggybackOrderSearchTerm]: (state, action) => action.payload
  },
  defaultState
);

export { setPiggybackOrderSearchTerm, reducer };
