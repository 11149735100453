import chance from 'chance'
import { setCostSheetField } from '../../../../../State/order'

export default (guid, action) => (dispatch, getState) => {

  const { cost_sheet_quotes: costSheetQuotes } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetQuotes = []

  costSheetQuotes.forEach(costSheetQuote => {

    if (costSheetQuote.guid !== guid) {
      newCostSheetQuotes.push({ ...costSheetQuote })
      return
    }

    if (action === 'add') {
      newCostSheetQuotes.push({ ...costSheetQuote })
      newCostSheetQuotes.push({
        guid: chance().guid(),
      })
    }

    if (action === 'duplicate') {
      newCostSheetQuotes.push({ ...costSheetQuote })
      newCostSheetQuotes.push({
        ...costSheetQuote,
        id: undefined,
        guid: chance().guid(),
      })
    }

    if (action === 'remove') {
      // We just simply omit it
    }

  })

  dispatch(setCostSheetField({ field: 'cost_sheet_quotes', value: newCostSheetQuotes }))

}
