import { message } from 'antd'
import { sendRemedialQuestionnaire } from '../../../HttpRequests/orders'
import fetchOrder from './fetchOrder'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { order } = getState().screens.OrderView
  
  try {
    
    await sendRemedialQuestionnaire({ id: order.id })

    dispatch(fetchOrder())

    message.success('Email was sent successfully')

  } catch (error) {
    
    handleException(error)

  }

}
