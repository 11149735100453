import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import SupplierRateModal from '../SupplierRate'

import Controls from './Components/Controls'
import Table from './Components/Table'

const Spacer = () => <div style={{ height: 12 }} />

const SupplierRates = props => {

  const {
    supplier,
    supplierRateModal,
    componentDidMount,
    componentWillUnmount,
    onCloseSupplierRateModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Modal
      title={`${supplier.name} Rates`}
      visible
      width={700}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >

      <Controls />
      
      <Spacer />

      <Table />

      {supplierRateModal.show && (
        <SupplierRateModal
          {...supplierRateModal}
          onClose={onCloseSupplierRateModal}
        />
      )}

    </Modal>

  )

}

SupplierRates.propTypes = {
  supplier: PropTypes.object.isRequired,
  supplierRateModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseSupplierRateModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SupplierRates
