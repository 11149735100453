import { message } from 'antd';

import { createFitter } from '../../../HttpRequests/fitters';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {

  try {

    const response = await createFitter({ data });

    message.success('Fitter Created');

    return response.data.data;
    
  }  catch(error) {
    
    handleException(error);

    return false;

  }
}
