import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectField, InputField, DatePickerField, TextAreaField } from 'redux-form-antd-fields';

const labelColSpan = 5;
const wrapperColSpan = 16;

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    inspectors: PropTypes.array.isRequired,
    builders: PropTypes.array.isRequired,
    sites: PropTypes.array.isRequired,
    values: PropTypes.object,
    onChangeBuilderId: PropTypes.func.isRequired,
  }

  static defaultProps = {
    values: {}
  }

  render () {

    const {
      submitting,
      inspectors,
      builders,
      sites,
      values,
      onChangeBuilderId,
    } = this.props;

    const inspectorOptions = inspectors.map(item => ({ value: item.id, label: item.name }))

    const builderOptions = builders.map(item => ({ value: item.id, label: item.name }))

    const siteOptions = sites.filter(item => item.builder_id === values.builder_id).map(item => ({ value: item.id, label: item.name }))

    return (

      <React.Fragment>
        
        <Field
          name="inspector_id"
          label="Inspector"
          component={SelectField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          options={inspectorOptions}
          required
        />
        
        <Field
          name="builder_id"
          label="Builder"
          component={SelectField}
          onChange={onChangeBuilderId}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          options={builderOptions}
          required
        />
        
        <Field
          name="site_id"
          label="Site"
          component={SelectField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          options={siteOptions}
          required
        />

        <Field
          name="plots"
          label="Plots"
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />
        
        <Field
          name="date"
          label="Date"
          component={DatePickerField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="notes"
          label="Notes"
          component={TextAreaField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ height: 130 }}
        />

      </React.Fragment>

    )
  }
}