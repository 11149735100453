import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import Component from './Component';

import showApprovalBanner from './Selectors/showApprovalBanner'

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onClickCancel from './Handlers/onClickCancel';
import onCloseAvailableFittersModal from './Handlers/onCloseAvailableFittersModal'
import onCloseNotesModal from './Handlers/onCloseNotesModal'

import { formConfig, FORM_NAME } from './Form';

const value = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
  showApprovalBanner: showApprovalBanner(state),
  inspectionNotes: value(state, 'inspection_notes'),
  availableFittersModal: state.modals.Job.availableFittersModal,
  notesModal: state.modals.Job.notesModal,
});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  onClickCancel,
  onCloseAvailableFittersModal,
  onCloseNotesModal,
};

export default connect(mapStateToProps, actionCreators)(reduxForm(formConfig)(Component));