import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Button, Icon } from 'antd';
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    jobs: PropTypes.array.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickJob: PropTypes.func.isRequired
  }

  renderHeader = () => (

    <div className={styles.header}>

      <div className={styles.title}>
        Jobs
      </div>

      <Button onClick={this.props.onClickAdd}>
        <Icon type="plus-circle" />
        Add Job
      </Button>

    </div>

  )
  
  render () {

    const {
      jobs,
      onClickJob
    } = this.props;

    const columns = [
      
      {
        title: 'Fitter',
        dataIndex: 'fitter.name',
        width: 170,
        render: (text, record) => (
          <div onClick={() => onClickJob(record.id)} role="presentation" className={styles.link}>
            {record.fitter ? record.fitter.name : 'Unallocated'}
          </div>
        )
      },

      { 
        title: 'Dates', 
        dataIndex: 'dates',
        width: 150,
        render: (text, record) => `${moment(record.start_date).format('D MMM YYYY')} ${record.start_date !== record.end_date ? ` ~ ${moment(record.end_date).format('D MMM YYYY')}` : ''}`
      },
      
      {
        title: 'Metres',
        dataIndex: 'metres',
        width: 150,
        render: (text, record) => record.metres
      }
      
    ];

    return (

      <Table
        dataSource={jobs}
        columns={columns} 
        rowKey={record => record.id}
        pagination={false}
        bordered
        title={this.renderHeader}
        locale={{ emptyText: <b>There are no jobs for this order yet</b>}}
      />

    )
  }
}