export default (values) => {

  const errors = {};

  const flags = values.flags || [];

  if (flags.includes('custom_entry')) {

    if (!values.order_category_custom) errors.order_category_custom = 'This field is required';
    if (!values.builder_custom) errors.builder_custom = 'This field is required';
    if (!values.site_custom) errors.site_custom = 'This field is required';
    if (!values.town_custom) errors.town_custom = 'This field is required';

  } else {

    if (!values.order_category_id) errors.order_category_id = 'This field is required';
    if (!values.builder_id) errors.builder_id = 'This field is required';
    if (!values.site_id) errors.site_id = 'This field is required';

  }

  if (!values.plot_number) errors.plot_number = 'This field is required';
  if (!values.date) errors.date = 'This field is required';

  return errors;
  
}