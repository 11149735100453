import { connect } from 'react-redux';
import Component from './Component';

import showImportButton from './Selectors/showImportButton'

import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onClickNew from './Handlers/onClickNew';
import onClickImport from './Handlers/onClickImport';

const mapStateToProps = (state) => ({
  searchTerm: state.screens.Orders.searchTerm,
  showImportButton: showImportButton(state),
});

const actionCreators = {
  onChangeSearch,
  onSubmitSearch,
  onClickNew,
  onClickImport,
};

export default connect(mapStateToProps, actionCreators)(Component);
