import { change, untouch } from 'redux-form';
import { FORM_NAME } from '../../../Form';

export default () => (dispatch) => {

  dispatch(change(FORM_NAME, 'plot_type_id', null));

  dispatch(untouch(FORM_NAME,
    'plot_type_id'
  ));
  
}