import { combineReducers } from 'redux'

import { reducer as order } from './State/order'
import { reducer as original } from './State/original'
import { reducer as editing } from './State/editing'
import { reducer as saving } from './State/saving'
import { reducer as materialTypesModal } from './State/materialTypesModal'
import { reducer as manufacturersModal } from './State/manufacturersModal'
import { reducer as materialRangesModal } from './State/materialRangesModal'
import { reducer as suppliersModal } from './State/suppliersModal'
import { reducer as fittingRatesModal } from './State/fittingRatesModal'

import Areas from './Components/Areas/reducer'
import Materials from './Components/Materials/reducer'

export default combineReducers({
  order,
  original,
  editing,
  saving,
  materialTypesModal,
  manufacturersModal,
  materialRangesModal,
  suppliersModal,
  fittingRatesModal,

  Areas,
  Materials,
})
