import { createAction, handleActions } from 'redux-actions'

const openSupplierRateModal = createAction('MODALS/SUPPLIER_RATES/OPEN_SUPPLIER_RATE_MODAL')
const closeSupplierRateModal = createAction('MODALS/SUPPLIER_RATES/CLOSE_SUPPLIER_RATE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSupplierRateModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeSupplierRateModal]: () => defaultState,
  },
  defaultState,
)

export { openSupplierRateModal, closeSupplierRateModal, reducer }
