import { connect } from 'react-redux'
import Form from './Form'

import selectMaterialTypes from './Selectors/selectMaterialTypes'
import selectMaterialRanges from './Selectors/selectMaterialRanges'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  materialTypes: selectMaterialTypes(state),
  initialValues: state.modals.SiteMaterialRange.Form.formState.initialValues,
  materialRanges: selectMaterialRanges(state),
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
