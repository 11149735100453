import { createAction, handleActions } from 'redux-actions';

const setFromOrderSearchTerm = createAction("SCREENS/ORDER_VIEW/SET_FROM_ORDER_SEARCH_TERM");

const defaultState = "";

const reducer = handleActions(
  {
    [setFromOrderSearchTerm]: (state, action) => action.payload
  },
  defaultState
);

export { setFromOrderSearchTerm, reducer };
