import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Form from './Form';

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    order: PropTypes.object,
    plotTypes: PropTypes.array.isRequired,
    materialTypes: PropTypes.array.isRequired,

    componentWillMount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    order: null
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      submitting,
      order,
      plotTypes,
      materialTypes,

      onClickCancel,
      onClickSubmit
    } = this.props;

    return (
        
      <Modal
        title={`${order ? 'Update Order': 'New Order'}`}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={`${order ? 'Update' : 'Create'}`}
      >
        
        <Form
          plotTypes={plotTypes}
          materialTypes={materialTypes}
        />

      </Modal>
    )
  }
}