import moment from 'moment';
import { getUsers } from '../../HttpRequests/users';
import { loadUsers } from '../State/users';

let lastGetTimestamp = null;

const cacheTTL = 60000;

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'));

      if (now < (lastGetTimestamp + cacheTTL)) return;
    }
      
    lastGetTimestamp = Number(moment().format('x'));

    const params = {
      pagination: 0,
      sort: 'first_name,last_name',
      append: 'site_count'
    }

    const response = await getUsers({ params });

    dispatch(loadUsers(response.data.data));

  } catch (error) {
    
    throw error;

  }
}