import chance from 'chance'
import { setCostSheetField } from '../../../State/order'

export default (values = {}) => (dispatch, getState) => {

  const { cost_sheet_materials: costSheetMaterials } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetMaterials = [
    ...costSheetMaterials,
    { guid: chance().guid(), ...values },
  ]

  dispatch(setCostSheetField({ field: 'cost_sheet_materials', value: newCostSheetMaterials }))

}
