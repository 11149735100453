import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { siteId, onClose, didCreate, didUpdate } = getProps();
  
  if (!siteId) {

    const site = await dispatch(create(values));

    if (site) {
      onClose();
      didCreate(site);
    }

  } else {

    const site = await dispatch(update(values));

    if (site) {
      onClose();
      didUpdate(site);
    }

  }

};
