import { completeJobs } from '../../../HttpRequests/jobs'
import { setFetching } from '../State/fetching'
import _props from '../props'
import fetch from './fetch'
import { clearSelectedRowKeys } from '../State/selectedRowKeys'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  try {

    const data = getState().modals.CompletedJobs.selectedRowKeys

    dispatch(setFetching(true))

    await completeJobs({ data })

    dispatch(clearSelectedRowKeys())

    await dispatch(fetch())

    dispatch(setFetching(false))

    if (getState().modals.CompletedJobs.jobs.length === 0) {
      _props.get().onClose()
    }

  } catch (error) {

    dispatch(setFetching(false))

    handleException(error)

  }

}