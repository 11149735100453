import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createMaterialRange, updateMaterialRange } from '../../../../../HttpRequests/materialRanges'
import fetchMaterialRanges from '../../../../../Core/Services/fetchMaterialRanges'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { materialRange } = modalProps()

  const data = {
    name: values.name,
    manufacturer_id: values.manufacturer_id,
    material_type_id: values.material_type_id,
    categories: values.categories,
    siblings: values.siblings,
    square_metres: values.square_metres,
    wastage_perc: values.wastage_perc,
    target: values.target,
    coverage: values.coverage,
    symbol: values.symbol || null,
  }

  try {

    if (materialRange) {
      await updateMaterialRange({ id: materialRange.id, data })
      message.success('Updated Range')
    } else {
      await createMaterialRange({ data })
      message.success('Created Range')
    }

    modalProps().onClose(true)

    dispatch(fetchMaterialRanges({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
