import { createAction, handleActions } from 'redux-actions'

const openEditFileModal = createAction('SCREENS/ORDER_VIEW/OPEN_EDIT_FILE_MODAL')
const closeEditFileModal = createAction('SCREENS/ORDER_VIEW/CLOSE_EDIT_FILE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openEditFileModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeEditFileModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openEditFileModal, closeEditFileModal, reducer }