import { openDimensionsModal } from '../../../State/dimensionsModal'
import onSaveDimensions from './onSaveDimensions'
import onCloseDimensionsModal from './onCloseDimensionsModal'

export default (id, areaGroupId, dimensions) => (dispatch) => {

  dispatch(openDimensionsModal({
    id,
    areaGroupId,
    dimensions,
    onSave: onSaveDimensions,
    onClose: onCloseDimensionsModal,
  }))

}
