import { initialize } from 'redux-form';
import { findBuilder } from '../../../HttpRequests/builders';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { builderId } = getProps();
  
  if (builderId) {

    const response = await findBuilder({ id: builderId });

    const builder = response.data.data;

    dispatch(initialize(FORM_NAME, builder))
    
  } else {

    dispatch(initialize(FORM_NAME, { protection: false }))

  }

}