import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'

export default () => () => {

  const { plotNote } = modalProps()

  if (!plotNote) return

  const initialValues = {
    note: plotNote.note,
  }

  defer(() => formService().initialize(initialValues))

}
