import { createSelector } from 'reselect'

const selectJobStats = state => state.screens.Board.jobStats
const selectShowWeekends = state => state.screens.Board.showWeekends

export default createSelector(
  selectJobStats,
  selectShowWeekends,
  (jobStats, showWeekends) => {

    const tableRows = []

    const { days, totals } = jobStats

    const daysFiltered = showWeekends ? days : days.filter(day => !day.is_weekend)

    daysFiltered.forEach(day => {
      tableRows.push({
        type: 'day',
        key: day.date,
        ...day,
      })
    })

    tableRows.push({
      type: 'totals',
      key: 'totals',
      jobs_total: showWeekends ? totals.jobs_total : totals.jobs_total_week,
      jobs_started: showWeekends ? totals.jobs_started : totals.jobs_started_week,
      job_stats_by_tag: showWeekends ? totals.job_stats_by_tag : totals.job_stats_by_tag_week,
    })

    return tableRows

  },
)
