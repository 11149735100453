export default (key) => (dispatch, getState) => {

  const { order } = getState().screens.CostSheet

  const { token } = getState()
  
  switch (key) {
    case 'cost-sheet':
      window.open(`/api/cost_sheets/${order.id}/cost_sheet_pdf?token=${token}`)
      break
    case 'job-sheet':
      window.open(`/api/cost_sheets/${order.id}/job_sheet_pdf?token=${token}`)
      break
    case 'supply-sheet':
      window.open(`/api/cost_sheets/${order.id}/supply_sheet_pdf?token=${token}`)
      break
    default:
      //
      break
  }

}
