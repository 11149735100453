import { initialize } from 'redux-form';
import { findSite } from '../../../HttpRequests/sites';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { siteId, builderId } = getProps();
  
  if (siteId) {

    const response = await findSite({ id: siteId });

    const site = response.data.data;

    dispatch(initialize(FORM_NAME, site))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      builder_id: builderId
    }))

  }

}