import * as React from 'react'

import Details from './Components/Details'

export default class extends React.PureComponent {

  render() {
    
    return (

      <div>

        <br />
        
        <Details />

      </div>
      
    )

  }

}
