export default () => (dispatch, getState) => {

  const state = getState()

  const { token } = state

  const { dateRange } = state.screens.Board

  const date = dateRange[0]

  const params = {
    date,
  }

  let url = `/api/jobs/wip/export?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key] !== undefined) url += `&${key}=${params[key]}`
  })

  window.open(url)

}
