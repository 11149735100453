import { setPagination } from '../../../State/pagination';
import fetchFitters from '../../../Services/fetchFitters';

export default (pagination) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  await dispatch(fetchFitters());

}