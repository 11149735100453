import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchOnHoldReasons from '../../../Core/Services/fetchOnHoldReasons';
import fetchOrders from '../Services/fetchOrders';

export default () => async (dispatch) => {
  
  dispatch(fetchUsers());
  dispatch(fetchOnHoldReasons());
  dispatch(fetchOrders());
  
}