import React from 'react'
import PropTypes from 'prop-types'
import HeightSpacer from '../../../HeightSpacer'

import Controls from './Components/Controls'
import Headings from './Components/Headings'
import Item from './Components/Item'
import Placeholder from './Components/Placeholder'

const Quotes = props => {

  const {
    items,
  } = props

  return (
    
    <React.Fragment>

      <Controls />

      <HeightSpacer type='controls' />

      {!!items.length && <Headings />}

      {items.map(item => <Item key={item.guid} item={item} />)}

      {!items.length && <Placeholder />}

    </React.Fragment>
    
  )

}

Quotes.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Quotes
