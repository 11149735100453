import { closeMaterialTypeModal } from '../State/materialTypeModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeMaterialTypeModal())

  if (reload) dispatch(fetch())

}
