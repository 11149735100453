import PropTypes from 'prop-types'
import { useForm } from 'react-final-form'

const ExposeFormToHelper = (props) => {

  const { helper, children } = props

  const form = useForm()

  helper(form)

  return children
}

ExposeFormToHelper.propTypes = {
  helper: PropTypes.func.isRequired,
  children: PropTypes.node,
}

ExposeFormToHelper.defaultProps = {
  children: null,
}

export default ExposeFormToHelper