import { createAction, handleActions } from 'redux-actions'

const setMaterialType = createAction('SCREENS/MATERIALS/MATERIAL_RANGES/SET_MATERIAL_TYPE')
const resetMaterialType = createAction('SCREENS/MATERIALS/MATERIAL_RANGES/RESET_MATERIAL_TYPE')

const defaultState = null

const reducer = handleActions(
  {
    [setMaterialType]: (state, action) => action.payload,
    [resetMaterialType]: () => defaultState,
  },
  defaultState,
)

export { setMaterialType, resetMaterialType, reducer }
