import { combineReducers } from 'redux'

import { reducer as setupModal } from './State/setupModal'
import { reducer as addModal } from './State/addModal'
import { reducer as dimensionsModal } from './State/dimensionsModal'
import { reducer as notesModal } from './State/notesModal'

import SetupModal from './Components/SetupModal/reducer'
import AddModal from './Components/AddModal/reducer'

export default combineReducers({
  setupModal,
  addModal,
  dimensionsModal,
  notesModal,

  SetupModal,
  AddModal,
})
