import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Menu.css'

import MenuItem from './MenuItem'
import MenuDivider from './MenuDivider'

const Menu = (props) => {

  const {
    children,
    className,
    style,
  } = props

  return (

    <div
      className={classNames(styles.menu, className)}
      style={style}
    >
      {children}
    </div>

  )
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

Menu.defaultProps = {
  className: undefined,
  style: undefined,
}

Menu.Item = MenuItem
Menu.Divider = MenuDivider

export default Menu