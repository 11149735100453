import { createAction, handleActions } from 'redux-actions'

const openMaterialTypesModal = createAction('SCREENS/COST_SHEET/OPEN_MATERIAL_TYPES_MODAL')
const closeMaterialTypesModal = createAction('SCREENS/COST_SHEET/CLOSE_MATERIAL_TYPES_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openMaterialTypesModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeMaterialTypesModal]: () => defaultState,
  },
  defaultState,
)

export { openMaterialTypesModal, closeMaterialTypesModal, reducer }
