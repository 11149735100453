import { connect } from "react-redux";
import Component from "./Component";

import onClickEdit from "./Handlers/onClickEdit";
import onChangeTab from "./Handlers/onChangeTab";

const mapStateToProps = state => ({
  builder: state.screens.BuilderView.builder,
  activeTab: state.screens.BuilderView.activeTab
});

const actionCreators = {
  onClickEdit,
  onChangeTab
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
