import { getBuilderMaterialRanges } from '../../../../../HttpRequests/builderMaterialRanges'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/isFetching'
import { setPagination } from '../State/pagination'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { builder: { id: builderId } } = getState().screens.BuilderView

  const { searchTerm, pagination } = getState().screens.BuilderView.BuilderMaterialRanges

  const params = {
    'filter[builder_id]': builderId,
    sort: 'order_no',
  }

  if (searchTerm) params.search = searchTerm

  params.include = 'material_type,material_range.manufacturer'

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getBuilderMaterialRanges({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
