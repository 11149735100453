import { createAction, handleActions } from 'redux-actions'

const openAreaGroupModal = createAction('SCREENS/PLOT_TYPES/OPEN_AREA_GROUP_MODAL')
const closeAreaGroupModal = createAction('SCREENS/PLOT_TYPES/CLOSE_AREA_GROUP_MODAL')

const defaultState = {
  show: false,
  id: null,
  plotTypeId: null,
}

const reducer = handleActions(
  {
    [openAreaGroupModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      plotTypeId: action.payload.plotTypeId,
    }),
    [closeAreaGroupModal]: () => defaultState,
  },
  defaultState,
)

export { openAreaGroupModal, closeAreaGroupModal, reducer }
