import _props from '../props'
import { clearJobs } from '../State/jobs'
import { clearSelectedRowKeys } from '../State/selectedRowKeys'

export default () => (dispatch) => {

  _props.clear()

  dispatch(clearJobs())
  dispatch(clearSelectedRowKeys())
  
}