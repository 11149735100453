import { createAction, handleActions } from 'redux-actions'

const openFittingRatesModal = createAction('SCREENS/COST_SHEET/OPEN_FITTING_RATES_MODAL')
const closeFittingRatesModal = createAction('SCREENS/COST_SHEET/CLOSE_FITTING_RATES_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openFittingRatesModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeFittingRatesModal]: () => defaultState,
  },
  defaultState,
)

export { openFittingRatesModal, closeFittingRatesModal, reducer }
