import fetchOrders from '../../../Services/fetchOrders';
import { setFilters, resetFilters } from '../../../State/filters';
import { setPagination } from '../../../State/pagination';
import { setSelectedRowKeys } from '../../../State/selectedRowKeys';

export default (filter, value) => async (dispatch) => {
  
  dispatch(setFilters({ [filter]: value }));

  if (filter === 'type') {
    if (value === 'credits') dispatch(resetFilters());
    dispatch(setFilters({ questionnaire_status: undefined }));
    dispatch(setFilters({ credit_status: undefined }));
  }

  dispatch(setPagination({ current: 1 }));

  dispatch(setSelectedRowKeys([]));
  
  dispatch(fetchOrders());
  
}