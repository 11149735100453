import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectSites = state => state.core.sites;
const selectBuilderId = state => formValueSelector(FORM_NAME)(state, 'builder_id');

export default createSelector(
  selectSites,
  selectBuilderId,
  (sites, builderId) => {
    
    if (builderId) {

      return sites.filter(site => site.builder_id === builderId).map(site => ({
        label: site.name,
        value: site.id
      }))

    }

    return [];

  }
)