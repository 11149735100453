import { find } from 'lodash'
import createAreaSiteRate from '../Services/createAreaSiteRate'
import deleteAreaSiteRate from '../Services/deleteAreaSiteRate'

export default (area, siteRate, value) => (dispatch) => {

  if (value === true) {

    dispatch(createAreaSiteRate(area.id, siteRate.id))

  } else {

    const areaSiteRate = find(area.area_site_rates, row => row.site_rate_id === siteRate.id)

    dispatch(deleteAreaSiteRate(areaSiteRate.id))

  }

}
