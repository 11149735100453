import moveJob from '../../../Services/moveJob'

export default (event, date) => (dispatch) => {

  event.preventDefault()

  const id = Number(event.dataTransfer.getData("id"))

  dispatch(moveJob(id, date))

}

