import { combineReducers } from 'redux'

import { reducer as orderSearchTerm } from './State/orderSearchTerm'
import { reducer as piggybackOrderSearchTerm } from './State/piggybackOrderSearchTerm'
import { reducer as availableFittersModal } from './State/availableFittersModal'
import { reducer as notesModal } from './State/notesModal'
import { reducer as plotNotes } from './State/plotNotes'

export default combineReducers({
  orderSearchTerm,
  piggybackOrderSearchTerm,
  availableFittersModal,
  notesModal,
  plotNotes,
})
