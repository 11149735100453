import { createAction, handleActions } from 'redux-actions'

const openManufacturerModal = createAction('MODALS/MANUFACTURERS/OPEN_MANUFACTURER_MODAL')
const closeManufacturerModal = createAction('MODALS/MANUFACTURERS/CLOSE_MANUFACTURER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openManufacturerModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeManufacturerModal]: () => defaultState,
  },
  defaultState,
)

export { openManufacturerModal, closeManufacturerModal, reducer }
