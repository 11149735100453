import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Dropdown, Menu, Radio, Icon, DatePicker, Input, Cascader } from 'antd';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    mode: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    searchTerm: PropTypes.string.isRequired,
    dateRange: PropTypes.array.isRequired,
    otherPeriodsWithResults: PropTypes.array.isRequired,
    canViewInspections: PropTypes.bool.isRequired,
    canViewJobBoardStats: PropTypes.bool.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    onChangeSource: PropTypes.func.isRequired,
    onSubmitSearch: PropTypes.func.isRequired,
    onClickAddButton: PropTypes.func.isRequired,
    onClickPrevButton: PropTypes.func.isRequired,
    onClickNextButton: PropTypes.func.isRequired,
    onChangeDate: PropTypes.func.isRequired,
    onSelectOtherPeriod: PropTypes.func.isRequired,
    onClickCompleted: PropTypes.func.isRequired,
    onClickMetresSummary: PropTypes.func.isRequired,
    onClickRebate: PropTypes.func.isRequired,
    onClickWip: PropTypes.func.isRequired,
    onClickRemedialDates: PropTypes.func.isRequired,
  }

  render () {

    const {
      mode,
      source,
      searchTerm,
      dateRange,
      otherPeriodsWithResults,
      canViewInspections,
      canViewJobBoardStats,
      onChangeSource,
      onChangeSearch,
      onSubmitSearch,
      onClickAddButton,
      onClickPrevButton,
      onClickNextButton,
      onChangeDate,
      onSelectOtherPeriod,
      onClickCompleted,
      onClickMetresSummary,
      onClickRebate,
      onClickWip,
      onClickRemedialDates,
    } = this.props;

    return (
      
      <div className={styles.wrapper}>

        <div className={styles.left}>

          <Radio.Group value={source} onChange={onChangeSource} buttonStyle="solid">

            <Radio.Button
              style={{ minWidth: 90, textAlign: 'center', fontSize: 12 }}
              value='JOBS'
              key='JOBS'
            >
              Jobs
            </Radio.Button>

            <Radio.Button
              style={{ minWidth: 90, textAlign: 'center', fontSize: 12 }}
              value='HOLIDAYS'
              key='HOLIDAYS'
            >
              Holidays
            </Radio.Button>

            {canViewInspections && (

              <Radio.Button
                style={{ minWidth: 90, textAlign: 'center', fontSize: 12 }}
                value='INSPECTOR_VISITS'
                key='INSPECTOR_VISITS'
              >
                Inspections
              </Radio.Button>

            )}

            {canViewInspections && (

              <Radio.Button
                style={{ minWidth: 90, textAlign: 'center', fontSize: 12 }}
                value='INSPECTIONS'
                key='INSPECTIONS'
              >
                Inspections [NEW]
              </Radio.Button>

            )}

            {canViewJobBoardStats && (

              <Radio.Button
                style={{ minWidth: 90, textAlign: 'center', fontSize: 12 }}
                value='JOB_STATS'
                key='JOB_STATS'
              >
                Stats
              </Radio.Button>

            )}

          </Radio.Group>

        </div>

        <div className={styles.center}>

          <Button onClick={onClickPrevButton}>
            <Icon type="arrow-left" />
          </Button>

          <div style={{ width: 8 }} />

          <DatePicker
            onChange={onChangeDate}
            value={undefined}
            placeholder={mode === 'WEEK' ? `${moment(dateRange[0]).format('DD MMM')} ~ ${moment(dateRange[1]).format('DD MMM')}` : moment(dateRange[0]).format('MMM YYYY')}
            style={{ width: 300, textAlign: 'center' }}
          />

          <div style={{ width: 8 }} />

          <Button onClick={onClickNextButton}>
            <Icon type="arrow-right" />
          </Button>

        </div>

        <div className={styles.right}>

          {(source === 'JOBS' && otherPeriodsWithResults.length > 0) && (
            <Cascader
              onChange={onSelectOtherPeriod}
              options={otherPeriodsWithResults.map(period => ({
                label: `${mode === 'WEEK' ? moment(period.period).format('[Week] DD MMM YYYY') : moment(period.period).format('MMM YYYY')} (${period.count} Jobs)`,
                value: period.period,
              }))}
            >
              <div className={styles.otherPeriodsWithResults}>
                {`${otherPeriodsWithResults.length} Periods `}
                <Icon type="caret-down" />
              </div>
            </Cascader>
          )}

          {source !== 'JOB_STATS' && (

            <React.Fragment>

              <div style={{ width: 16 }} />

              <Input.Search
                style={{ width: 200 }}
                placeholder="Search"
                onChange={onChangeSearch}
                onSearch={onSubmitSearch}
                value={searchTerm}
              />

              <div style={{ width: 8 }} />

              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item onClick={onClickCompleted}>Completed Jobs Export</Menu.Item>
                    <Menu.Item onClick={onClickMetresSummary}>Metres Summary Export</Menu.Item>
                    <Menu.Item onClick={onClickRebate}>Job Rebates Export</Menu.Item>
                    <Menu.Item onClick={onClickWip}>WIP Export</Menu.Item>
                    <Menu.Item onClick={onClickRemedialDates}>Remedial Appointments Export</Menu.Item>
                  </Menu>
                )}
              >
                <Button>
                  <Icon type="download" />
                </Button>
              </Dropdown>

              <div style={{ width: 8 }} />

              <Dropdown
                onClick={() => onClickAddButton()}
                trigger={['contextMenu']}
                overlay={(
                  <Menu>
                    <Menu.Item key="1" onClick={() => onClickAddButton()}>
                      <Icon type="plus" />
                      Add Linked Job
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => onClickAddButton({ legacy: true })}>
                      <Icon type="plus" />
                      Add Custom Job
                    </Menu.Item>
                  </Menu>
                )}
              >

                <Button type="primary">
                  <Icon type="plus" />
                </Button>

              </Dropdown>

            </React.Fragment>

          )}

        </div>

      </div>
      
    )
  }
}