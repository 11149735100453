import React from 'react'
import classNames from 'classnames'
import styles from './styles.css'

const Headings = () => (

  <div className={styles.wrapper}>

    <div className={classNames(styles.cell, styles.quotable)}>Item</div>

    <div className={classNames(styles.cell, styles.price)}>Price</div>

  </div>

)

export default Headings
