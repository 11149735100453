import { createAction, handleActions } from 'redux-actions';

const loadFitters = createAction("CORE/FITTERS/LOAD_FITTERS");
const clearFitters = createAction("CORE/FITTERS/CLEAR_FITTERS");

const defaultState = []

const reducer = handleActions(
  {
    [loadFitters]: (state, action) => action.payload,
    [clearFitters]: () => []
  },
  defaultState
);

export { loadFitters, clearFitters, reducer };