import React from 'react'
import PropTypes from 'prop-types'
import spacing from '../Utils/spacing'

const HeightSpacer = ({ type }) => <div style={{ height: spacing.height[type] }} />

HeightSpacer.propTypes = {
  type: PropTypes.string.isRequired,
}

export default HeightSpacer
