import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import componentDidUpdate from './Handlers/componentDidUpdate';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onCloseOrderModal from './Handlers/onCloseOrderModal';
import didUpdateOrder from './Handlers/didUpdateOrder';
import onDrop from "./Handlers/onDrop"
import onShowDropzone from "./Handlers/onShowDropzone"
import onHideDropzone from "./Handlers/onHideDropzone"

import { FORM_NAME } from './Components/Order/Form';

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order,
  showOrderModal: state.screens.OrderView.showOrderModal,
  activeTab: state.screens.OrderView.activeTab,
  isEditing: isDirty(FORM_NAME)(state),
  showDropzone: state.screens.OrderView.showDropzone,
  uploads: state.core.uploads,
  areaSelectorModal: state.screens.OrderView.areaSelectorModal,
  dimensionsModal: state.screens.OrderView.dimensionsModal,
});

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onCloseOrderModal,
  didUpdateOrder,
  onDrop,
  onShowDropzone,
  onHideDropzone,
};

export default connect(mapStateToProps, actionCreators)(Component);