import { message } from 'antd'
import { updateArea } from '../../../../../HttpRequests/areas'
import fetchAreas from './fetch'
import fetchAreaGroups from '../../AreaGroups/Services/fetch'
import { handleException } from '../../../../../../packages/sota'

export default (id, column, value) => async (dispatch) => {

  const data = {
    [column]: value || null,
  }

  try {

    await updateArea({ id, data })

    dispatch(fetchAreas())

    dispatch(fetchAreaGroups())

    message.success('Changes Saved')

  } catch (error) {

    handleException(error)

    throw error

  }

}
