import { combineReducers } from 'redux';

import { reducer as fitters } from './State/fitters';
import { reducer as inspectors } from './State/inspectors';
import { reducer as suppliers } from './State/suppliers';
import { reducer as supplierRates } from './State/supplierRates';
import { reducer as manufacturers } from './State/manufacturers';
import { reducer as materialTypes } from './State/materialTypes';
import { reducer as fittingRates } from './State/fittingRates';
import { reducer as materialRanges } from './State/materialRanges';
import { reducer as rangeVariants } from './State/rangeVariants';
import { reducer as materialRangesCascaded } from './State/materialRangesCascaded';
import { reducer as builders } from './State/builders';
import { reducer as sites } from './State/sites';
import { reducer as plotTypes } from './State/plotTypes';
import { reducer as orderNumbers } from './State/orderNumbers';
import { reducer as orderCategories } from './State/orderCategories';
import { reducer as onHoldReasons } from './State/onHoldReasons';
import { reducer as materialUnits } from './State/materialUnits';
import { reducer as materialVariantUnits } from './State/materialVariantUnits';
import { reducer as unitOfPrices } from './State/unitOfPrices';
import { reducer as users } from './State/users';
import { reducer as uploads } from './State/uploads';

export default combineReducers({
  fitters,
  inspectors,
  suppliers,
  supplierRates,
  manufacturers,
  materialTypes,
  fittingRates,
  materialRanges,
  rangeVariants,
  materialRangesCascaded,
  builders,
  sites,
  plotTypes,
  orderNumbers,
  orderCategories,
  onHoldReasons,
  materialUnits,
  materialVariantUnits,
  unitOfPrices,
  users,
  uploads,
})
