import { createAction, handleActions } from 'redux-actions';

const setShowSiteModal = createAction("SCREENS/SITE_VIEW/SET_SHOW_SITE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowSiteModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowSiteModal, reducer };