import { connect } from 'react-redux'
import Component from './Component'

import onSelect from './Handlers/onSelect'
import onSelectAll from './Handlers/onSelectAll'

const mapStateToProps = (state) => ({
  jobs: state.modals.CompletedJobs.jobs,
  fetching: state.modals.CompletedJobs.fetching,
  selectedRowKeys:  state.modals.CompletedJobs.selectedRowKeys,
})

const actionCreators = {
  onSelect,
  onSelectAll,
}

export default connect(mapStateToProps, actionCreators)(Component)
