import { createAction, handleActions } from 'redux-actions'

const openBuilderRateRangeVariantsModal = createAction('MODALS/BUILDER_RATE/OPEN_BUILDER_RATE_RANGE_VARIANTS_MODAL')
const closeBuilderRateRangeVariantsModal = createAction('MODALS/BUILDER_RATE/CLOSE_BUILDER_RATE_RANGE_VARIANTS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openBuilderRateRangeVariantsModal]: (state, { payload }) => Object.assign({}, state, {
      show: true,
      ...payload,
    }),
    [closeBuilderRateRangeVariantsModal]: () => defaultState,
  },
  defaultState,
)

export { openBuilderRateRangeVariantsModal, closeBuilderRateRangeVariantsModal, reducer }
