import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Select, Checkbox, InputNumber } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    materialTypes: PropTypes.array.isRequired,
    orderCategories: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
    materialType: PropTypes.object,
    materialRanges: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    materialType: null,
  }

  render() {

    const {
      materialTypes,
      orderCategories,
      initialValues,
      materialType,
      materialRanges,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >

        {({ handleSubmit, values, form }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ values: true, submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name='title'>
              {({ input, meta }) => (
                <Form.Item
                  label='Title'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='material_type_id'>
              {({ input }) => (
                <Form.Item
                  label='Material'
                >
                  <Select
                    {...input}
                    value={input.value || undefined}
                    onChange={value => {
                      input.onChange(value || null)
                      form.change('order_category_id', null)
                      form.change('material_type_ids', [])
                      form.change('material_range_ids', [])
                      form.change('remedial', null)
                      form.change('tar_value', null)
                    }}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    placeholder='None'
                  >
                    {materialTypes.map($materialType => (
                      <Select.Option key={$materialType.id} value={$materialType.id}>
                        {$materialType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            {!!materialType && !!materialType.is_flooring && (

              <React.Fragment>

                <Field name='order_category_id'>
                  {({ input }) => (
                    <Form.Item
                      label='Category'
                    >
                      <Select
                        {...input}
                        value={input.value || undefined}
                        onChange={value => input.onChange(value || null)}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        placeholder='None'
                      >
                        {orderCategories.map(orderCategory => (
                          <Select.Option key={orderCategory.id} value={orderCategory.id}>
                            {orderCategory.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

                <Field name='material_range_ids'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Range'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        mode='multiple'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        placeholder='None'
                      >
                        <Select.OptGroup label='Active'>
                          {materialRanges.filter(materialRange => !materialRange.archived_at).map(materialRange => (
                            <Select.Option key={materialRange.id} value={materialRange.id}>
                              {materialRange.name}
                              {' • '}
                              <span style={{ color: '#9e9e9e' }}>{materialRange.manufacturer.name}</span>
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {materialRanges.filter(materialRange => !!materialRange.archived_at).map(materialRange => (
                            <Select.Option key={materialRange.id} value={materialRange.id} style={{ color: '#9e9e9e' }}>
                              {`${materialRange.name} • ${materialRange.manufacturer.name}`}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

                <Field name='remedial' type='checkbox'>
                  {({ input }) => (
                    <Form.Item
                      label='Remedial'
                    >
                      <Checkbox
                        {...input}
                      />
                    </Form.Item>
                  )}
                </Field>

              </React.Fragment>

            )}

            {!!materialType && !materialType.is_flooring && (

              <React.Fragment>

                <Field name='material_type_ids'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Target'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        mode='multiple'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        placeholder='None'
                      >
                        {materialTypes.filter($materialType => !!$materialType.is_flooring).map($materialType => (
                          <Select.Option key={$materialType.id} value={$materialType.id}>
                            {$materialType.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

                <Field name='tar_value'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Value'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </React.Fragment>

            )}

            <Field name='rate'>
              {({ input, meta }) => (
                <Form.Item
                  label='Rate'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    precision={2}
                    min={0}
                    style={{ width: 90 }}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
