import { createAction, handleActions } from 'redux-actions';

const setPlotNotes = createAction("MODALS/JOB/SET_PLOT_NOTES");
const resetPlotNotes = createAction("MODALS/JOB/RESET_PLOT_NOTES");

const defaultState = [];

const reducer = handleActions(
  {
    [setPlotNotes]: (state, action) => action.payload,
    [resetPlotNotes]: () => defaultState,
  },
  defaultState
);

export { setPlotNotes, resetPlotNotes, reducer };
