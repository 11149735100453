import { setShowJobModal } from '../State/showJobModal';
import { setOpenedJobId } from '../State/openedJobId';
import { setCreateLegacyJob } from '../State/createLegacyJob';

export default () => async (dispatch) => {

  dispatch(setShowJobModal(false))
  dispatch(setOpenedJobId(null))
  dispatch(setCreateLegacyJob(false))

}

