import { message } from 'antd'
import { deleteAreaMaterialTypes } from '../../../HttpRequests/areaMaterialTypes'
import fetch from '../Components/AreaGroups/Services/fetch'
import { resetSelectedRows } from '../Components/AreaGroups/State/selectedRows'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { selectedRows } = getState().screens.PlotType.AreaGroups

  const data = { ids: selectedRows }

  try {

    await deleteAreaMaterialTypes({ data })

    message.success('Deleted Lines')

    dispatch(fetch())

    dispatch(resetSelectedRows())

  } catch (error) {

    handleException(error)

  }

}
