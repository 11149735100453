import { connect } from 'react-redux';

import Component from './Component';

import onChangeFitter from "./Handlers/onChangeFitter";
import onChangeDates from "./Handlers/onChangeDates";

const mapStateToProps = (state) => ({
  fitters: state.core.fitters,
  users: state.core.users,
  values: state.form.Holiday.values
});

const actionCreators = {
  onChangeFitter,
  onChangeDates,
};

export default connect(mapStateToProps, actionCreators)(Component);