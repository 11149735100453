import { createAction, handleActions } from 'redux-actions'

const setSelectedAreaIds = createAction('MODALS/AREA_GROUP/SET_SELECTED_AREA_IDS')
const resetSelectedAreaIds = createAction('MODALS/AREA_GROUP/RESET_SELECTED_AREA_IDS')

const defaultState = []

const reducer = handleActions(
  {
    [setSelectedAreaIds]: (state, action) => action.payload,
    [resetSelectedAreaIds]: () => defaultState,
  },
  defaultState,
)

export { setSelectedAreaIds, resetSelectedAreaIds, reducer }
