import { createSelector } from "reselect";
import moment from 'moment';

const selectDateRange = state => state.screens.Board.dateRange;
const selectShowWeekends = state => state.screens.Board.showWeekends;

export default createSelector(
  selectDateRange,
  selectShowWeekends,
  (dateRange, showWeekends) => {

    const monthStartDate = dateRange[0];
    const monthEndDate = dateRange[1];

    const boardStartDate = moment(monthStartDate).startOf('isoWeek');
    const boardEndDate = moment(monthEndDate).endOf('isoWeek');

    const weeks = [];

    for (let weekStartDate = moment(boardStartDate); weekStartDate.isBefore(boardEndDate); weekStartDate.add(1, 'weeks')) {

      const week = [];

      const weekEndDate = weekStartDate.clone().add((showWeekends ? 6 : 4), 'days');

      for (let date = moment(weekStartDate); date.diff(weekEndDate, 'days') <= 0; date.add(1, 'days')) {

        week.push({
          date: date.format('YYYY-MM-DD'),
          otherMonth: date.isBefore(monthStartDate) || date.isAfter(monthEndDate)
        });

      }

      weeks.push(week);

    }

    return weeks;
  }
)