import { createSelector } from 'reselect'
import selectAreas from '../../../../../Selectors/selectAreas'
import areaOptions from '../../../../../Utils/areaOptions'

const selectCostSheet = state => state.screens.CostSheet.order.cost_sheet

export default createSelector(
  selectAreas,
  selectCostSheet,
  (areas, costSheet) => {

    const areaIds = costSheet.cost_sheet_areas.map(row => row.area_id)

    return areaOptions(areas.filter(row => !areaIds.includes(row.id)))

  },
)
