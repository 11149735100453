import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import styles from './Component.css'

export default class _UploadProgress_ extends React.PureComponent {

  static propTypes = {
    show: PropTypes.bool.isRequired,
    queueLength: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    filename: PropTypes.string.isRequired,
  }

  render () {

    const {
      show,
      queueLength,
      progress,
      filename,
    } = this.props

    if (!show) return null
    
    return (

      <div className={styles.wrapper}>

        <div className={styles.bar}>

          <Icon type="sync" spin className={styles.icon} />

          <div className={styles.filename}>
            {`${progress === 100 ? 'Processing' : 'Uploading'} ${filename}`}
          </div>

          <div className={styles.filesLeft}>
            {`${queueLength} files left`}
          </div>

          <div className={styles.progressOuter}>
            <div className={styles.progressInner} style={{ width: `${progress}%`}} />
          </div>

        </div>

      </div>

    )
  }
}