import { message } from 'antd'
import { removeMaterialRangeFromSiteRate } from '../../../HttpRequests/siteRates'
import fetch from './fetch'
import { handleException } from '../../../../packages/sota'

export default (materialRangeId) => async (dispatch, getState) => {

  const { siteRate: { id } } = getState().modals.SiteRate

  try {

    await removeMaterialRangeFromSiteRate({ id, materialRangeId })

    dispatch(fetch())

    message.success('Range Removed')

  } catch (error) {

    handleException(error)

    throw error

  }

}
