import chance from 'chance'
import { setCostSheetField } from '../../../State/order'

export default (values = {}) => (dispatch, getState) => {

  const { cost_sheet_areas: costSheetAreas } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetAreas = [
    ...costSheetAreas,
    { guid: chance().guid(), ...values },
  ]

  dispatch(setCostSheetField({ field: 'cost_sheet_areas', value: newCostSheetAreas }))

}
