import { initialize } from 'redux-form';
import { getLeftover } from '../../../HttpRequests/leftovers';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { leftoverId, fromOrderId } = getProps();
  
  if (leftoverId) {

    const response = await getLeftover({ id: leftoverId });

    const leftover = response.data.data;

    dispatch(initialize(FORM_NAME, Object.assign({}, leftover, {
      material: [ leftover.material_type_id, leftover.material_id, leftover.material_range_id ]
    })));
    
  } else {

    dispatch(initialize(FORM_NAME, {
      from_order_id: fromOrderId
    }))

  }

}