import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createManufacturer, updateManufacturer } from '../../../../../HttpRequests/manufacturers'
import fetchManufacturers from '../../../../../Core/Services/fetchManufacturers'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { manufacturer } = modalProps()

  const data = {
    name: values.name,
  }

  try {

    if (manufacturer) {
      await updateManufacturer({ id: manufacturer.id, data })
      message.success('Updated Manufacturer')
    } else {
      await createManufacturer({ data })
      message.success('Created Manufacturer')
    }

    modalProps().onClose(true)

    dispatch(fetchManufacturers({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
