import { connect } from 'react-redux'
import AreaSelectorModal from './AreaSelectorModal'

import handleAddAreas from './Handlers/handleAddAreas'
import handleClose from './Handlers/handleClose'

const mapStateToProps = () => ({
})

const actionCreators = {
  handleAddAreas,
  handleClose,
}

export default connect(mapStateToProps, actionCreators)(AreaSelectorModal)
