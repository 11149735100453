import { createAction, handleActions } from 'redux-actions'

const openFittingRateModal = createAction('MODALS/FITTING_RATES/OPEN_FITTING_RATE_MODAL')
const closeFittingRateModal = createAction('MODALS/FITTING_RATES/CLOSE_FITTING_RATE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openFittingRateModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeFittingRateModal]: () => defaultState,
  },
  defaultState,
)

export { openFittingRateModal, closeFittingRateModal, reducer }
