import React from 'react'
import { Modal, Icon } from 'antd'
import bulkUpdate from '../../../Services/bulkUpdate'

const terms = {
  'called-off': 'Called Off',
  'fitted': 'Fitted',
  'invoiced': 'Invoiced',
  'closed': 'Closed',
}

export default ({ key }) => async (dispatch, getState) => {

  const { selectedRowKeys } = getState().screens.Orders;

  Modal.confirm({
    title: `Mark ${selectedRowKeys.length} Orders as ${terms[key]}?`,
    content: 'This action cannot be undone.',
    icon: <Icon type="to-top" />,
    okText: 'Yes',
    onOk: () => dispatch(bulkUpdate(key, selectedRowKeys)),
  })

}
