import { connect } from 'react-redux'
import Header from './Header'

import onEdit from './Handlers/onEdit'

const mapStateToProps = (state) => ({
  order: state.screens.CostSheet.order,
})

const actionCreators = {
  onEdit,
}

export default connect(mapStateToProps, actionCreators)(Header)
