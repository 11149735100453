import { combineReducers } from "redux";

import { reducer as suppliers } from "./State/suppliers";
import { reducer as pagination } from "./State/pagination";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showSupplierModal } from "./State/showSupplierModal";

export default combineReducers({
  suppliers,
  pagination,
  searchTerm,
  fetching,
  showSupplierModal
});
