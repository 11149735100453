import { createAction, handleActions } from 'redux-actions'

const openSupplierRatesModal = createAction('MODALS/SUPPLIERS/OPEN_SUPPLIER_RATES_MODAL')
const closeSupplierRatesModal = createAction('MODALS/SUPPLIERS/CLOSE_SUPPLIER_RATES_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSupplierRatesModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeSupplierRatesModal]: () => defaultState,
  },
  defaultState,
)

export { openSupplierRatesModal, closeSupplierRatesModal, reducer }
