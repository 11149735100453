import { createSelector } from 'reselect'
import { get } from 'lodash'
import float from '../../../../../Utils/float'

const selectOrder = state => state.screens.CostSheet.order

export default createSelector(
  selectOrder,
  (order) => order.cost_sheet.cost_sheet_expenses.map(costSheetExpense => ({
    ...costSheetExpense,
    fitting_rate: get(costSheetExpense, 'fitting_rate.title', ''),
    cost: float(costSheetExpense.cost),
  })),
)
