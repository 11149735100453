import { message } from 'antd'
import { pick } from 'lodash'
import { createInspectorVisit } from '../../../HttpRequests/inspectorVisits';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {

  try {

    const data = pick(values, [
      'inspector_id',
      'site_id',
      'plots',
      'date',
      'notes',
    ])

    await createInspectorVisit({ data });

    message.success('Inspection Created');

    return true;
    
  } catch (error) {
    
    handleException(error);

    return false;

  }
}
