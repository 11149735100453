import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field, FormSpy } from 'react-final-form'
import { Modal, Button, Form, Select } from 'antd'
import { ExposeFormToHelper } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    areaGroups: PropTypes.array.isRequired,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render() {

    const {
      areaGroups,
      submitting,
      onChangeFormState,
      onSubmit,
      validate,
      onClose,
    } = this.props

    const footer = [
      <Button key='cancel' onClick={() => onClose()} disabled={submitting}>Cancel</Button>,
      <Button key='save' type='primary' onClick={() => formService().submit()} disabled={submitting} loading={submitting}>Add</Button>,
    ]

    return (

      <Modal
        visible
        title='Group Areas'
        width={500}
        footer={footer}
        onCancel={() => onClose()}
      >

        <FormProvider
          validate={validate}
          onSubmit={onSubmit}
        >

          {({ handleSubmit }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              colon={false}
              autoComplete='off'
            >

              <ExposeFormToHelper helper={formService} />

              <FormSpy subscription={{ submitting: true }} onChange={onChangeFormState} />

              <Field name='area_group_id'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Group'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      disabled={submitting}
                      allowClear
                      showSearch
                      optionFilterProp='children'
                    >
                      {areaGroups.map(areaGroup => (
                        <Select.Option key={areaGroup.id} value={areaGroup.id}>
                          {`${areaGroup.name} (${areaGroup.material_type.name})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>

      </Modal>

    )

  }

}
