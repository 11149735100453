import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteSiteMaterialRange } from '../../../HttpRequests/siteMaterialRanges'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  const { siteMaterialRange } = modalProps()

  try {

    await deleteSiteMaterialRange({ id: siteMaterialRange.id })

    message.success('Removed Material')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
