import { createAction, handleActions } from 'redux-actions'

const loadJobStats = createAction('SCREENS/BOARD/LOAD_JOB_STATS')

const defaultState = {
  tags: [],
  days: [],
  totals: {
    jobs_total: 0,
    jobs_started: 0,
    job_stats_by_tag: {},
    job_stats_by_tag_week: {},
  },
}

const reducer = handleActions(
  {
    [loadJobStats]: (state, action) => action.payload,
  },
  defaultState,
);

export { loadJobStats, reducer }
