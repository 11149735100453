import { createSelector } from "reselect";
import moment from 'moment';

const selectDateRange = state => state.screens.Board.dateRange;
const selectShowWeekends = state => state.screens.Board.showWeekends;

export default createSelector(
  selectDateRange,
  selectShowWeekends,
  (dateRange, showWeekends) => {

    const start = dateRange[0];
    const end = dateRange[1];

    const dates = [];

    for (let m = moment(start); m.diff(end, 'days') <= (showWeekends ? 0 : -2); m.add(1, 'days')) {
      dates.push(m.format('YYYY-MM-DD'));
    }

    return dates;
  }
)