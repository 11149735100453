import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchData from '../Services/fetchData'
import checkForCompletedJobs from '../Services/checkForCompletedJobs'
import { setProps } from '../props'

export default (props) => async (dispatch) => {

  setProps(props)

  await dispatch(fetchUsers())
  await dispatch(fetchData())

  dispatch(checkForCompletedJobs())
  
}