import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, DatePicker } from 'antd'
import { get, orderBy } from 'lodash'
import moment from 'moment'
import spacing from '../../Utils/spacing'
import styles from './styles.css'

import Takeoff from './Components/Takeoff'

const Header = props => {

  const {
    order,
    onEdit,
  } = props
  
  const job = orderBy(order.jobs, 'start_date')[0]

  const [notes, setNotes] = useState(undefined)

  return (
    
    <Row gutter={spacing.width.block}>

      <Col span={8}>
        
        <div className={styles.section}>

          <div className={styles.detail}>
            <div className={styles.label}>Order No</div>
            <div className={styles.value}>{order.order_number}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Builder</div>
            <div className={styles.value}>{get(order, 'builder.name')}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Site</div>
            <div className={styles.value}>{get(order, 'site.name')}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Plot Type</div>
            <div className={styles.value}>{get(order, 'plot_type.name')}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Plot No</div>
            <div className={styles.value}>{order.plot_number}</div>
          </div>

        </div>
      
      </Col>

      <Col span={8}>

        <Takeoff />
      
      </Col>

      <Col span={8}>

        <div className={styles.section}>

          <div className={styles.detail}>
            <div className={styles.label}>Manager</div>
            <div className={styles.value}>{get(order, 'site.manager.name')}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Ordered</div>
            <div className={styles.value}>
              <DatePicker
                value={order.cost_sheet.ordered_date}
                onChange={value => onEdit('ordered_date', value)}
                format='DD/MM/YYYY'
                placeholder=''
                className={styles.antInput}
              />
            </div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Delivery</div>
            <div className={styles.value}>
              <DatePicker
                value={order.cost_sheet.delivery_date}
                onChange={value => onEdit('delivery_date', value)}
                format='DD/MM/YYYY'
                placeholder=''
                className={styles.antInput}
              />
            </div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Fitting</div>
            <div className={styles.value}>{job && moment(job.start_date).format('DD/MM/YYYY')}</div>
          </div>

          <div className={styles.detail}>
            <div className={styles.label}>Supply Ref</div>
            <div className={styles.value}>
              <Input.TextArea
                value={notes !== undefined ? notes : order.cost_sheet.supplier_ref}
                onChange={e => setNotes(e.target.value)}
                onBlur={e => {
                  onEdit('supplier_ref', e.target.value)
                  setNotes(undefined)
                }}
                className={styles.antInput}
              />
            </div>
          </div>

        </div>
      
      </Col>

    </Row>
    
  )

}

Header.propTypes = {
  order: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default Header
