import { createSelector } from 'reselect'
import chance from 'chance'
import selectAreaSummary from '../../../../../Selectors/selectAreaSummary'
import float from '../../../../../Utils/float'

export default createSelector(
  selectAreaSummary,
  (areaSummary) => ({
    items: areaSummary.takeoff.map(({ area, squareMetresNet, squareMetresGross }) => ({
      key: chance().guid(),
      area,
      net: float(squareMetresNet),
      gross: float(squareMetresGross),
    })),
    squareMetresNet: float(areaSummary.squareMetresNet),
    squareMetresGross: float(areaSummary.squareMetresGross),
  }),
)
