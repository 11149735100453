import { createSelector } from 'reselect'
import { flatten } from 'lodash'
import selectMaterialRanges from '../../../../../Selectors/selectMaterialRanges'
import selectAreaSummary from '../../../../../Selectors/selectAreaSummary'
import materialOptions from '../../../../../Utils/materialOptions'

const selectMaterialTypes = state => state.core.materialTypes
const selectManufacturers = state => state.core.manufacturers

export default createSelector(
  selectMaterialTypes,
  selectManufacturers,
  selectMaterialRanges,
  selectAreaSummary,
  (materialTypes, manufacturers, materialRanges, areaSummary) => {
    const materialTypeIds = areaSummary.materialTypes.map(({ materialTypeId }) => materialTypeId)
    const $materialTypes = materialTypes.filter(row => materialTypeIds.includes(row.id) || !row.is_flooring)
    const materialRangeIds = flatten($materialTypes.map(row => row.category_of)).map(row => row.id)
    const $materialRanges = materialRanges.filter(row => {
      if (materialTypeIds.includes(row.material_type_id)) return true
      if (materialRangeIds.includes(row.id)) return true
      return false
    })
    return materialOptions($materialTypes, manufacturers, $materialRanges)
  },
)
