import { combineReducers } from "redux";

import { reducer as orders } from "./State/orders";
import { reducer as pagination } from "./State/pagination";
import { reducer as sorter } from "./State/sorter";
import { reducer as filters } from "./State/filters";
import { reducer as selectedRowKeys } from "./State/selectedRowKeys";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showOrderCreateModal } from "./State/showOrderCreateModal";

export default combineReducers({
  orders,
  pagination,
  sorter,
  filters,
  selectedRowKeys,
  searchTerm,
  fetching,
  showOrderCreateModal
});
