import modalProps from '../modalProps'
import { resetBuilderRate } from '../State/builderRate'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetBuilderRate())
  
}
