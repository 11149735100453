import handleException from '../../../Core/Helpers/handleException';
import { bulkUpdateOrderStatuses } from '../../../HttpRequests/orders';
import { setSelectedRowKeys } from '../State/selectedRowKeys';
import fetchOrders from './fetchOrders';

export default (markAs, selectedRowKeys) => async (dispatch) => {

  try {

    const data = {
      order_ids: selectedRowKeys,
      mark_as: markAs
    };

    await bulkUpdateOrderStatuses({ data });
    
    dispatch(setSelectedRowKeys([]));

    dispatch(fetchOrders());

  } catch (error) {

    handleException(error);
    
  }
}