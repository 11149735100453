import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from "./Component.css";

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  }

  render () {

    const {
      data,
      onClick
    } = this.props;

    return (

      <div
        onClick={(event) => {
          event.stopPropagation()
          onClick(data.id)
        }}
        role="presentation"
        className={classnames({
          [styles.wrapper]: true
        })}
      >
        {data.fitter && data.fitter.name}
      </div>

    )
  }
}