import * as React from 'react';
import PropTypes from 'prop-types';
import { Screen } from 'evolve-portal';
import Beforeunload from 'react-beforeunload';
import { Prompt } from 'react-router-dom';
import { Spin } from 'antd';

import Header from './Components/Header';
import Order from './Components/Order';
import Fitting from './Components/Fitting';
// import Leftovers from './Components/Leftovers';
import Files from './Components/Files';
import Activity from './Components/Activity';
import PlotNotes from './Components/PlotNotes';
import OrderNotes from './Components/OrderNotes';
import Questionnaire from './Components/Questionnaire';
import Dropzone from "../../Components/Dropzone"
import UploadProgress from "../../Components/UploadProgress"

import OrderModal from '../../Modals/Order';
import AreaSelectorModal from '../../Modals/AreaSelectorModal';
import Dimensions from '../../Modals/Dimensions';

export default class extends React.PureComponent{

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    order: PropTypes.object,
    showOrderModal: PropTypes.bool.isRequired,
    activeTab: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    showDropzone: PropTypes.bool.isRequired,
    uploads: PropTypes.object.isRequired,
    areaSelectorModal: PropTypes.object.isRequired,
    dimensionsModal: PropTypes.object.isRequired,
    onCloseOrderModal: PropTypes.func.isRequired,
    didUpdateOrder: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
    onShowDropzone: PropTypes.func.isRequired,
    onHideDropzone: PropTypes.func.isRequired,
  }

  static defaultProps = {
    order: null
  }

  componentDidMount = () => this.props.componentDidMount(this.props);

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      order,
      showOrderModal,
      activeTab,
      isEditing,
      showDropzone,
      uploads,
      areaSelectorModal,
      dimensionsModal,
      onCloseOrderModal,
      didUpdateOrder,
      onDrop,
      onShowDropzone,
      onHideDropzone,
    } = this.props;
    
    if (!order) return (
      <div style={{ textAlign: 'center', marginTop: 250 }}>
        <Spin size="large" />
      </div>
    )

    return (

      <Screen maxWidth={1200}>

        {isEditing && <Beforeunload onBeforeunload={() => "Changes that you made may not be saved."} />}

        <Prompt
          when={isEditing}
          message={() => `Are you sure you want to leave this page? Changes that you made may not be saved.`}
        />

        <Header />
            
        {activeTab === '1' && <Order />}

        {activeTab === '2' && <Fitting />}

        {/* {activeTab === '3' && <Leftovers />} */}

        {activeTab === '4' && <Files />}

        {activeTab === '5' && <Activity />}

        {activeTab === '6' && <PlotNotes />}

        {activeTab === '7' && <OrderNotes />}

        {activeTab === '8' && <Questionnaire />}

        {showOrderModal && (
          <OrderModal
            order={order}
            onClose={onCloseOrderModal}
            didUpdate={didUpdateOrder}
          />
        )}

        <Dropzone
          show={showDropzone}
          onDrop={onDrop}
          onShow={onShowDropzone}
          onHide={onHideDropzone}
        />

        <UploadProgress
          show={uploads.queue.length > 0}
          queueLength={uploads.queue.length}
          progress={uploads.progress}
          filename={uploads.queue.length > 0 ? uploads.queue[0].file.name : ''}
        />

        {areaSelectorModal.show && (
          <AreaSelectorModal
            {...areaSelectorModal}
          />
        )}

        {dimensionsModal.show && (
          <Dimensions
            {...dimensionsModal}
            isNew={false}
          />
        )}

      </Screen>
        
    )
  }
}