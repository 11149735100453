import { createSelector } from 'reselect'
import chance from 'chance'

const selectValues = state => state.modals.SupplierRate.Form.formState.values

export default createSelector(
  selectValues,
  (values) => values.dimensions ? values.dimensions.map(row => ({
    ...row,
    guid: chance().guid(),
  })) : null,
)
