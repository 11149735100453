import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchFitters from '../../../Core/Services/fetchFitters';

export default (props) => (dispatch) => {

  setProps(props);

  dispatch(initialize());

  dispatch(fetchFitters());
  
}