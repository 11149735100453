import { createSelector } from 'reselect'
import { find, flatten, orderBy } from 'lodash'
import selectSiteRates from './selectSiteRates'

const selectMaterialTypes = state => state.core.materialTypes
const selectMaterialRanges = state => state.core.materialRanges

export default createSelector(
  selectMaterialTypes,
  selectMaterialRanges,
  selectSiteRates,
  (materialTypes, materialRanges, siteRates) => {

    const materialRangeIds = flatten(siteRates.map(row => row.material_ranges)).map(row => row.id)
    const rangeVariantIds = flatten(siteRates.map(row => row.range_variants)).map(row => row.id)

    const $materialRanges = []

    materialRanges.forEach(materialRange => {
      const materialType = find(materialTypes, row => row.id === materialRange.material_type_id)

      if (!materialType.is_flooring) {
        $materialRanges.push({
          ...materialRange,
          range_variants: orderBy(materialRange.range_variants, 'name'),
        })
      } else if (materialRangeIds.includes(materialRange.id)) {
        $materialRanges.push({
          ...materialRange,
          range_variants: orderBy(materialRange.range_variants.filter(row => rangeVariantIds.includes(row.id)), 'name'),
        })
      }
    })

    return $materialRanges

  },
)
