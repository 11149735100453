import modalProps from '../modalProps'
import fetchMaterialTypes from '../../../Core/Services/fetchMaterialTypes'
import fetchMaterialRanges from '../../../Core/Services/fetchMaterialRanges'
import fetch from '../Services/fetch'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => async (dispatch) => {

  modalProps(props)

  dispatch(fetchMaterialTypes())
  dispatch(fetchMaterialRanges())

  await dispatch(fetch())
  
  dispatch(initializeForm())
  
}
