import { getJobs } from '../../../HttpRequests/jobs'
import { openCompletedJobsModal } from '../State/completedJobsModal'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  try {

    const params = {
      'filter[for_completion]': 3,
      'filter[manager_id]': getState().user.id,
    }

    const response = await getJobs({ params })

    const jobs = response.data.data

    if (jobs.length > 0) {
      dispatch(openCompletedJobsModal())
    }

  } catch (error) {

    handleException(error)

  }

}