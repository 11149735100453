import submit from '../Services/submit'
import addItem from '../../../Services/addItem'

export default () => (dispatch, getState) => {

  dispatch(submit())
  
  const { cost_sheet: costSheet } = getState().screens.CostSheet.order

  // Add an item if there wasn't anything to submit
  if (!costSheet.cost_sheet_areas.length) {
    dispatch(addItem())
  }

}
