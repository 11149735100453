import { formValueSelector } from 'redux-form'
import { Modal } from 'antd'
import { FORM_NAME } from '../Form'
import { getFitterAvailabilityForDates } from '../../../HttpRequests/fitters'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {
  
  try {

    const values = formValueSelector(FORM_NAME)(getState(), 'fitter_id', 'start_date', 'end_date')

    if (!values.fitter_id || !values.start_date || !values.end_date) return

    const id = values.fitter_id

    const params = {
      start_date: values.start_date,
      end_date: values.end_date,
    }

    const response = await getFitterAvailabilityForDates({ id, params })

    const availability = response.data.data;

    if (availability.assigned_to_job) {
      await new Promise(resolve => {
        Modal.warning({
          title: 'Fitter Assigned',
          content: 'This Fitter has already been assigned to another job on these dates.',
          width: 530,
          onOk: () => resolve(true),
        })
      })
    } else if (availability.on_holiday) {
      await new Promise(resolve => {
        Modal.warning({
          title: 'Fitter on Holiday',
          content: 'This Fitter has a holiday booked during these dates.',
          width: 500,
          onOk: () => resolve(true),
        })
      })
    }

  } catch (error) {
    
    handleException(error)

  }
}