import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {

  const data = {
    id: values.id,
    from_order_id: values.from_order_id,
    to_order_id: values.to_order_id,
    material_type_id: values.material[0],
    material_id: values.material[1],
    material_range_id: values.material[2] || null,
    width_in_metres: values.width_in_metres,
    length_in_metres: values.length_in_metres,
    square_metres: values.square_metres,
    linear_metres: values.linear_metres,
    weight_in_kilograms: values.weight_in_kilograms,
    volume_in_litres: values.volume_in_litres
  }
  
  const { leftoverId, onClose, didCreate, didUpdate } = getProps();
  
  if (!leftoverId) {

    const leftover = await dispatch(create(data));

    if (leftover) {
      onClose();
      didCreate(leftover);
    }

  } else {

    const leftover = await dispatch(update(data));

    if (leftover) {
      onClose();
      didUpdate(leftover);
    }

  }

};
