import save from '../../../Services/save'
import { setEditing } from '../../../State/editing'

export default () => async (dispatch) => {

  await dispatch(save())

  dispatch(setEditing(false))

}
