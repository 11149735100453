import { createAction, handleActions } from 'redux-actions';

const loadBuilders = createAction('SCREENS/BUILDERS/LOAD_BUILDERS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadBuilders]: (state, action) => action.payload,
  },
  defaultState
);

export { loadBuilders, reducer };