import * as React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash'

import Jobs from './Components/Jobs';
import FitterInvoices from './Components/FitterInvoices';
import JobModal from '../../../../Modals/Job';
import FitterInvoiceModal from '../../../../Modals/FitterInvoice';

export default class extends React.PureComponent {

  static propTypes = {
    order: PropTypes.object,
    openedJobId: PropTypes.number,
    openedFitterInvoiceId: PropTypes.number,
    showJobModal: PropTypes.bool.isRequired,
    showFitterInvoiceModal: PropTypes.bool.isRequired,
    didCreateJob: PropTypes.func.isRequired,
    didCreateFitterInvoice: PropTypes.func.isRequired,
    didUpdateJob: PropTypes.func.isRequired,
    didUpdateFitterInvoice: PropTypes.func.isRequired,
    didDeleteJob: PropTypes.func.isRequired,
    didDeleteFitterInvoice: PropTypes.func.isRequired,
    onCloseJobModal: PropTypes.func.isRequired,
    onCloseFitterInvoiceModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    order: null,
    openedJobId: null,
    openedFitterInvoiceId: null
  }

  render () {

    const {
      order,
      openedJobId,
      openedFitterInvoiceId,
      showJobModal,
      showFitterInvoiceModal,
      didCreateJob,
      didCreateFitterInvoice,
      didUpdateJob,
      didUpdateFitterInvoice,
      didDeleteJob,
      didDeleteFitterInvoice,
      onCloseJobModal,
      onCloseFitterInvoiceModal
    } = this.props;
    
    return (

      <div>
        
        <br />

        <Jobs />

        <br />

        <FitterInvoices />
        
        {showJobModal && (
          <JobModal
            jobId={openedJobId}
            orderId={get(order, 'id', null)}
            sugTag={get(order, 'cost_sheet.sug_tag')}
            sugMetres={get(order, 'cost_sheet.sug_metres')}
            didCreate={didCreateJob}
            didUpdate={didUpdateJob}
            didDelete={didDeleteJob}
            onClose={onCloseJobModal}
          />
        )}
        
        {showFitterInvoiceModal && (
          <FitterInvoiceModal
            fitterInvoiceId={openedFitterInvoiceId}
            orderId={get(order, 'id', null)}
            didCreate={didCreateFitterInvoice}
            didUpdate={didUpdateFitterInvoice}
            didDelete={didDeleteFitterInvoice}
            onClose={onCloseFitterInvoiceModal}
          />
        )}

      </div>
      
    )
  }
}
