import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchInspectors from '../../../Core/Services/fetchInspectors';
import fetchBuilders from '../../../Core/Services/fetchBuilders';
import fetchSites from '../../../Core/Services/fetchSites';

export default (props) => (dispatch) => {

  setProps(props);

  dispatch(initialize());
  
  dispatch(fetchInspectors());
  dispatch(fetchBuilders());
  dispatch(fetchSites());
  
}