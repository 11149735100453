import { connect } from 'react-redux'
import Header from './Header'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = (state) => ({
  searchTerm: state.screens.SiteView.SiteRates.searchTerm,
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(Header)
