import moment from 'moment';
import { getOrderCategories } from '../../HttpRequests/orderCategories';
import { loadOrderCategories } from '../State/orderCategories';

let lastGetTimestamp = null;

const cacheTTL = 60000;

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'));

      if (now < (lastGetTimestamp + cacheTTL)) return;
    }
      
    lastGetTimestamp = Number(moment().format('x'));

    const params = {
      sort: 'name',
      pagination: 0
    }

    const response = await getOrderCategories({ params });

    dispatch(loadOrderCategories(response.data.data));

  } catch (error) {
    
    throw error;

  }
}