import formService from '../Components/Form/formService'
import { closeDimensionsModal } from '../State/dimensionsModal'

export default (dimensions) => (dispatch) => {

  if (dimensions !== undefined) {
    formService().change('dimensions', dimensions)
  }

  dispatch(closeDimensionsModal())

}
