import { setOrderSearchTerm } from "../../../State/orderSearchTerm";
import fetchOrder from "../../../Services/fetchOrder";
import { resetPlotNotes } from "../../../State/plotNotes";
import fetchPlotNotes from "../../../Services/fetchPlotNotes";

export default () => (dispatch) => {
  
  dispatch(setOrderSearchTerm(''));

  setTimeout(() => {
    dispatch(fetchOrder())
    dispatch(resetPlotNotes())
    dispatch(fetchPlotNotes())
  });
  
}