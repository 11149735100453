import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { supplierRate } = modalProps()

  if (!supplierRate) return

  const initialValues = {
    ...supplierRate,
    format: supplierRate.dimensions && supplierRate.dimensions.length ? 'roll' : 'cut',
  }

  dispatch(setFormInitialValues(initialValues))

}
