import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styles from './Component.css';

import LinkButton from '../../../../Components/LinkButton'

export default class _Footer_ extends React.PureComponent {

  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    selectedRowKeys: PropTypes.array.isRequired,
    onClickLater: PropTypes.func.isRequired,
    onClickComplete: PropTypes.func.isRequired,
  }

  render () {

    const {
      fetching,
      selectedRowKeys,
      onClickLater,
      onClickComplete,
    } = this.props;

    return (
        
      <div className={styles.footer}>
      
        <LinkButton
          type="default"
          onClick={onClickLater}
          className={styles.remindButton}
          disabled={fetching}
        >
          Remind me later
        </LinkButton>

        <Button
          type="primary"
          disabled={selectedRowKeys.length === 0}
          onClick={onClickComplete}
          loading={fetching}
        >
          Complete Jobs
        </Button>

      </div>

    )
  }
}