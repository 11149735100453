import handleException from '../../../Core/Helpers/handleException';
import { getPlotTypes } from '../../../HttpRequests/plotTypes';
import { loadPlotTypes } from '../State/plotTypes';

export default () => async (dispatch) => {

  try {

    const params = {
      pagination: 0
    }

    const response = await getPlotTypes({ params });

    dispatch(loadPlotTypes(response.data.data));

  } catch(error) {

    handleException(error)

  }
}