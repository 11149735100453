import { connect } from 'react-redux';

import Component from './Component';

import onClick from "./Handlers/onClick";

const mapStateToProps = () => ({
});

const actionCreators = {
  onClick
};

export default connect(mapStateToProps, actionCreators)(Component);
