import { createAction, handleActions } from 'redux-actions'

const openRangeVariantsModal = createAction('MODALS/MATERIAL_RANGES/OPEN_RANGE_VARIANTS_MODAL')
const closeRangeVariantsModal = createAction('MODALS/MATERIAL_RANGES/CLOSE_RANGE_VARIANTS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openRangeVariantsModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeRangeVariantsModal]: () => defaultState,
  },
  defaultState,
)

export { openRangeVariantsModal, closeRangeVariantsModal, reducer }
