import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createRangeVariant, updateRangeVariant } from '../../../../../HttpRequests/rangeVariants'
import fetchRangeVariants from '../../../../../Core/Services/fetchRangeVariants'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { rangeVariant, materialRangeId } = modalProps()

  const data = {
    name: values.name,
    code: values.code || null,
    design: values.design || null,
  }

  try {

    if (rangeVariant) {
      await updateRangeVariant({ id: rangeVariant.id, data })
      message.success('Updated Variant')
    } else {
      data.material_range_id = materialRangeId
      await createRangeVariant({ data })
      message.success('Created Variant')
    }

    modalProps().onClose(true)

    dispatch(fetchRangeVariants({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
