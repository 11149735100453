import axios from 'axios'

export const createArea = ({ data }) => axios.post(`areas`, data)
export const deleteArea = ({ id }) => axios.delete(`areas/${id}`)
export const findArea = ({ id, params } = {}) => axios.get(`areas/${id}`, { params })
export const getAreas = ({ params } = {}) => axios.get(`areas`, { params })
export const updateArea = ({ id, data }) => axios.put(`areas/${id}`, data)

export const deleteAreas = ({ data }) => axios.put(`areas/delete_many`, data)
export const reorderAreas = ({ data }) => axios.put(`areas/reorder`, data)
