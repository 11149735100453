import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { fittingRate } = modalProps()

  if (!fittingRate) {
    dispatch(setFormInitialValues({ material_type_ids: [], material_range_ids: [] }))
    return
  }

  const initialValues = {
    ...fittingRate,
    material_type_ids: fittingRate.material_types.map(row => row.id),
    material_range_ids: fittingRate.material_ranges.map(row => row.id),
  }

  dispatch(setFormInitialValues(initialValues))

}
