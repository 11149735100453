import { createAction, handleActions } from 'redux-actions'

const setRangeVariants = createAction('MODALS/RANGE_VARIANTS/SET_RANGE_VARIANTS')
const resetRangeVariants = createAction('MODALS/RANGE_VARIANTS/RESET_RANGE_VARIANTS')

const defaultState = []

const reducer = handleActions(
  {
    [setRangeVariants]: (state, { payload }) => payload,
    [resetRangeVariants]: () => defaultState,
  },
  defaultState,
)

export { setRangeVariants, resetRangeVariants, reducer }
