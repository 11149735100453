import { connect } from 'react-redux'
import PlotNotes from './PlotNotes'

import componentDidMount from './Handlers/componentDidMount'
import onClickAdd from './Handlers/onClickAdd'
import onClickItem from './Handlers/onClickItem'
import onClickViewOrder from './Handlers/onClickViewOrder'
import onClosePlotNoteModal from './Handlers/onClosePlotNoteModal'

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.screens.OrderView.PlotNotes.data,
  plotNoteModal: state.screens.OrderView.PlotNotes.plotNoteModal,
})

const actionCreators = {
  componentDidMount,
  onClickAdd,
  onClickItem,
  onClickViewOrder,
  onClosePlotNoteModal,
}

export default connect(mapStateToProps, actionCreators)(PlotNotes)
