import { closeAreaGroupModal } from '../State/areaGroupModal'
import fetch from '../Components/AreaGroups/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeAreaGroupModal())

  if (reload) {
    dispatch(fetch())
  }

}
