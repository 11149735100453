import { message } from 'antd';
import { createSite } from '../../../HttpRequests/sites';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {

  try {

    const response = await createSite({ data });

    message.success('Site Created');

    return response.data.data;
    
  }  catch(error) {
    
    handleException(error);

    return false;

  }
}
