import { createAction, handleActions } from 'redux-actions';

const loadJobs = createAction("SCREENS/BOARD/LOAD_JOBS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadJobs]: (state, action) => action.payload
  },
  defaultState
);

export { loadJobs, reducer };
