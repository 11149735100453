import { createAction, handleActions } from 'redux-actions'

const setPlotType = createAction('SCREENS/PLOT_TYPE/SET_PLOT_TYPE')
const resetPlotType = createAction('SCREENS/PLOT_TYPE/RESET_PLOT_TYPE')

const defaultState = null

const reducer = handleActions(
  {
    [setPlotType]: (state, action) => action.payload,
    [resetPlotType]: () => defaultState,
  },
  defaultState,
)

export { setPlotType, resetPlotType, reducer }
