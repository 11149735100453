import { closeSupplierModal } from '../State/supplierModal'
import fetch from '../Components/Suppliers/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeSupplierModal())

  if (reload) {
    dispatch(fetch())
  }

}
