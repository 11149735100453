import { connect } from 'react-redux';

import materialUnit from '../../Selectors/materialUnit';
import materialOptions from './Selectors/materialOptions';
import fromOrderOptions from './Selectors/fromOrderOptions';
import toOrderOptions from './Selectors/toOrderOptions';

import onSearchFromOrder from './Handlers/onSearchFromOrder';
import onSearchToOrder from './Handlers/onSearchToOrder';
import onToggleFromOrder from './Handlers/onToggleFromOrder';
import onToggleToOrder from './Handlers/onToggleToOrder';

import Component from './Component';

const mapStateToProps = (state) => ({
  fromOrderOptions: fromOrderOptions(state),
  toOrderOptions: toOrderOptions(state),
  materialOptions: materialOptions(state),
  materialUnit: materialUnit(state),
});

const actionCreators = {
  onSearchFromOrder,
  onSearchToOrder,
  onToggleFromOrder,
  onToggleToOrder
};

export default connect(mapStateToProps, actionCreators)(Component);