import { message } from 'antd';
import { getProps } from '../props';
import { deleteHoliday } from '../../../HttpRequests/holidays';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { holidayId, onClose, didDelete } = getProps();

    await deleteHoliday({ id: holidayId });
    
    message.success("Holiday Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}