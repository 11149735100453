import { connect } from 'react-redux'
import Controls from './Controls'

import onClickEdit from './Handlers/onClickEdit'
import onClickSave from './Handlers/onClickSave'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'
import onClickExport from './Handlers/onClickExport'
import onClickPlotType from './Handlers/onClickPlotType'
import onClickSite from './Handlers/onClickSite'
import onClickMaterialTypes from './Handlers/onClickMaterialTypes'
import onClickManufacturers from './Handlers/onClickManufacturers'
import onClickMaterialRanges from './Handlers/onClickMaterialRanges'
import onClickSuppliers from './Handlers/onClickSuppliers'
import onClickFittingRates from './Handlers/onClickFittingRates'

const mapStateToProps = (state) => ({
  order: state.screens.CostSheet.order,
  editing: state.screens.CostSheet.editing,
  saving: state.screens.CostSheet.saving,
})

const actionCreators = {
  onClickEdit,
  onClickSave,
  onClickCancel,
  onClickDelete,
  onClickExport,
  onClickPlotType,
  onClickSite,
  onClickMaterialTypes,
  onClickManufacturers,
  onClickMaterialRanges,
  onClickSuppliers,
  onClickFittingRates,
}

export default connect(mapStateToProps, actionCreators)(Controls)
