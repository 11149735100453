import { message } from 'antd'
import { find } from 'lodash'
import modalProps from '../modalProps'
import { createAreaMaterialType } from '../../../../../HttpRequests/areaMaterialTypes'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch, getState) => {

  const { areaGroupId, materialTypeId } = modalProps()

  const { areas } = getState().screens.PlotType.Areas

  const area = find(areas, row => row.id === values.area_id)

  const { area_material_types: areaMaterialTypes } = area

  const areaMaterialType = find(areaMaterialTypes.filter(row => !row.area_group_id), row => row.material_type_id === materialTypeId)

  const data = {
    area_group_id: areaGroupId,
    area_id: values.area_id,
    material_type_id: materialTypeId,
    dimensions: areaMaterialType ? areaMaterialType.dimensions : [],
    square_metres: areaMaterialType ? areaMaterialType.square_metres : 0,
  }

  try {

    const response = await createAreaMaterialType({ data })

    modalProps().onClose(response.data.data)

    message.success(`Line Added`)

  } catch (error) {

    handleException(error)

    throw error

  }

}
