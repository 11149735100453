import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input } from 'antd'
import { ExposeFormToHelper } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
      >

        {({ handleSubmit }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            colon={false}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <Field name='note'>
              {({ input, meta }) => (
                <Form.Item
                  label={false}
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input.TextArea
                    {...input}
                    rows={6}
                  />
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
