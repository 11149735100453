import { createAction, handleActions } from 'redux-actions'

const setData = createAction('SCREENS/BUILDER_VIEW/BUILDER_MATERIAL_RANGES/SET_DATA')
const resetData = createAction('SCREENS/BUILDER_VIEW/BUILDER_MATERIAL_RANGES/RESET_DATA')

const defaultState = []

const reducer = handleActions(
  {
    [setData]: (state, action) => action.payload,
    [resetData]: () => defaultState,
  },
  defaultState,
)

export { setData, resetData, reducer }
