import { createAction, handleActions } from 'redux-actions'

const openAddAreaMaterialTypeModal = createAction('SCREENS/PLOT_TYPES/OPEN_ADD_AREA_MATERIAL_TYPE_MODAL')
const closeAddAreaMaterialTypeModal = createAction('SCREENS/PLOT_TYPES/CLOSE_ADD_AREA_MATERIAL_TYPE_MODAL')

const defaultState = {
  show: false,
  areaGroupId: null,
  materialTypeId: null,
}

const reducer = handleActions(
  {
    [openAddAreaMaterialTypeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      areaGroupId: action.payload.areaGroupId,
      materialTypeId: action.payload.materialTypeId,
    }),
    [closeAddAreaMaterialTypeModal]: () => defaultState,
  },
  defaultState,
)

export { openAddAreaMaterialTypeModal, closeAddAreaMaterialTypeModal, reducer }
