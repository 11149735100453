import { formValueSelector } from 'redux-form'
import { FORM_NAME } from '../../../Form'
import { openAvailableFittersModal } from "../../../State/availableFittersModal"

export default () => (dispatch, getState) => {

  const { tag, dates } = formValueSelector(FORM_NAME)(getState(), 'tag', 'dates')
  
  dispatch(openAvailableFittersModal({ tag, dates }))

}