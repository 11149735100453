import { createAction, handleActions } from 'redux-actions'

const setBuilderRate = createAction('MODALS/BUILDER_RATE/SET_BUILDER_RATE')
const resetBuilderRate = createAction('MODALS/BUILDER_RATE/RESET_BUILDER_RATE')

const defaultState = null

const reducer = handleActions(
  {
    [setBuilderRate]: (state, action) => action.payload,
    [resetBuilderRate]: () => defaultState,
  },
  defaultState,
)

export { setBuilderRate, resetBuilderRate, reducer }
