import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Component from './Component';

import { FORM_NAME } from '../../Form';

import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onClickDelete from './Handlers/onClickDelete';
import onClickArchive from './Handlers/onClickArchive';
import onClickUnarchive from './Handlers/onClickUnarchive';

const mapStateToProps = (state) => ({
  formValues: getFormValues(FORM_NAME)(state),
});

const actionCreators = {
  onClickCancel,
  onClickSubmit,
  onClickDelete,
  onClickArchive,
  onClickUnarchive,
};

export default connect(mapStateToProps, actionCreators)(Component);