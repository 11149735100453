import { message } from 'antd';
import { createPlotType } from '../../../HttpRequests/plotTypes';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {

  try {

    const response = await createPlotType({ data });

    message.success('Plot Type Created');

    return response.data.data;
    
  } catch (error) {
    
    handleException(error);

    return false;

  }
}
