import { createAction, handleActions } from 'redux-actions'

const setCostMatrixSearch = createAction('SCREENS/SITE_VIEW/SET_COST_MATRIX_SEARCH')
const resetCostMatrixSearch = createAction('SCREENS/SITE_VIEW/RESET_COST_MATRIX_SEARCH')

const defaultState = ''

const reducer = handleActions(
  {
    [setCostMatrixSearch]: (state, action) => action.payload,
    [resetCostMatrixSearch]: () => defaultState,
  },
  defaultState,
)

export { setCostMatrixSearch, resetCostMatrixSearch, reducer }
