import { message } from 'antd'
import { deleteFile } from '../../../HttpRequests/files';
import fetchOrder from './fetchOrder';
import handleException from '../../../Core/Helpers/handleException';

export default (id) => async (dispatch) => {
  
  try {

    await deleteFile({ id });
    
    message.success("File Deleted");

    await dispatch(fetchOrder());

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}