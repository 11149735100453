import React from 'react'
import PropTypes from 'prop-types'
import { List, Button, Avatar, Tag } from 'antd'
import moment from 'moment'
import Panel from '../Panel'

import PlotNoteModal from '../../../../Modals/PlotNote'

export default class PlotNotes extends React.PureComponent {

  static propTypes = {
    user: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    plotNoteModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired,
    onClickViewOrder: PropTypes.func.isRequired,
    onClosePlotNoteModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render() {

    const {
      user,
      data,
      plotNoteModal,
      onClickAdd,
      onClickItem,
      onClickViewOrder,
      onClosePlotNoteModal,
    } = this.props

    const getActions = (item) => {

      const actions = [
        <a onClick={() => onClickViewOrder(item.order_id)}>View</a>,
      ]

      if (item.user_id === user.id) {
        actions.push(
          <a onClick={() => onClickItem(item)}>Edit</a>,
        )
      }
      
      return actions

    }

    return (

      <Panel
        title='Plot Notes'
        tools={(
          <Button type='primary' onClick={onClickAdd}>Add</Button>
        )}
      >

        <div style={{ padding: 20 }}>

          <List
            dataSource={data}
            renderItem={item => (
              <List.Item actions={getActions(item)}>
                <List.Item.Meta
                  avatar={<Avatar icon='exclamation' style={{ backgroundColor: 'orange' }} />}
                  title={(
                    <div style={{ display: 'flex' }}>
                      <div>{`${item.order.plot_number} ${item.order.site.name}`}</div>
                      <Tag style={{ marginLeft: 5 }}>{moment(item.created_at).fromNow()}</Tag>
                    </div>
                  )}
                  description={(
                    <div>
                      <div style={{ fontWeight: 500 }}>{`${item.order.remedial ? 'Remedial' : 'Order'}: ${item.order.order_number}`}</div>
                      <div style={{ marginTop: 5, marginBottom: 5 }}>{item.note}</div>
                      <div style={{ fontSize: 13, fontStyle: 'italic' }}>
                        {`Created by ${item.user.name} on ${moment(item.created_at).format('Do MMMM YYYY')} at ${moment(item.created_at).format('HH:MM')}`}
                      </div>
                    </div>
                  )}
                />
              </List.Item>
            )}
          />

        </div>

        {plotNoteModal.show && (
          <PlotNoteModal
            {...plotNoteModal}
            onClose={onClosePlotNoteModal}
          />
        )}

      </Panel>

    )

  }

}
