import { createAction, handleActions } from 'redux-actions';

const loadMaterials = createAction("MODALS/ADD_MATERIAL_TO_PLOT_TYPE/LOAD_MATERIALS");
const clearMaterials = createAction("MODALS/ADD_MATERIAL_TO_PLOT_TYPE/CLEAR_MATERIALS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadMaterials]: (state, action) => action.payload,
    [clearMaterials]: () => []
  },
  defaultState
);

export { loadMaterials, clearMaterials, reducer };