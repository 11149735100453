import { createSelector } from 'reselect'

const selectFittingRates = state => state.modals.FittingRates.fittingRates

const getUnit = fittingRate => {

  const materialType = fittingRate.material_type

  if (!materialType) return 'Each'

  if (materialType.usage_key === 'dimensions') return 'Per M2'
  if (materialType.usage_key === 'square-metres') return 'Per M2'
  if (materialType.usage_key === 'item') return 'Each'

  return null

}

export default createSelector(
  selectFittingRates,
  (fittingRates) => fittingRates.map(fittingRate => ({
    ...fittingRate,
    unit: getUnit(fittingRate),
  })),
)
