export default (values) => () => {

  const errors = {}

  errors.dimensions = values.dimensions.map(row => {

    const rowErrors = {}

    if (!row.length) rowErrors.length = true

    if (!row.width) rowErrors.width = true

    return rowErrors

  })

  return errors

}
