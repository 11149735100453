import { connect } from 'react-redux'
import Materials from './Materials'

import selectItems from './Selectors/selectItems'
import selectShowAncCost from './Selectors/selectShowAncCost'
import selectShowRebateCost from './Selectors/selectShowRebateCost'

import onCloseDimensionsModal from './Handlers/onCloseDimensionsModal'
import onCloseNotesModal from './Handlers/onCloseNotesModal'

const mapStateToProps = (state) => ({
  order: state.screens.CostSheet.order,
  items: selectItems(state),
  showAncCost: selectShowAncCost(state),
  showRebateCost: selectShowRebateCost(state),
  dimensionsModal: state.screens.CostSheet.Materials.dimensionsModal,
  notesModal: state.screens.CostSheet.Materials.notesModal,
})

const actionCreators = {
  onCloseDimensionsModal,
  onCloseNotesModal,
}

export default connect(mapStateToProps, actionCreators)(Materials)
