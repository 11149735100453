import { connect } from 'react-redux'
import CostMatrix from './CostMatrix'

import selectColumns from './Selectors/selectColumns'
import selectPlotTypes from './Selectors/selectPlotTypes'

import componentDidMount from './Handlers/componentDidMount'
import onClickRegenerate from './Handlers/onClickRegenerate'
import onGoToPlotType from './Handlers/onGoToPlotType'
import onClickPriceCell from './Handlers/onClickPriceCell'

const mapStateToProps = (state) => ({
  columns: selectColumns(state),
  plotTypes: selectPlotTypes(state),
  fetched: state.screens.SiteView.CostMatrix.data.fetched,
  isFetching: state.screens.SiteView.CostMatrix.isFetching,
})

const actionCreators = {
  componentDidMount,
  onClickRegenerate,
  onGoToPlotType,
  onClickPriceCell,
}

export default connect(mapStateToProps, actionCreators)(CostMatrix)
