import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Divider } from 'antd'

import Form from './Components/Form'
import MaterialRanges from './Components/MaterialRanges'
import AddMaterialRangeModal from './Components/AddMaterialRangeModal'
import SiteRateRangeVariantsModal from './Components/SiteRateRangeVariantsModal'

export default class extends React.PureComponent {

  static propTypes = {
    isNew: PropTypes.bool.isRequired,
    siteRate: PropTypes.object,
    addMaterialRangeModal: PropTypes.object.isRequired,
    siteRateRangeVariantsModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onAddMaterialRange: PropTypes.func.isRequired,
    onCloseAddMaterialRangeModal: PropTypes.func.isRequired,
    onCloseSiteRateRangeVariantsModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    siteRate: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      isNew,
      siteRate,
      addMaterialRangeModal,
      siteRateRangeVariantsModal,
      onSubmit,
      onDelete,
      onAddMaterialRange,
      onCloseAddMaterialRangeModal,
      onCloseSiteRateRangeVariantsModal,
      onClose,
    } = this.props

    const footer = []

    if (!isNew) {
      footer.push(
        <Button key='delete' type='danger' onClick={onDelete} style={{ float: 'left' }}>
          Delete
        </Button>,
      )
      if (siteRate) {
        footer.push(
          <Button key='add' type='link' onClick={onAddMaterialRange} style={{ float: 'left' }}>
            Add Range
          </Button>,
        )
      }
    }

    footer.push(
      <Button key='cancel' onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' onClick={onSubmit}>
        {isNew ? 'Create' : 'Update'}
      </Button>,
    )

    return (
        
      <Modal
        visible  
        title={isNew ? 'New Rate' : 'Rate'}
        width={600}
        footer={footer}
        onCancel={() => onClose()}
      >
        
        <Form />

        <Divider />

        <MaterialRanges />

        {addMaterialRangeModal.show && (
          <AddMaterialRangeModal
            {...addMaterialRangeModal}
            onClose={onCloseAddMaterialRangeModal}
          />
        )}

        {siteRateRangeVariantsModal.show && (
          <SiteRateRangeVariantsModal
            siteRateId={siteRate.id}
            {...siteRateRangeVariantsModal}
            onClose={onCloseSiteRateRangeVariantsModal}
          />
        )}

      </Modal>
      
    )

  }

}
