import { Modal } from 'antd';
import deletePlotType from '../../../Services/delete';

export default () => (dispatch) => {

  Modal.confirm({
    title: `Delete Plot Type?`,
    content: 'This action cannot be undone',
    okType: 'danger',
    okText: 'Delete',
    width: 400,
    onOk: () => dispatch(deletePlotType())
  })

}