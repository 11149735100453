import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'

const delayForAnHour = createAction('MODALS/COMPLETED_JOBS/DELAY_FOR_AN_HOUR')

const defaultState = false

const reducer = handleActions(
  {
    [delayForAnHour]: () => moment().add(1, 'hour'),
  },
  defaultState
)

export { delayForAnHour, reducer }