import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createAreaGroup, updateAreaGroup } from '../../../../../HttpRequests/areaGroups'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch, getState) => {

  const { id, plotTypeId } = modalProps()

  const { selectedAreaIds } = getState().modals.AreaGroup

  const data = {
    name: values.name,
    material_type_id: values.material_type_id,
    is_custom: values.is_custom,
    area_ids: selectedAreaIds,
  }

  let response

  try {

    if (id) {

      response = await updateAreaGroup({ id, data })

      modalProps().onClose(response.data.data)

      message.success(`Area Group Updated`)

    } else {

      data.plot_type_id = plotTypeId

      response = await createAreaGroup({ data })

      modalProps().onClose(response.data.data)

      message.success(`Area Group Added`)

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
