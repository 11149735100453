import { createAction, handleActions } from 'redux-actions';

const setOpenedInspectorVisitId = createAction("SCREENS/BOARD/SET_OPENED_INSPECTOR_VISIT_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setOpenedInspectorVisitId]: (state, action) => action.payload
  },
  defaultState
);

export { setOpenedInspectorVisitId, reducer };
