export default (values) => () => {

  const errors = {}

  if (!values.name) errors.name = true

  if (!values.usage_key) errors.usage_key = true

  if (values.is_flooring) {
    if (!values.vat_perc && values.vat_perc !== 0) errors.vat_perc = true
  }

  return errors

}
