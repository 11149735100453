import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import styles from './MenuItem.css'

const MenuItem = (props) => {

  const {
    children,
    className,
    style,
    href,
    onClick,
  } = props

  const handleClick = (event) => {
    onClick && onClick(event)
  }

  const Component = () => (
    <div
      className={classNames(styles.item, className)}
      style={style}
      onClick={handleClick}
      data-closes="true"
    >
      {children}
    </div>
  )

  if (href) {
    return (
      <Link to={href}>
        <Component />
      </Link>
    )
  }

  return <Component />
}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

MenuItem.defaultProps = {
  className: undefined,
  style: undefined,
  href: undefined,
  onClick: undefined,
}

export default MenuItem