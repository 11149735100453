import { createSelector } from 'reselect'
import selectMaterialRanges from '../../../../../Selectors/selectMaterialRanges'

const selectMaterialTypes = state => state.core.materialTypes

export default createSelector(
  selectMaterialTypes,
  selectMaterialRanges,
  (materialTypes, materialRanges) => {

    const materialTypeIds = materialRanges.map(row => row.material_type_id)

    return materialTypes.filter(row => materialTypeIds.includes(row.id) && !!row.is_flooring)

  },
)
