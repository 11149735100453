import { createAction, handleActions } from 'redux-actions';

const setShowOrderCreateModal = createAction("SCREENS/ORDERS/SET_SHOW_ORDER_CREATE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowOrderCreateModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowOrderCreateModal, reducer };