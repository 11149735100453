import { connect } from 'react-redux'
import Form from './Form'

import onToggleIsCustom from './Handlers/onToggleIsCustom'
import onToggleAreaId from './Handlers/onToggleAreaId'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  materialTypes: state.core.materialTypes.filter(row => row.usage_key === 'dimensions' && !!row.is_flooring),
  selectedAreaIds: state.modals.AreaGroup.selectedAreaIds,
})

const actionCreators = {
  onToggleIsCustom,
  onToggleAreaId,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
