import { setShowJobModal } from "../../../State/showJobModal";
import { setShowHolidayModal } from "../../../State/showHolidayModal";
import { setShowInspectorVisitModal } from "../../../State/showInspectorVisitModal";
import { setCreateLegacyJob } from "../../../State/createLegacyJob";

export default ({ legacy = false } = {}) => async (dispatch, getState) => {

  switch (getState().screens.Board.source) {

    default:
    case 'JOBS':
      dispatch(setShowJobModal(true));
      if (legacy) dispatch(setCreateLegacyJob(true));
    break;

    case 'HOLIDAYS':
      dispatch(setShowHolidayModal(true));
    break;

    case 'INSPECTOR_VISITS':
      dispatch(setShowInspectorVisitModal(true));
    break;

    case 'INSPECTIONS':
      dispatch(setShowJobModal(true));
      if (legacy) dispatch(setCreateLegacyJob(true));
    break;

  }
}