import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Component from './Component';
import { FORM_NAME } from '../../Form';

import orderNumbers from './Selectors/orderNumbers';
import piggybackOrderNumbers from './Selectors/piggybackOrderNumbers';
import fitterInfo from './Selectors/fitterInfo';
import inspectionInfo from './Selectors/inspectionInfo';
import canFindAvailableFitters from './Selectors/canFindAvailableFitters';

import onChangeDates from "./Handlers/onChangeDates";
import onChangeFitter from "./Handlers/onChangeFitter";
import onChangeInspection from "./Handlers/onChangeInspection";
import onChangeFlags from "./Handlers/onChangeFlags";
import onChangeOrderSearch from "./Handlers/onChangeOrderSearch";
import onChangePiggybackOrderSearch from "./Handlers/onChangePiggybackOrderSearch";
import onChangeOrder from "./Handlers/onChangeOrder";
import onBlurOrder from "./Handlers/onBlurOrder";
import onBlurPiggybackOrder from "./Handlers/onBlurPiggybackOrder";
import onClickFindFitter from "./Handlers/onClickFindFitter";
import onClickNotes from "./Handlers/onClickNotes";

const value = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
  fitters: state.core.fitters,
  inspectors: state.core.inspectors,
  orderNumbers: orderNumbers(state),
  piggybackOrderNumbers: piggybackOrderNumbers(state),
  legacy: value(state, 'legacy'),
  orderId: value(state, 'order_id'),
  fitterId: value(state, 'fitter_id'),
  fitterInfo: fitterInfo(state),
  inspectionInfo: inspectionInfo(state),
  canFindAvailableFitters: canFindAvailableFitters(state),
});

const actionCreators = {
  onChangeDates,
  onChangeFitter,
  onChangeInspection,
  onChangeFlags,
  onChangeOrderSearch,
  onChangePiggybackOrderSearch,
  onChangeOrder,
  onBlurOrder,
  onBlurPiggybackOrder,
  onClickFindFitter,
  onClickNotes,
};

export default connect(mapStateToProps, actionCreators)(Component);