import * as React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Icon } from 'antd';
import { orderBy } from 'lodash';
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    plotTypes: PropTypes.array.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired
  }

  renderHeader = () => (

    <div className={styles.header}>

      <div className={styles.title}>
        Plot Types
      </div>

      <Button onClick={this.props.onClickAdd} type="primary">
        <Icon type="plus-circle" />
        New Plot Type
      </Button>

    </div>

  )
  
  render () {

    const {
      plotTypes,
      onClickItem
    } = this.props;

    const columns = [
      
      {
        title: 'Name',
        dataIndex: 'name',
        width: 170,
        render: (text, record) => (
          <div onClick={() => onClickItem(record.id)} role="presentation" className={styles.link}>
            {record.name}
          </div>
        )
      },
      
      {
        title: 'Format',
        dataIndex: 'format',
        width: 170,
        render: (text, record) => record.format
      }
      
    ];

    return (

      <Table
        dataSource={orderBy(plotTypes, 'name')}
        columns={columns} 
        rowKey={record => record.id}
        pagination={false}
        bordered
        title={this.renderHeader}
        locale={{ emptyText: 'There are no sites for this site yet' }}
      />

    )
  }
}