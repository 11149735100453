import { findSite } from '../../../HttpRequests/sites';
import { loadSite } from '../State/site';
import handleException from '../../../Core/Helpers/handleException';
import { getProps } from '../props';

export default () => async (dispatch) => {

  try {
    
    const { id } = getProps().match.params;

    const params = {};

    params.include = ['builder','plot_types'].join();

    const response = await findSite({ id, params });

    const site = response.data.data;

    dispatch(loadSite(site));

  } catch (error) {

    handleException(error);

  }
}