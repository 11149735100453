import { getProps } from '../props';
import { getOrder } from '../../../HttpRequests/orders';
import { loadOrder } from '../State/order';
import initializeOrderForm from './initializeOrderForm';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch) => {

  try {

    const { id } = getProps().match.params;

    const params = {};

    params.include = [
      'order_category',
      'builder',
      'site',
      'questionnaire_sent_by_user',
      'remedial_questionnaires',
      // 'plot_type.areas',
      // 'plot_type.site.builder.materials.material_type',
      // 'plot_type.site.builder.materials.material_variants',
      // 'plot_type.site.builder.materials.material_ranges',
      // 'specifications',
      // 'specifications.areas',
      // 'specifications.specification_lines',
      // 'order_materials',
      // 'leftovers_used',
      'jobs',
      'jobs.fitter',
      // 'leftovers_stored',
      // 'leftovers_stored.from_order',
      // 'leftovers_stored.material_type',
      // 'leftovers_stored.material',
      // 'leftovers_stored.material_range',
      // 'supply_costs',
      // 'fitting_costs',
      'fitter_invoices',
      'fitter_invoices.fitter',
      'order_logs',
      'order_logs.user',
      'cost_sheet',
    ].join();

    params.append = [
      'files'
    ].join();

    const response = await getOrder({ id, params });

    const order = response.data.data;

    dispatch(loadOrder(order));

    dispatch(initializeOrderForm(order));

  } catch (error) {

    handleException(error)

  }
}