import { createAction, handleActions } from 'redux-actions';

const loadBuilderMaterialRates = createAction("MODALS/ADD_MATERIAL_TO_PLOT_TYPE/LOAD_BUILDER_MATERIAL_RATES");
const clearBuilderMaterialRates = createAction("MODALS/ADD_MATERIAL_TO_PLOT_TYPE/CLEAR_BUILDER_MATERIAL_RATES");

const defaultState = [];

const reducer = handleActions(
  {
    [loadBuilderMaterialRates]: (state, action) => action.payload,
    [clearBuilderMaterialRates]: () => []
  },
  defaultState
);

export { loadBuilderMaterialRates, clearBuilderMaterialRates, reducer };