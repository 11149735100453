import { createAction, handleActions } from 'redux-actions';

const setOpenedLeftoverId = createAction("SCREENS/ORDER_VIEW/SET_OPENED_LEFTOVER_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setOpenedLeftoverId]: (state, action) => action.payload
  },
  defaultState
);

export { setOpenedLeftoverId, reducer };
