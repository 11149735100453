import { nextWeek, nextMonth } from "../../../State/dateRange";
import fetchData from '../../../Services/fetchData';

export default () => async (dispatch, getState) => {
  
  const { mode } = getState().screens.Board;

  if (mode === 'WEEK') dispatch(nextWeek());
  if (mode === 'MONTH') dispatch(nextMonth());

  dispatch(fetchData());

}