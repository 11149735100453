import React from 'react'
import { Modal, Icon } from 'antd'
import deleteRangeVariant from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: 'Delete Variant',
    content: 'Are you sure you want to delete this variant?',
    icon: <Icon type='delete' style={{ color: '#ff4d4f' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteRangeVariant()),
  })

}
