import { createAction, handleActions } from 'redux-actions';

const setThetfordOnly = createAction("SCREENS/BOARD/SET_THETFORD_ONLY");

const defaultState = false;

const reducer = handleActions(
  {
    [setThetfordOnly]: (state, action) => action.payload
  },
  defaultState
);

export { setThetfordOnly, reducer };
