import React from 'react'
import { formValueSelector, getFormInitialValues } from 'redux-form'
import { Modal } from 'antd'
import { intersection } from 'lodash'
import moment from 'moment'
import { getProps } from '../props'
import { FORM_NAME } from '../Form'
import { getFitterAvailabilityForDates } from '../../../HttpRequests/fitters'
import { getJobPiggybacked } from '../../../HttpRequests/jobs'
import handleException from '../../../Core/Helpers/handleException'

const bankHolidays = [
  '2023-04-07',
  '2023-04-10',
  '2023-05-01',
  '2023-05-08',
  '2023-05-29',
  '2023-08-28',
  '2023-12-25',
  '2023-12-26',

  '2024-01-01',
  '2024-03-29',
  '2024-04-01',
  '2024-05-06',
  '2024-05-27',
  '2024-08-26',
  '2024-12-25',
  '2024-12-26',
]

export default () => async (dispatch, getState) => {
  
  try {
  
    const { jobId } = getProps()

    const values = formValueSelector(FORM_NAME)(getState(), 'tag', 'fitter_id', 'start_date', 'end_date')

    const initialValues = getFormInitialValues(FORM_NAME)(getState())

    if (!values.fitter_id || !values.start_date || !values.end_date) return

    const id = values.fitter_id

    const params = {
      start_date: values.start_date,
      end_date: values.end_date,
      exclude_job_id: jobId,
    }

    const diff = moment(values.end_date).diff(moment(values.start_date), 'days')

    const dates = []

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= diff; i++) {
      const date = moment(values.start_date)
        .add(i, 'days')
        .format('YYYY-MM-DD')
      dates.push(date)
    }

    if (intersection(dates, bankHolidays).length > 0) {
      await new Promise(resolve => {
        Modal.warning({
          title: 'Bank Holiday',
          content: 'The selected dates fall on a bank holiday.',
          width: 530,
          onOk: () => resolve(true),
        })
      })
    }

    const response = await getFitterAvailabilityForDates({ id, params })

    const availability = response.data.data;

    if (availability.assigned_to_job) {
      await new Promise(resolve => {
        Modal.warning({
          title: 'Fitter Assigned',
          content: 'This Fitter has already been assigned to another job on these dates.',
          width: 530,
          onOk: () => resolve(true),
        })
      })
    } else if (availability.on_holiday) {
      await new Promise(resolve => {
        Modal.warning({
          title: 'Fitter on Holiday',
          content: 'This Fitter has a holiday booked during these dates.',
          width: 500,
          onOk: () => resolve(true),
        })
      })
    }

    if (!jobId || initialValues.tag === 'Remedial' || !initialValues.fitter_id) return

    const { data: piggybacked } = await getJobPiggybacked({ id: jobId })

    if (piggybacked.length) {

      const remedials = piggybacked.map(remedial => ({
        ...remedial,
        formatted: `
            ${remedial.collected ? '◆ ' : ''}
            ${remedial.sent ? '✓ ' : ''}
            ${remedial.approved === 0 ? '★ ' : ''}
            ${remedial.via_thetford ? 'T ' : ''}
            ${remedial.order ? remedial.order.plot_number : remedial.legacy_plot_number}
            ${remedial.order ? `${remedial.order.site ? remedial.order.site.name : remedial.order.site_custom} @ ${remedial.order.site ? remedial.order.site.town : remedial.order.site_custom}` : remedial.legacy_site}
            ${' | '}
            ${remedial.metres ? `${remedial.metres}${remedial.town_house ? ' T/H' : ''} | ` : ''}
            ${remedial.fitter.name}
          `,
      }))

      Modal.warning({
        title: 'Remedial Piggybacking',
        content: (
          <div>
            <div>{`There are remedial jobs that ${remedials[0].fitter.name} has been assigned to which may be 'piggybacking' the job that you're trying to update:`}</div>
            <br />
            <ul>
              {remedials.map(remedial => (
                <li key={remedial.id}>{remedial.formatted}</li>
              ))}
            </ul>
            <div>Please alert customer care of this update.</div>
          </div>
        ),
        width: 500,
      })

    }

  } catch (error) {
    
    handleException(error)

  }
}