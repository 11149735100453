import { initialize } from 'redux-form';
import { findPlotType } from '../../../HttpRequests/plotTypes';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { plotTypeId, siteId } = getProps();
  
  if (plotTypeId) {

    const response = await findPlotType({ id: plotTypeId });

    const plotType = response.data.data;

    dispatch(initialize(FORM_NAME, Object.assign({}, plotType, {
      plots: plotType.plots ? plotType.plots.split(',') : []
    })))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      site_id: siteId,
      plots: []
    }))

  }

}