import { createAction, handleActions } from 'redux-actions';
import { includes, without, concat } from 'lodash';

const toggleTag = createAction("SCREENS/BOARD/TOGGLE_TAG");

const defaultState = [];

const reducer = handleActions(
  {
    [toggleTag]: (state, action) => includes(state, action.payload) ? without(state, action.payload) : concat(state, action.payload)
  },
  defaultState
);

export { toggleTag, reducer };
