import { getPlotNotes } from '../../../../../HttpRequests/plotNotes'
import { setData } from '../State/data'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { order } = getState().screens.OrderView

  const params = {
    'filter[order]': order.id,
    sort: '-created_at',
    include: 'order.site,user',
  }

  try {

    const response = await getPlotNotes({ params })

    dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
