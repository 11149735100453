import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Table from './Components/Table'

export default class _AvailableFitters_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      onClose,
    } = this.props

    return (
        
      <Modal
        visible  
        title="Available Fitters"
        onCancel={onClose}
        footer={null}
        width={700}
      >
        
        <Table />

      </Modal>
      
    )
  }
}