import { createSelector } from 'reselect'
import { groupBy, orderBy, uniq } from 'lodash'
import cheatSheetOptions from '../../../../../Utils/cheatSheetOptions'

const selectCheatSheets = state => state.screens.CostSheet.order.cheat_sheets

export default createSelector(
  selectCheatSheets,
  (cheatSheets) => {

    // We only want cheat sheets which have a single material range (for variant selection purposes)
    const $cheatSheets = cheatSheets.filter(cheatSheet => {
      const materialRangeIds = cheatSheet.cost_sheet_areas
        .filter(row => !!row.material_range_id)
        .map(row => row.material_range_id)
      return uniq(materialRangeIds).length === 1
    })

    // We don't want duplicate labels appearing
    const cheatSheetOptionsByLabel = groupBy(cheatSheetOptions($cheatSheets), 'label')

    // Finally, order by label
    return orderBy(
      Object.keys(cheatSheetOptionsByLabel).map((label) => ({
        label,
        value: cheatSheetOptionsByLabel[label][0].value,
      })),
      'label',
    )

  },
)
