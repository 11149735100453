import { createAction, handleActions } from 'redux-actions'

const openSupplierModal = createAction('MODALS/SUPPLIERS/OPEN_SUPPLIER_MODAL')
const closeSupplierModal = createAction('MODALS/SUPPLIERS/CLOSE_SUPPLIER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSupplierModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeSupplierModal]: () => defaultState,
  },
  defaultState,
)

export { openSupplierModal, closeSupplierModal, reducer }
