import { createAction, handleActions } from 'redux-actions';

const setLocked = createAction("SCREENS/BOARD/SET_LOCKED");

const defaultState = true;

const reducer = handleActions(
  {
    [setLocked]: (state, action) => action.payload
  },
  defaultState
);

export { setLocked, reducer };
