import { pick } from 'lodash'
import updateOrder from '../../../Services/updateOrder'

export default async (values, dispatch) => {

  const orderValues = pick(values, [
    'credit',
    'custom_entry',
    'private',

    'credit_reference',
    'credit_status',

    'original_order_id',
    'order_category_id',
    'order_category_custom',
    'builder_id',
    'builder_custom',
    'site_id',
    'site_custom',
    'town_custom',
    'plot_type_id',
    'plot_number',
    'builder_po_number',

    'date',
    'ordered_date',
    'delivery_date',

    'sale_price',
    'contract_works_price',
    'customer_extra_price',
    'matwell_price',
    'protection_price',

    'customer_email',
    'customer_phone_number',
    'remedial_overview',
    'custom_price_1',
    'custom_price_1_label',
    'custom_price_2',
    'custom_price_2_label',
    'custom_price_3',
    'custom_price_3_label',

    'sage_export_skip',
  ])
  
  await dispatch(updateOrder(orderValues))

}
