import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openPlotNoteModal = createAction('SCREENS/ORDER_VIEW/PLOT_NOTES/OPEN_PLOT_NOTE_MODAL')
const closePlotNoteModal = createAction('SCREENS/ORDER_VIEW/PLOT_NOTES/CLOSE_PLOT_NOTE_MODAL')

const defaultState = {
  show: false,
  plotNote: null,
  orderId: null,
}

const reducer = handleActions(
  {
    [openPlotNoteModal]: (state, action) => ({
      ...state,
      show: true,
      plotNote: get(action.payload, 'plotNote'),
      orderId: get(action.payload, 'orderId'),
    }),
    [closePlotNoteModal]: () => defaultState,
  },
  defaultState,
)

export { openPlotNoteModal, closePlotNoteModal, reducer }
