import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'antd'
import styles from './LinkButton.css'

const LinkButton = (props) => {

  const {
    children,
    type,
    icon,
    iconRight,
    onClick,
    disabled,
    style,
    className,
  } = props

  return (
    
    <span onClick={disabled ? undefined : onClick} className={classNames(styles.button, styles[disabled ? 'disabled' : type], className)} style={style}>

      {icon && <Icon type={icon} style={{ marginRight: children ? 8 : undefined }} />}

      {children}

      {iconRight && <Icon type={iconRight} style={{ marginLeft: children ? 8 : undefined }} />}

    </span>

  )
}

LinkButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['default','primary','danger']),
  icon: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

LinkButton.defaultProps = {
  children: undefined,
  type: 'default',
  icon: undefined,
  iconRight: undefined,
  onClick: undefined,
  disabled: false,
  style: undefined,
  className: undefined,
}

export default LinkButton