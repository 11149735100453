import { change, untouch } from 'redux-form';
import { setOrderSearchTerm } from "../../../State/orderSearchTerm";
import { FORM_NAME } from '../../../Form';

export default (event, value) => (dispatch) => {
  
  dispatch(setOrderSearchTerm(''));

  if (value === 'order') {

    dispatch(change(FORM_NAME, 'remedial', false));

    dispatch(change(FORM_NAME, 'credit_reference', null));
    dispatch(change(FORM_NAME, 'credit_status', null));

    dispatch(change(FORM_NAME, 'original_order_id', null));
    dispatch(change(FORM_NAME, 'custom_price_1', null));
    dispatch(change(FORM_NAME, 'custom_price_1_label', null));
    dispatch(change(FORM_NAME, 'custom_price_2', null));
    dispatch(change(FORM_NAME, 'custom_price_2_label', null));
    dispatch(change(FORM_NAME, 'custom_price_3', null));
    dispatch(change(FORM_NAME, 'custom_price_3_label', null));
    dispatch(change(FORM_NAME, 'customer_email', null));
    dispatch(change(FORM_NAME, 'customer_phone_number', null));
    dispatch(change(FORM_NAME, 'remedial_overview', null));

    dispatch(untouch(FORM_NAME,
      'original_order_id',
      'custom_price_1',
      'custom_price_1_label',
      'custom_price_2',
      'custom_price_2_label',
      'custom_price_3',
      'custom_price_3_label',
      'customer_email',
      'customer_phone_number',
      'remedial_overview',
    ));

  }

  if (value === 'remedial') {

    dispatch(change(FORM_NAME, 'remedial', true));

    dispatch(change(FORM_NAME, 'plot_type_id', null));
    dispatch(change(FORM_NAME, 'sale_price', null));
    dispatch(change(FORM_NAME, 'contract_works_price', null));
    dispatch(change(FORM_NAME, 'customer_extra_price', null));
    dispatch(change(FORM_NAME, 'matwell_price', null));
    dispatch(change(FORM_NAME, 'protection_price', null));

    dispatch(untouch(FORM_NAME,
      'plot_type_id',
      'sale_price',
      'contract_works_price',
      'customer_extra_price',
      'matwell_price',
      'protection_price'
    ));

  }
  
}