import { createAction, handleActions } from 'redux-actions';

const setShowBuilderModal = createAction("SCREENS/BUILDERS/SET_SHOW_BUILDER_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowBuilderModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowBuilderModal, reducer };