import modalProps from '../modalProps'
import { resetAreaRangeQuote } from '../State/areaRangeQuote'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetAreaRangeQuote())
  
}
