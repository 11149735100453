import { connect } from 'react-redux'
import Areas from './Areas'

import selectItems from './Selectors/selectItems'

import onCloseSetupModal from './Handlers/onCloseSetupModal'
import onCloseAddModal from './Handlers/onCloseAddModal'
import onCloseDimensionsModal from './Handlers/onCloseDimensionsModal'
import onCloseNotesModal from './Handlers/onCloseNotesModal'

const mapStateToProps = (state) => ({
  items: selectItems(state),
  setupModal: state.screens.CostSheet.Areas.setupModal,
  addModal: state.screens.CostSheet.Areas.addModal,
  dimensionsModal: state.screens.CostSheet.Areas.dimensionsModal,
  notesModal: state.screens.CostSheet.Areas.notesModal,
})

const actionCreators = {
  onCloseSetupModal,
  onCloseAddModal,
  onCloseDimensionsModal,
  onCloseNotesModal,
}

export default connect(mapStateToProps, actionCreators)(Areas)
