import { push } from 'connected-react-router';

import fetchSite from '../Services/fetchSite';

export default (plotType) => async (dispatch, getState) => {

  dispatch(fetchSite());

  const { site } = getState().screens.SiteView;

  dispatch(push(`/portal/builders/${site.builder_id}/sites/${site.id}/plot_types/${plotType.id}`));

}