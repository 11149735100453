import { combineReducers } from 'redux';

import AddMaterialToPlotType from './AddMaterialToPlotType/reducer';
import Job from './Job/reducer';
import Order from './Order/reducer';
import OrderCreate from './OrderCreate/reducer';
import Leftover from './Leftover/reducer';
import UpdateMaterialInArea from './UpdateMaterialInArea/reducer';
import UpdateMaterialTypeInArea from './UpdateMaterialTypeInArea/reducer';
import AvailableFitters from './AvailableFitters/reducer';
import CompletedJobs from './CompletedJobs/reducer';
import Manufacturers from './Manufacturers/reducer';
import Manufacturer from './Manufacturer/reducer';
import MaterialTypes from './MaterialTypes/reducer';
import MaterialType from './MaterialType/reducer';
import FittingRates from './FittingRates/reducer';
import FittingRate from './FittingRate/reducer';
import MaterialRanges from './MaterialRanges/reducer';
import MaterialRange from './MaterialRange/reducer';
import RangeVariants from './RangeVariants/reducer';
import RangeVariant from './RangeVariant/reducer';
import BuilderRate from './BuilderRate/reducer';
import BuilderMaterialRange from './BuilderMaterialRange/reducer';
import SiteRate from './SiteRate/reducer';
import SiteMaterialRange from './SiteMaterialRange/reducer';
import AreaGroup from './AreaGroup/reducer';
import AreaRangeQuote from './AreaRangeQuote/reducer';
import Suppliers from './Suppliers/reducer';
import Supplier from './Supplier/reducer';
import SupplierRates from './SupplierRates/reducer';
import SupplierRate from './SupplierRate/reducer';

export default combineReducers({
  AddMaterialToPlotType,
  Job,
  Order,
  OrderCreate,
  Leftover,
  UpdateMaterialInArea,
  UpdateMaterialTypeInArea,
  AvailableFitters,
  CompletedJobs,
  Manufacturers,
  Manufacturer,
  MaterialTypes,
  MaterialType,
  FittingRates,
  FittingRate,
  MaterialRanges,
  MaterialRange,
  RangeVariants,
  RangeVariant,
  BuilderRate,
  BuilderMaterialRange,
  SiteRate,
  SiteMaterialRange,
  AreaGroup,
  AreaRangeQuote,
  Suppliers,
  Supplier,
  SupplierRates,
  SupplierRate,
})