import modalProps from '../modalProps'
import { resetSiteRate } from '../State/siteRate'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetSiteRate())
  
}
