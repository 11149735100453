import { createAction, handleActions } from 'redux-actions'

const openSiteRateModal = createAction('SCREENS/SITE_VIEW/OPEN_SITE_RATE_MODAL')
const closeSiteRateModal = createAction('SCREENS/SITE_VIEW/CLOSE_SITE_RATE_MODAL')

const defaultState = {
  show: false,
  id: null,
  siteId: null,
}

const reducer = handleActions(
  {
    [openSiteRateModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      siteId: action.payload.siteId,
    }),
    [closeSiteRateModal]: () => defaultState,
  },
  defaultState,
)

export { openSiteRateModal, closeSiteRateModal, reducer }
