import { createAction, handleActions } from 'redux-actions';

const setShowJobModal = createAction("SCREENS/ORDER_VIEW/SET_SHOW_JOB_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowJobModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowJobModal, reducer };