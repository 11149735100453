import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openSupplierModal = createAction('SCREENS/MATERIALS/OPEN_SUPPLIER_MODAL')
const closeSupplierModal = createAction('SCREENS/MATERIALS/CLOSE_SUPPLIER_MODAL')

const defaultState = {
  show: false,
  supplier: null,
}

const reducer = handleActions(
  {
    [openSupplierModal]: (state, action) => Object.assign({}, state, {
      show: true,
      supplier: get(action, 'payload.supplier'),
    }),
    [closeSupplierModal]: () => defaultState,
  },
  defaultState,
)

export { openSupplierModal, closeSupplierModal, reducer }
