import { addSelectedRowKeys, removeSelectedRowKeys } from '../../../State/selectedRowKeys'

export default (selected, selectedRows, changedRows) => (dispatch) => {

  if (selected) {
    dispatch(addSelectedRowKeys(changedRows.map(job => job.id)))
  } else {
    dispatch(removeSelectedRowKeys(changedRows.map(job => job.id)))
  }
  
}