import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteRangeVariant } from '../../../HttpRequests/rangeVariants'
import fetchRangeVariants from '../../../Core/Services/fetchRangeVariants'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { rangeVariant } = modalProps()

  try {

    await deleteRangeVariant({ id: rangeVariant.id })

    message.success('Deleted Variant')

    modalProps().onClose(true)

    dispatch(fetchRangeVariants({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
