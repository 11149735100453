import { combineReducers } from 'redux'

import { reducer as builderRate } from './State/builderRate'
import { reducer as addMaterialRangeModal } from './State/addMaterialRangeModal'
import { reducer as builderRateRangeVariantsModal } from './State/builderRateRangeVariantsModal'

import BuilderRateRangeVariantsModal from './Components/BuilderRateRangeVariantsModal/reducer'

export default combineReducers({
  builderRate,
  addMaterialRangeModal,
  builderRateRangeVariantsModal,

  BuilderRateRangeVariantsModal,
})
