import { connect } from 'react-redux'

import Component from './Component'

import onDrop from '../../../../Handlers/onDrop'
import onClickUpload from './Handlers/onClickUpload'
import onClickDownload from './Handlers/onClickDownload'
import onClickDelete from './Handlers/onClickDelete'

const mapStateToProps = (state) => ({
  files: state.screens.OrderView.order ? state.screens.OrderView.order.files : [],
})

const actionCreators = {
  onDrop,
  onClickUpload,
  onClickDownload,
  onClickDelete,
}

export default connect(mapStateToProps, actionCreators)(Component)

