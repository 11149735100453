import { createAction, handleActions } from 'redux-actions'

const setSelectedRangeVariantIds = createAction('MODALS/SITE_RATE/SITE_RATE_RANGE_VARIANTS_MODAL/SET_SELECTED_RANGE_VARIANT_IDS')
const resetSelectedRangeVariantIds = createAction('MODALS/SITE_RATE/SITE_RATE_RANGE_VARIANTS_MODAL/RESET_SELECTED_RANGE_VARIANT_IDS')

const defaultState = []

const reducer = handleActions(
  {
    [setSelectedRangeVariantIds]: (_, { payload }) => payload,
    [resetSelectedRangeVariantIds]: () => defaultState,
  },
  defaultState,
)

export { setSelectedRangeVariantIds, resetSelectedRangeVariantIds, reducer }
