import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import WidthSpacer from '../../../WidthSpacer'
import styles from './styles.css'

const Controls = props => {

  const {
    order,
    editing,
    onClickAdd,
    onClickReset,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={styles.title}>Areas</div>

      {!!order.cost_sheet.material_type_id && editing && (

        <React.Fragment>

          <Button type='primary' size='small' onClick={onClickAdd}>Add</Button>

          <WidthSpacer type='button-small' />

          <Button size='small' onClick={onClickReset}>Reset</Button>

        </React.Fragment>

      )}

    </div>
    
  )

}

Controls.propTypes = {
  order: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onClickReset: PropTypes.func.isRequired,
}

export default Controls
