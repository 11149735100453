import { message } from 'antd'
import { deleteOrderLog, updateOrderLog } from '../../../HttpRequests/orderLogs';
import fetchOrder from './fetchOrder';
import handleException from '../../../Core/Helpers/handleException';

export default ({ id, content }) => async (dispatch) => {
  
  try {

    if (content) {

      const data = {
        content,
      }

      await updateOrderLog({ id, data });

      message.success("Log Updated");

      await dispatch(fetchOrder());

    } else {

      await deleteOrderLog({ id });

      message.success("Log Deleted");

      await dispatch(fetchOrder());

    }

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}