import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField, SelectField } from 'redux-form-antd-fields';

const labelColSpan = 6;
const wrapperColSpan = 16;

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired
  }

  render () {

    const {
      submitting
    } = this.props;

    return (
      
      <React.Fragment>

        <Field
          name="name"
          label="Name"
          disabled={submitting}
          component={InputField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="colour"
          label="Colour"
          component={SelectField}
          disabled={submitting}
          options={[
            { label: 'Blue', value: 'blue' },
            { label: 'Red', value: 'red' },
            { label: 'Orange', value: 'orange' },
            { label: 'Black', value: 'black' },
            { label: 'Purple', value: 'purple' },
            { label: 'Pink', value: '#e04193' },
          ]}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

      </React.Fragment>

    )
  }
}