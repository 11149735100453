import { connect } from 'react-redux';
import Component from './Component';

import didCreateJob from "./Handlers/didCreateJob";
import didCreateFitterInvoice from "./Handlers/didCreateFitterInvoice";
import didUpdateJob from "./Handlers/didUpdateJob";
import didUpdateFitterInvoice from "./Handlers/didUpdateFitterInvoice";
import didDeleteJob from "./Handlers/didDeleteJob";
import didDeleteFitterInvoice from "./Handlers/didDeleteFitterInvoice";
import onCloseJobModal from "./Handlers/onCloseJobModal";
import onCloseFitterInvoiceModal from "./Handlers/onCloseFitterInvoiceModal";

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order,
  showJobModal: state.screens.OrderView.showJobModal,
  showFitterInvoiceModal: state.screens.OrderView.showFitterInvoiceModal,
  openedJobId: state.screens.OrderView.openedJobId,
  openedFitterInvoiceId: state.screens.OrderView.openedFitterInvoiceId
});

const actionCreators = {
  didCreateJob,
  didCreateFitterInvoice,
  didUpdateJob,
  didUpdateFitterInvoice,
  didDeleteJob,
  didDeleteFitterInvoice,
  onCloseJobModal,
  onCloseFitterInvoiceModal
};

export default connect(mapStateToProps, actionCreators)(Component);
