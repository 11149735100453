import { createAction, handleActions } from 'redux-actions'

const openCompletedJobsModal = createAction('SCREENS/BOARD/OPEN_COMPLETED_JOBS_MODAL')
const closeCompletedJobsModal = createAction('SCREENS/BOARD/CLOSE_COMPLETED_JOBS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openCompletedJobsModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeCompletedJobsModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openCompletedJobsModal, closeCompletedJobsModal, reducer }