import { connect } from 'react-redux'
import SupplierRate from './SupplierRate'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'
import onCloseDimensionsModal from './Handlers/onCloseDimensionsModal'

const mapStateToProps = (state) => ({
  submitting: state.modals.SupplierRate.Form.formState.submitting,
  dimensionsModal: state.modals.SupplierRate.dimensionsModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onSubmit,
  onDelete,
  onCloseDimensionsModal,
}

export default connect(mapStateToProps, actionCreators)(SupplierRate)
