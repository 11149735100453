import { createAction, handleActions } from 'redux-actions'

const openMaterialRangeModal = createAction('SCREENS/MATERIALS/OPEN_MATERIAL_RANGE_MODAL')
const closeMaterialRangeModal = createAction('SCREENS/MATERIALS/CLOSE_MATERIAL_RANGE_MODAL')

const defaultState = {
  show: false,
  id: null,
  materialType: null,
}

const reducer = handleActions(
  {
    [openMaterialRangeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      materialType: action.payload.materialType,
    }),
    [closeMaterialRangeModal]: () => defaultState,
  },
  defaultState,
)

export { openMaterialRangeModal, closeMaterialRangeModal, reducer }
