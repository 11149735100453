import { createSelector } from 'reselect'
import { find } from 'lodash'

const selectMaterialTypes = state => state.core.materialTypes
const selectAreas = state => state.screens.PlotType.Areas.areas

const selectTableRows = createSelector(
  selectMaterialTypes,
  selectAreas,
  (materialTypes, areas) => {

    const totals = materialTypes
      .filter(materialType => !!materialType.is_flooring && materialType.usage_key === 'dimensions')
      .map(materialType => ({ ...materialType, total: 0 }))

    areas.forEach(area => {

      area.area_material_types
        .filter(areaMaterialType => !areaMaterialType.area_group_id)
        .forEach(areaMaterialType => {
          const materialType = find(totals, row => row.id === areaMaterialType.material_type_id)
          materialType.total += Number(areaMaterialType.square_metres)
        })

    })
    
    return totals

  }

)

export default selectTableRows
