import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openManufacturerModal = createAction('SCREENS/MATERIALS/OPEN_MANUFACTURER_MODAL')
const closeManufacturerModal = createAction('SCREENS/MATERIALS/CLOSE_MANUFACTURER_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openManufacturerModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id'),
    }),
    [closeManufacturerModal]: () => defaultState,
  },
  defaultState,
)

export { openManufacturerModal, closeManufacturerModal, reducer }
