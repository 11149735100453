import { createAction, handleActions } from 'redux-actions';

const setOpenedJobId = createAction("SCREENS/ORDER_VIEW/SET_OPENED_JOB_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setOpenedJobId]: (state, action) => action.payload
  },
  defaultState
);

export { setOpenedJobId, reducer };
