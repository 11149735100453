import modalProps from '../modalProps'
import { resetControls } from '../State/controls'
import { resetSupplierRates } from '../State/supplierRates'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetControls())
  dispatch(resetSupplierRates())

}
