import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as suppliers } from './State/suppliers'
import { reducer as fetching } from './State/fetching'
import { reducer as supplierModal } from './State/supplierModal'
import { reducer as supplierRatesModal } from './State/supplierRatesModal'

export default combineReducers({
  controls,
  suppliers,
  fetching,
  supplierModal,
  supplierRatesModal,
})
