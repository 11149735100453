import { openSiteRateRangeVariantsModal } from '../../../State/siteRateRangeVariantsModal'

export default (materialRange) => (dispatch, getState) => {

  const { siteRate } = getState().modals.SiteRate

  const rangeVariantIds = materialRange.range_variants.map(row => row.id)

  const rangeVariants = siteRate.range_variants.filter(row => rangeVariantIds.includes(row.id))

  dispatch(openSiteRateRangeVariantsModal({ materialRange, rangeVariants }))

}
