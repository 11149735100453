import { find, reduce } from 'lodash'
import selectAreaSummary from '../../../../../Selectors/selectAreaSummary'

export default (costSheetExpense, state) => {

  const { materialTypes, rangeVariants } = selectAreaSummary(state)

  const $costSheetExpense = { ...costSheetExpense }

  const { fitting_rate: fittingRate } = $costSheetExpense

  const materialTypeIds = fittingRate.material_types.map(row => row.id)
  const materialRangeIds = fittingRate.material_ranges.map(row => row.id)

  // Non flooring & targeting material types specifically?
  if (materialTypeIds.length > 0) {
    const $materialTypes = materialTypes.filter(({ materialTypeId }) => materialTypeIds.includes(materialTypeId))
    if ($materialTypes.length === 0) return $costSheetExpense
    const squareMetres = reduce($materialTypes, (sum, row) => sum + (row.squareMetres || 0), 0)
    if (fittingRate.material_type.usage_key === 'dimensions' || fittingRate.material_type.usage_key === 'square-metres') {
      $costSheetExpense.cost = (squareMetres * fittingRate.tar_value) * fittingRate.rate
    }
    if (fittingRate.material_type.usage_key === 'item') {
      $costSheetExpense.cost = Math.ceil(squareMetres / fittingRate.tar_value) * fittingRate.rate
    }
    return $costSheetExpense
  }

  // Flooring & targeting material ranges specifically?
  if (materialRangeIds.length > 0) {
    const $rangeVariants = rangeVariants.filter(({ materialRangeId }) => materialRangeIds.includes(materialRangeId))
    if ($rangeVariants.length === 0) return $costSheetExpense
    const squareMetres = reduce($rangeVariants, (sum, row) => sum + (row.squareMetres || 0), 0)
    $costSheetExpense.cost = squareMetres * fittingRate.rate
    return $costSheetExpense
  }

  const { material_type: materialType } = fittingRate

  if (!materialType || !materialType.is_flooring) {
    $costSheetExpense.cost = fittingRate.rate
  }

  const $materialType = find(materialTypes, ({ materialTypeId }) => materialTypeId === fittingRate.material_type_id)

  // Since this data is coming from the area summary, each material type should
  // be flooring, meaning its usage key will either be dimensions or square
  // metres. In which case, it's safe to assume that we'll be multiplying the
  // fitting rate by the given square metres value

  if ($materialType) {
    $costSheetExpense.cost = $materialType.squareMetres * fittingRate.rate
  }

  return $costSheetExpense
  
}
