import { setMode } from '../../../State/mode'
import { setAsWeekForDate } from '../../../State/dateRange'
import fetchData from '../../../Services/fetchData'

export default (date) => (dispatch) => {

  dispatch(setMode('WEEK'))
  dispatch(setAsWeekForDate(date))
  
  dispatch(fetchData())

}