import { connect } from 'react-redux'
import MaterialRanges from './MaterialRanges'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseMaterialRangeModal from './Handlers/onCloseMaterialRangeModal'
import onCloseRangeVariantsModal from './Handlers/onCloseRangeVariantsModal'

const mapStateToProps = (state) => ({
  materialRangeModal: state.modals.MaterialRanges.materialRangeModal,
  rangeVariantsModal: state.modals.MaterialRanges.rangeVariantsModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseMaterialRangeModal,
  onCloseRangeVariantsModal,
}

export default connect(mapStateToProps, actionCreators)(MaterialRanges)
