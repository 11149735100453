import { closeSupplierModal } from '../State/supplierModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeSupplierModal())

  if (reload) dispatch(fetch())

}
