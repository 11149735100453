import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './MenuDivider.css'

const MenuDivider = (props) => {

  const {
    className,
    style,
  } = props

  return (

    <div
      className={classNames(styles.divider, className)}
      style={style}
    />

  )
}

MenuDivider.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

MenuDivider.defaultProps = {
  className: undefined,
  style: undefined,
}

export default MenuDivider