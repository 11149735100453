import { Modal } from 'antd'
import screenProps from '../screenProps'
import fetchBuilders from '../../../Core/Services/fetchBuilders'
import fetchMaterialTypes from '../../../Core/Services/fetchMaterialTypes'
import fetchManufacturers from '../../../Core/Services/fetchManufacturers'
import fetchMaterialRanges from '../../../Core/Services/fetchMaterialRanges'
import fetchFittingRates from '../../../Core/Services/fetchFittingRates'
import fetchSuppliers from '../../../Core/Services/fetchSuppliers'
import fetchSupplierRates from '../../../Core/Services/fetchSupplierRates'
import updateMaterialCosts from '../Components/Materials/Services/updateCosts'
import updateExpenseCosts from '../Components/Footer/Components/Expenses/Services/updateCosts'
import updateQuotePrices from '../Components/Footer/Components/Quotes/Services/updatePrices'
import fetch from '../Services/fetch'
import save from '../Services/save'

export default (props) => async (dispatch, getState) => {

  screenProps(props)

  await Promise.all([
    dispatch(fetchBuilders()),
    dispatch(fetchMaterialTypes()),
    dispatch(fetchManufacturers()),
    dispatch(fetchMaterialRanges()),
    dispatch(fetchFittingRates()),
    dispatch(fetchSuppliers()),
    dispatch(fetchSupplierRates()),
  ])

  await dispatch(fetch())

  // Has the order been called off since the cost sheet was last updated?

  const { order } = getState().screens.CostSheet

  if (order.called_off_at > order.cost_sheet.updated_at) {
    Modal.confirm({
      title: 'Update Costs & Prices?',
      content: `This cost sheet hasn't been updated since it was called off. Would you like to update its costs & prices?`,
      okText: 'Yes, Update',
      cancelText: 'No',
      onOk: async () => {
        dispatch(updateMaterialCosts())
        dispatch(updateExpenseCosts())
        dispatch(updateQuotePrices())

        await dispatch(save())
      }
    })
  }

}
