import { connect } from 'react-redux';

import Component from './Component';

import onClickAdd from './Handlers/onClickAdd';
import onClickJob from './Handlers/onClickJob';

const mapStateToProps = (state) => ({
  jobs: state.screens.OrderView.order ? state.screens.OrderView.order.jobs : []
});

const actionCreators = {
  onClickAdd,
  onClickJob
};

export default connect(mapStateToProps, actionCreators)(Component);
