import { setActiveTab } from '../../../State/activeTab'
import { setPagination } from '../../../State/pagination'
import fetchFitters from '../../../Services/fetchFitters'
import fetchInspectors from '../../../Services/fetchInspectors'

export default (key) => (dispatch) => {

  dispatch(setActiveTab(key))

  dispatch(setPagination({ current: 1 }));

  if (key === 'fitters') {
    dispatch(fetchFitters())
  }

  if (key === 'inspectors') {
    dispatch(fetchInspectors())
  }

}
