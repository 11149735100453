import { connect } from 'react-redux';
import Component from './Component';

import { getProps } from '../../props';

const mapStateToProps = (state) => {
  const { siteId, builderId } = getProps();

  return {
    siteId,
    managers: state.core.users,
    sites: state.core.sites.filter(site => site.builder_id === builderId),
  }
};

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Component);