import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Input } from 'antd'

const NotesModal = props => {

  const {
    title,
    value,
    onClose,
  } = props

  const [$value, setValue] = useState(value)

  return (

    <Modal
      title={title}
      visible
      width={360}
      okText='Update'
      onOk={() => onClose($value)}
      onCancel={() => onClose($value)}
      maskClosable={false}
    >
      
      <Input.TextArea
        value={$value}
        onChange={e => setValue(e.target.value)}
        autoFocus
      />

    </Modal>

  )

}

NotesModal.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

NotesModal.defaultProps = {
  value: null,
}

export default NotesModal
