import { createAction, handleActions } from 'redux-actions'

const openMaterialTypeModal = createAction('MODALS/MATERIAL_TYPES/OPEN_MATERIAL_TYPE_MODAL')
const closeMaterialTypeModal = createAction('MODALS/MATERIAL_TYPES/CLOSE_MATERIAL_TYPE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openMaterialTypeModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeMaterialTypeModal]: () => defaultState,
  },
  defaultState,
)

export { openMaterialTypeModal, closeMaterialTypeModal, reducer }
