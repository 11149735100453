import { createSelector } from 'reselect'

const selectSupplierRates = state => state.modals.SupplierRates.supplierRates

const getFormat = supplierRate => {

  const materialType = supplierRate.material_type

  if (materialType.usage_key === 'dimensions') {
    const { dimensions } = supplierRate
    if (dimensions && dimensions.length) return 'Roll'
    return 'Cut'
  }

  return null

}

const getUnit = supplierRate => {

  const materialType = supplierRate.material_type

  if (materialType.usage_key === 'dimensions') return 'Per M2'
  if (materialType.usage_key === 'square-metres') return 'Per M2'
  if (materialType.usage_key === 'item') return 'Each'

  return null

}

export default createSelector(
  selectSupplierRates,
  (supplierRates) => supplierRates.map(supplierRate => ({
    ...supplierRate,
    format: getFormat(supplierRate),
    unit: getUnit(supplierRate),
  })),
)
