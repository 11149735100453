import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import Sites from "./Components/Sites";
import OldSites from "./Components/OldSites";
import BuilderRates from "./Components/BuilderRates";
import BuilderMaterialRanges from "./Components/BuilderMaterialRanges";

import BuilderModal from "../../Modals/Builder";
import SiteModal from "../../Modals/Site";
import BuilderRateModal from "../../Modals/BuilderRate";
import BuilderMaterialRangeModal from "../../Modals/BuilderMaterialRange";

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    builder: PropTypes.object,
    activeTab: PropTypes.string.isRequired,
    showBuilderModal: PropTypes.bool.isRequired,
    showSiteModal: PropTypes.bool.isRequired,
    builderRateModal: PropTypes.object.isRequired,
    builderMaterialRangeModal: PropTypes.object.isRequired,
    onCloseBuilderModal: PropTypes.func.isRequired,
    onCloseSiteModal: PropTypes.func.isRequired,
    didUpdateBuilder: PropTypes.func.isRequired,
    didDeleteBuilder: PropTypes.func.isRequired,
    didCreateSite: PropTypes.func.isRequired,
    onCloseBuilderRateModal: PropTypes.func.isRequired,
    onCloseBuilderMaterialRangeModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    builder: null,
  };

  componentDidMount = () => this.props.componentDidMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      builder,
      activeTab,
      showBuilderModal,
      showSiteModal,
      builderRateModal,
      builderMaterialRangeModal,
      onCloseBuilderModal,
      onCloseSiteModal,
      didUpdateBuilder,
      didDeleteBuilder,
      didCreateSite,
      onCloseBuilderRateModal,
      onCloseBuilderMaterialRangeModal,
    } = this.props;

    if (!builder) return null;

    return (

      <Screen>

        <Breadcrumbs builder={builder} />

        <Header />

        {activeTab === "SITES" && <Sites />}

        {activeTab === "OLD_SITES" && <OldSites />}

        {activeTab === "BUILDER_RATES" && <BuilderRates />}

        {activeTab === "BUILDER_MATERIAL_RANGES" && <BuilderMaterialRanges />}

        {showBuilderModal && (
          <BuilderModal
            builderId={builder.id}
            onClose={onCloseBuilderModal}
            didUpdate={didUpdateBuilder}
            didDelete={didDeleteBuilder}
          />
        )}

        {showSiteModal && (
          <SiteModal
            builderId={builder.id}
            onClose={onCloseSiteModal}
            didCreate={didCreateSite}
          />
        )}

        {builderRateModal.show && (

          <BuilderRateModal
            {...builderRateModal}
            onClose={onCloseBuilderRateModal}
          />

        )}

        {builderMaterialRangeModal.show && (

          <BuilderMaterialRangeModal
            {...builderMaterialRangeModal}
            onClose={onCloseBuilderMaterialRangeModal}
          />

        )}

      </Screen>

    )
  }
}
