import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Splashscreen.css'

import spinner from '../Resources/spinner.gif'

const Splashscreen = (props) => {

  const {
    className,
    style,
  } = props

  return (

    <div
      className={classNames(styles.splashscreen, className)}
      style={style}
    >
      <img src={spinner} style={{ width: 128, height: 128 }} />
    </div>

  )
}

Splashscreen.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

Splashscreen.defaultProps = {
  className: undefined,
  style: undefined,
}

export default Splashscreen