import React from 'react'
import PropTypes from 'prop-types'
import { List, Button, Avatar, Tag } from 'antd'
import moment from 'moment'
import Panel from '../Panel'

import OrderNoteModal from '../../../../Modals/OrderNote'

export default class OrderNotes extends React.PureComponent {

  static propTypes = {
    user: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    orderNoteModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired,
    onCloseOrderNoteModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render() {

    const {
      user,
      data,
      orderNoteModal,
      onClickAdd,
      onClickItem,
      onCloseOrderNoteModal,
    } = this.props

    const getActions = (item) => {

      const actions = []

      if (item.user_id === user.id) {
        actions.push(
          <a onClick={() => onClickItem(item)}>Edit</a>,
        )
      }
      
      return actions

    }

    return (

      <Panel
        title='Order Notes'
        tools={(
          <Button type='primary' onClick={onClickAdd}>Add</Button>
        )}
      >

        <div style={{ padding: 20 }}>

          <List
            dataSource={data}
            renderItem={item => (
              <List.Item actions={getActions(item)}>
                <List.Item.Meta
                  avatar={<Avatar icon='info' style={{ backgroundColor: 'green' }} />}
                  title={(
                    <div style={{ display: 'flex' }}>
                      <div>{`${item.user.name} on ${moment(item.created_at).format('Do MMMM YYYY')} at ${moment(item.created_at).format('HH:MM')}`}</div>
                      <Tag style={{ marginLeft: 5 }}>{moment(item.created_at).fromNow()}</Tag>
                    </div>
                  )}
                  description={item.note}
                />
              </List.Item>
            )}
          />

        </div>

        {orderNoteModal.show && (
          <OrderNoteModal
            {...orderNoteModal}
            onClose={onCloseOrderNoteModal}
          />
        )}

      </Panel>

    )

  }

}
