import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import Component from './Component';

import onEditContent from './Handlers/onEditContent';
import onClickSubmit from './Handlers/onClickSubmit';

import { formConfig, FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  user: state.user,
  hasEnteredContent: !!formValueSelector(FORM_NAME)(state,'content'),
  orderLogs: state.screens.OrderView.order ? state.screens.OrderView.order.order_logs : []
});

const actionCreators = {
  onEditContent,
  onClickSubmit,
};

export default connect(mapStateToProps, actionCreators)(reduxForm(formConfig)(Component));
