import { createAction, handleActions } from 'redux-actions'

const setFittingRates = createAction('MODALS/FITTING_RATES/SET_FITTING_RATES')
const resetFittingRates = createAction('MODALS/FITTING_RATES/RESET_FITTING_RATES')

const defaultState = []

const reducer = handleActions(
  {
    [setFittingRates]: (state, { payload }) => payload,
    [resetFittingRates]: () => defaultState,
  },
  defaultState,
)

export { setFittingRates, resetFittingRates, reducer }
