import React from 'react'
import PropTypes from 'prop-types'
import { List, Avatar, Tag, Divider } from 'antd'
import moment from 'moment'

export default class PlotNotes extends React.PureComponent {

  static propTypes = {
    plotNotes: PropTypes.array.isRequired,
    onClickViewOrder: PropTypes.func.isRequired,
  }

  render() {

    const {
      plotNotes,
      onClickViewOrder,
    } = this.props

    if (!plotNotes.length) return false

    return (

      <div>

        <Divider style={{ marginBottom: 0 }} />

        <div style={{ height: 220, overflow: 'auto' }}>

          <h4 style={{ marginTop: 10 }}>Plot Notes</h4>

          <List
            dataSource={plotNotes}
            renderItem={item => (
              <List.Item
                actions={[<a onClick={() => onClickViewOrder(item.order_id)}>View</a>]}
              >
                <List.Item.Meta
                  avatar={<Avatar icon='exclamation' style={{ backgroundColor: 'orange' }} />}
                  title={(
                    <div style={{ display: 'flex' }}>
                      <div>{`${item.order.plot_number} ${item.order.site.name}`}</div>
                      <Tag style={{ marginLeft: 5 }}>{moment(item.created_at).fromNow()}</Tag>
                    </div>
                  )}
                  description={(
                    <div>
                      <div style={{ fontWeight: 500 }}>{`${item.order.remedial ? 'Remedial' : 'Order'}: ${item.order.order_number}`}</div>
                      <div style={{ marginTop: 5, marginBottom: 5 }}>{item.note}</div>
                      <div style={{ fontSize: 13, fontStyle: 'italic' }}>
                        {`Created by ${item.user.name} on ${moment(item.created_at).format('Do MMMM YYYY')} at ${moment(item.created_at).format('HH:MM')}`}
                      </div>
                    </div>
                  )}
                />
              </List.Item>
            )}
          />

        </div>

      </div>

    )

  }

}
