import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    inspectors: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired,
  }

  render () {

    const {
      inspectors,
      pagination,
      fetching,
      onChangeTable,
      onClickItem
    } = this.props;
    
    const columns = [

      { 
        title: "Name", 
        dataIndex: "name",
        width: 200,
        render: (text, record) => (
          <div onClick={() => onClickItem(record.id)} role="presentation" className={styles.link}>
            {record.name}
          </div>
        )
      },
      
    ]
    
    return (

      <Table
        dataSource={inspectors}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />

    )
  }
}