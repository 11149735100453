import { closeRangeVariantModal } from '../State/rangeVariantModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeRangeVariantModal())

  if (reload) dispatch(fetch())

}
