import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Spin, Affix, BackTop, Icon } from 'antd'
import UploadProgress from './UploadProgress'
import styles from './Screen.css'

export default class Screen extends React.PureComponent {

  static propTypes = {
    AppBar: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    HeaderInner: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    title: PropTypes.node,
    showBackButton: PropTypes.bool,
    onBack: PropTypes.func,
    minScreenWidth: PropTypes.number,
    maxScreenWidth: PropTypes.number,
    fitToScreen: PropTypes.bool,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    headerRight: PropTypes.node,
    headerLeft: PropTypes.node,
    headerClassName: PropTypes.string,
    headerStyle: PropTypes.object,
    footerLeft: PropTypes.node,
    footerRight: PropTypes.node,
    footerClassName: PropTypes.string,
    footerStyle: PropTypes.object,
    contentLoading: PropTypes.bool,
    contentClassName: PropTypes.string,
    contentStyle: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
    uploads: PropTypes.object,
    backToTop: PropTypes.bool,
  }

  static defaultProps = {
    AppBar: undefined,
    HeaderInner: undefined,
    title: null,
    showBackButton: false,
    onBack: undefined,
    minScreenWidth: 1200,
    maxScreenWidth: 1600,
    fitToScreen: false,
    showHeader: true,
    showFooter: false,
    headerLeft: null,
    headerRight: null,
    headerClassName: undefined,
    headerStyle: undefined,
    contentLoading: false,
    contentClassName: undefined,
    contentStyle: undefined,
    footerLeft: null,
    footerRight: null,
    footerClassName: undefined,
    footerStyle: undefined,
    className: undefined,
    style: undefined,
    children: null,
    uploads: undefined,
    backToTop: false,
  }

  render () {

    const {
      AppBar,
      HeaderInner,
      title,
      showBackButton,
      onBack,
      minScreenWidth,
      maxScreenWidth,
      fitToScreen,
      showHeader,
      showFooter,
      headerLeft,
      headerRight,
      headerClassName,
      headerStyle,
      contentLoading,
      contentClassName,
      contentStyle,
      footerLeft,
      footerRight,
      footerClassName,
      footerStyle,
      className,
      style,
      children,
      uploads,
      backToTop,
    } = this.props

    return (

      <div className={classnames(styles.screen, { [styles.fitToScreen]: fitToScreen }, className)} style={{ minWidth: minScreenWidth, ...style }}>

        {AppBar && <AppBar />}

        {showHeader && (
        
        <Affix offsetTop={0}>

          <div className={classnames('sota-screen-header', styles.headerWrapper, headerClassName)} style={{ ...headerStyle }}>

            <div className={styles.headerInner} style={{ maxWidth: fitToScreen ? undefined : maxScreenWidth }}>
              
              {HeaderInner && <HeaderInner />}

              {(!HeaderInner && (headerLeft || title || showBackButton)) && (

                <div className={styles.headerLeft}>

                  {showBackButton && (
                    <Icon type="arrow-left" className={styles.backButton} onClick={onBack} />
                  )}
                  
                  
                  <div className={styles.title}>
                    {title}
                  </div>

                  {headerLeft}

                </div>

              )}

              {(!HeaderInner && headerRight) && (

                <div className={styles.headerRight}>
                  {headerRight}
                </div>

              )}

            </div>
          
          </div>

        </Affix>
          
        )}

        <div className={classnames(styles.contentWrapper, { [styles.fixedFooterReserve]: showFooter }, contentClassName)} style={contentStyle}>
    
          <div className={styles.contentInner} style={{ maxWidth: fitToScreen ? undefined : maxScreenWidth }}>

            {!!contentLoading && <Spin style={{ display: 'block', margin: 'auto', marginTop: 30 }} /> }

            {!contentLoading && children}

          </div>
        
        </div>

        {showFooter && (

        <div className={classnames('sota-screen-footer', styles.footerWrapper, footerClassName)} style={footerStyle}>

          <div className={styles.footerInner} style={{ maxWidth: fitToScreen ? undefined : maxScreenWidth }}>

            {footerLeft && (

              <div className={styles.footerLeft}>
                {footerLeft}
              </div>

            )}

            {footerRight && (

              <div className={styles.footerRight}>
                {footerRight}
              </div>

            )}  

          </div>

        </div>
          
        )}
        
        {uploads && (
          <UploadProgress
            show={uploads.queue.length > 0}
            queueLength={uploads.queue.length}
            progress={uploads.progress}
            filename={uploads.queue.length > 0 ? uploads.queue[0].file.name : ''}
          />
        )}

        {backToTop && <BackTop />}

      </div>

    )
  }
}