import * as React from 'react';
import PropTypes from 'prop-types';
import styles from './Panel.css';

export default class extends React.PureComponent {

  static propTypes = {
    title: PropTypes.string.isRequired,
    tools: PropTypes.node,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    tools:  null
  }

  render () {

    const {
      title,
      tools,
      children
    } = this.props;

    return (

      <div className={styles.container}>

        <div className={styles.header}>

          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.tools}>
            {tools}
          </div>

        </div>

        <div className={styles.body}>
          {children}
        </div>

      </div>
      
    )
  }
}
