import { connect } from 'react-redux'
import Placeholder from './Placeholder'

import onClickSelect from './Handlers/onClickSelect'

const mapStateToProps = (state) => ({
  editing: state.screens.CostSheet.editing,
})

const actionCreators = {
  onClickSelect,
}

export default connect(mapStateToProps, actionCreators)(Placeholder)
