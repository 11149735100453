import { message } from 'antd';
import { getProps } from '../props';
import { deleteSite } from '../../../HttpRequests/sites';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { siteId, onClose, didDelete } = getProps();

    await deleteSite({ id: siteId });
    
    message.success("Site Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}