import saveAreaMaterialType from '../../../Services/saveAreaMaterialType'
import deleteAreaMaterialType from '../../../Services/deleteAreaMaterialType'

export default (dimensions) => async (dispatch, getState) => {

  const { id, areaId, materialTypeId } = getState().screens.PlotType.dimensionsModal

  if (dimensions.length === 0) {
    dispatch(deleteAreaMaterialType(id))
    return
  }
  
  const values = {
    dimensions,
  }

  if (!id) {
    values.area_id = areaId
    values.material_type_id = materialTypeId
  }

  await dispatch(saveAreaMaterialType(id, values))

}
