import { connect } from 'react-redux'
import PlotNotes from './PlotNotes'

import onClickViewOrder from './Handlers/onClickViewOrder'

const mapStateToProps = (state) => ({
  plotNotes: state.modals.Job.plotNotes,
})

const actionCreators = {
  onClickViewOrder,
}

export default connect(mapStateToProps, actionCreators)(PlotNotes)
