import modalProps from '../modalProps'
import fetch from '../Services/fetch'
import initializeForm from '../Components/Form/Services/initialize'

export default (props, prevProps) => async (dispatch) => {

  modalProps(props)

  if (prevProps.id !== props.id) {

    await dispatch(fetch())

    dispatch(initializeForm())

  }

}
