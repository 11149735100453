import { createSelector } from 'reselect'

const selectMaterialRanges = state => state.core.materialRanges
const selectValues = state => state.modals.FittingRate.Form.formState.values

export default createSelector(
  selectMaterialRanges,
  selectValues,
  (materialRanges, values) => {
    if (!values.material_type_id) return []
    return materialRanges.filter(row => row.material_type_id === values.material_type_id)
  },
)
