import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createBuilderMaterialRange, updateBuilderMaterialRange } from '../../../../../HttpRequests/builderMaterialRanges'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { builderMaterialRange, builderId } = modalProps()

  const data = {
    material_type_id: values.material_type_id,
    material_range_id: values.material_range_id,
  }

  try {

    if (builderMaterialRange) {
      await updateBuilderMaterialRange({ id: builderMaterialRange.id, data })
      message.success('Updated Material')
    } else {
      data.builder_id = builderId
      await createBuilderMaterialRange({ data })
      message.success('Created Material')
    }

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

    throw error

  }

}
