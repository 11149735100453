import { connect } from 'react-redux';

import Component from './Component';

import onClickAdd from './Handlers/onClickAdd';
import onClickItem from './Handlers/onClickItem';

const mapStateToProps = (state) => ({
  plotTypes: state.screens.SiteView.site ? state.screens.SiteView.site.plot_types : []
});

const actionCreators = {
  onClickAdd,
  onClickItem
};

export default connect(mapStateToProps, actionCreators)(Component);
