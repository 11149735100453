import moment from 'moment';
import { getMaterials } from '../../HttpRequests/materials';
import { loadMaterials } from '../State/materials';

let lastGetTimestamp = null;

const cacheTTL = 60000;

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'));

      if (now < (lastGetTimestamp + cacheTTL)) return;
    }
      
    lastGetTimestamp = Number(moment().format('x'));

    const params = {
      include: 'material_type,material_ranges,material_variants',
      pagination: 0
    }

    const response = await getMaterials({ params });

    dispatch(loadMaterials(response.data.data));

  } catch (error) {
    
    throw error;

  }
}