import React from 'react'
import { Modal, Icon } from 'antd'
import archive from '../Services/archive'

export default () => (dispatch) => {

  Modal.confirm({
    title: 'Archive Range',
    content: 'Are you sure you want to archive this range?',
    icon: <Icon type="eye-invisible" />,
    okText: 'Archive',
    onOk: () => dispatch(archive()),
  })

}
