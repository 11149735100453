import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { materialRange, manufacturerId } = modalProps()

  if (!materialRange) {
    dispatch(setFormInitialValues({ manufacturer_id: manufacturerId, categories: [], siblings: [] }))
    return
  }

  const initialValues = {
    ...materialRange,
    categories: materialRange.categories.map(row => row.id),
    siblings: materialRange.siblings.map(row => row.id),
  }

  dispatch(setFormInitialValues(initialValues))

}
