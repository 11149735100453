import { closePlotNoteModal } from '../State/plotNoteModal'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closePlotNoteModal())

  if (reload) {
    dispatch(fetch())
  }

}
