import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchFitters from '../../../Core/Services/fetchFitters';
import fetchInspectors from '../../../Core/Services/fetchInspectors';
import fetchOrderNumbers from '../../../Core/Services/fetchOrderNumbers';

export default (props) => (dispatch) => {

  setProps(props);

  dispatch(initialize());

  dispatch(fetchFitters());
  dispatch(fetchInspectors());
  dispatch(fetchOrderNumbers());
  
}