import { formValueSelector } from 'redux-form'
import { FORM_NAME } from '../Form'
import { getPlotNotes } from '../../../HttpRequests/plotNotes'
import { setPlotNotes } from '../State/plotNotes'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {
  
  try {

    const orderId = formValueSelector(FORM_NAME)(getState(), 'order_id')

    if (!orderId) return

    const params = {
      'filter[order]': orderId,
      sort: '-created_at',
      include: 'order.site,user',
    }

    const response = await getPlotNotes({ params })

    dispatch(setPlotNotes(response.data.data))

  } catch (error) {
    
    handleException(error)

  }
}