import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { find } from 'lodash';
import { Radio, Switch, Dropdown, Menu, Icon } from 'antd';
import tags from '../../../../Core/Misc/tags';
import styles from './Component.css';

const modes = [
  { key: 'MONTH', label: 'Month' },
  { key: 'WEEK', label: 'Week' }
];

export default class extends React.PureComponent {

  static propTypes = {
    mode: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    showWeekends: PropTypes.bool.isRequired,
    hideAllocated: PropTypes.bool.isRequired,
    thetfordOnly: PropTypes.bool.isRequired,
    hideOrdered: PropTypes.bool.isRequired,
    managers: PropTypes.array.isRequired,
    manager: PropTypes.number,
    selectedTags: PropTypes.array.isRequired,
    onChangeMode: PropTypes.func.isRequired,
    onChangeHideAllocated: PropTypes.func.isRequired,
    onChangeManager: PropTypes.func.isRequired,
    onChangeShowWeekends: PropTypes.func.isRequired,
    onChangeThetfordOnly: PropTypes.func.isRequired,
    onChangeHideOrdered: PropTypes.func.isRequired,
    onClickTag: PropTypes.func.isRequired
  }

  static defaultProps = {
    manager: null
  }

  render () {

    const {
      mode,
      source,
      showWeekends,
      hideAllocated,
      thetfordOnly,
      hideOrdered,
      managers,
      manager,
      selectedTags,
      onChangeMode,
      onChangeHideAllocated,
      onChangeManager,
      onChangeShowWeekends,
      onChangeThetfordOnly,
      onChangeHideOrdered,
      onClickTag
    } = this.props;

    const managerMenu = (

      <Menu onClick={({ key }) => onChangeManager(key === 'ALL' ? null : Number(key))}>

        <Menu.Item key="ALL" style={manager === null ? { color: '#1990FF' } : {}}>
          <Icon type="check-circle" theme={manager === null ? 'filled' : 'outlined'} />
          {' Anyone'}
        </Menu.Item>

        <Menu.Divider />

        {managers.map(item => (
          <Menu.Item key={item.id} style={manager === item.id ? { color: '#1990FF' } : {}}>
            <Icon type="check-circle" theme={manager === item.id ? 'filled' : 'outlined'} />
            {` ${item.first_name} ${item.last_name}`}
          </Menu.Item>
        ))}
        
      </Menu>

    )

    const managedBySelector = (

      <Dropdown overlay={managerMenu} trigger={['click']} placement="topLeft">

        <div className={classnames(styles.dropdownLink)}>

          <Icon type="team" className={styles.dropdownIcon} />

          <div className={styles.dropdownText}>
            {manager === null ? `Managed by: Anyone ▾` : (() => {
              const item = find(managers, { id: manager });
              return `Managed by: ${item.first_name} ${item.last_name} ▾`
            })()}
          </div>

        </div>

      </Dropdown>

    )

    return (
      
      <div className={styles.wrapper}>

        <div className={styles.left}>

          <Radio.Group value={mode} onChange={onChangeMode} buttonStyle="solid" size="small">

            {modes.map(tab => (

              <Radio.Button
                style={{ minWidth: 65, textAlign: 'center', fontSize: 12 }}
                value={tab.key}
                key={tab.key}
              >
                {tab.label}
              </Radio.Button>

            ))}

          </Radio.Group>

          <div style={{ width: 20 }} />

          <div className={styles.switchWrapper}>

            <Switch
              onChange={onChangeShowWeekends}
              checked={showWeekends}
            />
            
            <div className={styles.switchLabel} onClick={() => onChangeShowWeekends(!showWeekends)} role="presentation">
              {'Show Weekends'}
            </div>

          </div>

          {(source === 'JOBS' || source === 'INSPECTIONS') && (

            <React.Fragment>

              <div style={{ width: 24 }} />

              <div className={styles.switchWrapper}>

                <Switch
                  onChange={onChangeHideAllocated}
                  checked={hideAllocated}
                />
                
                <div className={styles.switchLabel} onClick={() => onChangeHideAllocated(!hideAllocated)} role="presentation">
                  {'Hide Allocated'}
                </div>

              </div>

              <div style={{ width: 24 }} />
              
              {managedBySelector}

              <div style={{ width: 24 }} />

              <div className={styles.switchWrapper}>

                <Switch
                  onChange={onChangeThetfordOnly}
                  checked={thetfordOnly}
                />

                <div className={styles.switchLabel} onClick={() => onChangeThetfordOnly(!thetfordOnly)} role="presentation">
                  {'NHC Delivering'}
                </div>

              </div>

              <div style={{ width: 24 }} />

              <div className={styles.switchWrapper}>

                <Switch
                  onChange={onChangeHideOrdered}
                  checked={hideOrdered}
                />

                <div className={styles.switchLabel} onClick={() => {}} role="presentation">
                  {'Hide Ordered'}
                </div>

              </div>
            
            </React.Fragment>

          )}

          {source === 'JOB_STATS' && (

            <React.Fragment>

              <div style={{ width: 24 }} />

              {managedBySelector}

            </React.Fragment>

          )}

        </div>

        <div className={styles.right}>

          {(source === 'JOBS' || source === 'INSPECTIONS') && (

            <React.Fragment>
        
              {tags.map(tag => (

                <div
                  key={tag.name}
                  onClick={() => onClickTag(tag.name)}
                  role="presentation"
                  className={classnames({
                    [styles.tag]: true,
                    [styles[`tag${tag.color}`]]: true,
                    [styles.tagActive]: selectedTags.includes(tag.name),
                  })}
                >
                  {tag.name}
                </div>

              ))}
            
            </React.Fragment>

          )}

        </div>

      </div>
      
    )
  }
}