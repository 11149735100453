import { createAction, handleActions } from 'redux-actions';

const loadSuppliers = createAction("CORE/SUPPLIERS/LOAD_SUPPLIERS");
const clearSuppliers = createAction("CORE/SUPPLIERS/CLEAR_SUPPLIERS");

const defaultState = []

const reducer = handleActions(
  {
    [loadSuppliers]: (state, action) => action.payload,
    [clearSuppliers]: () => []
  },
  defaultState
);

export { loadSuppliers, clearSuppliers, reducer };