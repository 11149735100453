import { get } from 'lodash'

export default (values, materialType) => () => {

  const errors = {}

  if (!values.material_type_id) errors.material_type_id = true

  if (!values.material_range_id) errors.material_range_id = true

  if (get(materialType, 'usage_key') === 'dimensions') {

    if (!values.format) errors.format = true

    if (values.format === 'roll') {
      if (!values.dimensions || !values.dimensions.length) errors.dimensions = true
    }

  }

  if (!values.rate) errors.rate = true

  return errors

}
