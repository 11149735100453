import { setEditing } from '../../../State/editing'
import { setCostSheet } from '../../../State/order'

export default () => (dispatch, getState) => {

  dispatch(setEditing(false))

  dispatch(setCostSheet(getState().screens.CostSheet.original))

}
