import { createAction, handleActions } from 'redux-actions';

const setShowHolidayModal = createAction("SCREENS/BOARD/SET_SHOW_HOLIDAY_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowHolidayModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowHolidayModal, reducer };
