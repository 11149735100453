import { createAction, handleActions } from 'redux-actions'

const setAreas = createAction('SCREENS/PLOT_TYPE/AREAS/SET_AREAS')
const resetAreas = createAction('SCREENS/PLOT_TYPE/AREAS/RESET_AREAS')

const defaultState = []

const reducer = handleActions(
  {
    [setAreas]: (state, action) => action.payload,
    [resetAreas]: () => defaultState,
  },
  defaultState,
)

export { setAreas, resetAreas, reducer }
