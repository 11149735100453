import { setCostMatrixFormat } from '../State/costMatrixFormat'
import fetch from '../Components/CostMatrix/Services/fetch'

export default (value) => async (dispatch) => {

  dispatch(setCostMatrixFormat(value))

  dispatch(fetch())

}
