import { showSpinner, hideSpinner } from '../State/showSpinner'
import { getJobStats } from '../../../HttpRequests/jobs'
import { loadJobStats } from '../State/jobStats'
import handleException from '../../../Core/Helpers/handleException'

export default ({ quiet } = { quiet: false }) => async (dispatch, getState) => {

  const { dateRange, manager } = getState().screens.Board

  const params = {
    'filter[date_between]': dateRange.join(),
  }

  if (manager) params['filter[manager_id]'] = manager

  if (!quiet) dispatch(showSpinner())
  
  try {
    
    const response = await getJobStats({ params })

    if (!quiet) dispatch(hideSpinner())
    
    dispatch(loadJobStats(response.data))

  } catch (error) {

    if (!quiet) dispatch(hideSpinner())
    
    handleException(error)

  }

}
