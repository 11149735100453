import handleException from '../../../Core/Helpers/handleException';
import { getSuppliers } from '../../../HttpRequests/suppliers';
import { setFetching } from '../State/fetching';
import { loadSuppliers } from '../State/suppliers';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm } = getState().screens.Suppliers;

  try {

    const params = {};
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (searchTerm) {
      params.search = searchTerm;
    }

    params.sort = 'name';

    dispatch(setFetching(true));

    const response = await getSuppliers({ params });
    const suppliers = response.data;

    dispatch(loadSuppliers(suppliers.data));
    
    dispatch(setPagination({
      total: suppliers.pagination.total,
      pageSize: suppliers.pagination.per_page,
      current: suppliers.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}