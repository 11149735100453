import { createAction, handleActions } from 'redux-actions'

const openAreaSelectorModal = createAction('SCREENS/ORDER_VIEW/OPEN_AREA_SELECTOR_MODAL')
const closeAreaSelectorModal = createAction('SCREENS/ORDER_VIEW/CLOSE_AREA_SELECTOR_MODAL')

const defaultState = {
  show: false,
  areas: [],
  onAddAreas: Function,
  onClose: Function,
}

const reducer = handleActions(
  {
    [openAreaSelectorModal]: (state, action) => Object.assign({}, state, {
      show: true,
      areas: action.payload.areas,
      onAddAreas: action.payload.onAddAreas,
      onClose: action.payload.onClose,
    }),
    [closeAreaSelectorModal]: () => defaultState,
  },
  defaultState,
)

export { openAreaSelectorModal, closeAreaSelectorModal, reducer }
