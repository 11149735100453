import { initialize } from 'redux-form';
import { getFitter } from '../../../HttpRequests/fitters';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { fitterId } = getProps();
  
  if (fitterId) {

    const response = await getFitter({ id: fitterId });

    const fitter = response.data.data;

    dispatch(initialize(FORM_NAME, fitter))
    
  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}