import { message } from 'antd'
import { getProps } from '../props';
import { createOrderLog } from '../../../HttpRequests/orderLogs';
import fetchOrder from './fetchOrder';
import handleException from '../../../Core/Helpers/handleException';

export default ({ content }) => async (dispatch, getState) => {
  
  try {

    const { id } = getProps().match.params;

    const { user } = getState();

    const data = {
      order_id: id,
      user_id: user.id,
      content
    }

    await createOrderLog({ data });
    
    message.success("Log Created");

    await dispatch(fetchOrder());

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}