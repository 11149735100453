import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import MaterialTypeModal from '../MaterialType'

import Controls from './Components/Controls'
import Table from './Components/Table'

const Spacer = () => <div style={{ height: 12 }} />

const MaterialTypes = props => {

  const {
    materialTypeModal,
    componentDidMount,
    componentWillUnmount,
    onCloseMaterialTypeModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Modal
      title='Materials'
      visible
      width={600}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >

      <Controls />

      <Spacer />

      <Table />

      {materialTypeModal.show && (
        <MaterialTypeModal
          {...materialTypeModal}
          onClose={onCloseMaterialTypeModal}
        />
      )}

    </Modal>

  )

}

MaterialTypes.propTypes = {
  materialTypeModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseMaterialTypeModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default MaterialTypes
