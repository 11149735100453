import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Cascader, Dropdown, Menu, Icon } from 'antd'
import classNames from 'classnames'
import styles from './styles.css'

const Item = props => {

  const {
    areas,
    materials,
    item,
    onEditField,
    onClickDimensions,
    onClickAction,
    onClickSupplySheetNotes,
  } = props

  const [squareMetres, setSquareMetres] = useState(undefined)
  const [notes, setNotes] = useState(undefined)

  const onBlurNumber = (field, value) => {
    const $value = (value || value === '0') ? parseFloat(value.replace(',', '')) : null
    onEditField(item.guid, field, $value)
  }

  const actions = (
    <Menu>
      <Menu.SubMenu
        title={(
          <React.Fragment>
            <Icon type='plus' />
            Add
          </React.Fragment>
        )}
      >
        <Menu.Item key='add' onClick={() => onClickAction(item.guid, 'add')}>
          Standard Row
        </Menu.Item>
        <Menu.Item key='custom' onClick={() => onClickAction(item.guid, 'custom')}>
          Custom Row (Full)
        </Menu.Item>
        <Menu.Item key='custom-area' onClick={() => onClickAction(item.guid, 'custom-area')}>
          Custom Row (Area Only)
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key='duplicate' onClick={() => onClickAction(item.guid, 'duplicate')}>
        <Icon type='copy' />
        Duplicate
      </Menu.Item>
      <Menu.Item key='supply-sheet-notes' onClick={() => onClickSupplySheetNotes(item.guid, item.supply_sheet_notes)}>
        <Icon type='message' />
        Supply Sheet Notes
      </Menu.Item>
      <Menu.Item key='remove' onClick={() => onClickAction(item.guid, 'remove')}>
        <Icon type='delete' />
        Remove
      </Menu.Item>
    </Menu>
  )

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.area)}>
        <Cascader
          options={areas}
          onChange={value => onEditField(item.guid, 'area', value)}
          expandTrigger='hover'
        >
          <div className={styles.content}>{item.area}</div>
        </Cascader>
      </div>

      <div className={classNames(styles.cell, styles.material)}>
        <Cascader
          options={materials}
          onChange={value => onEditField(item.guid, 'material', value)}
          expandTrigger='hover'
        >
          <div className={styles.content}>{item.material}</div>
        </Cascader>
      </div>

      <div className={classNames(styles.cell, styles.dimensions)}>
        <div
          onClick={() => onClickDimensions(item.guid, item.dimensions)}
          className={classNames(styles.content, styles.number)}
        >
          {!!item.dimensions && item.dimensions.map(({ guid, length, width }) => (
            <div key={guid}>{`${length} x ${width}`}</div>
          ))}
        </div>
      </div>

      <div className={classNames(styles.cell, styles.squareMetres)}>
        <input
          value={squareMetres !== undefined ? squareMetres : item.square_metres}
          onChange={e => setSquareMetres(e.target.value)}
          onBlur={e => {
            onBlurNumber('square_metres', e.target.value)
            setSquareMetres(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
      </div>

      <div className={classNames(styles.cell, styles.notes)}>
        <input
          value={notes !== undefined ? notes : item.notes}
          onChange={e => setNotes(e.target.value)}
          onBlur={e => {
            onEditField(item.guid, 'notes', e.target.value)
            setNotes(undefined)
          }}
          className={styles.input}
        />
        <div className={styles.actions}>
          <Dropdown
            trigger={['click']}
            overlay={actions}
          >
            <Icon type='caret-down' />
          </Dropdown>
        </div>
      </div>

    </div>
    
  )

}

Item.propTypes = {
  areas: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  onEditField: PropTypes.func.isRequired,
  onClickDimensions: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
  onClickSupplySheetNotes: PropTypes.func.isRequired,
}

export default Item
