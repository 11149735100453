import { change, untouch } from 'redux-form'
import { FORM_NAME } from '../../../Form'
import resetPlotType from '../Services/resetPlotType'

export default () => (dispatch) => {

  dispatch(change(FORM_NAME, 'site_id', null))
  dispatch(change(FORM_NAME, 'plot_type_id', null))
  dispatch(change(FORM_NAME, 'protection_price', null))
  dispatch(change(FORM_NAME, 'cost_sheet', null))

  dispatch(untouch(FORM_NAME, 'site_id', 'plot_type_id', 'protection_price'))

  dispatch(resetPlotType())
  
}
