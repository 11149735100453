import { closeEditFileModal } from '../../../State/editFileModal'
import fetchOrder from "../../../Services/fetchOrder";

export default (file) => async (dispatch) => {

  dispatch(closeEditFileModal())

  if (file) {
    dispatch(fetchOrder())
  }
  
}