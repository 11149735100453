import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { inspectorId, onClose, didCreate, didUpdate } = getProps();
  
  if (!inspectorId) {

    const inspector = await dispatch(create(values));

    if (inspector) {
      onClose();
      didCreate(inspector);
    }

  } else {

    const inspector = await dispatch(update(values));

    if (inspector) {
      onClose();
      didUpdate(inspector);
    }

  }

};
