import { connect } from "react-redux";

import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import onCloseSupplierModal from "./Handlers/onCloseSupplierModal";
import didCreateSupplier from "./Handlers/didCreateSupplier";

const mapStateToProps = state => ({
  showSupplierModal: state.screens.Suppliers.showSupplierModal
});

const actionCreators = {
  componentDidMount,
  onCloseSupplierModal,
  didCreateSupplier
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
