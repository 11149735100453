import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from 'antd'
import { Module } from '../../../../../../../packages/sota'

const Header = (props) => {

  const {
    searchTerm,
    onChangeSearch,
    onSearch,
    onClickNew,
  } = props

  const renderLeft = () => (

    <Input.Search
      value={searchTerm}
      onChange={onChangeSearch}
      onSearch={onSearch}
      placeholder='Search'
      style={{ width: 250 }}
    />

  )

  const renderRight = () => (
    
    <Button type='primary' onClick={onClickNew}>New Type</Button>

  )
  
  return (

    <Module.Header
      renderLeft={renderLeft}
      renderRight={renderRight}
    />

  )

}

Header.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickNew: PropTypes.func.isRequired,
}

export default Header
