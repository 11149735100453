import { toggleTag } from '../../../State/selectedTags';
import fetchJobs from '../../../Services/fetchJobs';
import fetchInspections from '../../../Services/fetchInspections';

export default (tag) => async (dispatch) => {

  dispatch(toggleTag(tag));
  dispatch(fetchJobs());
  dispatch(fetchInspections());
  
}