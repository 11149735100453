import { createAction, handleActions } from 'redux-actions';

const loadUnitOfPrices = createAction("CORE/UNIT_OF_PRICES/LOAD_UNIT_OF_PRICES");
const clearUnitOfPrices = createAction("CORE/UNIT_OF_PRICES/CLEAR_UNIT_OF_PRICES");

const defaultState = []

const reducer = handleActions(
  {
    [loadUnitOfPrices]: (state, action) => action.payload,
    [clearUnitOfPrices]: () => []
  },
  defaultState
);

export { loadUnitOfPrices, clearUnitOfPrices, reducer };