import { setPagination } from '../../../State/pagination';
import fetchOrders from '../../../Services/fetchOrders';
import { setSelectedRowKeys } from '../../../State/selectedRowKeys';

export default () => async (dispatch) => {

  dispatch(setPagination({ current: 1 }));

  dispatch(setSelectedRowKeys([]));

  dispatch(fetchOrders());
  
}