import { createAction, handleActions } from 'redux-actions';

const setToOrderSearchTerm = createAction("SCREENS/ORDER_VIEW/SET_TO_ORDER_SEARCH_TERM");

const defaultState = "";

const reducer = handleActions(
  {
    [setToOrderSearchTerm]: (state, action) => action.payload
  },
  defaultState
);

export { setToOrderSearchTerm, reducer };
