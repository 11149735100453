import { createAction, handleActions } from 'redux-actions';

const setMode = createAction("SCREENS/BOARD/SET_MODE");

const defaultState = "WEEK";

const reducer = handleActions(
  {
    [setMode]: (state, action) => action.payload
  },
  defaultState
);

export { setMode, reducer };
