import { createAction, handleActions } from 'redux-actions';

const setSearchTerm = createAction("SCREENS/BOARD/SET_SEARCH_TERM");

const defaultState = "";

const reducer = handleActions(
  {
    [setSearchTerm]: (state, action) => action.payload
  },
  defaultState
);

export { setSearchTerm, reducer };
