import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import ManufacturerModal from '../Manufacturer'
import MaterialRangesModal from '../MaterialRanges'

import Controls from './Components/Controls'
import Table from './Components/Table'

const Spacer = () => <div style={{ height: 12 }} />

const Manufacturers = props => {

  const {
    manufacturerModal,
    materialRangesModal,
    componentDidMount,
    componentWillUnmount,
    onCloseManufacturerModal,
    onCloseMaterialRangesModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Modal
      title='Manufacturers'
      visible
      width={600}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >

      <Controls />

      <Spacer />

      <Table />

      {manufacturerModal.show && (
        <ManufacturerModal
          {...manufacturerModal}
          onClose={onCloseManufacturerModal}
        />
      )}

      {materialRangesModal.show && (
        <MaterialRangesModal
          {...materialRangesModal}
          onClose={onCloseMaterialRangesModal}
        />
      )}

    </Modal>

  )

}

Manufacturers.propTypes = {
  manufacturerModal: PropTypes.object.isRequired,
  materialRangesModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseManufacturerModal: PropTypes.func.isRequired,
  onCloseMaterialRangesModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Manufacturers
