import { createSelector } from 'reselect'
import { find } from 'lodash'

const selectMaterialTypes = state => state.core.materialTypes
const selectValues = state => state.modals.FittingRate.Form.formState.values

export default createSelector(
  selectMaterialTypes,
  selectValues,
  (materialTypes, values) => {
    if (!values.material_type_id) return null
    return find(materialTypes, row => row.id === values.material_type_id)
  },
)
