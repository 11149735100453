import { connect } from 'react-redux'
import Materials from './Materials'

import componentDidMount from './Handlers/componentDidMount'
import onChangeActiveTab from './Handlers/onChangeActiveTab'
import onCloseManufacturerModal from './Handlers/onCloseManufacturerModal'
import onCloseSupplierModal from './Handlers/onCloseSupplierModal'
import onCloseMaterialTypeModal from './Handlers/onCloseMaterialTypeModal'
import onCloseMaterialRangeModal from './Handlers/onCloseMaterialRangeModal'

const mapStateToProps = (state) => ({
  materialTypes: state.core.materialTypes,
  activeTab: state.screens.Materials.activeTab,
  manufacturerModal: state.screens.Materials.manufacturerModal,
  supplierModal: state.screens.Materials.supplierModal,
  materialTypeModal: state.screens.Materials.materialTypeModal,
  materialRangeModal: state.screens.Materials.materialRangeModal,
})

const actionCreators = {
  componentDidMount,
  onChangeActiveTab,
  onCloseManufacturerModal,
  onCloseSupplierModal,
  onCloseMaterialTypeModal,
  onCloseMaterialRangeModal,
}

export default connect(mapStateToProps, actionCreators)(Materials)
