import { createAction, handleActions } from 'redux-actions'

const openAddMaterialRangeModal = createAction('MODALS/BUILDER_RATE/OPEN_ADD_MATERIAL_RANGE_MODAL')
const closeAddMaterialRangeModal = createAction('MODALS/BUILDER_RATE/CLOSE_ADD_MATERIAL_RANGE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openAddMaterialRangeModal]: (state) => Object.assign({}, state, {
      show: true,
    }),
    [closeAddMaterialRangeModal]: () => defaultState,
  },
  defaultState,
)

export { openAddMaterialRangeModal, closeAddMaterialRangeModal, reducer }
