import { connect } from 'react-redux'
import Quotes from './Quotes'

import selectItems from './Selectors/selectItems'

const mapStateToProps = (state) => ({
  items: selectItems(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Quotes)
