import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import HeightSpacer from '../HeightSpacer'
import styles from './styles.css'

const Header = props => {

  const {
    order,
    onEditNotes,
  } = props

  const [notes, setNotes] = useState(undefined)

  return (

    <React.Fragment>
      
      <div className={styles.title}>Notes</div>

      <HeightSpacer type='controls' />

      <Input.TextArea
        value={notes !== undefined ? notes : order.cost_sheet.notes}
        onChange={e => setNotes(e.target.value)}
        onBlur={e => {
          onEditNotes(e.target.value)
          setNotes(undefined)
        }}
        className={styles.notes}
      />

    </React.Fragment>
    
  )

}

Header.propTypes = {
  order: PropTypes.object.isRequired,
  onEditNotes: PropTypes.func.isRequired,
}

export default Header
