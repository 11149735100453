import { createAction, handleActions } from 'redux-actions'

const setEditing = createAction('SCREENS/COST_SHEET/SET_EDITING')
const resetEditing = createAction('SCREENS/COST_SHEET/RESET_EDITING')

const defaultState = false

const reducer = handleActions(
  {
    [setEditing]: (state, { payload }) => payload,
    [resetEditing]: () => defaultState,
  },
  defaultState,
)

export { setEditing, resetEditing, reducer }
