import { getSuppliers } from '../../../HttpRequests/suppliers'
import { setSuppliers } from '../State/suppliers'
import { setFetching } from '../State/fetching'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { search, sort } = getState().modals.Suppliers.controls

  const params = {
    search,
    sort: sort || 'name',
    include: 'supplier_rates',
  }

  dispatch(setFetching(true))

  try {

    const response = await getSuppliers({ params })

    dispatch(setSuppliers(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
