import { createAction, handleActions } from 'redux-actions';

const loadOnHoldReasons = createAction("CORE/ON_HOLD_REASONS/LOAD_ON_HOLD_REASONS");
const clearOnHoldReasons = createAction("CORE/ON_HOLD_REASONS/CLEAR_ON_HOLD_REASONS");

const defaultState = []

const reducer = handleActions(
  {
    [loadOnHoldReasons]: (state, action) => action.payload,
    [clearOnHoldReasons]: () => []
  },
  defaultState
);

export { loadOnHoldReasons, clearOnHoldReasons, reducer };