import React from 'react'
import PropTypes from 'prop-types'
import spacing from '../Utils/spacing'

const WidthSpacer = ({ type }) => <div style={{ width: spacing.width[type] }} />

WidthSpacer.propTypes = {
  type: PropTypes.string.isRequired,
}

export default WidthSpacer
