import { connect } from 'react-redux'
import MaterialTypes from './MaterialTypes'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseMaterialTypeModal from './Handlers/onCloseMaterialTypeModal'

const mapStateToProps = (state) => ({
  materialTypeModal: state.modals.MaterialTypes.materialTypeModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseMaterialTypeModal,
}

export default connect(mapStateToProps, actionCreators)(MaterialTypes)
