import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { materialType } = modalProps()

  if (!materialType) return

  const initialValues = {
    ...materialType,
  }

  dispatch(setFormInitialValues(initialValues))

}
