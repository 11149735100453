import { combineReducers } from "redux";

import { reducer as leftovers } from "./State/leftovers";
import { reducer as pagination } from "./State/pagination";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showLeftoverModal } from "./State/showLeftoverModal";
import { reducer as selectedLeftoverId } from "./State/selectedLeftoverId";


export default combineReducers({
  leftovers,
  pagination,
  searchTerm,
  fetching,
  showLeftoverModal,
  selectedLeftoverId
});
