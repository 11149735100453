import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Icon } from 'antd'

const Table = props => {

  const {
    manufacturers,
    fetching,
    onChangeTable,
    onClickRow,
    onClickMaterialRanges,
  } = props

  return (

    <TableProvider
      dataSource={manufacturers}
      rowKey='id'
      size='small'
      loading={fetching}
      onChange={onChangeTable}
      onRow={record => ({
        onClick: () => onClickRow(record),
        style: { cursor: 'pointer' },
      })}
    >

      <TableProvider.Column
        key='name'
        title='Name'
        sorter
        render={(text, record) => record.name}
      />

      <TableProvider.Column
        key='material_ranges'
        render={(text, record) => (
          <a
            onClick={e => {
              onClickMaterialRanges(record)
              e.stopPropagation()
            }}
          >
            <Icon type='zoom-in' />
            {` ${record.material_ranges.length} Ranges`}
          </a>
        )}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onClickMaterialRanges: PropTypes.func.isRequired,
}

export default Table
