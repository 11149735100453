import React from 'react'
import PropTypes from 'prop-types'

import Item from './Components/Item'
import Total from './Components/Total'

const Takeoff = props => {

  const {
    takeoff,
  } = props

  if (!takeoff.items.length) return false

  return (
    
    <React.Fragment>

      {takeoff.items.map(item => <Item key={item.key} item={item} />)}

      <Total squareMetresNet={takeoff.squareMetresNet} squareMetresGross={takeoff.squareMetresGross} />

    </React.Fragment>
    
  )

}

Takeoff.propTypes = {
  takeoff: PropTypes.object.isRequired,
}

export default Takeoff
