import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb, Divider } from 'antd'
import { NavLink } from 'react-router-dom'

const { Item } = Breadcrumb

export default class extends React.PureComponent{

  static propTypes = {
    plotType: PropTypes.object.isRequired,
  }

  render() {

    const {
      plotType,
    } = this.props

    return (

      <React.Fragment>

        <Breadcrumb separator='»'>

          <Item>
            Home
          </Item>
          
          <Item>
            <NavLink to='/portal/builders'>
              Builders
            </NavLink>
          </Item>

          <Item>
            <NavLink to={`/portal/builders/${plotType.site.builder.id}`}>
              {plotType.site.builder.name}
            </NavLink>
          </Item>

          <Item>
            <NavLink to={`/portal/builders/${plotType.site.builder.id}/sites/${plotType.site.id}`}>
              {plotType.site.name}
            </NavLink>
          </Item>

          <Item>
            {`${plotType.name}`}
          </Item>

        </Breadcrumb>

        <Divider type='horizontal' />
        
      </React.Fragment>

    )

  }

}
