import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { Spin } from 'antd';
import SummaryItem from '../SummaryItem';
import HolidayItem from '../HolidayItem';
import InspectorVisitItem from '../InspectorVisitItem';
import InspectionItem from '../InspectionItem';
import styles from "./Component.css";

export default class extends React.PureComponent {

  static propTypes = {
    source: PropTypes.string.isRequired,
    summaries: PropTypes.object.isRequired,
    holidays: PropTypes.object.isRequired,
    inspectorVisits: PropTypes.object.isRequired,
    inspections: PropTypes.object.isRequired,
    dates: PropTypes.array.isRequired,
    showSpinner: PropTypes.bool.isRequired,
    onClickWeekRow: PropTypes.func.isRequired,
  }

  render () {

    const {
      source,
      summaries,
      holidays,
      inspectorVisits,
      inspections,
      dates,
      showSpinner,
      onClickWeekRow
    } = this.props;

    return (

      <div className={styles.wrapper}>

        <div className={styles.board}>

          {dates.map((week) => (

            <div
              key={week[0].date}
              className={styles.weekRow}
              onClick={() => onClickWeekRow(week[0].date)}
            >

              {week.map((date) => (

                <div
                  key={date.date}
                  className={classnames(styles.dayColumn, { [styles.otherMonth]: date.otherMonth })}
                >

                  <div className={styles.dayLabel}>
                    {moment(date.date).format('ddd DD')}
                  </div>

                  {source === 'JOBS' && summaries[date.date].filter(summary => summary.count > 0).map(summary => <SummaryItem key={summary.tag} data={summary} />)}

                  {source === 'HOLIDAYS' && holidays[date.date].map(holiday => <HolidayItem key={holiday.id} data={holiday} />)}

                  {source === 'INSPECTOR_VISITS' && inspectorVisits[date.date].map(inspectorVisit => <InspectorVisitItem key={inspectorVisit.id} data={inspectorVisit} />)}

                  {source === 'INSPECTIONS' && inspections[date.date].map(inspection => <InspectionItem key={inspection.id} data={inspection} />)}

                </div>

              ))}

            </div>

          ))}

        </div>
        
        {showSpinner && (
          <div className={styles.spin}>
            <Spin spinning />
          </div>
        )}

      </div>

    )
  }
}