import { connect } from 'react-redux'
import Totals from './Totals'

import selectTotals from './Selectors/selectTotals'

const mapStateToProps = (state) => ({
  totals: selectTotals(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Totals)
