import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    leftovers: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onClickItem: PropTypes.func.isRequired,
  }

  render () {

    const {
      leftovers,
      pagination,
      fetching,
      onChangeTable,
      onClickItem
    } = this.props;
    
    const columns = [

      { 
        title: "Reference", 
        dataIndex: "id",
        width: 120,
        render: (text, record) => (
          <div onClick={() => onClickItem(record.id)} role="presentation" className={styles.link}>
            {record.id}
          </div>
        )
      },

      { 
        title: "From Order No", 
        dataIndex: "from_order.order_number",
        width: 150
      },

      {
        title: "Material",
        dataIndex: "material",
        width: 400,
        render: (text, record) => `${record.material_type.name} / ${record.material.name} ${record.material_range ? ` / ${record.material_range.name}` : ''}`
      },

      {
        title: "Measurement",
        dataIndex: "measurement",
        width: 200
      },

      { 
        title: "Status", 
        dataIndex: "status",
        width: 150,
        render: (text, record) => record.to_order ? `Allocated to ${record.to_order.order_number}` : 'Unallocated'
      },

    ]
    
    return (

      <Table
        dataSource={leftovers}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />

    )
  }
}