import { createSelector } from 'reselect'
import selectMaterialRanges from '../../../Selectors/selectMaterialRanges'
import materialOptions from '../../../Utils/materialOptions'

const selectMaterialTypes = state => state.core.materialTypes
const selectManufacturers = state => state.core.manufacturers

export default createSelector(
  selectMaterialTypes,
  selectManufacturers,
  selectMaterialRanges,
  (materialTypes, manufacturers, materialRanges) => materialOptions(materialTypes.filter(row => !!row.is_flooring), manufacturers, materialRanges),
)
