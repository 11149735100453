import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createFittingRate, updateFittingRate } from '../../../../../HttpRequests/fittingRates'
import fetchFittingRates from '../../../../../Core/Services/fetchFittingRates'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { fittingRate } = modalProps()

  const data = {
    title: values.title,
    material_type_id: values.material_type_id,
    order_category_id: values.order_category_id,
    material_type_ids: values.material_type_ids,
    material_range_ids: values.material_range_ids,
    remedial: values.remedial || false,
    tar_value: values.tar_value,
    rate: values.rate,
  }

  try {

    if (fittingRate) {
      await updateFittingRate({ id: fittingRate.id, data })
      message.success('Updated Fitting Rate')
    } else {
      await createFittingRate({ data })
      message.success('Created Fitting Rate')
    }

    modalProps().onClose(true)

    dispatch(fetchFittingRates({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
