import { get } from 'lodash'
import { openAreaRangeQuoteModal } from '../../../State/areaRangeQuoteModal'

export default (column, record, index) => (dispatch) => {

  dispatch(openAreaRangeQuoteModal({
    id: get(record.columns[index].area_range_quote, 'id'),
    squareMetres: record.columns[index].square_metres,
    siteRate: column.site_rate,
    type: record.type,
    areaOrGroupId: record.id,
  }))

}
