import { createAction, handleActions } from 'redux-actions'

const openMaterialRangesModal = createAction('MODALS/MANUFACTURERS/OPEN_MATERIAL_RANGES_MODAL')
const closeMaterialRangesModal = createAction('MODALS/MANUFACTURERS/CLOSE_MATERIAL_RANGES_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openMaterialRangesModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeMaterialRangesModal]: () => defaultState,
  },
  defaultState,
)

export { openMaterialRangesModal, closeMaterialRangesModal, reducer }
