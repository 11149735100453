import React from 'react'
import { Modal, Icon } from 'antd'
import removeMaterialRange from '../../../Services/removeMaterialRange'

export default (id) => async (dispatch) => {

  Modal.confirm({
    title: 'Remove Range?',
    content: 'This action cannot be undone.',
    icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Remove',
    onOk: () => dispatch(removeMaterialRange(id)),
  })

}
