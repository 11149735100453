import { combineReducers } from "redux";

import { reducer as builder } from "./State/builder";
import { reducer as activeTab } from "./State/activeTab";
import { reducer as showBuilderModal } from "./State/showBuilderModal";
import { reducer as showSiteModal } from "./State/showSiteModal";
import { reducer as builderRateModal } from "./State/builderRateModal";
import { reducer as builderMaterialRangeModal } from "./State/builderMaterialRangeModal";

import BuilderRates from './Components/BuilderRates/reducer';
import BuilderMaterialRanges from './Components/BuilderMaterialRanges/reducer';

export default combineReducers({
  builder,
  activeTab,
  showBuilderModal,
  showSiteModal,
  builderRateModal,
  builderMaterialRangeModal,

  BuilderRates,
  BuilderMaterialRanges,
});
