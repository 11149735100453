import { createSelector } from 'reselect';

const selectOrderCategories = state => state.core.orderCategories;

export default createSelector(
  selectOrderCategories,
  (orderCategories) => orderCategories.filter(row => !row.archived_at).map(orderCategory => ({
    label: orderCategory.name,
    value: orderCategory.id
  }))
)