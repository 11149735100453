import { createSelector } from "reselect";
import { find, findIndex, slice } from 'lodash';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectOrderNumbers = state => state.core.orderNumbers;
const selectPiggybackOrderSearchTerm = state => state.modals.Job.piggybackOrderSearchTerm;
const selectPiggybackOrderId = state => formValueSelector(FORM_NAME)(state, 'piggyback_order_id');

export default createSelector(
  selectOrderNumbers,
  selectPiggybackOrderSearchTerm,
  selectPiggybackOrderId,
  (orderNumbers, piggybackOrderSearchTerm, piggybackOrderId) => {

    if (piggybackOrderSearchTerm.length > 3) {

      const position = findIndex(orderNumbers, item => String(item.order_number || item.legacy_order_number).startsWith(piggybackOrderSearchTerm));

      if (position > -1) {
        return slice(orderNumbers, position, position+100)
      }
    }

    if (!piggybackOrderSearchTerm && piggybackOrderId) {
      
      const orderNumber = find(orderNumbers, item => item.id === piggybackOrderId);

      if (orderNumber) {
        return [ orderNumber ];
      }
    }

    return [];
  }
)