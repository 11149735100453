import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import Component from './Component';

import onClickBack from './Handlers/onClickBack';
import onClickMarkAsCalledOff from './Handlers/onClickMarkAsCalledOff';
import onClickMarkAsFitted from './Handlers/onClickMarkAsFitted';
import onClickMarkAsInvoiced from './Handlers/onClickMarkAsInvoiced';
import onClickMarkAsClosed from './Handlers/onClickMarkAsClosed';
import onClickUnmarkAsCalledOff from './Handlers/onClickUnmarkAsCalledOff';
import onClickUnmarkAsFitted from './Handlers/onClickUnmarkAsFitted';
import onClickUnmarkAsInvoiced from './Handlers/onClickUnmarkAsInvoiced';
import onClickUnmarkAsClosed from './Handlers/onClickUnmarkAsClosed';
import onClickActionMenu from './Handlers/onClickActionMenu';
import onChangeActiveTab from './Handlers/onChangeActiveTab';
import onClickSave from './Handlers/onClickSave';
import onClickCancel from './Handlers/onClickCancel';
import onClickCostSheet from './Handlers/onClickCostSheet';

import { FORM_NAME } from '../Order/Form';

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order,
  isEditing: isDirty(FORM_NAME)(state),
  activeTab: state.screens.OrderView.activeTab,
  onHoldReasons: state.core.onHoldReasons,
});

const actionCreators = {
  onClickBack,
  onClickMarkAsCalledOff,
  onClickMarkAsFitted,
  onClickMarkAsInvoiced,
  onClickMarkAsClosed,
  onClickUnmarkAsCalledOff,
  onClickUnmarkAsFitted,
  onClickUnmarkAsInvoiced,
  onClickUnmarkAsClosed,
  onClickActionMenu,
  onChangeActiveTab,
  onClickSave,
  onClickCancel,
  onClickCostSheet,
};

export default connect(mapStateToProps, actionCreators)(Component);
