import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Header from "./Components/Header";
import Filters from "./Components/Filters";
import Table from "./Components/Table";
import OrderCreateModal from "../../Modals/OrderCreate";

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    showOrderCreateModal: PropTypes.bool.isRequired,
    onCloseOrderCreateModal: PropTypes.func.isRequired,
    didCreateOrder: PropTypes.func.isRequired
  };

  static defaultProps = {

  };

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      showOrderCreateModal,
      onCloseOrderCreateModal,
      didCreateOrder
    } = this.props;

    return (

      <Screen>

        <Header />

        <Filters />

        <Table />

        {showOrderCreateModal && (
          <OrderCreateModal
            onClose={onCloseOrderCreateModal}
            didCreate={didCreateOrder}
          />
        )}

      </Screen>

    )
  }
}
