import { combineReducers } from "redux";

import { reducer as builders } from "./State/builders";
import { reducer as pagination } from "./State/pagination";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showBuilderModal } from "./State/showBuilderModal";

export default combineReducers({
  builders,
  pagination,
  searchTerm,
  fetching,
  showBuilderModal
});
