import { createSelector } from 'reselect'
import selectAreas from '../../../../../../../Selectors/selectAreas'
import areaOptions from '../../../../../../../Utils/areaOptions'
import materialTypeOptions from '../../../../../../../Utils/materialTypeOptions'

const selectMaterialTypes = state => state.core.materialTypes

export default createSelector(
  selectAreas,
  selectMaterialTypes,
  (areas, materialTypes) => {

    const $materialTypes = materialTypes.filter(row => !!row.is_quotable)

    const quoatables = []

    if (areas.length) quoatables.push(
      {
        label: 'Areas',
        value: 'area',
        children: areaOptions(areas),
      },
    )

    if ($materialTypes.length) quoatables.push(
      {
        label: 'Materials',
        value: 'material-type',
        children: materialTypeOptions($materialTypes),
      },
    )

    return quoatables
    
  },
)
