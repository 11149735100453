import { createOrder } from '../../../HttpRequests/orders';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async (dispatch, getState) => {

  const data = {
    ...values,
  }

  if (values.credit) {
    data.plot_number = 'N/A'
  }

  try {

    const response = await createOrder({ data })

    return {
      order: response.data.data,
      shouldCreateAnother: getState().modals.OrderCreate.shouldCreateAnother,
    }
    
  } catch (error) {
    
    handleException(error)

    return false

  }
}
