import { connect } from 'react-redux'
import SetupModal from './SetupModal'

import selectCheatSheets from './Selectors/selectCheatSheets'
import selectMaterialTypes from './Selectors/selectMaterialTypes'
import selectAreaGroups from '../../../../Selectors/selectAreaGroups'
import selectAreaGroup from './Selectors/selectAreaGroup'
import selectMaterialRanges from './Selectors/selectMaterialRanges'
import selectRangeVariants from './Selectors/selectRangeVariants'
import selectAreas from './Selectors/selectAreas'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onChangeField from './Handlers/onChangeField'
import onSubmit from './Handlers/onSubmit'

const mapStateToProps = (state) => ({
  cheatSheets: selectCheatSheets(state),
  materialTypes: selectMaterialTypes(state),
  areaGroups: selectAreaGroups(state),
  areaGroup: selectAreaGroup(state),
  materialRanges: selectMaterialRanges(state),
  rangeVariants: selectRangeVariants(state),
  areas: selectAreas(state),
  fields: state.screens.CostSheet.Areas.SetupModal.fields,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onChangeField,
  onSubmit,
}

export default connect(mapStateToProps, actionCreators)(SetupModal)
