import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import ApprovalBanner from './Components/ApprovalBanner';
import Fields from './Components/Fields';
import PlotNotes from './Components/PlotNotes';
import Footer from './Components/Footer';
import AvailableFitters from '../AvailableFitters'
import Notes from '../Notes'

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool,
    jobId: PropTypes.number,
    showApprovalBanner: PropTypes.bool.isRequired,
    inspectionNotes: PropTypes.string,
    availableFittersModal: PropTypes.object.isRequired,
    notesModal: PropTypes.object.isRequired,
    componentWillMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onCloseAvailableFittersModal: PropTypes.func.isRequired,
    onCloseNotesModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: false,
    jobId: null,
    inspectionNotes: null,
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      submitting,
      jobId,
      showApprovalBanner,
      inspectionNotes,
      availableFittersModal,
      notesModal,
      onClickCancel,
      onCloseAvailableFittersModal,
      onCloseNotesModal,
    } = this.props;

    return (
    
      <Modal
        visible
        title={`${jobId ? 'Job': 'New Job'}`}
        footer={<Footer submitting={submitting} jobId={jobId} />}
        onCancel={onClickCancel}
        width={1100}
        bodyStyle={{ position: 'relative', paddingTop: showApprovalBanner ? 80 : undefined }}
      >

        {showApprovalBanner && (
          <ApprovalBanner />
        )}
        
        <Fields submitting={submitting} />

        <PlotNotes />

        {availableFittersModal.show && (
          <AvailableFitters
            onClose={onCloseAvailableFittersModal}
            tag={availableFittersModal.tag}
            dates={availableFittersModal.dates}
          />
        )}

        {notesModal.show && (
          <Notes
            notes={inspectionNotes}
            onClose={onCloseNotesModal}
          />
        )}

      </Modal>
      
    )
  }
}