import React from 'react'
import { Modal } from 'antd'
import { requestFileImport } from '../../../../../HttpRequests/files'

export default () => () => {

  const queueFileUploads = async () => {
    
    const { data: { data }} = await requestFileImport()

    const count = data.length
    
    if (count > 0) {

      Modal.success({
        title: `Importing ${count} Files`,
        content: (
          <div style={{ paddingTop: 10 }}>
            <b>The files are now being uploaded in the background.</b>
            <br />
            Most files should take a few seconds each so expect to wait a little while before being able to view all files on the system.
          </div>
        ),
        width: 485
      });

    } else {

      Modal.info({
        title: `0 Files Waiting`,
        content: 'The scan did not find any files waiting for upload.',
        width: 485,
      });

    }

  }

  Modal.confirm({
    title: 'Import Files',
    content: 'This will scan the import folders for any new files and then begin uploading them to the system.',
    okText: "Import",
    width: 485,
    onOk: () => queueFileUploads(),
  })

}