import { createAction, handleActions } from 'redux-actions';

const loadAreaMaterialType = createAction("MODALS/AREA_MATERIAL_TYPE/LOAD_AREA_MATERIAL_TYPE");
const clearAreaMaterialType = createAction("MODALS/AREA_MATERIAL_TYPE/CLEAR_AREA_MATERIAL_TYPE");

const defaultState = null;

const reducer = handleActions(
  {
    [loadAreaMaterialType]: (state, action) => action.payload,
    [clearAreaMaterialType]: () => null
  },
  defaultState
);

export { loadAreaMaterialType, clearAreaMaterialType, reducer };