import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, PageHeader, Tabs, Tag, Dropdown, Icon, Menu, Affix, Popconfirm } from 'antd';
import styles from './Component.css';

const canSendQuestionnaire = (order) => {
  if (!order.remedial || !order.customer_email) return false
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(order.customer_email.trim())
}

export default class extends React.PureComponent{

  static propTypes = {
    order: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    activeTab: PropTypes.string.isRequired,
    onHoldReasons: PropTypes.array.isRequired,
    onClickBack: PropTypes.func.isRequired,
    onClickMarkAsCalledOff: PropTypes.func.isRequired,
    onClickMarkAsFitted: PropTypes.func.isRequired,
    onClickMarkAsInvoiced: PropTypes.func.isRequired,
    onClickMarkAsClosed: PropTypes.func.isRequired,
    onClickUnmarkAsCalledOff: PropTypes.func.isRequired,
    onClickUnmarkAsFitted: PropTypes.func.isRequired,
    onClickUnmarkAsInvoiced: PropTypes.func.isRequired,
    onClickUnmarkAsClosed: PropTypes.func.isRequired,
    onClickActionMenu: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onClickSave: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickCostSheet: PropTypes.func.isRequired,
  }

  render () {

    const {
      order,
      isEditing,
      activeTab,
      onHoldReasons,
      onClickBack,
      onClickMarkAsCalledOff,
      onClickMarkAsFitted,
      onClickMarkAsInvoiced,
      onClickMarkAsClosed,
      onClickUnmarkAsCalledOff,
      onClickUnmarkAsFitted,
      onClickUnmarkAsInvoiced,
      onClickUnmarkAsClosed,
      onClickActionMenu,
      onChangeActiveTab,
      onClickSave,
      onClickCancel,
      onClickCostSheet,
    } = this.props;

    const statusColors = {
      'Not Fitted': 'cyan',
      'For Invoicing': 'blue',
      'Invoiced': 'geekblue',
      'Closed': 'purple',
      'Cancelled': 'red'
    }

    const menu = (

      <Menu onClick={onClickActionMenu}>

        <Menu.SubMenu key="onholdreason" title={<span><Icon type="pause-circle" style={{ marginRight: 8 }} /><span>On Hold Reason</span></span>}>

          <Menu.Item key="onholdreason:">
            <span className={styles.onHoldReasonCheckWrapper}>
              {order.on_hold_reason_key === null && <Icon type="check" />}
            </span>
            <span className={styles.onHoldReasonName}>
              None
            </span>
          </Menu.Item>

          <Menu.Divider />

          {onHoldReasons.map(item => (
            <Menu.Item key={`onholdreason:${item.key}`}>
              <span className={styles.onHoldReasonCheckWrapper}>
                {order.on_hold_reason_key === item.key && <Icon type="check" />}
              </span>
              <span className={styles.onHoldReasonName}>
                {item.name}
              </span>
            </Menu.Item>
          ))}

        </Menu.SubMenu>

        <Menu.Divider />

        {!order.cancelled_at && <Menu.Item key="cancel"><Icon type="stop" />Mark As Cancelled</Menu.Item>}

        {!!order.cancelled_at && <Menu.Item key="uncancel"><Icon type="rollback" />Unmark As Cancelled</Menu.Item>}

        <Menu.Divider />

        <Menu.Item key="duplicate"><Icon type="copy" />Duplicate</Menu.Item>

        <Menu.Divider />

        <Menu.Item key="builder" disabled={!order.builder_id}><Icon type="tool" />Go to Builder</Menu.Item>

        <Menu.Item key="site" disabled={!order.site_id}><Icon type="environment" />Go to Site</Menu.Item>

        <Menu.Item key="plotType" disabled={!order.plot_type_id}><Icon type="home" />Go to Plot Type</Menu.Item>

        <Menu.Divider />

        <Menu.Item key="send_questionnaire" disabled={!canSendQuestionnaire(order)}><Icon type="mail" />Send Questionnaire</Menu.Item>

      </Menu>
    );

    return (

      <Affix offsetTop={45}>

        <div className={styles.wrapper}>

          <PageHeader
            onBack={onClickBack}
            title={(
              <span style={{ fontSize: 20 }}>
                {`Order ${order.order_number || order.legacy_order_number || 'N/A'}`}
              </span>
            )}
            subTitle={order.order_category ? order.order_category.name : order.order_category_custom}
            tags={[
              (<Tag key="status" color={statusColors[order.status]} style={{ marginTop: 5 }}>{order.status}</Tag>),
              (!!order.on_hold_reason && <Tag key="onholdreason" color="orange" style={{ marginTop: 5 }}>{`On Hold: ${order.on_hold_reason.name}`}</Tag>),
            ]}
            extra={isEditing ? [
              
              (
                <Popconfirm key="reset" title="Are you sure?" onConfirm={onClickCancel}>
                  <a href="#" className={styles.cancelButton}>
                    <Icon type="close" /> Cancel
                  </a>
                </Popconfirm>
              ),

              <span style={{ width: 8 }} key="spacer" />,

              <Button type="primary" key="save" icon="check" onClick={onClickSave}>Save Changes</Button>,

            ] : [

              <Button key="cost-sheet" icon="pound" onClick={onClickCostSheet}>Cost Sheet</Button>,

              <span style={{ width: 16 }} key="spacer-1" />,

              <Button.Group key="actions">
                {[

                  (!order.remedial && !order.private && !order.cancelled_at && order.called_off_at && !order.fitted_at) && (
                    <Popconfirm key="unmark-as-called-off" title="This will reset the order number. Are you sure?" onConfirm={onClickUnmarkAsCalledOff}>
                      <Button type="default" key="7" icon="close">Unmark As Called Off</Button>
                    </Popconfirm>
                  ),
                  (!order.cancelled_at && order.fitted_at && !order.invoiced_at) && (
                    <Button type="default" key="1" icon="close" onClick={onClickUnmarkAsFitted}>Unmark As Fitted</Button>
                  ),
                  (!order.cancelled_at && order.invoiced_at && !order.closed_at) && (
                    <Button type="default" key="2" icon="close" onClick={onClickUnmarkAsInvoiced}>Unmark As Invoiced</Button>
                  ),
                  (!order.cancelled_at && order.closed_at) && (
                    <Button type="default" key="3" icon="close" onClick={onClickUnmarkAsClosed}>Unmark As Closed</Button>
                  ),
                  
                  (!order.remedial && !order.private && !order.cancelled_at && !order.called_off_at) && (
                    <Button type="default" key="8" icon="check" onClick={onClickMarkAsCalledOff}>Mark As Called Off</Button>
                  ),
                  (!order.cancelled_at && order.called_off_at && !order.fitted_at) && (
                    <Button type="default" key="4" icon="check" onClick={onClickMarkAsFitted}>Mark As Fitted</Button>
                  ),
                  (!order.cancelled_at && order.fitted_at && !order.invoiced_at) && (
                    <Button type="default" key="5" icon="check" onClick={onClickMarkAsInvoiced}>Mark As Invoiced</Button>
                  ),
                  (!order.cancelled_at && order.invoiced_at && !order.closed_at) && (
                    <Button type="default" key="6" icon="check" onClick={onClickMarkAsClosed}>Mark As Closed</Button>
                  )

                ]}
              </Button.Group>,

              <span style={{ width: 16 }} key="spacer-2" />,
              
              <Dropdown overlay={menu} key="menu" placement="bottomRight">
                <Button type="default">
                  <Icon type="setting" /> More Options <Icon type="down" />
                </Button>
              </Dropdown>

            ]}
            footer={(
              <Tabs defaultActiveKey={activeTab} onChange={onChangeActiveTab}>
                <Tabs.TabPane tab="Order" key="1" />
                <Tabs.TabPane tab="Fitting" key="2" disabled={isEditing} />
                {/* <Tabs.TabPane tab="Leftovers" key="3" disabled={isEditing} /> */}
                <Tabs.TabPane tab="Files" key="4" disabled={isEditing} />
                <Tabs.TabPane tab="Activity" key="5" disabled={isEditing} />
                <Tabs.TabPane tab="Plot Alerts" key="6" disabled={isEditing} />
                <Tabs.TabPane tab="Order Notes" key="7" disabled={isEditing} />
                {!!order.remedial && <Tabs.TabPane tab="Questionnaire" key="8" disabled={isEditing} />}
              </Tabs>
            )}
          >

            {order.credit ?
              `${order.builder ? order.builder.name : order.builder_custom}` :
              `${order.plot_number}, ${order.site ? order.site.name : order.site_custom}, ${order.site ? order.site.town : order.town_custom}, ${order.builder ? order.builder.name : order.builder_custom}`
            }

          </PageHeader>

        </div>

      </Affix>
      
    )
  }
}