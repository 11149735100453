import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Row, Col, InputNumber, Button } from 'antd'
import { reduce } from 'lodash'
import numeral from 'numeral'
import chance from 'chance'

const Dimensions = props => {

  const {
    dimensions,
    showTotal,
    onClose,
  } = props

  const [$dimensions, setDimensions] = useState(dimensions || [])

  const add = () => {
    setDimensions([...$dimensions, {
      guid: chance().guid(),
      length: null,
      width: null,
    }])
  }

  const edit = (guid, field, value) => {
    const newDimensions = []
    $dimensions.forEach(row => {
      if (row.guid !== guid) {
        newDimensions.push(row)
        return
      }
      newDimensions.push({
        ...row,
        [field]: value,
      })
    })
    setDimensions(newDimensions)
  }

  const remove = (guid) => {
    const newDimensions = []
    $dimensions.forEach(row => {
      if (row.guid !== guid) {
        newDimensions.push(row)
      }
    })
    setDimensions(newDimensions)
  }

  const submit = () => {
    const $$dimensions = $dimensions.filter(({ length, width }) => length && width)
    onClose($$dimensions.length ? $$dimensions.map(({ length, width }) => ({ length, width })) : null)
  }

  const getSquareMetres = () => reduce($dimensions, (carry, dimension) => carry + (dimension.length * dimension.width), 0)

  if ($dimensions.length === 0) add()

  return (

    <Modal
      title='Sizes'
      visible
      width={350}
      onCancel={() => onClose()}
      maskClosable={false}
      footer={[
        <Button key='add' type='dashed' onClick={add} style={{ float: 'left' }}>Add</Button>,
        showTotal && (
          <span
            key='square-metres'
            style={{ fontWeight: 500, marginRight: 8 }}
          >
            {`M2: ${numeral(getSquareMetres()).format('0,0.[00]')}`}
          </span>
        ),
        <Button key='cancel' onClick={() => onClose()}>Cancel</Button>,
        <Button key='submit' type='primary' onClick={submit}>Update</Button>,
      ]}
    >

      <Form
        layout='vertical'
        onKeyUp={e => e.keyCode === 13 && submit()}
        autoComplete='off'
      >

        {$dimensions.map(({ guid, length, width }, index) => (

          <Row key={guid} gutter={8}>

            <Col span={2}>

              <div
                style={{
                  height: 32,
                  lineHeight: '32px',
                  marginTop: index === 0 && 29,
                }}
              >
                {index + 1}
              </div>

            </Col>

            <Col span={9}>

              <Form.Item
                label={index === 0 ? 'Length' : undefined}
              >
                <InputNumber
                  value={length}
                  onChange={value => edit(guid, 'length', value)}
                  precision={2}
                  min={0}
                  autoFocus={index === 0}
                  style={{ width: '100%' }}
                />
              </Form.Item>

            </Col>

            <Col span={9}>

              <Form.Item
                label={index === 0 ? 'Width' : undefined}
              >
                <InputNumber
                  value={width}
                  onChange={value => edit(guid, 'width', value)}
                  precision={2}
                  min={0}
                  style={{ width: '100%' }}
                />
              </Form.Item>

            </Col>

            <Col span={2}>

              <Button
                type='danger'
                icon='delete'
                onClick={() => remove(guid)}
                style={{ marginTop: index === 0 && 29 }}
              />

            </Col>

          </Row>

        ))}

      </Form>

    </Modal>

  )

}

Dimensions.propTypes = {
  dimensions: PropTypes.array,
  showTotal: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

Dimensions.defaultProps = {
  dimensions: null,
  showTotal: true,
}

export default Dimensions
