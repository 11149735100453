import { setPagination } from '../../../State/pagination';
import fetchLeftovers from '../../../Services/fetchLeftovers';

export default (pagination) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  await dispatch(fetchLeftovers());

}