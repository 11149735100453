import { connect } from 'react-redux'
import Questionnaire from './Questionnaire'

import onChangeAction from './Handlers/onChangeAction'

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order,
})

const actionCreators = {
  onChangeAction,
}

export default connect(mapStateToProps, actionCreators)(Questionnaire)
