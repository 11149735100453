export default (values) => () => {

  const errors = {}

  if (!values.name) errors.name = 'Name is required'

  if (!values.material_type_id) errors.material_type_id = 'Type is required'
  
  return errors

}
