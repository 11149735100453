import { setPagination } from '../../../State/pagination';
import fetchSuppliers from '../../../Services/fetchSuppliers';

export default (pagination) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  await dispatch(fetchSuppliers());

}