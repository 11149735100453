import { createSelector } from "reselect";
import moment from "moment";
import selectDates from './dates';

const selectHolidays = state => state.screens.Board.holidays;

export default createSelector(
  selectHolidays,
  selectDates,
  (holidays, dates) => {

    const data = dates.map(() => []);

    holidays.forEach(holiday => {
      
      for (let date = moment(holiday.start_date); date.diff(holiday.end_date, 'days') <= 0; date.add(1, 'days')) {
          
        const key = moment(date).format('YYYY-MM-DD');

        if (dates.indexOf(key) > -1) {
          data[dates.indexOf(key)].push(holiday);
        }

      }
    
    });
    
    return data;
  
  }
)