import { createAction, handleActions } from 'redux-actions';

const setToOrderOpen = createAction("SCREENS/ORDER_VIEW/SET_TO_ORDER_OPEN");

const defaultState = false;

const reducer = handleActions(
  {
    [setToOrderOpen]: (state, action) => action.payload
  },
  defaultState
);

export { setToOrderOpen, reducer };
