import { closeFittingRateModal } from '../State/fittingRateModal'
import fetch from '../Services/fetch'

export default (reload) => (dispatch) => {

  dispatch(closeFittingRateModal())

  if (reload) dispatch(fetch())

}
