import moment from 'moment'
import { getSupplierRates } from '../../HttpRequests/supplierRates'
import { loadSupplierRates } from '../State/supplierRates'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return

    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {}

    const response = await getSupplierRates({ params })

    dispatch(loadSupplierRates(response.data.data))

  } catch (error) {
    
    throw error

  }

}
