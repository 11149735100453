import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchOrderNumbers from '../../../Core/Services/fetchOrderNumbers';
import fetchOrderCategories from '../../../Core/Services/fetchOrderCategories';
import fetchBuilders from '../../../Core/Services/fetchBuilders';
import fetchSites from '../../../Core/Services/fetchSites';
import fetchPlotTypes from '../../../Core/Services/fetchPlotTypes';

export default (props) => (dispatch) => {

  setProps(props);

  dispatch(initialize());

  dispatch(fetchOrderNumbers());
  dispatch(fetchOrderCategories());
  dispatch(fetchBuilders());
  dispatch(fetchSites());
  dispatch(fetchPlotTypes());
  
}