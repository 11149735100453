import { connect } from 'react-redux'
import GroupAreas from './GroupAreas'

import componentDidMount from './Handlers/componentDidMount'
import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  areaGroups: state.screens.PlotType.AreaGroups.areaGroups,
  submitting: state.screens.PlotType.GroupAreas.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  onChangeFormState,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(GroupAreas)
