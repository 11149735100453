import { message } from 'antd'
import { deleteAreaSiteRate } from '../../../../../HttpRequests/areaSiteRates'
import fetch from './fetch'
import { handleException } from '../../../../../../packages/sota'

export default (areaSiteRateId) => async (dispatch) => {

  try {
    
    await deleteAreaSiteRate({ id: areaSiteRateId })

    dispatch(fetch())

    message.success('Site Rate Removed')

  } catch (error) {

    handleException(error)

    throw error

  }

}
