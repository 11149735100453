import React from 'react';
import { Icon, Modal } from 'antd';
import archiveSite from '../../../Services/archive';

export default () => (dispatch) => {

  Modal.confirm({
    title: `Archive Site?`,
    content: 'Are you sure you want to archive this site?',
    icon: <Icon type="eye-invisible" />,
    okText: 'Archive',
    onOk: () => dispatch(archiveSite())
  })

}