import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteBuilderMaterialRange } from '../../../HttpRequests/builderMaterialRanges'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  const { builderMaterialRange } = modalProps()

  try {

    await deleteBuilderMaterialRange({ id: builderMaterialRange.id })

    message.success('Removed Material')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
