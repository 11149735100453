import { createAction, handleActions } from 'redux-actions'

const openSiteMaterialRangeModal = createAction('SCREENS/SITE_VIEW/OPEN_SITE_MATERIAL_RANGE_MODAL')
const closeSiteMaterialRangeModal = createAction('SCREENS/SITE_VIEW/CLOSE_SITE_MATERIAL_RANGE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSiteMaterialRangeModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeSiteMaterialRangeModal]: () => defaultState,
  },
  defaultState,
)

export { openSiteMaterialRangeModal, closeSiteMaterialRangeModal, reducer }
