import { connect } from 'react-redux';

import Component from './Component';

import onChangeBuilderId from './Handlers/onChangeBuilderId'

const mapStateToProps = (state) => ({
  inspectors: state.core.inspectors,
  builders: state.core.builders,
  sites: state.core.sites,
  values: state.form.InspectorVisit.values
});

const actionCreators = {
  onChangeBuilderId,
};

export default connect(mapStateToProps, actionCreators)(Component);