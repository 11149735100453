import { connect } from 'react-redux'
import Suppliers from './Suppliers'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseSupplierModal from './Handlers/onCloseSupplierModal'
import onCloseSupplierRatesModal from './Handlers/onCloseSupplierRatesModal'

const mapStateToProps = (state) => ({
  supplierModal: state.modals.Suppliers.supplierModal,
  supplierRatesModal: state.modals.Suppliers.supplierRatesModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseSupplierModal,
  onCloseSupplierRatesModal,
}

export default connect(mapStateToProps, actionCreators)(Suppliers)
