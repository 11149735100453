import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectOrderNumbers = state => state.core.orderNumbers;
const selectFromOrderOpen = state => state.modals.Leftover.fromOrderOpen;
const selectFromOrderSearchTerm = state => state.modals.Leftover.fromOrderSearchTerm;
const selectFromOrderId = state => formValueSelector(FORM_NAME)(state, 'from_order_id');

export default createSelector(
  selectOrderNumbers,
  selectFromOrderOpen,
  selectFromOrderSearchTerm,
  selectFromOrderId,
  (orderNumbers, fromOrderOpen, fromOrderSearchTerm, fromOrderId) => {

    if (fromOrderOpen) {
      
      if (String(fromOrderSearchTerm).length > 3) {
      
        return orderNumbers
          .filter(item => String(item.order_number).startsWith(fromOrderSearchTerm))
          .map(item => ({ value: item.id, label: String(item.order_number) }));

      }
      
      return [];

    }

    if (fromOrderId) {
    
      return orderNumbers
        .filter(item => item.id === fromOrderId)
        .map(item => ({ value: item.id, label: String(item.order_number) }));
    
    }

    return [];
    
  }
)