import { change } from 'redux-form'
import { message } from 'antd'
import { FORM_NAME } from '../Form'
import { getProps } from '../props'
import { markJobApproved } from '../../../HttpRequests/jobs'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {
  
  try {

    dispatch(change(FORM_NAME, 'approved', 1))
  
    const { jobId: id, didUpdate, onClose } = getProps()

    await markJobApproved({ id })
    
    message.success("Approved")

    if (didUpdate) didUpdate()

    if (onClose) onClose()

  } catch (error) {
    
    handleException(error)

  }
}