import { get, reduce } from 'lodash'

export default (costSheetArea) => {

  const $costSheetArea = { ...costSheetArea, square_metres: null }

  if (get($costSheetArea, 'material_type.usage_key') === 'square-metres') {

    if (!$costSheetArea.area_id) return $costSheetArea

    const { area, material_range: materialRange } = $costSheetArea

    $costSheetArea.square_metres = area.square_metres_gross

    if (materialRange && materialRange.wastage_perc) {
      $costSheetArea.square_metres = area.square_metres + ((area.square_metres / 100) * materialRange.wastage_perc)
    }

  } else {

    if (!$costSheetArea.dimensions) return $costSheetArea

    $costSheetArea.square_metres = reduce($costSheetArea.dimensions, (carry, { length, width }) => carry + (length * width), 0)

  }

  return $costSheetArea

}
