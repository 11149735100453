import { connect } from "react-redux";
import Component from "./Component";

import onClickEdit from "./Handlers/onClickEdit";
import onChangeTab from "./Handlers/onChangeTab";

const mapStateToProps = state => ({
  site: state.screens.SiteView.site,
  activeTab: state.screens.SiteView.activeTab
});

const actionCreators = {
  onClickEdit,
  onChangeTab
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
