import { createAction, handleActions } from 'redux-actions'

const loadRangeVariants = createAction('CORE/RANGE_VARIANTS/LOAD_RANGE_VARIANTS')
const clearRangeVariants = createAction('CORE/RANGE_VARIANTS/CLEAR_RANGE_VARIANTS')

const defaultState = []

const reducer = handleActions(
  {
    [loadRangeVariants]: (state, action) => action.payload,
    [clearRangeVariants]: () => [],
  },
  defaultState,
)

export { loadRangeVariants, clearRangeVariants, reducer }
