import { find, first } from 'lodash'

const getGeneralOrBuilderRate = (supplierRates, builderId) => {

  let supplierRate

  // Is there a builder specific one?
  supplierRate = find(supplierRates, row => row.builder_id === builderId)

  // No builder specific one? Default to general one
  if (!supplierRate) {
    supplierRate = first(supplierRates.filter(row => !row.builder_id))
  }

  return supplierRate

}

const getDimensionsCost = (costSheetMaterial, supplierRates, builderId) => {

  let cost = null
  
  costSheetMaterial.dimensions.forEach(dimension => {

    // All rates which have a matching dimension
    let $supplierRates = supplierRates.filter(row => {
      if (!row.dimensions) return false
      return find(row.dimensions, $dimension => $dimension.length === dimension.length && $dimension.width === dimension.width)
    })

    let supplierRate = getGeneralOrBuilderRate($supplierRates, builderId)

    if (!supplierRate) {

      // All rates which have don't use dimensions
      $supplierRates = supplierRates.filter(row => !row.dimensions)

      supplierRate = getGeneralOrBuilderRate($supplierRates, builderId)
      
    }

    if (supplierRate) {
      cost += ((dimension.length * dimension.width) * supplierRate.rate) * costSheetMaterial.quantity
    }

  })

  return cost

}

const getSquareMetresCost = (costSheetMaterial, supplierRates, builderId) => {

  const supplierRate = getGeneralOrBuilderRate(supplierRates, builderId)

  if (!supplierRate) return null

  return (costSheetMaterial.square_metres * supplierRate.rate) * costSheetMaterial.quantity

}

const getItemCost = (costSheetMaterial, supplierRates, builderId) => {

  const supplierRate = getGeneralOrBuilderRate(supplierRates, builderId)

  if (!supplierRate) return null

  return supplierRate.rate * costSheetMaterial.quantity

}

export default (costSheetMaterial, prevCostSheetMaterial, state) => {

  const { supplierRates } = state.core

  const { order } = state.screens.CostSheet

  const $costSheetMaterial = { ...costSheetMaterial }

  if (!$costSheetMaterial.quantity) return $costSheetMaterial

  // If cost is set, calculate cost in accordance with the quantity
  if ($costSheetMaterial.cost && prevCostSheetMaterial) {
    if (!prevCostSheetMaterial.quantity) return $costSheetMaterial
    $costSheetMaterial.cost = ($costSheetMaterial.cost / prevCostSheetMaterial.quantity) * $costSheetMaterial.quantity
    return $costSheetMaterial
  }

  //

  const $supplierRates = supplierRates.filter(row => {
    if (row.supplier_id !== costSheetMaterial.supplier_id) return false
    if (row.material_range_id !== costSheetMaterial.material_range_id) return false
    return true
  })

  if (!$supplierRates.length) return $costSheetMaterial

  // Should be set, otherwise wouldn't have matching rates
  const materialType = $costSheetMaterial.material_type

  let cost = null

  if (materialType.usage_key === 'dimensions') {
    if (!$costSheetMaterial.dimensions) return $costSheetMaterial
    cost = getDimensionsCost($costSheetMaterial, $supplierRates, order.builder_id)
  }

  if (materialType.usage_key === 'square-metres') {
    if (!$costSheetMaterial.square_metres) return $costSheetMaterial
    cost = getSquareMetresCost($costSheetMaterial, $supplierRates, order.builder_id)
  }

  if (materialType.usage_key === 'item') {
    cost = getItemCost($costSheetMaterial, $supplierRates, order.builder_id)
  }

  $costSheetMaterial.cost = cost

  return $costSheetMaterial
  
}
