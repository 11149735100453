import { createSelector } from 'reselect'

const selectPlotTypes = state => state.screens.SiteView.CostMatrix.data.plotTypes

export default createSelector(
  selectPlotTypes,
  (plotTypes) => {

    const tableRows = []

    plotTypes.forEach(plotType => {
      const { area_groups: areaGroups, areas } = plotType
      const areasAndGroups = []
      // Push areas in first.
      areas.forEach(area => {
        areasAndGroups.push({
          type: 'Area',
          key: `area-${area.id}`,
          ...area,
        })
      })
      areaGroups.forEach(areaGroup => {
        areasAndGroups.push({
          type: 'AreaGroup',
          key: `area_group-${areaGroup.id}`,
          ...areaGroup,
        })
      })
      tableRows.push({ ...plotType, areas_and_groups: areasAndGroups })
    })

    return tableRows

  }

)
