import { createAction, handleActions } from 'redux-actions'

const setManufacturers = createAction('MODALS/MANUFACTURERS/SET_MANUFACTURERS')
const resetManufacturers = createAction('MODALS/MANUFACTURERS/RESET_MANUFACTURERS')

const defaultState = []

const reducer = handleActions(
  {
    [setManufacturers]: (state, { payload }) => payload,
    [resetManufacturers]: () => defaultState,
  },
  defaultState,
)

export { setManufacturers, resetManufacturers, reducer }
