import { find } from 'lodash'
import guessCost from '../Utils/guessCost'
import { setCostSheetField } from '../../../../../State/order'

const modValues = (costSheetExpense, field, value, state) => {

  let $costSheetExpense = { ...costSheetExpense, [field]: value }

  if (field === 'fitting_rate') {
    const { fittingRates } = state.core
    const fittingRate = find(fittingRates, row => row.id === value[0])
    $costSheetExpense.fitting_rate_id = fittingRate.id
    $costSheetExpense.fitting_rate = fittingRate
    $costSheetExpense = guessCost($costSheetExpense, state)
  }

  return $costSheetExpense

}

export default (guid, field, value) => (dispatch, getState) => {

  const state = getState()

  const { cost_sheet_expenses: costSheetExpenses } = state.screens.CostSheet.order.cost_sheet

  const $costSheetExpenses = []

  costSheetExpenses.forEach(costSheetExpense => {
    if (costSheetExpense.guid !== guid) {
      $costSheetExpenses.push({ ...costSheetExpense })
    } else {
      const $costSheetExpense = modValues(costSheetExpense, field, value, state)
      $costSheetExpenses.push($costSheetExpense)
    }
  })

  dispatch(setCostSheetField({ field: 'cost_sheet_expenses', value: $costSheetExpenses }))

}
