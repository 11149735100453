import modalProps from '../modalProps'
import { resetControls } from '../State/controls'
import { resetManufacturers } from '../State/manufacturers'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetControls())
  dispatch(resetManufacturers())

}
