export default (materialTypes, manufacturers, materialRanges) => {
  const materialTypeIds = materialRanges.map(materialRange => materialRange.material_type_id)
  return materialTypes.filter(materialType => materialTypeIds.includes(materialType.id)).map(materialType => {
    const $materialRanges = materialRanges.filter(materialRange => materialRange.material_type_id === materialType.id)
    const manufacturerIds = $materialRanges.map(materialRange => materialRange.manufacturer_id)
    const $manufacturers = manufacturers.filter(manufacturer => manufacturerIds.includes(manufacturer.id))
    return {
      label: materialType.name,
      value: materialType.id,
      children: $manufacturers.map(manufacturer => ({
        label: manufacturer.name,
        value: manufacturer.id,
        children: $materialRanges.filter(materialRange => materialRange.manufacturer_id === manufacturer.id).map(materialRange => ({
          label: materialRange.name,
          value: materialRange.id,
          children: materialRange.range_variants.map(rangeVariant => ({
            label: `${[rangeVariant.name, rangeVariant.code, rangeVariant.design].filter(item => !!item).join(' • ')}`,
            value: rangeVariant.id,
          })),
        })),
      })),
    }
  })
}
