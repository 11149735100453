import { message } from 'antd'
import modalProps from '../modalProps'
import { archiveMaterialRange } from '../../../HttpRequests/materialRanges'
import fetchMaterialRanges from '../../../Core/Services/fetchMaterialRanges'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { materialRange } = modalProps()

  try {

    await archiveMaterialRange({ id: materialRange.id })

    message.success('Archived Range')

    modalProps().onClose(true)

    dispatch(fetchMaterialRanges({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
