import { createAction, handleActions } from 'redux-actions'

const openNotesModal = createAction('MODALS/JOB/OPEN_NOTES_MODAL')
const closeNotesModal = createAction('MODALS/JOB/CLOSE_NOTES_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openNotesModal]: (state) => ({ ...state, show: true }),
    [closeNotesModal]: () => defaultState,
  },
  defaultState
)

export { openNotesModal, closeNotesModal, reducer }