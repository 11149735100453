import modalProps from '../../../modalProps'
import { setFormInitialValues } from '../State/formState'

export default () => (dispatch) => {

  const { siteMaterialRange } = modalProps()

  if (!siteMaterialRange) return

  const initialValues = {
    ...siteMaterialRange,
  }

  dispatch(setFormInitialValues(initialValues))

}
