import { createAction, handleActions } from 'redux-actions';

const setHideOrdered = createAction("SCREENS/BOARD/SET_HIDE_ORDERED");

const defaultState = false;

const reducer = handleActions(
  {
    [setHideOrdered]: (state, action) => action.payload
  },
  defaultState
);

export { setHideOrdered, reducer };
