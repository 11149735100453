import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { fitterInvoiceId, onClose, didCreate, didUpdate } = getProps();
  
  if (!fitterInvoiceId) {

    const fitterInvoice = await dispatch(create(values));

    if (fitterInvoice) {
      onClose();
      didCreate(fitterInvoice);
    }

  } else {

    const fitterInvoice = await dispatch(update(values));

    if (fitterInvoice) {
      onClose();
      didUpdate(fitterInvoice);
    }

  }

};
