export default (values) => {

  const errors = {};

  if (!values.inspector_id) errors.inspector_id = 'This field is required';
  if (!values.builder_id) errors.builder_id = 'This field is required';
  if (!values.site_id) errors.site_id = 'This field is required';
  if (!values.plots) errors.plots = 'This field is required';
  if (!values.date) errors.date = 'This field is required';

  return errors;
  
}