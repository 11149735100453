import { combineReducers } from 'redux'

import { reducer as activeTab } from './State/activeTab'
import { reducer as manufacturerModal } from './State/manufacturerModal'
import { reducer as supplierModal } from './State/supplierModal'
import { reducer as materialTypeModal } from './State/materialTypeModal'
import { reducer as materialRangeModal } from './State/materialRangeModal'

import Manufacturers from './Components/Manufacturers/reducer'
import Suppliers from './Components/Suppliers/reducer'
import MaterialTypes from './Components/MaterialTypes/reducer'
import MaterialRanges from './Components/MaterialRanges/reducer'

export default combineReducers({
  activeTab,
  manufacturerModal,
  supplierModal,
  materialTypeModal,
  materialRangeModal,

  Manufacturers,
  Suppliers,
  MaterialTypes,
  MaterialRanges,
})
