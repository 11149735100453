import chance from 'chance'
import { setCostSheetField } from '../../../State/order'

export default (guid, action) => (dispatch, getState) => {

  const { cost_sheet_areas: costSheetAreas } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetAreas = []

  costSheetAreas.forEach(costSheetArea => {

    if (costSheetArea.guid !== guid) {
      newCostSheetAreas.push({ ...costSheetArea })
      return
    }

    if (action === 'add') {
      newCostSheetAreas.push({ ...costSheetArea })
      newCostSheetAreas.push({
        type: 'Row',
        guid: chance().guid(),
      })
    }

    if (action === 'custom') {
      newCostSheetAreas.push({ ...costSheetArea })
      newCostSheetAreas.push({
        type: 'Custom',
        guid: chance().guid(),
      })
    }

    if (action === 'custom-area') {
      newCostSheetAreas.push({ ...costSheetArea })
      newCostSheetAreas.push({
        type: 'Custom Area',
        guid: chance().guid(),
      })
    }

    if (action === 'duplicate') {
      newCostSheetAreas.push({ ...costSheetArea })
      newCostSheetAreas.push({
        ...costSheetArea,
        id: undefined,
        guid: chance().guid(),
      })
    }

    if (action === 'remove') {
      // We just simply omit it
    }

  })

  dispatch(setCostSheetField({ field: 'cost_sheet_areas', value: newCostSheetAreas }))

}
