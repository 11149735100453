import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'

export default class Main extends React.PureComponent {

  static propTypes = {
    store: PropTypes.object.isRequired,
    persistor: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  }

  render () {

    const {
      store,
      persistor,
      history,
      children,
    } = this.props

    return (

      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            {children}
          </ConnectedRouter>
        </PersistGate>
      </Provider>

    )
  }
}