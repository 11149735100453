import { find } from 'lodash'
import guessAreaPrice from '../Utils/guessAreaPrice'
import { setCostSheetField } from '../../../../../State/order'

const modValues = (costSheetQuote, field, value, state) => {

  let $costSheetQuote = { ...costSheetQuote, [field]: value }

  if (field === 'quotable') {
    if (value[0] === 'area') {
      const { areas } = state.screens.CostSheet.order.plot_type
      const area = find(areas, row => row.id === value[1])
      $costSheetQuote.area_id = area.id
      $costSheetQuote.material_type_id = null
      $costSheetQuote.area = area
      $costSheetQuote.material_type = null
      $costSheetQuote = guessAreaPrice($costSheetQuote, state)
    }
    if (value[0] === 'material-type') {
      const { materialTypes } = state.core
      const materialType = find(materialTypes, row => row.id === value[1])
      $costSheetQuote.area_id = null
      $costSheetQuote.material_type_id = materialType.id
      $costSheetQuote.area = null
      $costSheetQuote.material_type = materialType
    }
  }

  return $costSheetQuote

}

export default (guid, field, value) => (dispatch, getState) => {

  const state = getState()

  const { cost_sheet_quotes: costSheetQuotes } = state.screens.CostSheet.order.cost_sheet

  const $costSheetQuotes = []

  costSheetQuotes.forEach(costSheetQuote => {
    if (costSheetQuote.guid !== guid) {
      $costSheetQuotes.push({ ...costSheetQuote })
    } else {
      const $costSheetQuote = modValues(costSheetQuote, field, value, state)
      $costSheetQuotes.push($costSheetQuote)
    }
  })

  dispatch(setCostSheetField({ field: 'cost_sheet_quotes', value: $costSheetQuotes }))

}
