import { message } from 'antd'
import { pick } from 'lodash'
import { updateHoliday } from '../../../HttpRequests/holidays';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {
  
  try {

    const { id } = values

    const data = pick(values, [
      'fitter_id',
      'start_date',
      'end_date',
      'notes',
    ])

    await updateHoliday({ id, data });
    
    message.success("Holiday Updated");

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}