import { connect } from "react-redux";
import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import componentWillUnmount from "./Handlers/componentWillUnmount";
import onCloseSiteModal from "./Handlers/onCloseSiteModal";
import onClosePlotTypeModal from "./Handlers/onClosePlotTypeModal";
import didUpdateSite from "./Handlers/didUpdateSite";
import didDeleteSite from "./Handlers/didDeleteSite";
import didCreatePlotType from "./Handlers/didCreatePlotType";
import onCloseSiteRateModal from "./Handlers/onCloseSiteRateModal";
import onCloseSiteMaterialRangeModal from "./Handlers/onCloseSiteMaterialRangeModal";
import onCloseAreaRangeQuoteModal from "./Handlers/onCloseAreaRangeQuoteModal";
import onChangeCostMatrixSearch from "./Handlers/onChangeCostMatrixSearch";
import onSearchCostMatrix from "./Handlers/onSearchCostMatrix";
import onChangeCostMatrixFormat from "./Handlers/onChangeCostMatrixFormat";
import onClickRegenerate from "./Handlers/onClickRegenerate";
import onClickExport from "./Handlers/onClickExport";

const mapStateToProps = state => ({
  site: state.screens.SiteView.site,
  activeTab: state.screens.SiteView.activeTab,
  showSiteModal: state.screens.SiteView.showSiteModal,
  showPlotTypeModal: state.screens.SiteView.showPlotTypeModal,
  siteRateModal: state.screens.SiteView.siteRateModal,
  siteMaterialRangeModal: state.screens.SiteView.siteMaterialRangeModal,
  areaRangeQuoteModal: state.screens.SiteView.areaRangeQuoteModal,
  costMatrixSearch: state.screens.SiteView.costMatrixSearch,
  costMatrixFormat: state.screens.SiteView.costMatrixFormat,
});

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseSiteModal,
  onClosePlotTypeModal,
  didUpdateSite,
  didDeleteSite,
  didCreatePlotType,
  onCloseSiteRateModal,
  onCloseSiteMaterialRangeModal,
  onCloseAreaRangeQuoteModal,
  onChangeCostMatrixSearch,
  onSearchCostMatrix,
  onChangeCostMatrixFormat,
  onClickRegenerate,
  onClickExport,
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
