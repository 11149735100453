import { connect } from "react-redux";

import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import onCloseBuilderModal from "./Handlers/onCloseBuilderModal";
import didCreateBuilder from "./Handlers/didCreateBuilder";

const mapStateToProps = state => ({
  showBuilderModal: state.screens.Builders.showBuilderModal
});

const actionCreators = {
  componentDidMount,
  onCloseBuilderModal,
  didCreateBuilder
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
