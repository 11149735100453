import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import tagColorMap from "../../../../Core/Misc/tagColorMap";
import styles from "./Component.css";

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  render () {

    const {
      data,
      onClick,
    } = this.props;

    const job = data;

    const { order } = job;

    const description = `
      ${job.collected ? '◆ '  : ''}
      ${job.sent ? '✓ ' : ''}
      ${job.approved === 0 ? '★ ' : ''}
      ${job.via_thetford ? 'T ' : ''}
      ${order ? order.plot_number : job.legacy_plot_number }
      ${order ? `${order.site ? order.site.name : order.site_custom} @ ${order.site ? order.site.town : order.town_custom}` : job.legacy_site}
      ${' | '}
      ${job.metres ? `${job.metres}${job.town_house ? ' T/H' : ''} | ` : ''}
      ${job.inspector ? job.inspector.name : '[NO INSPECTOR]' }
      ${job.inspection_notes ? `| ${job.inspection_notes}` : '' }
    `;

    return (

      <div
        onClick={(event) => {
          event.stopPropagation()
          onClick(job.id)
        }}
        role="presentation"
        className={classnames({
          [styles.wrapper]: true,
          [styles[`tag${tagColorMap[job.tag]}`]]: true
        })}
        draggable
        onDrop={() => undefined}
      >
        {description}
      </div>

    )
  }
}