import { destroy } from 'redux-form';
import { clearOrder } from '../State/order';
import { setActiveTab } from '../State/activeTab';
import { FORM_NAME } from '../Components/Order/Form';

export default () => async (dispatch) => {

  dispatch(destroy(FORM_NAME));
  dispatch(clearOrder());
  dispatch(setActiveTab('1'));

};