import { connect } from 'react-redux';

import Component from './Component';

import onClickAdd from './Handlers/onClickAdd';
import onClickItem from './Handlers/onClickItem';

const mapStateToProps = (state) => ({
  sites: state.screens.BuilderView.builder ? state.screens.BuilderView.builder.sites : []
});

const actionCreators = {
  onClickAdd,
  onClickItem
};

export default connect(mapStateToProps, actionCreators)(Component);
