import { createAction, handleActions } from 'redux-actions';

const loadHolidays = createAction("SCREENS/BOARD/LOAD_HOLIDAYS");

const defaultState = [
  { id: 1, date: '2018-12-31', },
  { id: 2, date: '2018-12-31', },
  { id: 3, date: '2019-01-03', },
  { id: 4, date: '2018-12-31', },
  { id: 5, date: '2018-12-31', }
];

const reducer = handleActions(
  {
    [loadHolidays]: (state, action) => action.payload
  },
  defaultState
);

export { loadHolidays, reducer };
