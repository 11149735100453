import { showSpinner, hideSpinner } from "../State/showSpinner";
import { getJobs, searchPeriodsWithJobs } from '../../../HttpRequests/jobs';
import { loadInspections } from '../State/inspections';
import { setOtherPeriodsWithResults, resetOtherPeriodsWithResults } from '../State/otherPeriodsWithResults';
import handleException from '../../../Core/Helpers/handleException';

export default ({ quiet } = { quiet: false }) => async (dispatch, getState) => {

  try {

    const { dateRange, selectedTags, hideAllocated, thetfordOnly, hideOrdered, manager, searchTerm, mode } = getState().screens.Board;

    const params = {};

    params.include = ['order.site', 'inspector'].join();

    params['filter[inspection_date_between]'] = dateRange.join();

    params['filter[tag]'] = selectedTags.join();

    if (hideAllocated) params['filter[without_inspector]'] = 1;

    if (thetfordOnly) params['filter[via_thetford]'] = 1;

    if (hideOrdered) params['filter[ordered]'] = 0;

    if (manager) params['filter[manager_id]'] = manager;

    if (searchTerm) params.search = searchTerm;

    params.sort = 'start_date,tag';

    params.pagination = 0;

    if (!quiet) dispatch(showSpinner());

    const response = await getJobs({ params });

    if (searchTerm) {

      const { data: { data: periods } } = await searchPeriodsWithJobs({
        params: {
          mode,
          search: searchTerm,
          manager_id: manager || undefined,
          tag: selectedTags.length > 0 ? selectedTags.join() : undefined,
        }
      })

      dispatch(setOtherPeriodsWithResults(periods))

    } else {

      dispatch(resetOtherPeriodsWithResults())

    }

    if (!quiet) dispatch(hideSpinner());

    const inspections = response.data.data;

    dispatch(loadInspections(inspections));

  } catch (error) {

    if (!quiet) dispatch(hideSpinner());

    handleException(error);

  }

}