import * as React from 'react';
import PropTypes from 'prop-types';
import { logout } from 'evolve-portal';

export default class extends React.PureComponent {

  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  componentDidMount = () => {
    this.props.dispatch(logout());
  }

  render = () => <div />
  
}