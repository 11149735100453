import axios from 'axios'

export const createSiteRate = ({ data }) => axios.post(`site_rates`, data)
export const deleteSiteRate = ({ id }) => axios.delete(`site_rates/${id}`)
export const findSiteRate = ({ id, params } = {}) => axios.get(`site_rates/${id}`, { params })
export const getSiteRates = ({ params } = {}) => axios.get(`site_rates`, { params })
export const updateSiteRate = ({ id, data }) => axios.put(`site_rates/${id}`, data)

export const reorderSiteRates = ({ data }) => axios.put(`site_rates/reorder`, data)
export const addMaterialRangeToSiteRate = ({ id, materialRangeId }) => axios.post(`site_rates/${id}/material_ranges/${materialRangeId}`)
export const removeMaterialRangeFromSiteRate = ({ id, materialRangeId }) => axios.delete(`site_rates/${id}/material_ranges/${materialRangeId}`)
export const syncSiteRateRangeVariants = ({ id, data }) => axios.put(`site_rates/${id}/range_variants`, data)
