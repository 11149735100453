import { get, reduce } from 'lodash'

export default (costSheetMaterial) => {

  const $costSheetMaterial = { ...costSheetMaterial, square_metres: null }

  if (get($costSheetMaterial, 'material_type.usage_key') === 'dimensions') {

    if (!$costSheetMaterial.dimensions) return $costSheetMaterial

    $costSheetMaterial.square_metres = reduce($costSheetMaterial.dimensions, (carry, { length, width }) => carry + (length * width), 0)
    
  }

  if (get($costSheetMaterial, 'material_type.usage_key') === 'square-metres') {

    // If material type is set then material range must be too
    $costSheetMaterial.square_metres = $costSheetMaterial.material_range.square_metres
    
  }

  return $costSheetMaterial

}
