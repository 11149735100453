import { createSelector } from "reselect";
import { sortBy } from 'lodash';
import moment from "moment";
import selectDates from './dates';

const selectJobs = state => state.screens.Board.jobs;
const selectSelectedTags = state => state.screens.Board.selectedTags;

export default createSelector(
  selectJobs,
  selectDates,
  selectSelectedTags,
  (jobs, dates, selectedTags) => {

    let data = dates.map(() => []);

    jobs
      .filter(job => selectedTags.length === 0 || selectedTags.includes(job.tag))
      .forEach(job => {
        
        for (let date = moment(job.start_date); date.diff(job.end_date, 'days') <= 0; date.add(1, 'days')) {
          
          const key = moment(date).format('YYYY-MM-DD');
  
          if (dates.indexOf(key) > -1) {
            data[dates.indexOf(key)].push(job);
          }
  
        }

      });

    data = data.map(items => sortBy(items, item => {

      const { order } = item

      if (item.order) {
        return `${order.site ? order.site.name : order.site_custom} ${order.plot_number}`.toLowerCase();
      }

      return `${item.legacy_site} ${item.legacy_plot_number}`.toLowerCase()
      
    }));

    return data;
  
  }
)