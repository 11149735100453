import { createAction, handleActions } from 'redux-actions';

const loadInspections = createAction("SCREENS/BOARD/LOAD_INSPECTIONS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadInspections]: (state, action) => action.payload
  },
  defaultState
);

export { loadInspections, reducer };
