import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.css'

const Item = props => {

  const {
    item,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.area)}>
        <div className={styles.content}>{item.area.name}</div>
      </div>

      <div className={classNames(styles.cell, styles.net)}>
        <div className={classNames(styles.content, styles.number)}>{item.net}</div>
      </div>

      <div className={classNames(styles.cell, styles.gross)}>
        <div className={classNames(styles.content, styles.number)}>{item.gross}</div>
      </div>

    </div>
    
  )

}

Item.propTypes = {
  item: PropTypes.object.isRequired,
}

export default Item
