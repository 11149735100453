import { createAction, handleActions } from 'redux-actions';

const setShowLeftoverModal = createAction("SCREENS/LEFTOVERS/SET_SHOW_LEFTOVER_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowLeftoverModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowLeftoverModal, reducer };