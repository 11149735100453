import { message } from 'antd';
import { getProps } from '../props';
import { deleteLeftover } from '../../../HttpRequests/leftovers';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { leftoverId, onClose, didDelete } = getProps();

    await deleteLeftover({ id: leftoverId });
    
    message.success("Leftover Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}