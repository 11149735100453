/* eslint-disable no-plusplus */
import { find, orderBy } from 'lodash'
import selectSiteMaterialRanges from '../../../Selectors/selectSiteMaterialRanges'
import selectAreaSummary from '../../../Selectors/selectAreaSummary'
import { setCostSheetField } from '../../../State/order'
import guessSupplier from '../Utils/guessSupplier'
import guessCost from '../Utils/guessCost'
import addItem from './addItem'

const calculateQuantity = (squareMetres, materialRange) => {
  if (!materialRange.siblings.length) {
    return Math.ceil(squareMetres / materialRange.coverage)
  }

  const materialRanges = orderBy([materialRange, ...materialRange.siblings.filter(row => !!row.coverage)], 'coverage', 'asc')

  let $squareMetres = squareMetres

  const quantities = {}

  while ($squareMetres > 0) {
    for (let i = 0; i < materialRanges.length; i++) {
      const $materialRange = materialRanges[i]
      if (Math.floor($squareMetres / $materialRange.coverage) === 0) {
        materialRanges.splice(i)
        $squareMetres -= $materialRange.coverage
        quantities[$materialRange.id] = 1
        break
      }
      if ((i + 1) === materialRanges.length && materialRanges.length > 1) {
        materialRanges.splice(i)
        const quantity = Math.floor($squareMetres / $materialRange.coverage)
        $squareMetres -= quantity * $materialRange.coverage
        quantities[$materialRange.id] = quantity
        break
      }
      if ((i + 1) === materialRanges.length && materialRanges.length === 1) {
        const quantity = Math.ceil($squareMetres / $materialRange.coverage)
        $squareMetres -= quantity * $materialRange.coverage
        quantities[$materialRange.id] = quantity
        break
      }
    }
  }

  return quantities[materialRange.id] || 0
}

export default () => (dispatch, getState) => {

  const state = getState()

  const { materialTypes, materialRanges } = state.core

  const siteMaterialRanges = selectSiteMaterialRanges(state)
  const areaSummary = selectAreaSummary(state)

  let ancCost = null
  let rebateCost = null

  areaSummary.materialTypes.forEach(({ squareMetres, materialType }) => {

    // Since this data is coming from the area summary, each material type should
    // be flooring, meaning its usage key will either be dimensions or square
    // metres. In which case, it's safe to assume that we'll be utilising the
    // given square metres value (i.e. no 'item' types)

    if (materialType.anc_coverage && materialType.anc_rate) {
      const $ancCost = Math.ceil(squareMetres / materialType.anc_coverage) * materialType.anc_rate
      ancCost += $ancCost > materialType.anc_min_amount ? $ancCost : materialType.anc_min_amount
    }

    if (materialType.rebate_rate) rebateCost += (squareMetres * materialType.rebate_rate)

  })

  dispatch(setCostSheetField({ field: 'anc_cost', value: ancCost }))
  dispatch(setCostSheetField({ field: 'rebate_cost', value: rebateCost }))

  areaSummary.rangeVariants.forEach(({ materialTypeId, materialRangeId, rangeVariantId, squareMetres, materialType, materialRange, rangeVariant }) => {

    let costSheetMaterial = {
      material_type_id: materialTypeId,
      material_range_id: materialRangeId,
      range_variant_id: rangeVariantId,
      material_type: materialType,
      material_range: materialRange,
      range_variant: rangeVariant,
    }

    costSheetMaterial = guessSupplier(costSheetMaterial, state)

    if (materialType.usage_key === 'dimensions') {
      // We won't guess dimensions for the time being
    }

    if (materialType.usage_key === 'square-metres') {
      costSheetMaterial.square_metres = materialRange.square_metres
      costSheetMaterial.quantity = squareMetres / materialRange.square_metres
    }

    costSheetMaterial = guessCost(costSheetMaterial, null, state)

    dispatch(addItem(costSheetMaterial))
    
  })

  /* ---------- Automations ---------- */

  const { squareMetres } = areaSummary

  siteMaterialRanges.forEach(siteMaterialRange => {

    const materialType = find(materialTypes, row => row.id === siteMaterialRange.material_type_id)
    const materialRange = find(materialRanges, row => row.id === siteMaterialRange.material_range_id)

    if (!materialRange.target || !materialRange.coverage) return

    const categoryMaterialTypeIds = materialRange.categories.map(row => row.id)

    let costSheetMaterial = {
      material_type_id: materialType.id,
      material_range_id: materialRange.id,
      range_variant_id: null,
      dimensions: null,
      square_metres: null,
      quantity: 0,
      material_type: materialType,
      material_range: materialRange,
      range_variant: null,
    }
    
    switch (materialRange.target) {
      case 'Net M2':
        if (materialRange.symbol) {
          const $squareMetresNet = categoryMaterialTypeIds.reduce((carry, materialTypeId) => {
            const symbol = areaSummary.materialTypeSymbolMap[materialTypeId][materialRange.symbol]
            return symbol ? carry + symbol.squareMetresNet : 0
          }, 0)
          costSheetMaterial.quantity = calculateQuantity($squareMetresNet, materialRange)
        } else {
          const $squareMetresNet = categoryMaterialTypeIds.reduce((carry, materialTypeId) => carry + areaSummary.materialTypeSymbolMap[materialTypeId].squareMetresNet, 0)
          costSheetMaterial.quantity = calculateQuantity($squareMetresNet, materialRange)
        }
        break
      case 'Gross M2':
        if (materialRange.symbol) {
          const $squareMetresGross = categoryMaterialTypeIds.reduce((carry, materialTypeId) => {
            const symbol = areaSummary.materialTypeSymbolMap[materialTypeId][materialRange.symbol]
            return symbol ? carry + symbol.squareMetresGross : 0
          }, 0)
          costSheetMaterial.quantity = calculateQuantity($squareMetresGross, materialRange)
        } else {
          const $squareMetresGross = categoryMaterialTypeIds.reduce((carry, materialTypeId) => carry + areaSummary.materialTypeSymbolMap[materialTypeId].squareMetresGross, 0)
          costSheetMaterial.quantity = calculateQuantity($squareMetresGross, materialRange)
        }
        break
      case 'Flooring M2':
        costSheetMaterial.quantity = calculateQuantity(squareMetres, materialRange)
        break
      default:
        break
    }

    if (!costSheetMaterial.quantity) return

    costSheetMaterial = guessSupplier(costSheetMaterial, state)
  
    costSheetMaterial = guessCost(costSheetMaterial, null, state)
  
    dispatch(addItem(costSheetMaterial))

  })

}
