import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { Field } from 'redux-form';
import { TextAreaField } from 'redux-form-antd-fields';

export default class extends React.PureComponent{

  static propTypes = {
    componentWillMount: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      onClickSubmit,
      onClickCancel,
    } = this.props;

    return (
        
      <Modal
        visible  
        title='Notes'
        onOk={onClickSubmit}
        onCancel={onClickCancel}
      >

        <Field
          name="notes"
          label={false}
          component={TextAreaField}
          rows={4}
        />

      </Modal>
    )
  }
}