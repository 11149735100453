import guessCost from '../Utils/guessCost'
import { setCostSheetField } from '../../../State/order'

export default () => (dispatch, getState) => {

  const state = getState()

  const { cost_sheet_materials: costSheetMaterials } = getState().screens.CostSheet.order.cost_sheet

  const $costSheetMaterials = []

  costSheetMaterials.forEach(costSheetMaterial => {
    $costSheetMaterials.push(guessCost(costSheetMaterial, null, state))
  })

  dispatch(setCostSheetField({ field: 'cost_sheet_materials', value: $costSheetMaterials }))

}
