import handleException from '../../../Core/Helpers/handleException';
import { getFitters } from '../../../HttpRequests/fitters';
import { setFetching } from '../State/fetching';
import { loadFitters } from '../State/fitters';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm } = getState().screens.Fitters;

  try {

    const params = {};
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (searchTerm) {
      params.search = searchTerm;
    }

    params['filter[without_archived]'] = 1

    params.sort = 'name';

    dispatch(setFetching(true));

    const response = await getFitters({ params });

    dispatch(loadFitters(response.data.data));
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}