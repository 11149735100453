export default (state) => {

  const allowedUsers = [
    1, // Lewis
    3, // Nick
    10, // Elaine
    14, // Lizzie
    20, // Cheryl
    23, // Chloe
    31, // Kim
    32, // Nichola
    33, // Wendy
    34, // Esther
    36, // Kayleigh
    48, // Kerry
    53, // Alexandra
    56, // Jo Doree
    57, // Abi
    58, // Grace
    59, // Sarah
  ]

  const isAllowedUser = allowedUsers.includes(state.user.id)

  return isAllowedUser

}