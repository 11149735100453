import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Footer.css'

const Footer = (props) => {

  const {
    className,
    style,
    renderLeft,
    renderRight,
    border,
  } = props
  
  return (

    <div className={classNames(styles.footer, {[styles.border]: border}, className)} style={style}>

      {renderLeft && (
        <div className={styles.left}>
          {renderLeft()}
        </div>
      )}

      {renderRight && (
        <div className={styles.right}>
          {renderRight()}
        </div>
      )}

    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
  border: PropTypes.bool,
}

Footer.defaultProps = {
  className: undefined,
  style: undefined,
  renderLeft: undefined,
  renderRight: undefined,
  border: false,
}

export default Footer