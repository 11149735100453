import { setPagination } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (pageNumber, pageSize) => (dispatch) => {

  dispatch(setPagination({ pageNumber, pageSize }))
  
  dispatch(fetch())

}
