import { createAction, handleActions } from 'redux-actions';

const loadOrderNumbers = createAction("CORE/ORDER_NUMBERS/LOAD_ORDER_NUMBERS");
const clearOrderNumbers = createAction("CORE/ORDER_NUMBERS/CLEAR_ORDER_NUMBERS");

const defaultState = []

const reducer = handleActions(
  {
    [loadOrderNumbers]: (state, action) => action.payload,
    [clearOrderNumbers]: () => []
  },
  defaultState
);

export { loadOrderNumbers, clearOrderNumbers, reducer };