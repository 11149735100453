import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Component from './Component';

import materialUnit from './Selectors/materialUnit';

import componentWillMount from './Handlers/componentWillMount';
import onClickCancel from './Handlers/onClickCancel';

import { formConfig } from './Form';

const mapStateToProps = (state) => ({
  materialUnit: materialUnit(state)
});

const actionCreators = {
  componentWillMount,
  onClickCancel
};

export default connect(mapStateToProps, actionCreators)(reduxForm(formConfig)(Component));