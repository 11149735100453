import moment from 'moment';
import { setMode } from '../../../State/mode';
import { setDateRange } from '../../../State/dateRange';
import fetchData from '../../../Services/fetchData';

export default (event) => async (dispatch, getState) => {

  dispatch(setMode(event.target.value));
  
  const { dateRange } = getState().screens.Board;

  if (event.target.value === 'WEEK') {
    
    dispatch(setDateRange([
      moment(dateRange[0]).startOf('isoWeek').format('YYYY-MM-DD'),
      moment(dateRange[0]).endOf('isoWeek').format('YYYY-MM-DD')
    ]))

  }
  
  if (event.target.value === 'MONTH') {

    dispatch(setDateRange([
      moment(dateRange[1]).startOf('month').format('YYYY-MM-DD'),
      moment(dateRange[1]).endOf('month').format('YYYY-MM-DD')
    ]))

  }

  dispatch(fetchData())
  
}