import { connect } from 'react-redux';
import Component from './Component';

import onChangeTable from './Handlers/onChangeTable';

const mapStateToProps = (state) => ({
  builders: state.screens.Builders.builders,
  pagination: state.screens.Builders.pagination,
  fetching: state.screens.Builders.fetching
});

const actionCreators = {
  onChangeTable
};

export default connect(mapStateToProps, actionCreators)(Component);
