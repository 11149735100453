import { change } from 'redux-form';
import { FORM_NAME } from '../../../Form';

export default (event, value) => (dispatch) => {

  dispatch(change(FORM_NAME, 'town_house', value.includes('town_house') ? 1 : 0))
  dispatch(change(FORM_NAME, 'via_thetford', value.includes('via_thetford') ? 1 : 0))
  dispatch(change(FORM_NAME, 'sent', value.includes('sent') ? 1 : 0))
  dispatch(change(FORM_NAME, 'collected',value.includes('collected') ? 1 : 0))
  dispatch(change(FORM_NAME, 'ordered',value.includes('ordered') ? 1 : 0))
  
}