import { message } from 'antd'
import { getProps } from '../props';
import { unmarkOrderAsInvoiced } from '../../../HttpRequests/orders';
import fetchOrder from './fetchOrder';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch) => {
  
  try {

    const { id } = getProps().match.params;

    await unmarkOrderAsInvoiced({ id });
    
    message.success("Unmarked as Invoiced");

    await dispatch(fetchOrder());

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}