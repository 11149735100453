import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Select } from 'antd'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    materialTypes: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
    materialRanges: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      materialTypes,
      initialValues,
      materialRanges,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >

        {({ handleSubmit, form }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ values: true, submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name='material_type_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Material'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    onChange={value => {
                      input.onChange(value)
                      form.change('material_range_id', null)
                    }}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    placeholder='None'
                  >
                    {materialTypes.map(materialType => (
                      <Select.Option key={materialType.id} value={materialType.id}>
                        {materialType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='material_range_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Range'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    placeholder='None'
                  >
                    <Select.OptGroup label='Active'>
                      {materialRanges.filter(materialRange => !materialRange.archived_at).map(materialRange => (
                        <Select.Option key={materialRange.id} value={materialRange.id}>
                          {materialRange.name}
                          {' • '}
                          <span style={{ color: '#9e9e9e' }}>{materialRange.manufacturer.name}</span>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {materialRanges.filter(materialRange => !!materialRange.archived_at).map(materialRange => (
                        <Select.Option key={materialRange.id} value={materialRange.id} style={{ color: '#9e9e9e' }}>
                          {`${materialRange.name} • ${materialRange.manufacturer.name}`}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
