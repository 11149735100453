import { createAction, handleActions } from 'redux-actions'

const setFields = createAction('SCREENS/COST_SHEET/AREAS/ADD_MODAL/SET_FIELDS')
const setField = createAction('SCREENS/COST_SHEET/AREAS/ADD_MODAL/SET_FIELD')
const resetFields = createAction('SCREENS/COST_SHEET/AREAS/ADD_MODAL/RESET_FIELDS')

const defaultState = {
  materialTypeId: null,
  materialRangeId: null,
  rangeVariantId: null,
  areaIds: [],
}

const reducer = handleActions(
  {
    [setFields]: (state, { payload }) => payload,
    [setField]: (state, { payload }) => ({ ...state, [payload.field]: payload.value }),
    [resetFields]: () => defaultState,
  },
  defaultState,
)

export { setFields, setField, resetFields, reducer }
