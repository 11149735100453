import { connect } from 'react-redux'
import AddArea from './AddArea'

import componentDidMount from './Handlers/componentDidMount'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentDidMount,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(AddArea)
