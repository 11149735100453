import { createAction, handleActions } from 'redux-actions'

const openSetupModal = createAction('SCREENS/COST_SHEET/AREAS/OPEN_SETUP_MODAL')
const closeSetupModal = createAction('SCREENS/COST_SHEET/AREAS/CLOSE_SETUP_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSetupModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeSetupModal]: () => defaultState,
  },
  defaultState,
)

export { openSetupModal, closeSetupModal, reducer }
