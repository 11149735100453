import { setControl } from '../../../State/controls'
import fetch from '../../../Services/fetch'

export default (pagination, filters, { columnKey, order }) => (dispatch, getState) => {

  const { sort } = getState().modals.Manufacturers.controls

  const value = columnKey ? `${order === 'descend' ? '-' : ''}${columnKey}` : null

  if (value === sort) return

  dispatch(setControl({ control: 'sort', value }))

  dispatch(fetch())

}
