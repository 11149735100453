import handleException from '../../../Core/Helpers/handleException';
import { getBuilders } from '../../../HttpRequests/builders';
import { setFetching } from '../State/fetching';
import { loadBuilders } from '../State/builders';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm } = getState().screens.Builders;

  try {

    const params = {};
    
    params.append = ['site_count'].join();
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (searchTerm) {
      params.search = searchTerm;
    }

    params.sort = 'name';

    dispatch(setFetching(true));

    const response = await getBuilders({ params });
    const builders = response.data;

    dispatch(loadBuilders(builders.data));
    
    dispatch(setPagination({
      total: builders.pagination.total,
      pageSize: builders.pagination.per_page,
      current: builders.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}