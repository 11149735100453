import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Icon } from 'antd'
import styles from './Component.css'

export default class _Dropzone_ extends React.PureComponent {

  static propTypes = {
    disabled: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    onShow: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  componentDidMount = () => {

    if (!this.props.disabled) {
      this.registerEvents()
    }

  }

  componentDidUpdate = (prevProps) => {

    if (prevProps.disabled && !this.props.disabled) {
      this.registerEvents()
    }

    if (!prevProps.disabled && this.props.disabled) {
      this.unregisterEvents()
    }

  }

  componentWillUnmount = () => {
    
    this.unregisterEvents()
  
  }

  registerEvents = () => {

    const {
      onShow,
      onHide,
    } = this.props

    let counter = 0

    window.ondragenter = (event) => {
      event.preventDefault()
      counter += 1
      onShow()
    }
    
    window.ondragleave = () => {
      counter -= 1
      if (counter === 0) onHide()
    }
    
    window.ondrop = () => {
      counter = 0
      onHide()
    }
  }

  unregisterEvents = () => {
    window.ondragenter = undefined
    window.ondragleave = undefined
    window.ondrop = undefined
  }

  render () {

    const {
      disabled,
      show,
      onDrop,
    } = this.props

    if (!show || disabled) return null
    
    return (

      <Dropzone onDrop={onDrop}>

        {({ getRootProps, getInputProps }) => (

          <div
            {...getRootProps()}
            className={styles.wrapper}
          >

            <input {...getInputProps()} />

            <Icon type="cloud-download" className={styles.icon} />

            <div className={styles.text}>Upload to Cloud</div>

          </div>

        )}

      </Dropzone>

    )
  }
}