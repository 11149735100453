import * as React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral'
import { Table, Icon, Tag, Dropdown, Menu, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    orders: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    selectedRowKeys: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onChangeRowSelection: PropTypes.func.isRequired,
    onClickBulkUpdateAction: PropTypes.func.isRequired,
    onClickExport: PropTypes.func.isRequired,
  }

  render () {

    const {
      orders,
      pagination,
      fetching,
      selectedRowKeys,
      type,
      onChangeTable,
      onChangeRowSelection,
      onClickBulkUpdateAction,
      onClickExport,
    } = this.props;

    const statusColors = {
      'Not Fitted': 'cyan',
      'For Invoicing': 'blue',
      'Invoiced': 'geekblue',
      'Closed': 'purple',
      'Cancelled': 'red'
    }
    
    const columns = [

      { 
        title: 'Order No', 
        dataIndex: 'order_number',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <NavLink key={record.id} to={`/portal/orders/${record.id}`}>
            {record.order_number || record.legacy_order_number || 'N/A'}
          </NavLink>
        ),
      },
      
      {
        title: 'Category',
        dataIndex: 'order_category.name',
        width: 150,
        sorter: true,
        render: (text, record) => (record.order_category ? record.order_category.name : record.order_category_custom),
      },
      
      {
        title: 'Plot No',
        dataIndex: 'plot_number',
        width: 100,
        sorter: true,
      },
      
      {
        title: 'Plot Type',
        dataIndex: 'plot_type.name',
        sorter: true,
      },
      
      {
        title: 'Site',
        dataIndex: 'site.name',
        sorter: true,
        render: (text, record) => (record.site ? record.site.name : record.site_custom),
      },
      
      {
        title: 'Town',
        dataIndex: 'site.town',
        sorter: true,
        render: (text, record) => (record.site ? record.site.town : record.town_custom),
      },
      
      {
        title: 'Builder',
        dataIndex: 'builder.name',
        sorter: true,
        render: (text, record) => (record.builder ? record.builder.name : record.builder_custom),
      },

      ...(() => {

        if (type === 'orders') {

          return [
            {
              title: 'Sale Price',
              dataIndex: 'sale_price',
              render: (text, record) => (record.sale_price ? `£${numeral(record.sale_price).format('0,0.00')}` : ''),
            },
            {
              title: 'C/W',
              dataIndex: 'contract_works_price',
              render: (text, record) => (record.contract_works_price ? `£${numeral(record.contract_works_price).format('0,0.00')}` : ''),
            },
            {
              title: 'C/E',
              dataIndex: 'customer_extra_price',
              render: (text, record) => (record.customer_extra_price ? `£${numeral(record.customer_extra_price).format('0,0.00')}` : ''),
            },
            {
              title: 'M/W',
              dataIndex: 'matwell_price',
              render: (text, record) => (record.matwell_price ? `£${numeral(record.matwell_price).format('0,0.00')}` : ''),
            },
            {
              title: 'Protection',
              dataIndex: 'protection_price',
              render: (text, record) => (record.protection_price ? `£${numeral(record.protection_price).format('0,0.00')}` : ''),
            },
          ]

        }

        if (type === 'remedials') {

          return [
            {
              title: 'Price 1',
              dataIndex: 'custom_price_1',
              render: (text, record) => (record.custom_price_1 ? `£${numeral(record.custom_price_1).format('0,0.00')}` : ''),
            },
            {
              title: 'Price 2',
              dataIndex: 'custom_price_2',
              render: (text, record) => (record.custom_price_2 ? `£${numeral(record.custom_price_2).format('0,0.00')}` : ''),
            },
            {
              title: 'Price 3',
              dataIndex: 'custom_price_3',
              render: (text, record) => (record.custom_price_3 ? `£${numeral(record.custom_price_3).format('0,0.00')}` : ''),
            },
            {
              title: 'Qn Response',
              dataIndex: 'questionnaire_received_at',
              sorter: true,
              render: (text, record) => (record.questionnaire_received_at ? moment(record.questionnaire_received_at).fromNow() : ''),
            },
          ]

        }

        return []

      })(),
      
      {
        title: 'Status',
        dataIndex: 'status',
        width: 120,
        sorter: true,
        render: (text, record) => <Tag color={statusColors[record.status]}>{record.status}</Tag>,
      },
      
      {
        title: 'On Hold',
        dataIndex: 'on_hold_reason_key',
        width: 150,
        sorter: true,
        render: (text, record) => (record.on_hold_reason && <Tag color="orange">{record.on_hold_reason.name}</Tag>),
      },
      
      {
        title: 'SS',
        dataIndex: 'site_survey_added',
        width: 60,
        sorter: true,
        render: (text, record) => (!!record.site_survey_added && <Icon type="check" />)
      },

      {
        title: 'P',
        dataIndex: 'photo_added',
        width: 60,
        sorter: true,
        render: (text, record) => (!!record.photo_added && <Icon type="check" />)
      },

    ];

    const creditColumns = [

      {
        title: 'Order No',
        dataIndex: 'order_number',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <NavLink key={record.id} to={`/portal/orders/${record.id}`}>
            {record.order_number || record.legacy_order_number || 'N/A'}
          </NavLink>
        ),
      },

      {
        title: 'Company',
        dataIndex: 'builder.name',
        sorter: true,
        render: (text, record) => (record.builder ? record.builder.name : record.builder_custom),
      },

      {
        title: 'Reference',
        dataIndex: 'credit_reference',
        sorter: true,
        render: (text, record) => record.credit_reference,
      },

      {
        title: 'Date',
        dataIndex: 'date',
        sorter: true,
        render: (text, record) => moment(record.date).format('Do MMM YYYY'),
      },

      {
        title: 'Amount',
        dataIndex: 'custom_price_1',
        sorter: true,
        render: (text, record) => (record.custom_price_1 ? `£${numeral(record.custom_price_1).format('0,0.00')}` : ''),
      },

      {
        title: 'Status',
        dataIndex: 'credit_status',
        sorter: true,
        render: (text, record) => {
          if (!record.credit_status) return null
          const colors = {
            'Awaiting Credit': 'red',
            'Credit Received': 'green',
          };
          return <Tag color={colors[record.credit_status]}>{record.credit_status}</Tag>
        },
      },

    ];

    const bulkUpdateMenu = (
      <Menu onClick={onClickBulkUpdateAction}>
        <Menu.Item key="called-off">Mark as Called Off</Menu.Item>
        <Menu.Item key="fitted">Mark as Fitted</Menu.Item>
        <Menu.Item key="invoiced">Mark as Invoiced</Menu.Item>
        <Menu.Item key="closed">Mark as Closed</Menu.Item>
      </Menu>
    );
    
    return (

      <React.Fragment>

        <Table
          dataSource={orders}
          columns={type === 'credits' ? creditColumns : columns} 
          rowKey={record => record.id}
          loading={fetching}
          onChange={onChangeTable}
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40', '50'],
            ...pagination
          }}
          rowSelection={type !== 'credits' ? {
            selectedRowKeys,
            onChange: onChangeRowSelection
          } : null}
          bordered
        />
        
        <div className={styles.footerButtons}>

          {type !== 'credits' && (

            <React.Fragment>

              <Dropdown overlay={bulkUpdateMenu} placement="topLeft" trigger={["click"]} disabled={selectedRowKeys.length === 0}>
                <Button type="primary">
                  Mark Orders ({selectedRowKeys.length})
                  <Icon type="down" />
                </Button>
              </Dropdown>

              <div style={{ width: 8 }} />

            </React.Fragment>

          )}

          <Button type="default" icon="file-excel" onClick={onClickExport} disabled={type === 'all'}>
            Export CSV
          </Button>

        </div>

      </React.Fragment>
    )
  }
}