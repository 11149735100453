import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as manufacturers } from './State/manufacturers'
import { reducer as fetching } from './State/fetching'
import { reducer as manufacturerModal } from './State/manufacturerModal'
import { reducer as materialRangesModal } from './State/materialRangesModal'

export default combineReducers({
  controls,
  manufacturers,
  fetching,
  manufacturerModal,
  materialRangesModal,
})
