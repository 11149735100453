export default (values) => () => {

  const errors = {}

  if (!values.square_metres) errors.square_metres = true

  if (values.use_rate) {
    if (!values.rate) errors.rate = true
  }

  if (!values.price_net) errors.price_net = true
  
  return errors

}
