import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import LinkButton from '../../../../Components/LinkButton'

export default class _Table_ extends React.PureComponent {

  static propTypes = {
    fitters: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    onSelectFitter: PropTypes.func.isRequired,
  }

  render () {

    const {
      fitters,
      fetching,
      onSelectFitter,
    } = this.props
    
    const columns = [

      {
        title: 'Name',
        dataIndex: 'name',
        width: 200,
        render: (text, record) => (
          <LinkButton type="primary" onClick={() => onSelectFitter(record)}>
            {record.name}
          </LinkButton>
        ),
      },

      {
        title: 'Tags',
        dataIndex: 'tags',
        width: 200,
        render: (text, record) => record.tags.join(', '),
      },
      
    ]
    
    return (

      <React.Fragment>

        <Table
          dataSource={fitters}
          columns={columns} 
          rowKey={record => record.id}
          loading={fetching}
          size="small"
        />

      </React.Fragment>

    )
  }
}