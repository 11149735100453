import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createSupplierRate, updateSupplierRate } from '../../../../../HttpRequests/supplierRates'
import fetchSupplierRates from '../../../../../Core/Services/fetchSupplierRates'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { supplierRate, supplierId } = modalProps()

  const data = {
    builder_id: values.builder_id,
    material_type_id: values.material_type_id,
    material_range_id: values.material_range_id,
    dimensions: values.dimensions,
    rate: values.rate,
  }

  try {

    if (supplierRate) {
      await updateSupplierRate({ id: supplierRate.id, data })
      message.success('Updated Supplier Rate')
    } else {
      data.supplier_id = supplierId
      await createSupplierRate({ data })
      message.success('Created Supplier Rate')
    }

    modalProps().onClose(true)

    dispatch(fetchSupplierRates({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
