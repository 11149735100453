import { createAction, handleActions } from 'redux-actions';

const setFilters = createAction("SCREENS/ORDERS/SET_FILTERS");
const resetFilters = createAction("SCREENS/ORDERS/RESET_FILTERS");

const defaultState = {
  type: 'all',
  status: undefined,
  manager_id: undefined,
  on_hold_reason_key: undefined,
  site_survey_added: undefined,
  photo_added: undefined,
  questionnaire_status: undefined,
  credit_status: undefined,
};

const reducer = handleActions(
  {
    [setFilters]: (state, action) => Object.assign({}, state, action.payload),
    [resetFilters]: (state) => Object.assign({}, defaultState, { type: state.type }),
  },
  defaultState
);

export { setFilters, resetFilters, reducer };