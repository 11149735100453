import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Component from './Component';

import orderNumberOptions from './Selectors/orderNumberOptions';
import orderCategoryOptions from './Selectors/orderCategoryOptions';
import builderOptions from './Selectors/builderOptions';
import siteOptions from './Selectors/siteOptions';
import plotTypeOptions from './Selectors/plotTypeOptions';
import credit from './Selectors/credit';
import customEntry from './Selectors/customEntry';

import onBlurOrder from './Handlers/onBlurOrder';
import onChangeFlags from './Handlers/onChangeFlags';
import onChangeOrder from './Handlers/onChangeOrder';
import onChangeOrderSearch from './Handlers/onChangeOrderSearch';
import onChangeOrderType from './Handlers/onChangeOrderType';
import onChangeBuilder from './Handlers/onChangeBuilder';
import onChangeSite from './Handlers/onChangeSite';

import { FORM_NAME } from '../../Form';

const mapStateToProps = (state) => ({
  orderNumberOptions: orderNumberOptions(state),
  orderCategoryOptions: orderCategoryOptions(state),
  builderOptions: builderOptions(state),
  siteOptions: siteOptions(state),
  plotTypeOptions: plotTypeOptions(state),
  orderType: formValueSelector(FORM_NAME)(state, 'order_type'),
  builderId: formValueSelector(FORM_NAME)(state, 'builder_id'),
  credit: credit(state),
  customEntry: customEntry(state)
});

const actionCreators = {
  onBlurOrder,
  onChangeFlags,
  onChangeOrder,
  onChangeOrderSearch,
  onChangeOrderType,
  onChangeBuilder,
  onChangeSite
};

export default connect(mapStateToProps, actionCreators)(Component);