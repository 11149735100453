import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormSpy } from 'react-final-form'
import { defer } from 'lodash'

const FormInterceptor = React.forwardRef((props, ref) => {

  const {
    subscription,
    onChange,
  } = props

  if (ref) {
    ref(useForm())
  }

  if (onChange) {
    return (
      <FormSpy onChange={formState => defer(() => onChange(formState))} subscription={subscription} />
    )
  }

  return null

})

FormInterceptor.propTypes = {
  subscription: PropTypes.object,
  onChange: PropTypes.func,
}

FormInterceptor.defaultProps = {
  subscription: undefined,
  onChange: undefined,
}

export default FormInterceptor
