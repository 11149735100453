import { connect } from 'react-redux'
import Form from './Form'

import modalProps from '../../modalProps'

import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  isNew: !modalProps().id,
  materialTypes: state.core.materialTypes.filter(materialType => !!materialType.is_flooring),
  materialRanges: state.core.materialRanges,
})

const actionCreators = {
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
