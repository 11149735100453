import { getMaterialRanges } from '../../../../../HttpRequests/materialRanges'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/isFetching'
import { setPagination } from '../State/pagination'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { materialType, searchTerm, sorting, pagination } = getState().screens.Materials.MaterialRanges

  const params = {
    'filter[material_type_id]': materialType.id,
  }

  if (searchTerm) params.search = searchTerm

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'name'

  params.include = 'manufacturer'

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getMaterialRanges({ params })

    dispatch(setData(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
    }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
