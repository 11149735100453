import { createAction, handleActions } from 'redux-actions'

const openDimensionsModal = createAction('MODALS/SUPPLIER_RATE/OPEN_DIMENSIONS_MODAL')
const closeDimensionsModal = createAction('MODALS/SUPPLIER_RATE/CLOSE_DIMENSIONS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openDimensionsModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeDimensionsModal]: () => defaultState,
  },
  defaultState,
)

export { openDimensionsModal, closeDimensionsModal, reducer }
