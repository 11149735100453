import { connect } from 'react-redux'
import Table from './Table'

import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  rangeVariants: state.modals.RangeVariants.rangeVariants,
  fetching: state.modals.RangeVariants.fetching,
})

const actionCreators = {
  onChangeTable,
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(Table)
