import modalProps from '../../../modalProps'
import { createSiteRate, updateSiteRate } from '../../../../../HttpRequests/siteRates'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { id, siteId } = modalProps()

  const data = {
    title: values.title,
    subtitle: values.subtitle || null,
    material_type_id: values.material_type_id,
    material_range_id: values.material_range_id,
    rate: values.rate,
    group_rate: values.group_rate || null,
  }

  let response

  try {

    if (id) {

      response = await updateSiteRate({ id, data })

      modalProps().onClose(true)

    } else {

      data.site_id = siteId

      response = await createSiteRate({ data })

      modalProps().onClose(true, response.data.data)

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
