import screenProps from '../screenProps'
import { resetPlotType } from '../State/plotType'
import { resetActiveTab } from '../State/activeTab'
import { resetAreas } from '../Components/Areas/State/areas'
import { resetSelectedRows as resetSelectedAreas } from '../Components/Areas/State/selectedRows'
import { resetAreaGroups } from '../Components/AreaGroups/State/areaGroups'
import { resetSelectedRows as resetSelectedAreaGroups } from '../Components/AreaGroups/State/selectedRows'

export default () => (dispatch) => {

  screenProps.reset()

  dispatch(resetPlotType())
  dispatch(resetActiveTab())

  dispatch(resetAreas())
  dispatch(resetSelectedAreas())

  dispatch(resetAreaGroups())
  dispatch(resetSelectedAreaGroups())

}
