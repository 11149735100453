import { connect } from 'react-redux'
import OrderNotes from './OrderNotes'

import componentDidMount from './Handlers/componentDidMount'
import onClickAdd from './Handlers/onClickAdd'
import onClickItem from './Handlers/onClickItem'
import onCloseOrderNoteModal from './Handlers/onCloseOrderNoteModal'

const mapStateToProps = (state) => ({
  user: state.user,
  data: state.screens.OrderView.OrderNotes.data,
  orderNoteModal: state.screens.OrderView.OrderNotes.orderNoteModal,
})

const actionCreators = {
  componentDidMount,
  onClickAdd,
  onClickItem,
  onCloseOrderNoteModal,
}

export default connect(mapStateToProps, actionCreators)(OrderNotes)
