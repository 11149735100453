import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Table } from 'antd'
import LinkButton from '../../../../Components/LinkButton'

export default class _Table_ extends React.PureComponent {

  static propTypes = {
    jobs: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    selectedRowKeys: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
  }

  render () {

    const {
      jobs,
      fetching,
      selectedRowKeys,
      onSelect,
      onSelectAll,
    } = this.props
    
    const columns = [

      {
        title: 'End Date',
        key: 'end_date',
        width: 100,
        render: (text, record) => moment(record.end_date).format('DD-MM-YYYY'),
      },

      {
        title: 'Job',
        key: 'job',
        width: 300,
        render: (text, job) => {

          const { order } = job;

          return `
            ${order ? order.plot_number : job.legacy_plot_number }
            ${order ? `${order.site ? order.site.name : order.site_custom} @ ${order.site ? order.site.town : order.site_custom}` : job.legacy_site}
            ${' | '}
            ${job.fitter ? job.fitter.name : 'Unallocated' }
          `
        }
      },

      {
        title: 'Comments',
        key: 'comments',
        width: 300,
        render: (text, record) => record.completed_notes,
      },
      
    ]

    const rowSelection = {
      selectedRowKeys,
      onSelect,
      onSelectAll,
    }
    
    return (

      <React.Fragment>

        <Table
          dataSource={jobs}
          columns={columns}
          rowSelection={rowSelection}
          rowKey={record => record.id}
          loading={fetching}
          size="small"
        />

      </React.Fragment>

    )
  }
}