import { createAction, handleActions } from 'redux-actions';

const loadLeftovers = createAction('SCREENS/LEFTOVERS/LOAD_LEFTOVERS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadLeftovers]: (state, action) => action.payload,
  },
  defaultState
);

export { loadLeftovers, reducer };