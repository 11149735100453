import { message } from 'antd'
import { reorderBuilderMaterialRanges } from '../../../../../HttpRequests/builderMaterialRanges'
import fetch from './fetch'
import { handleException } from '../../../../../../packages/sota'

export default (ids) => async (dispatch) => {

  const data = { ids }

  try {

    await reorderBuilderMaterialRanges({ data })

    dispatch(fetch())

    message.success('Reordered')

  } catch (error) {

    handleException(error)

    throw error

  }

}
