import { closeAreaRangeQuoteModal } from '../State/areaRangeQuoteModal'
import fetch from '../Components/CostMatrix/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeAreaRangeQuoteModal())

  if (reload) {
    dispatch(fetch())
  }

}
