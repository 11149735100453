import * as React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, Divider } from 'antd';
import { NavLink } from 'react-router-dom';

const { Item } = Breadcrumb;

export default class extends React.PureComponent {

  static propTypes = {
    site: PropTypes.object.isRequired
  }

  render() {

    const { site } = this.props;

    return (

      <React.Fragment>

        <Breadcrumb separator="»">

          <Item>
            Home
          </Item>

          <Item>
            <NavLink to="/portal/builders/">
              Builders
            </NavLink>
          </Item>

          <Item>
            <NavLink to={`/portal/builders/${site.builder.id}`}>
              {site.builder.name}
            </NavLink>
          </Item>

          <Item>
            { `${site.name}`}
          </Item>

        </Breadcrumb>

        <Divider />
        
      </React.Fragment>

    )
  };
}