import { connect } from 'react-redux'
import CustomArea from './CustomArea'

import selectMaterials from '../../Selectors/selectMaterials'

import onEditField from './Handlers/onEditField'
import onClickDimensions from './Handlers/onClickDimensions'
import onClickAction from './Handlers/onClickAction'
import onClickSupplySheetNotes from './Handlers/onClickSupplySheetNotes'

const mapStateToProps = (state) => ({
  materials: selectMaterials(state),
})

const actionCreators = {
  onEditField,
  onClickDimensions,
  onClickAction,
  onClickSupplySheetNotes,
}

export default connect(mapStateToProps, actionCreators)(CustomArea)
