import { connect } from 'react-redux'
import Table from './Table'

import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickSupplierRates from './Handlers/onClickSupplierRates'

const mapStateToProps = (state) => ({
  suppliers: state.modals.Suppliers.suppliers,
  fetching: state.modals.Suppliers.fetching,
})

const actionCreators = {
  onChangeTable,
  onClickRow,
  onClickSupplierRates,
}

export default connect(mapStateToProps, actionCreators)(Table)
