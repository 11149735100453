import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectPlotTypes = state => state.core.plotTypes;
const selectSiteId = state => formValueSelector(FORM_NAME)(state, 'site_id');

export default createSelector(
  selectPlotTypes,
  selectSiteId,
  (plotTypes, siteId) => {
    
    if (siteId) {

      return plotTypes.filter(plotType => plotType.site_id === siteId).map(plotType => ({
        label: plotType.name,
        value: plotType.id
      }))

    }

    return [];

  }
)