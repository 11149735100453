import updateItem from '../Services/updateItem'
import { closeNotesModal } from '../State/notesModal'

export default (value) => (dispatch, getState) => {

  const { guid, field } = getState().screens.CostSheet.Materials.notesModal

  dispatch(updateItem(guid, field, value))

  dispatch(closeNotesModal())

}
