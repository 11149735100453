import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as fittingRates } from './State/fittingRates'
import { reducer as fetching } from './State/fetching'
import { reducer as fittingRateModal } from './State/fittingRateModal'

export default combineReducers({
  controls,
  fittingRates,
  fetching,
  fittingRateModal,
})
