import React from 'react'
import PropTypes from 'prop-types'
import prettyBytes from 'pretty-bytes'
import { Table, Button, Popconfirm } from 'antd'
import Dropzone from 'react-dropzone'
import styles from './Component.css'

import LinkButton from '../../../../../../Components/LinkButton'

export default class extends React.PureComponent {

  static propTypes = {
    files: PropTypes.array.isRequired,
    onDrop: PropTypes.func.isRequired,
    onClickUpload: PropTypes.func.isRequired,
    onClickDownload: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
  }

  renderHeader = () => (

    <div className={styles.header}>

      <div className={styles.title}>
        Files
      </div>

      <Dropzone onDrop={this.props.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={styles.dropWrapper}>
            <input {...getInputProps()} />
            <Button icon="cloud-upload">
              Upload File
            </Button>
          </div>
        )}
      </Dropzone>

    </div>

  )
  
  render () {

    const {
      files,
      onClickDownload,
      onClickDelete,
    } = this.props

    const columns = [

      { 
        title: "Name", 
        key: "name",
        render: (text, record) => <b>{record.name}</b>,
      },

      { 
        title: "Size", 
        dataIndex: "key",
        width: 100,
        render: (text, record) => prettyBytes(record.size),
      },

      {
        title: "Actions",
        dataIndex: "actions",
        width: 200,
        render: (text, record) => (

          <div className={styles.actions}>

            <LinkButton
              type="primary"
              icon="cloud-download"
              onClick={() => onClickDownload(record.id)}
            >
              Download
            </LinkButton>

            <div style={{ width: 16 }} />

            <Popconfirm
              title="Delete this file?"
              onConfirm={() => onClickDelete(record.id)}
            >
              <LinkButton
                type="danger"
                icon="delete"
              >
                Delete
              </LinkButton>
            </Popconfirm>

          </div>
        )
      },
      
    ];

    return (

      <Table
        dataSource={files}
        columns={columns} 
        rowKey={record => record.id}
        pagination={false}
        bordered
        title={this.renderHeader}
        scroll={{ x: 1200 }}
        locale={{ emptyText: <b>There are no files for this order yet</b> }}
      />

    )
  }
}