import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Modal, Button, Form, Select } from 'antd'
import { ExposeFormToHelper } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    materialTypeId: PropTypes.number.isRequired,
    materialRanges: PropTypes.array.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render() {

    const {
      materialTypeId,
      materialRanges,
      onSubmit,
      validate,
      onClose,
    } = this.props

    const footer = [
      <Button key='cancel' onClick={() => onClose()}>Cancel</Button>,
      <Button key='save' type='primary' onClick={() => formService().submit()}>Add</Button>,
    ]

    return (

      <Modal
        visible
        title='Add Range'
        width={500}
        footer={footer}
        onCancel={() => onClose()}
      >

        <FormProvider
          validate={validate}
          onSubmit={onSubmit}
        >

          {({ handleSubmit }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              colon={false}
              autoComplete='off'
            >

              <ExposeFormToHelper helper={formService} />

              <Field name='material_range_id'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Range'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      allowClear
                      showSearch
                      optionFilterProp='children'
                    >
                      <Select.OptGroup label='Active'>
                        {materialRanges.filter(materialRange => materialRange.material_type_id === materialTypeId && !materialRange.archived_at).map(materialRange => (
                          <Select.Option key={materialRange.id} value={materialRange.id}>
                            {materialRange.name}
                            {' • '}
                            <span style={{ color: '#9e9e9e' }}>{materialRange.manufacturer.name}</span>
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label='Archived'>
                        {materialRanges.filter(materialRange => materialRange.material_type_id === materialTypeId && !!materialRange.archived_at).map(materialRange => (
                          <Select.Option key={materialRange.id} value={materialRange.id} style={{ color: '#9e9e9e' }}>
                            {`${materialRange.name} • ${materialRange.manufacturer.name}`}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>

      </Modal>

    )

  }

}
