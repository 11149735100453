import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown, Menu, Icon, Tooltip, Popconfirm } from 'antd'
import WidthSpacer from '../WidthSpacer'
import styles from './styles.css'

const Controls = props => {

  const {
    order,
    editing,
    saving,
    onClickEdit,
    onClickSave,
    onClickCancel,
    onClickDelete,
    onClickExport,
    onClickPlotType,
    onClickSite,
    onClickMaterialTypes,
    onClickManufacturers,
    onClickMaterialRanges,
    onClickSuppliers,
    onClickFittingRates,
  } = props

  const confirmationText = 'You have unsaved changes. Are you sure you want to continue?'

  return (

    <div className={styles.wrapper}>

      <div className={styles.inner}>

        <div className={styles.left}>

          {editing ? (

            <React.Fragment>

              <Button type='primary' icon='upload' loading={saving} onClick={onClickSave} className={styles.button}>
                Save
              </Button>

              <WidthSpacer type='button' />

              <Popconfirm title={confirmationText} onConfirm={onClickCancel}>
                <Button className={styles.button}>Cancel</Button>
              </Popconfirm>

            </React.Fragment>

          ) : (

            <React.Fragment>

              <Button icon='edit' onClick={onClickEdit} className={styles.button}>
                Edit
              </Button>

              {!!order.cost_sheet.id && (

                <React.Fragment>

                  <WidthSpacer type='button' />

                  <Button type='danger' icon='delete' onClick={onClickDelete} className={styles.button}>
                    Delete
                  </Button>

                  <WidthSpacer type='button' />

                  <Dropdown
                    trigger={['click']}
                    getPopupContainer={triggerNode => triggerNode.parentElement}
                    overlay={(
                      <Menu onClick={({ key }) => onClickExport(key)}>
                        <Menu.Item key='cost-sheet'>
                          <Icon type='file-pdf' />
                          Cost Sheet
                        </Menu.Item>
                        <Menu.Item key='job-sheet'>
                          <Icon type='file-pdf' />
                          Job Sheet
                        </Menu.Item>
                        <Menu.Item key='supply-sheet'>
                          <Icon type='file-pdf' />
                          Supply Sheet
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Button icon='download' className={styles.button}>
                      Export
                    </Button>
                  </Dropdown>

                </React.Fragment>

              )}

            </React.Fragment>

          )}

        </div>

        <div className={styles.right}>

          <Tooltip title='Takeoff'>
            <Button icon='form' onClick={onClickPlotType} />
          </Tooltip>

          <WidthSpacer type='button' />

          <Tooltip title='Site'>
            <Button icon='environment' onClick={onClickSite} />
          </Tooltip>

          <WidthSpacer type='button' />

          <Tooltip title='Materials'>
            <Button icon='appstore' onClick={onClickMaterialTypes} />
          </Tooltip>

          <WidthSpacer type='button' />

          <Tooltip title='Manufacturers'>
            <Button icon='setting' onClick={onClickManufacturers} />
          </Tooltip>

          <WidthSpacer type='button' />

          <Tooltip title='Ranges'>
            <Button icon='tag' onClick={onClickMaterialRanges} />
          </Tooltip>

          <WidthSpacer type='button' />

          <Tooltip title='Suppliers'>
            <Button icon='shop' onClick={onClickSuppliers} />
          </Tooltip>

          <WidthSpacer type='button' />

          <Tooltip title='Fitting Rates'>
            <Button icon='tool' onClick={onClickFittingRates} />
          </Tooltip>

        </div>

      </div>

    </div>

  )

}

Controls.propTypes = {
  order: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onClickPlotType: PropTypes.func.isRequired,
  onClickSite: PropTypes.func.isRequired,
  onClickMaterialTypes: PropTypes.func.isRequired,
  onClickManufacturers: PropTypes.func.isRequired,
  onClickMaterialRanges: PropTypes.func.isRequired,
  onClickSuppliers: PropTypes.func.isRequired,
  onClickFittingRates: PropTypes.func.isRequired,
}

export default Controls
