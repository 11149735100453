import { openSiteRateModal, closeSiteRateModal } from '../State/siteRateModal'
import fetch from '../Components/SiteRates/Services/fetch'

export default (reload = false, siteRate = undefined) => (dispatch) => {

  if (siteRate) {
    dispatch(openSiteRateModal({ id: siteRate.id }))
  } else {
    dispatch(closeSiteRateModal())
  }

  if (reload) {
    dispatch(fetch())
  }

}
