import React from 'react'
import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import { orderBy } from 'lodash'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    siteRate: PropTypes.object,
    onClickRow: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  }

  static defaultProps = {
    siteRate: null,
  }

  render() {

    const {
      siteRate,
      onClickRow,
      onRemove,
    } = this.props
    
    return (

      <Table
        dataSource={siteRate ? orderBy(siteRate.material_ranges, 'name') : []}
        rowKey='id'
        pagination={false}
        scroll={{ y: 215 }}
        className={styles.table}
        onRow={(record) => ({
          onClick: () => onClickRow(record),
        })}
      >

        <Table.Column
          key='name'
          title='Ranges'
          render={(text, record) => record.name}
        />

        <Table.Column
          key='delete'
          render={(text, record) => (
            <Icon
              type='delete'
              onClick={(e) => {
                onRemove(record.id)
                e.stopPropagation()
              }}
              style={{ color: '#FF4D4F' }}
            />
          )}
        />

      </Table>

    )

  }

}
