import axios from 'axios'

export const createAreaMaterialType = ({ data }) => axios.post(`area_material_types`, data)
export const deleteAreaMaterialType = ({ id }) => axios.delete(`area_material_types/${id}`)
export const findAreaMaterialType = ({ id, params } = {}) => axios.get(`area_material_types/${id}`, { params })
export const getAreaMaterialTypes = ({ params } = {}) => axios.get(`area_material_types`, { params })
export const updateAreaMaterialType = ({ id, data }) => axios.put(`area_material_types/${id}`, data)

export const createAreaMaterialTypes = ({ data }) => axios.post(`area_material_types/many`, data)
export const deleteAreaMaterialTypes = ({ data }) => axios.put(`area_material_types/delete_many`, data)
