import { connect } from 'react-redux'
import Table from './Table'

import selectTableRows from './Selectors/selectTableRows'

import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
  fetching: state.modals.FittingRates.fetching,
})

const actionCreators = {
  onChangeTable,
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(Table)
