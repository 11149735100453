import { formValueSelector } from 'redux-form'
import { FORM_NAME } from '../Form'

export default (state) => {

  const allowedUsers = [
    1, // Lewis
    3, // Nick
    5, // Josh
  ]

  const isAllowedUser = allowedUsers.includes(state.user.id)
  
  const { id, approved } = formValueSelector(FORM_NAME)(state, 'id', 'approved')

  if (id && !approved && isAllowedUser) {
    return true
  }

  return false

}