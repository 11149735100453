import { createAction, handleActions } from 'redux-actions';

const loadBuilder = createAction("SCREENS/BUILDER_VIEW/LOAD_BUILDER");
const clearBuilder = createAction("SCREENS/BUILDER_VIEW/CLEAR_BUILDER");

const defaultState = null;

const reducer = handleActions(
  {
    [loadBuilder]: (state, action) => action.payload,
    [clearBuilder]: () => defaultState
  },
  defaultState
);

export { loadBuilder, clearBuilder, reducer };