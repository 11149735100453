import { setControl } from '../../../State/controls'
import fetch from '../../../Services/fetch'

export default (control, value) => (dispatch) => {

  dispatch(setControl({ control, value }))

  if (control === 'search') return

  dispatch(fetch())

}
