import fetchUsers from '../../../../../Core/Services/fetchUsers'
import fetch from '../Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchUsers())

  dispatch(fetch())

}
