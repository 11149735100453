import { connect } from 'react-redux'

import dates from './Selectors/dates'
import jobs from './Selectors/jobs'
import holidays from './Selectors/holidays'
import inspectorVisits from './Selectors/inspectorVisits'
import inspections from './Selectors/inspections'

import onDragOver  from './Handlers/onDragOver'
import onDrop  from './Handlers/onDrop'

import Component from './Component'

const mapStateToProps = (state) => ({
  source: state.screens.Board.source,
  jobs: jobs(state),
  holidays: holidays(state),
  inspectorVisits: inspectorVisits(state),
  inspections: inspections(state),
  dates: dates(state),
  showSpinner: state.screens.Board.showSpinner,
})

const actionCreators = {
  onDragOver,
  onDrop,
}

export default connect(mapStateToProps, actionCreators)(Component)
