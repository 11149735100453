import { createAction, handleActions } from 'redux-actions';

const loadPlotTypes = createAction("CORE/PLOT_TYPES/LOAD_PLOT_TYPES");
const clearPlotTypes = createAction("CORE/PLOT_TYPES/CLEAR_PLOT_TYPES");

const defaultState = []

const reducer = handleActions(
  {
    [loadPlotTypes]: (state, action) => action.payload,
    [clearPlotTypes]: () => []
  },
  defaultState
);

export { loadPlotTypes, clearPlotTypes, reducer };