import moment from 'moment'
import { getMaterialTypes } from '../../HttpRequests/materialTypes'
import { loadMaterialTypes } from '../State/materialTypes'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return

    }

    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      sort: 'name',
      include: 'category_of',
    }

    const response = await getMaterialTypes({ params })

    dispatch(loadMaterialTypes(response.data.data))

  } catch (error) {

    throw error

  }

}
