export default [
  { name: 'Carpet', color: 'Black' },
  { name: 'Wood', color: 'Green' },
  { name: 'Vinyl', color: 'Blue' },
  { name: 'LVT', color: 'Purple' },
  { name: 'Latex', color: 'Gold' },
  { name: 'Communal', color: 'Orange' },
  { name: 'Remedial', color: 'Red' },
  { name: 'Wasted Day', color: 'Brown' },
  { name: 'Customer Not Home', color: 'Grey' },
];