import modalProps from '../modalProps'
import { resetControls } from '../State/controls'
import { resetFittingRates } from '../State/fittingRates'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetControls())
  dispatch(resetFittingRates())

}
