import moment from 'moment'
import { getFittingRates } from '../../HttpRequests/fittingRates'
import { loadFittingRates } from '../State/fittingRates'

let lastGetTimestamp = null

const cacheTTL = 60000

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'))

      if (now < (lastGetTimestamp + cacheTTL)) return

    }
      
    lastGetTimestamp = Number(moment().format('x'))

    const params = {
      include: 'material_type,material_types,material_ranges',
    }

    const response = await getFittingRates({ params })

    dispatch(loadFittingRates(response.data.data))

  } catch (error) {
    
    throw error

  }

}
