import { connect } from 'react-redux'
import SupplierRates from './SupplierRates'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseSupplierRateModal from './Handlers/onCloseSupplierRateModal'

const mapStateToProps = (state) => ({
  supplierRateModal: state.modals.SupplierRates.supplierRateModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseSupplierRateModal,
}

export default connect(mapStateToProps, actionCreators)(SupplierRates)
