import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Icon } from 'antd'
import classNames from 'classnames'
import styles from './styles.css'

const Custom = props => {

  const {
    item,
    onEditField,
    onClickAction,
    onClickSupplySheetNotes,
  } = props

  const [customArea, setCustomArea] = useState(undefined)
  const [customMaterial, setCustomMaterial] = useState(undefined)
  const [customDimensions, setCustomDimensions] = useState(undefined)
  const [customSquareMetres, setCustomSquareMetres] = useState(undefined)
  const [notes, setNotes] = useState(undefined)

  const actions = (
    <Menu>
      <Menu.SubMenu
        title={(
          <React.Fragment>
            <Icon type='plus' />
            Add
          </React.Fragment>
        )}
      >
        <Menu.Item key='add' onClick={() => onClickAction(item.guid, 'add')}>
          Standard Row
        </Menu.Item>
        <Menu.Item key='custom' onClick={() => onClickAction(item.guid, 'custom')}>
          Custom Row (Full)
        </Menu.Item>
        <Menu.Item key='custom-area' onClick={() => onClickAction(item.guid, 'custom-area')}>
          Custom Row (Area Only)
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key='duplicate' onClick={() => onClickAction(item.guid, 'duplicate')}>
        <Icon type='copy' />
        Duplicate
      </Menu.Item>
      <Menu.Item key='supply-sheet-notes' onClick={() => onClickSupplySheetNotes(item.guid, item.supply_sheet_notes)}>
        <Icon type='message' />
        Supply Sheet Notes
      </Menu.Item>
      <Menu.Item key='remove' onClick={() => onClickAction(item.guid, 'remove')}>
        <Icon type='delete' />
        Remove
      </Menu.Item>
    </Menu>
  )

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.customArea)}>
        <input
          value={customArea !== undefined ? customArea : item.custom_area}
          onChange={e => setCustomArea(e.target.value)}
          onBlur={e => {
            onEditField(item.guid, 'custom_area', e.target.value)
            setCustomArea(undefined)
          }}
          className={styles.input}
        />
      </div>

      <div className={classNames(styles.cell, styles.customMaterial)}>
        <input
          value={customMaterial !== undefined ? customMaterial : item.custom_material}
          onChange={e => setCustomMaterial(e.target.value)}
          onBlur={e => {
            onEditField(item.guid, 'custom_material', e.target.value)
            setCustomMaterial(undefined)
          }}
          className={styles.input}
        />
      </div>

      <div className={classNames(styles.cell, styles.customDimensions)}>
        <input
          value={customDimensions !== undefined ? customDimensions : item.custom_dimensions}
          onChange={e => setCustomDimensions(e.target.value)}
          onBlur={e => {
            onEditField(item.guid, 'custom_dimensions', e.target.value)
            setCustomDimensions(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
      </div>

      <div className={classNames(styles.cell, styles.customSquareMetres)}>
        <input
          value={customSquareMetres !== undefined ? customSquareMetres : item.custom_square_metres}
          onChange={e => setCustomSquareMetres(e.target.value)}
          onBlur={e => {
            onEditField(item.guid, 'custom_square_metres', e.target.value)
            setCustomSquareMetres(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
      </div>

      <div className={classNames(styles.cell, styles.notes)}>
        <input
          value={notes !== undefined ? notes : item.notes}
          onChange={e => setNotes(e.target.value)}
          onBlur={e => {
            onEditField(item.guid, 'notes', e.target.value)
            setNotes(undefined)
          }}
          className={styles.input}
        />
        <div className={styles.actions}>
          <Dropdown
            trigger={['click']}
            overlay={actions}
          >
            <Icon type='caret-down' />
          </Dropdown>
        </div>
      </div>

    </div>
    
  )

}

Custom.propTypes = {
  item: PropTypes.object.isRequired,
  onEditField: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
  onClickSupplySheetNotes: PropTypes.func.isRequired,
}

export default Custom
