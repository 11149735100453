import { createAction, handleActions } from 'redux-actions'

const openDimensionsModal = createAction('SCREENS/PLOT_TYPES/OPEN_DIMENSIONS_MODAL')
const closeDimensionsModal = createAction('SCREENS/PLOT_TYPES/CLOSE_DIMENSIONS_MODAL')

const defaultState = {
  show: false,
  id: null,
  areaGroupId: null,
  areaId: null,
  materialTypeId: null,
  dimensions: [],
  onSave: Function,
  onClose: Function,
}

const reducer = handleActions(
  {
    [openDimensionsModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      areaGroupId: action.payload.areaGroupId,
      areaId: action.payload.areaId,
      materialTypeId: action.payload.materialTypeId,
      dimensions: action.payload.dimensions,
      onSave: action.payload.onSave,
      onClose: action.payload.onClose,
    }),
    [closeDimensionsModal]: () => defaultState,
  },
  defaultState,
)

export { openDimensionsModal, closeDimensionsModal, reducer }
