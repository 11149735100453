import { connect } from 'react-redux'
import Component from './Component'

import onCloseEditFileModal from "./Handlers/onCloseEditFileModal"

const mapStateToProps = (state) => ({
  orderId: state.screens.OrderView.order && state.screens.OrderView.order.id,
  editFileModal: state.screens.OrderView.editFileModal,
})

const actionCreators = {
  onCloseEditFileModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
