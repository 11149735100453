import { createAction, handleActions } from 'redux-actions';

const loadAreaPlotTypeMaterial = createAction("MODALS/AREA_PLOT_TYPE_MATERIAL/LOAD_AREA_PLOT_TYPE_MATERIAL");
const clearAreaPlotTypeMaterial = createAction("MODALS/AREA_PLOT_TYPE_MATERIAL/CLEAR_AREA_PLOT_TYPE_MATERIAL");

const defaultState = null;

const reducer = handleActions(
  {
    [loadAreaPlotTypeMaterial]: (state, action) => action.payload,
    [clearAreaPlotTypeMaterial]: () => null
  },
  defaultState
);

export { loadAreaPlotTypeMaterial, clearAreaPlotTypeMaterial, reducer };