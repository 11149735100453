import { connect } from 'react-redux'
import Form from './Form'

import selectMaterialType from './Selectors/selectMaterialType'
import selectMaterialRanges from './Selectors/selectMaterialRanges'

import onChangeFormState from './Handlers/onChangeFormState'
import onSubmit from './Handlers/onSubmit'
import validate from './Handlers/validate'

const mapStateToProps = (state) => ({
  materialTypes: state.core.materialTypes,
  orderCategories: state.core.orderCategories,
  initialValues: state.modals.FittingRate.Form.formState.initialValues,
  materialType: selectMaterialType(state),
  materialRanges: selectMaterialRanges(state),
})

const actionCreators = {
  onChangeFormState,
  onSubmit,
  validate,
}

export default connect(mapStateToProps, actionCreators)(Form)
