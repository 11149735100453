import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Icon } from 'antd'
import numeral from 'numeral'

const Table = props => {

  const {
    manufacturer,
    materialRanges,
    fetching,
    onChangeTable,
    onClickRow,
    onClickRangeVariants,
  } = props

  return (

    <TableProvider
      dataSource={materialRanges}
      rowKey='id'
      size='small'
      loading={fetching}
      onChange={onChangeTable}
      onRow={record => ({
        onClick: () => onClickRow(record),
        style: { cursor: 'pointer' },
      })}
    >

      <TableProvider.Column
        key='name'
        title='Name'
        sorter
        render={(text, record) => manufacturer ? record.name : (
          <React.Fragment>
            {record.name}
            {' • '}
            <span style={{ color: '#9e9e9e' }}>{record.manufacturer.name}</span>
          </React.Fragment>
        )}
      />

      <TableProvider.Column
        key='material_type.name'
        title='Material'
        sorter
        render={(text, record) => record.material_type.name}
      />

      <TableProvider.Column
        key='square_metres'
        title='M2'
        sorter
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => record.square_metres !== null && numeral(record.square_metres).format('0,0.00')}
      />

      <TableProvider.Column
        key='spacer'
        width={20}
        render={() => false}
      />

      <TableProvider.Column
        key='material_ranges'
        render={(text, record) => (
          <a
            onClick={e => {
              onClickRangeVariants(record)
              e.stopPropagation()
            }}
          >
            <Icon type='bg-colors' />
            {` ${record.range_variants.length} Variants`}
          </a>
        )}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  manufacturer: PropTypes.object,
  materialRanges: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onClickRangeVariants: PropTypes.func.isRequired,
}

Table.defaultProps = {
  manufacturer: null,
}

export default Table
