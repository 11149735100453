import { connect } from 'react-redux'
import Table from './Table'

import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickMaterialRanges from './Handlers/onClickMaterialRanges'

const mapStateToProps = (state) => ({
  manufacturers: state.modals.Manufacturers.manufacturers,
  fetching: state.modals.Manufacturers.fetching,
})

const actionCreators = {
  onChangeTable,
  onClickRow,
  onClickMaterialRanges,
}

export default connect(mapStateToProps, actionCreators)(Table)
