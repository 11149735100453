import { message } from 'antd';
import { createFitterInvoice } from '../../../HttpRequests/fitterInvoices';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {

  try {

    const response = await createFitterInvoice({ data });

    message.success('FitterInvoice Created');

    return response.data.data;
    
  } catch (error) {
    
    handleException(error);

    return false;

  }
}
