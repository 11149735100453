import screenProps from '../screenProps'
import { findPlotType } from '../../../HttpRequests/plotTypes'
import { startFetching, finishFetching } from '../State/isFetching'
import { setPlotType } from '../State/plotType'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {
    include: [
      'site.builder',
      'site.site_rates.material_type',
    ].join(','),
  }

  dispatch(startFetching())

  try {

    const response = await findPlotType({ id, params })

    dispatch(setPlotType(response.data.data))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
