import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import RangeVariantModal from '../RangeVariant'

import Controls from './Components/Controls'
import Table from './Components/Table'

const Spacer = () => <div style={{ height: 12 }} />

const RangeVariants = props => {

  const {
    materialRange,
    rangeVariantModal,
    componentDidMount,
    componentWillUnmount,
    onCloseRangeVariantModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Modal
      title={`${materialRange.name} Variants`}
      visible
      width={700}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >

      <Controls />
      
      <Spacer />

      <Table />

      {rangeVariantModal.show && (
        <RangeVariantModal
          {...rangeVariantModal}
          onClose={onCloseRangeVariantModal}
        />
      )}

    </Modal>

  )

}

RangeVariants.propTypes = {
  materialRange: PropTypes.object.isRequired,
  rangeVariantModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseRangeVariantModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RangeVariants
