import { createAction, handleActions } from 'redux-actions'

const openBuilderRateModal = createAction('SCREENS/SITE_VIEW/OPEN_SITE_BUILDER_MODAL')
const closeBuilderRateModal = createAction('SCREENS/SITE_VIEW/CLOSE_SITE_BUILDER_MODAL')

const defaultState = {
  show: false,
  id: null,
  builderId: null,
}

const reducer = handleActions(
  {
    [openBuilderRateModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      builderId: action.payload.builderId,
    }),
    [closeBuilderRateModal]: () => defaultState,
  },
  defaultState,
)

export { openBuilderRateModal, closeBuilderRateModal, reducer }
