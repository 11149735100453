import { createAction, handleActions } from 'redux-actions';

const setOpenedHolidayId = createAction("SCREENS/BOARD/SET_OPENED_HOLIDAY_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setOpenedHolidayId]: (state, action) => action.payload
  },
  defaultState
);

export { setOpenedHolidayId, reducer };
