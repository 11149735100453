import { createSelector } from "reselect";
import moment from "moment";
import { flatten } from 'lodash';
import selectDates from './dates';

const selectHolidays = state => state.screens.Board.holidays;

export default createSelector(
  selectHolidays,
  selectDates,
  (holidays, dates) => {

    const data = flatten(
      dates.map(week => week.map(date => date.date))
    ).reduce(
      (object, date) => Object.assign(object, { [date]: [] }),
      {}
    );

    holidays.forEach(holiday => {

      for (let date = moment(holiday.start_date); date.diff(holiday.end_date, 'days') <= 0; date.add(1, 'days')) {
          
        const key = moment(date).format('YYYY-MM-DD');

        if (data[key]) data[key].push(holiday);

      }
    
    });
    
    return data;
  
  }
)