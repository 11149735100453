import { message } from 'antd'
import { reorderAreas } from '../../../../../HttpRequests/areas'
import fetch from './fetch'
import { handleException } from '../../../../../../packages/sota'

export default (ids) => async (dispatch) => {

  const data = { ids }

  try {

    await reorderAreas({ data })

    dispatch(fetch())

    message.success('Reordered')

  } catch (error) {

    handleException(error)

    throw error

  }

}
