import { connect } from 'react-redux'
import Component from './Component'

import dates from './Selectors/dates'
import summaries from './Selectors/summaries'
import holidays from './Selectors/holidays'
import inspectorVisits from './Selectors/inspectorVisits'
import inspections from './Selectors/inspections'

import onClickWeekRow from './Handlers/onClickWeekRow'

const mapStateToProps = (state) => ({
  source: state.screens.Board.source,
  summaries: summaries(state),
  holidays: holidays(state),
  inspectorVisits: inspectorVisits(state),
  inspections: inspections(state),
  dates: dates(state),
  showSpinner: state.screens.Board.showSpinner,
})

const actionCreators = {
  onClickWeekRow,
}

export default connect(mapStateToProps, actionCreators)(Component)
