import { createAction, handleActions } from 'redux-actions'

const loadSupplierRates = createAction('CORE/SUPPLIER_RATES/LOAD_SUPPLIER_RATES')
const clearSupplierRates = createAction('CORE/SUPPLIER_RATES/CLEAR_SUPPLIER_RATES')

const defaultState = []

const reducer = handleActions(
  {
    [loadSupplierRates]: (state, action) => action.payload,
    [clearSupplierRates]: () => [],
  },
  defaultState,
)

export { loadSupplierRates, clearSupplierRates, reducer }
