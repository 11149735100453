export default (values) => () => {

  const errors = {}

  if (!values.material_type_id) errors.material_type_id = true

  if (!values.material_range_id) errors.material_range_id = true

  return errors

}
