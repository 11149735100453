import { connect } from 'react-redux'
import Table from './Table'
import modalProps from '../../modalProps'

import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickRangeVariants from './Handlers/onClickRangeVariants'

const mapStateToProps = (state) => ({
  manufacturer: modalProps().manufacturer,
  materialRanges: state.modals.MaterialRanges.materialRanges,
  fetching: state.modals.MaterialRanges.fetching,
})

const actionCreators = {
  onChangeTable,
  onClickRow,
  onClickRangeVariants,
}

export default connect(mapStateToProps, actionCreators)(Table)
