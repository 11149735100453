import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.css'

const Total = props => {

  const {
    squareMetresNet,
    squareMetresGross,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.area)} />

      <div className={classNames(styles.cell, styles.net)}>
        <div className={classNames(styles.content, styles.number)}>{squareMetresNet}</div>
      </div>

      <div className={classNames(styles.cell, styles.gross)}>
        <div className={classNames(styles.content, styles.number)}>{squareMetresGross}</div>
      </div>

    </div>
    
  )

}

Total.propTypes = {
  squareMetresNet: PropTypes.string.isRequired,
  squareMetresGross: PropTypes.string.isRequired,
}

export default Total
