import moment from 'moment'
import _props from '../props'
import fetch from '../Services/fetch'

export default (props) => async (dispatch, getState) => {

  _props.set(props)
  
  const { delay } = getState().modals.CompletedJobs

  if (delay && moment().isBefore(delay)) {
    props.onClose()
    return
  }

  await dispatch(fetch())
  
}