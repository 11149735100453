import { connect } from 'react-redux'
import Controls from './Controls'

const mapStateToProps = (state) => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Controls)
