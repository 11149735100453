import { createAction, handleActions } from 'redux-actions';

const loadOrderCategories = createAction("CORE/ORDER_CATEGORIES/LOAD_ORDER_CATEGORIES");
const clearOrderCategories = createAction("CORE/ORDER_CATEGORIES/CLEAR_ORDER_CATEGORIES");

const defaultState = []

const reducer = handleActions(
  {
    [loadOrderCategories]: (state, action) => action.payload,
    [clearOrderCategories]: () => []
  },
  defaultState
);

export { loadOrderCategories, clearOrderCategories, reducer };