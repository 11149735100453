import { initialize } from 'redux-form'
import { FORM_NAME } from '../Components/Order/Form'

export default (order) => (dispatch) => {

  const initialValues = {
    ...order,
    order_type: order.remedial ? 'remedial' : 'order',
    flags: ['credit', 'custom_entry', 'private'].filter(key => order[key]),
    sage_export_skip: !!order.sage_export_skip,
  }

  dispatch(initialize(FORM_NAME, initialValues))

}
