import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as materialRanges } from './State/materialRanges'
import { reducer as fetching } from './State/fetching'
import { reducer as materialRangeModal } from './State/materialRangeModal'
import { reducer as rangeVariantsModal } from './State/rangeVariantsModal'

export default combineReducers({
  controls,
  materialRanges,
  fetching,
  materialRangeModal,
  rangeVariantsModal,
})
