import { connect } from 'react-redux'
import Manufacturers from './Manufacturers'

import componentDidMount from './Handlers/componentDidMount'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentDidMount,
}

export default connect(mapStateToProps, actionCreators)(Manufacturers)
