import { setPagination } from '../../../State/pagination';
import { setSorter, setDefaultSorter } from '../../../State/sorter';
import { setSelectedRowKeys } from '../../../State/selectedRowKeys';
import fetchOrders from '../../../Services/fetchOrders';

export default (pagination, filters, sorting) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  if (sorting.columnKey) {
    dispatch(setSorter(sorting));
  } else {
    dispatch(setDefaultSorter());
  }

  dispatch(setSelectedRowKeys([]));

  await dispatch(fetchOrders());

}