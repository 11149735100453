import { change } from 'redux-form'
import { FORM_NAME } from '../../../Form'
import resetPlotType from '../Services/resetPlotType'

export default () => (dispatch) => {

  dispatch(change(FORM_NAME, 'cost_sheet', null))

  dispatch(resetPlotType())
  
}
