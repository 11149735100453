import { message } from 'antd'

import handleException from '../../../Core/Helpers/handleException';
import { updateOrder } from '../../../HttpRequests/orders';

export default (values) => async () => {
  
  try {
    const data = {...values};

    await updateOrder({ id: values.id, data });
    
    message.success("Order Updated.");

    return true;

  }  catch(error) {
    
    handleException(error);

    return false;

  }
}