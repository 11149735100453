import { createAction, handleActions } from 'redux-actions';

const setManager = createAction("SCREENS/BOARD/SET_MANAGER");

const defaultState = null;

const reducer = handleActions(
  {
    [setManager]: (state, action) => action.payload
  },
  defaultState
);

export { setManager, reducer };
