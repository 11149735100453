import modalProps from '../modalProps'
import { syncSiteRateRangeVariants } from '../../../../../HttpRequests/siteRates'
import { setSaving } from '../State/saving'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { siteRateId, materialRange, onClose } = modalProps()
  
  const { selectedRangeVariantIds } = getState().modals.SiteRate.SiteRateRangeVariantsModal

  const data = {
    material_range_id: materialRange.id,
    range_variant_ids: selectedRangeVariantIds,
  }

  dispatch(setSaving(true))

  try {

    await syncSiteRateRangeVariants({ id: siteRateId, data })

    onClose()

  } catch (error) {

    handleException(error)
    
    throw error

  } finally {

    dispatch(setSaving(false))

  }
  
}
