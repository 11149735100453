import { get } from 'lodash'

export default (values, materialType) => () => {

  const errors = {}

  if (!values.name) errors.name = true

  if (!values.manufacturer_id) errors.manufacturer_id = true

  if (!values.material_type_id) errors.material_type_id = true

  if (get(materialType, 'usage_key') === 'square-metres') {
    if (!values.square_metres) errors.square_metres = true
  }

  return errors

}
