import { createSelector } from "reselect";
import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectInspectionSetAt = state => formValueSelector(FORM_NAME)(state, 'inspection_set_at');
const selectInspectionSetByUser = state => formValueSelector(FORM_NAME)(state, 'inspection_set_by_user');

export default createSelector(
  selectInspectionSetAt,
  selectInspectionSetByUser,
  (inspectionSetAt, inspectionSetByUser) => {

    if (inspectionSetAt && inspectionSetByUser) {
      return `Set by ${inspectionSetByUser.first_name} at ${moment(inspectionSetAt).format('HH:mm DD/MM/YYYY')}`
    }
    
    return null;
  }
)