import { message } from 'antd'
import { deleteAreas } from '../../../HttpRequests/areas'
import fetchAreas from '../Components/Areas/Services/fetch'
import fetchAreaGroups from '../Components/AreaGroups/Services/fetch'
import { resetSelectedRows } from '../Components/Areas/State/selectedRows'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { selectedRows } = getState().screens.PlotType.Areas

  const data = { ids: selectedRows }

  try {

    await deleteAreas({ data })

    message.success('Deleted Areas')

    dispatch(fetchAreas())

    dispatch(fetchAreaGroups())

    dispatch(resetSelectedRows())

  } catch (error) {

    handleException(error)

  }

}
