import { connect } from "react-redux";

import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import onCloseFitterModal from "./Handlers/onCloseFitterModal";
import didCreateFitter from "./Handlers/didCreateFitter";
import didUpdateFitter from "./Handlers/didUpdateFitter";
import didDeleteFitter from "./Handlers/didDeleteFitter";
import onCloseInspectorModal from "./Handlers/onCloseInspectorModal";
import didCreateInspector from "./Handlers/didCreateInspector";
import didUpdateInspector from "./Handlers/didUpdateInspector";
import didDeleteInspector from "./Handlers/didDeleteInspector";

const mapStateToProps = state => ({
  activeTab: state.screens.Fitters.activeTab,
  showFitterModal: state.screens.Fitters.showFitterModal,
  showInspectorModal: state.screens.Fitters.showInspectorModal,
  selectedFitterId: state.screens.Fitters.selectedFitterId,
  selectedInspectorId: state.screens.Fitters.selectedInspectorId
});

const actionCreators = {
  componentDidMount,
  onCloseFitterModal,
  didCreateFitter,
  didUpdateFitter,
  didDeleteFitter,
  onCloseInspectorModal,
  didCreateInspector,
  didUpdateInspector,
  didDeleteInspector
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
