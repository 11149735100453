import { connect } from 'react-redux'
import Component from './Component'

import onClickLater from './Handlers/onClickLater'
import onClickComplete from './Handlers/onClickComplete'

const mapStateToProps = (state) => ({
  fetching: state.modals.CompletedJobs.fetching,
  selectedRowKeys: state.modals.CompletedJobs.selectedRowKeys,
})

const actionCreators = {
  onClickLater,
  onClickComplete,
}

export default connect(mapStateToProps, actionCreators)(Component)