import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import { Icon } from 'antd'
import styles from './Module.css'

import Empty from './Empty'
import Header from './Header'
import Footer from './Footer'
import Section from './Section'

const Module = (props) => {

  const {
    children,
    className,
    style,
    dropzone,
    dropzoneProps,
    dropzoneIcon,
    dropzoneTitle,
    dropzoneText,
  } = props

  if (dropzone) {

    const Prompt = () => (

      <div className={classNames(styles.dropzone)}>

        <div className={styles.dropzoneBorder}>

          <span className={styles.dropzoneIcon}>

            {typeof dropzoneIcon === 'string' && <Icon type={dropzoneIcon} />}

            {typeof dropzoneIcon === 'object' && dropzoneIcon}

          </span>

          <div className={styles.dropzoneTitle}>
            {dropzoneTitle}
          </div>

          <div className={styles.dropzoneText}>
            {dropzoneText}
          </div>

        </div>

      </div>
      
    )

    return (

      <Dropzone {...dropzoneProps}>

        {({ getRootProps, getInputProps, open, isDragActive }) => (

          <div {...getRootProps()} onClick={() => null} className={classNames(styles.card, className)} {...{ style }}>

            <input {...getInputProps()} />

            {typeof children === 'function' && children({ openFileSelector: open })}

            {typeof children === 'object' && children}

            {isDragActive && <Prompt />}

          </div>

        )}

      </Dropzone>

    )
  }
  
  return (

    <div className={classNames(styles.card, className)} {...{ style }}>

      {typeof children === 'function' && children()}

      {typeof children === 'object' && children}

    </div>
  )
}

Module.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  dropzone: PropTypes.bool,
  dropzoneProps: PropTypes.object,
  dropzoneIcon: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  dropzoneTitle: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  dropzoneText: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
}

Module.defaultProps = {
  className: undefined,
  style: undefined,
  dropzone: false,
  dropzoneProps: undefined,
  dropzoneIcon: 'upload',
  dropzoneTitle: 'Files',
  dropzoneText: 'Drop your files here to upload them',
}

Module.Empty = Empty
Module.Header = Header
Module.Footer = Footer
Module.Section = Section

export default Module