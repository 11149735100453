import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'

export default () => (dispatch, getState) => {

  const { id } = modalProps()

  if (!id) return

  const { siteRate } = getState().modals.SiteRate

  const initialValues = {
    title: siteRate.title,
    subtitle: siteRate.subtitle,
    material_type_id: siteRate.material_type_id,
    material_range_id: siteRate.material_range_id,
    rate: siteRate.rate,
    group_rate: siteRate.group_rate,
  }

  defer(() => formService().initialize(initialValues))

}
