import { createAction, handleActions } from 'redux-actions'

const setMaterialRanges = createAction('MODALS/MATERIAL_RANGES/SET_MATERIAL_RANGES')
const resetMaterialRanges = createAction('MODALS/MATERIAL_RANGES/RESET_MATERIAL_RANGES')

const defaultState = []

const reducer = handleActions(
  {
    [setMaterialRanges]: (state, { payload }) => payload,
    [resetMaterialRanges]: () => defaultState,
  },
  defaultState,
)

export { setMaterialRanges, resetMaterialRanges, reducer }
