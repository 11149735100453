import { message } from 'antd';
import { getProps } from '../props';
import { unarchiveSite } from '../../../HttpRequests/sites';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { siteId, onClose, didUnarchive } = getProps();

    await unarchiveSite({ id: siteId });
    
    message.success("Site Unarchived");

    onClose();
    
    if (didUnarchive) didUnarchive();

  } catch (error) {
    
    handleException(error);

  }
}