import { message } from 'antd';
import { getProps } from '../props';
import { archiveSite } from '../../../HttpRequests/sites';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { siteId, onClose, didArchive } = getProps();

    await archiveSite({ id: siteId });
    
    message.success("Site Archived");

    onClose();
    
    if (didArchive) didArchive();

  } catch (error) {
    
    handleException(error);

  }
}