import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from 'antd'
import styles from './Component.css'

export default class _LinkButton_ extends React.PureComponent {

  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(['default','primary','danger']),
    icon: PropTypes.string,
    iconRight: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
  }

  static defaultProps = {
    children: undefined,
    type: 'default',
    icon: undefined,
    iconRight: undefined,
    onClick: undefined,
    disabled: false,
    style: undefined,
    className: undefined,
  }

  render () {

    const {
      children,
      type,
      icon,
      iconRight,
      onClick,
      disabled,
      style,
      className,
    } = this.props
    
    return (

      <span onClick={disabled ? undefined : onClick} className={classnames(styles.button, styles[disabled ? 'disabled' : type], className)} style={style}>

        {icon && <Icon type={icon} style={{ marginRight: children ? 8 : undefined }} />}

        {children}

        {iconRight && <Icon type={iconRight} style={{ marginLeft: children ? 8 : undefined }} />}

      </span>

    )
  }
}