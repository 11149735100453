import { message } from 'antd'
import selectAreaSummary from '../Selectors/selectAreaSummary'
import { updateCostSheet } from '../../../HttpRequests/costSheets'
import fetch from './fetch'
import { setSaving } from '../State/saving'
import { handleException } from '../../../../packages/sota'

const getSugTag = ({ materialType }) => {
  const tags = ['Carpet', 'Communal', 'LVT', 'Latex', 'Remedial', 'Vinyl', 'Wasted Day', 'Wood', 'Customer Not Home']
  if (tags.includes(materialType.name)) return materialType.name
  return null
}

export default () => async (dispatch, getState) => {

  const state = getState()

  const { order } = state.screens.CostSheet

  const { cost_sheet: costSheet } = order

  const { materialTypes } = selectAreaSummary(state)
  
  const data = {
    ordered_date: costSheet.ordered_date ? costSheet.ordered_date.format('YYYY-MM-DD') : null, // binding order data here for ease of use
    delivery_date: costSheet.delivery_date ? costSheet.delivery_date.format('YYYY-MM-DD') : null, // binding order data here for ease of use
    material_type_id: costSheet.material_type_id,
    area_group_id: costSheet.area_group_id,
    supplier_ref: costSheet.supplier_ref,
    quote_areas: costSheet.quote_areas,
    anc_cost: costSheet.anc_cost,
    rebate_cost: costSheet.rebate_cost,
    travel_cost: costSheet.travel_cost,
    sug_tag: materialTypes.length === 1 ? getSugTag(materialTypes[0]) : null,
    sug_metres: materialTypes.reduce((carry, { squareMetres }) => carry + squareMetres, 0),
    notes: costSheet.notes,
    cost_sheet_areas: costSheet.cost_sheet_areas.map(costSheetArea => ({
      id: costSheetArea.id,
      type: costSheetArea.type,
      area_id: costSheetArea.area_id,
      custom_area: costSheetArea.custom_area,
      material_type_id: costSheetArea.material_type_id,
      material_range_id: costSheetArea.material_range_id,
      range_variant_id: costSheetArea.range_variant_id,
      custom_material: costSheetArea.custom_material,
      dimensions: costSheetArea.dimensions,
      custom_dimensions: costSheetArea.custom_dimensions,
      square_metres: costSheetArea.square_metres,
      custom_square_metres: costSheetArea.custom_square_metres,
      notes: costSheetArea.notes,
      supply_sheet_notes: costSheetArea.supply_sheet_notes,
      guid: costSheetArea.guid,
    })),
    cost_sheet_materials: costSheet.cost_sheet_materials.map(costSheetMaterial => ({
      id: costSheetMaterial.id,
      material_type_id: costSheetMaterial.material_type_id,
      material_range_id: costSheetMaterial.material_range_id,
      range_variant_id: costSheetMaterial.range_variant_id,
      supplier_id: costSheetMaterial.supplier_id,
      dimensions: costSheetMaterial.dimensions,
      square_metres: costSheetMaterial.square_metres,
      quantity: costSheetMaterial.quantity,
      cost: costSheetMaterial.cost,
      on_job_sheet: costSheetMaterial.on_job_sheet,
      supply_sheet_notes: costSheetMaterial.supply_sheet_notes,
      guid: costSheetMaterial.guid,
    })),
    cost_sheet_expenses: costSheet.cost_sheet_expenses.map(costSheetExpense => ({
      id: costSheetExpense.id,
      fitting_rate_id: costSheetExpense.fitting_rate_id,
      cost: costSheetExpense.cost,
      guid: costSheetExpense.guid,
    })),
    cost_sheet_quotes: costSheet.cost_sheet_quotes.map(costSheetQuote => ({
      id: costSheetQuote.id,
      area_id: costSheetQuote.area_id,
      material_type_id: costSheetQuote.material_type_id,
      price: costSheetQuote.price,
      guid: costSheetQuote.guid,
    })),
  }

  dispatch(setSaving(true))

  try {

    await updateCostSheet({ orderId: order.id, data })

    message.success('Updated Cost Sheet')

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setSaving(false))

  }

}
