import { closeManufacturerModal } from '../State/manufacturerModal'
import fetch from '../Components/Manufacturers/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeManufacturerModal())

  if (reload) {
    dispatch(fetch())
  }

}
