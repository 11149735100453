import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Select, Input, InputNumber } from 'antd'
import { find, get } from 'lodash'
import { ExposeFormToHelper } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    isNew: PropTypes.bool.isRequired,
    materialTypes: PropTypes.array.isRequired,
    materialRanges: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      isNew,
      materialTypes,
      materialRanges,
      onSubmit,
      validate,
    } = this.props

    const getMaterialType = (materialTypeId) => find(materialTypes, row => row.id === materialTypeId)

    return (

      <FormProvider
        validate={values => validate(values, materialTypes)}
        onSubmit={onSubmit}
      >

        {({ handleSubmit, values, form }) => {

          const materialType = getMaterialType(values.material_type_id)

          return (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              colon={false}
              autoComplete='off'
            >

              <ExposeFormToHelper helper={formService} />

              <Field name='title'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Title'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Input
                      {...input}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name='subtitle'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Subtitle'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Input
                      {...input}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name='material_type_id'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Material'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      onChange={value => {
                        input.onChange(value)
                        form.change('material_range_id', null)
                      }}
                      allowClear
                      showSearch
                      optionFilterProp='children'
                      disabled={!isNew}
                    >
                      {materialTypes.map($materialType => (
                        <Select.Option key={$materialType.id} value={$materialType.id}>
                          {$materialType.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Field>

              {get(materialType, 'usage_key') === 'square-metres' && (

                <Field name='material_range_id'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Range'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                      >
                        <Select.OptGroup label='Active'>
                          {materialRanges.filter(materialRange => materialRange.material_type_id === get(materialType, 'id') && !materialRange.archived_at).map(materialRange => (
                            <Select.Option key={materialRange.id} value={materialRange.id}>
                              {materialRange.name}
                              {' • '}
                              <span style={{ color: '#9e9e9e' }}>{materialRange.manufacturer.name}</span>
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {materialRanges.filter(materialRange => materialRange.material_type_id === get(materialType, 'id') && !!materialRange.archived_at).map(materialRange => (
                            <Select.Option key={materialRange.id} value={materialRange.id} style={{ color: '#9e9e9e' }}>
                              {`${materialRange.name} • ${materialRange.manufacturer.name}`}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              )}

              <Field name='rate'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Rate'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <InputNumber
                      {...input}
                      min={0}
                      step={1}
                      precision={2}
                      style={{ width: '100px' }}
                    />
                  </Form.Item>
                )}
              </Field>

              {get(materialType, 'usage_key') === 'dimensions' && (

                <Field name='group_rate'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='TO Rate'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '100px' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              )}

            </Form>

          )

        }}

      </FormProvider>

    )

  }

}
