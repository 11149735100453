import { createAction, handleActions } from 'redux-actions'

const loadJobs = createAction('MODALS/COMPLETED_JOBS/LOAD_JOBS')
const clearJobs = createAction('MODALS/COMPLETED_JOBS/CLEAR_JOBS')

const defaultState = []

const reducer = handleActions(
  {
    [loadJobs]: (state, action) => action.payload,
    [clearJobs]: () => [],
  },
  defaultState
)

export { loadJobs, clearJobs, reducer }