import { combineReducers } from 'redux'

import { reducer as areas } from './State/areas'
import { reducer as isFetching } from './State/isFetching'
import { reducer as selectedRows } from './State/selectedRows'

export default combineReducers({
  areas,
  isFetching,
  selectedRows,
})
