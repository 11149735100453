import { setAreas } from '../State/areas'
import reorder from '../Services/reorder'

export default (tableRows) => (dispatch) => {

  dispatch(setAreas(tableRows.map(row => row.area)))

  dispatch(reorder(tableRows.map(row => row.id)))

}
