import handleException from '../../../Core/Helpers/handleException';
import { getOrders } from '../../../HttpRequests/orders';
import { setFetching } from '../State/fetching';
import { loadOrders } from '../State/orders';
import { setPagination } from '../State/pagination';
import fetchPlotNotes from './fetchPlotNotes';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm, filters, sorter } = getState().screens.Orders;

  try {

    const params = {};
    
    params.include = ['order_category','plot_type','site','builder'].join();
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.type === 'orders') {
      params['filter[remedial]'] = 0;
    }

    if (filters.type === 'remedials') {
      params['filter[remedial]'] = 1;
    }

    if (filters.type === 'credits') {
      params['filter[credit]'] = 1;
    } else {
      params['filter[credit]'] = 0;
    }

    if (filters.status) {
      params['filter[status]'] = filters.status;
    }

    if (filters.manager_id) {
      params['filter[site_manager_id]'] = filters.manager_id;
    }

    if (filters.on_hold_reason_key) {
      if (filters.on_hold_reason_key === 'ANY') {
        params['filter[on_hold]'] = 1
      } else {
        params['filter[on_hold_reason_key]'] = filters.on_hold_reason_key;
      }
    }

    if (filters.site_survey_added !== undefined) {
      params['filter[site_survey_added]'] = filters.site_survey_added;
    }

    if (filters.photo_added !== undefined) {
      params['filter[photo_added]'] = filters.photo_added;
    }

    if (filters.questionnaire_status !== undefined) {
      params['filter[questionnaire_status]'] = filters.questionnaire_status;
    }

    if (filters.credit_status !== undefined) {
      params['filter[credit_status]'] = filters.credit_status;
    }
  
    if (sorter.columnKey) {
      params.sort = `${sorter.order === 'descend' ? '-' : '' }${sorter.columnKey}`;
    }

    dispatch(setFetching(true));

    const response = await getOrders({ params });
    const orders = response.data;

    dispatch(loadOrders(orders.data));
    
    dispatch(setPagination({
      total: orders.pagination.total,
      pageSize: orders.pagination.per_page,
      current: orders.pagination.current_page
    }));

    dispatch(setFetching(false))

    dispatch(fetchPlotNotes())

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}