import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.css'

const Totals = props => {

  const {
    totals,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.col1)} />

      <div className={classNames(styles.cell, styles.col2)} />

      <div className={classNames(styles.cell, styles.col3)} />

      <div className={classNames(styles.cell, styles.total)}>
        <div className={classNames(styles.content, styles.number)}>
          {totals.map(({ key, total }, index) => (
            <React.Fragment key={key}>
              {total}
              {(index + 1) !== totals.length && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={classNames(styles.cell, styles.col5)} />

    </div>
    
  )

}

Totals.propTypes = {
  totals: PropTypes.array.isRequired,
}

export default Totals
