import { createAction, handleActions } from 'redux-actions';

const setSelectedFitterId = createAction("SCREENS/FITTERS/SET_SELECTED_FITTER_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedFitterId]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedFitterId, reducer };
