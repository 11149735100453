import { connect } from 'react-redux'
import Footer from './Footer'

import onChangePagination from './Handlers/onChangePagination'

const mapStateToProps = (state) => ({
  materialType: state.screens.BuilderView.BuilderMaterialRanges.materialType,
  pagination: state.screens.BuilderView.BuilderMaterialRanges.pagination,
})

const actionCreators = {
  onChangePagination,
}

export default connect(mapStateToProps, actionCreators)(Footer)
