import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import Table from "./Components/Table";
import InspectorsTable from "./Components/InspectorsTable";
import FitterModal from "../../Modals/Fitter";
import InspectorModal from "../../Modals/Inspector";

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    activeTab: PropTypes.string.isRequired,
    showFitterModal: PropTypes.bool.isRequired,
    showInspectorModal: PropTypes.bool.isRequired,
    selectedFitterId: PropTypes.number,
    selectedInspectorId: PropTypes.number,
    onCloseFitterModal: PropTypes.func.isRequired,
    didCreateFitter: PropTypes.func.isRequired,
    didUpdateFitter: PropTypes.func.isRequired,
    didDeleteFitter: PropTypes.func.isRequired,
    onCloseInspectorModal: PropTypes.func.isRequired,
    didCreateInspector: PropTypes.func.isRequired,
    didUpdateInspector: PropTypes.func.isRequired,
    didDeleteInspector: PropTypes.func.isRequired
  };

  static defaultProps = {
    selectedFitterId: null,
    selectedInspectorId: null
  };

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      activeTab,
      showFitterModal,
      selectedFitterId,
      showInspectorModal,
      selectedInspectorId,
      onCloseFitterModal,
      didCreateFitter,
      didUpdateFitter,
      didDeleteFitter,
      onCloseInspectorModal,
      didCreateInspector,
      didUpdateInspector,
      didDeleteInspector
    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header />

        {activeTab === 'fitters' && (
          <Table />
        )}

        {activeTab === 'inspectors' && (
          <InspectorsTable />
        )}

        {showFitterModal && (
          <FitterModal
            fitterId={selectedFitterId}
            onClose={onCloseFitterModal}
            didCreate={didCreateFitter}
            didUpdate={didUpdateFitter}
            didDelete={didDeleteFitter}
          />
        )}

        {showInspectorModal && (
          <InspectorModal
            inspectorId={selectedInspectorId}
            onClose={onCloseInspectorModal}
            didCreate={didCreateInspector}
            didUpdate={didUpdateInspector}
            didDelete={didDeleteInspector}
          />
        )}

      </Screen>

    )
  }
}
