import { createAction, handleActions } from 'redux-actions';

const setFromOrderOpen = createAction("SCREENS/ORDER_VIEW/SET_FROM_ORDER_OPEN");

const defaultState = false;

const reducer = handleActions(
  {
    [setFromOrderOpen]: (state, action) => action.payload
  },
  defaultState
);

export { setFromOrderOpen, reducer };
