import { message } from 'antd';

import { updateFitter } from '../../../HttpRequests/fitters';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {
  
  try {

    const response = await updateFitter({ id: data.id, data });
    
    message.success("Fitter Updated");

    return response.data.data;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}