import { connect } from 'react-redux'
import AreaGroup from './AreaGroup'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  onSubmit,
  onDelete,
}

export default connect(mapStateToProps, actionCreators)(AreaGroup)
