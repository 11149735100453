import { getProps } from '../../../props'
import { getSiteCostMatrix } from '../../../../../HttpRequests/sites'
import { startFetching, finishFetching } from '../State/isFetching'
import { setData } from '../State/data'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { id } = getProps().match.params

  const { costMatrixSearch: search, costMatrixFormat: format } = getState().screens.SiteView

  const params = {
    search,
    format: format !== 'All' ? format.trim() : undefined,
  }

  dispatch(startFetching())

  try {

    const response = await getSiteCostMatrix({ id, params })
    
    const { columns, plot_types: plotTypes } = response.data.data

    dispatch(setData({ columns, plotTypes, fetched: true }))

    dispatch(finishFetching())

  } catch (error) {

    dispatch(finishFetching())

    handleException(error)

  }

}
