import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteFittingRate } from '../../../HttpRequests/fittingRates'
import fetchFittingRates from '../../../Core/Services/fetchFittingRates'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { fittingRate } = modalProps()

  try {

    await deleteFittingRate({ id: fittingRate.id })

    message.success('Deleted Fitting Rate')

    modalProps().onClose(true)

    dispatch(fetchFittingRates({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
