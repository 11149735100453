import { closeSiteMaterialRangeModal } from '../State/siteMaterialRangeModal'
import fetch from '../Components/SiteMaterialRanges/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeSiteMaterialRangeModal())

  if (reload) {
    dispatch(fetch())
  }

}
