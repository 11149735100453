import { createSelector } from 'reselect'

const selectUser = state => state.user

// 1: Lewis Bird
// 3: Nick Hutson
// 4: Russell Freedman
// 5: Joshua Freedman

export default createSelector(
  selectUser,
  (user) => [1, 3, 4, 5].indexOf(user.id) !== -1,
)
