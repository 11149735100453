import { combineReducers } from 'redux';

import Board from './Board/reducer';
import Builders from './Builders/reducer';
import BuilderView from './BuilderView/reducer';
import SiteView from './SiteView/reducer';
import PlotType from './PlotType/reducer';
import Materials from './Materials/reducer';
import Suppliers from './Suppliers/reducer';
import Orders from './_Orders/reducer';
import OrderView from './OrderView/reducer';
import CostSheet from './CostSheet/reducer';
import Fitters from './Fitters/reducer';
import Leftovers from './Leftovers/reducer';

export default combineReducers({
  Board,
  Builders,
  BuilderView,
  SiteView,
  PlotType,
  Materials,
  Suppliers,
  Orders,
  OrderView,
  CostSheet,
  Fitters,
  Leftovers
})
