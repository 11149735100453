export default () => (dispatch, getState) => {

  const state = getState()

  const { token } = state

  const { dateRange } = state.screens.Board

  const params = {}

  params['filter[date_between]'] = dateRange.join()

  let url = `/api/jobs/completed/export?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key] !== undefined) url += `&${key}=${params[key]}`
  })

  window.location = url

}
