import { connect } from 'react-redux'
import Component from './Component'

import onClick from "./Handlers/onClick"
import onDragStart from "./Handlers/onDragStart"

const mapStateToProps = () => ({})

const actionCreators = {
  onClick,
  onDragStart,
}

export default connect(mapStateToProps, actionCreators)(Component)
