import { setProps } from '../props';
import fetchOrder from '../Services/fetchOrder';
// import fetchLeftovers from '../Services/fetchLeftovers';
import fetchOrderCategories from '../../../Core/Services/fetchOrderCategories';
import fetchOrderNumbers from '../../../Core/Services/fetchOrderNumbers';
import fetchBuilders from '../../../Core/Services/fetchBuilders';
import fetchSites from '../../../Core/Services/fetchSites';
import fetchPlotTypes from '../../../Core/Services/fetchPlotTypes';
import fetchMaterialTypes from '../../../Core/Services/fetchMaterialTypes';
import fetchMaterialRanges from '../../../Core/Services/fetchMaterialRanges';
import fetchRangeVariants from '../../../Core/Services/fetchRangeVariants';
import fetchSuppliers from '../../../Core/Services/fetchSuppliers';
// import fetchMaterials from '../../../Core/Services/fetchMaterials';
import fetchOnHoldReasons from '../../../Core/Services/fetchOnHoldReasons';

export default (props) => async (dispatch) => {
    
  setProps(props);

  await dispatch(fetchOrderCategories());
  await dispatch(fetchOrderNumbers());
  await dispatch(fetchBuilders());
  await dispatch(fetchSites());
  await dispatch(fetchPlotTypes());
  await dispatch(fetchMaterialTypes());
  await dispatch(fetchMaterialRanges());
  await dispatch(fetchRangeVariants());
  await dispatch(fetchSuppliers());
  // await dispatch(fetchMaterials());
  await dispatch(fetchOnHoldReasons());

  // await dispatch(fetchLeftovers());
  await dispatch(fetchOrder());
    
}