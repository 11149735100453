import React from 'react';
import { Icon, Modal } from 'antd';
import deleteSite from '../../../Services/delete';

export default () => (dispatch) => {

  Modal.confirm({
    title: 'Delete Site?',
    content: 'This action cannot be undone.',
    icon: <Icon type="delete" style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteSite())
  })

}