import { change } from 'redux-form'
import { FORM_NAME } from '../Form'
import { closeAvailableFittersModal } from '../State/availableFittersModal'
import logFitterChange from '../Services/logFitterChange'

export default (fitter) => (dispatch) => {
  
  dispatch(closeAvailableFittersModal())

  if (fitter) {

    dispatch(change(FORM_NAME, 'fitter_id', fitter.id))
    
    dispatch(logFitterChange())

  }
  
}