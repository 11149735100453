import React from 'react'
import logo from '../../../../resources/images/logo.png'

const Banner = () => (

  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    
    <div style={{ fontSize: 28, fontWeight: 500 }}>Cost Sheet</div>

    <img src={logo} height={55} />

  </div>

)

export default Banner
