import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Header.css'

const Header = (props) => {

  const {
    className,
    style,
    title,
    titleClassName,
    titleStyle,
    renderLeft,
    renderRight,
  } = props
  
  return (

    <div className={classNames(styles.header, className)} style={style}>

      {title && (
        <div className={classNames(styles.title, titleClassName)} style={titleStyle}>
          {title}
        </div>
      )}

      {renderLeft && (
        <div className={styles.left}>
          {renderLeft()}
        </div>
      )}

      {renderRight && (
        <div className={styles.right}>
          {renderRight()}
        </div>
      )}

    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  titleClassName: PropTypes.string,
  titleStyle: PropTypes.object,
  renderLeft: PropTypes.func,
  renderRight: PropTypes.func,
}

Header.defaultProps = {
  className: undefined,
  style: undefined,
  title: undefined,
  titleClassName: undefined,
  titleStyle: undefined,
  renderLeft: undefined,
  renderRight: undefined,
}

export default Header