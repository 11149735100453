import modalProps from '../modalProps'
import { getMaterialRanges } from '../../../HttpRequests/materialRanges'
import { setMaterialRanges } from '../State/materialRanges'
import { setFetching } from '../State/fetching'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { manufacturer } = modalProps()

  const { activeTab, search, sort } = getState().modals.MaterialRanges.controls

  const params = {
    search,
    sort: sort || 'name',
    include: 'manufacturer,material_type,categories,siblings,range_variants',
  }

  if (manufacturer) {
    params['filter[manufacturer_id]'] = manufacturer.id
  }

  if (activeTab === 'active') {
    params['filter[active_only]'] = 1
  }

  if (activeTab === 'archived') {
    params['filter[archived_only]'] = 1
  }

  dispatch(setFetching(true))

  try {

    const response = await getMaterialRanges({ params })

    dispatch(setMaterialRanges(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
