import { createAction, handleActions } from 'redux-actions'

const openAreaRangeQuoteModal = createAction('SCREENS/SITE_VIEW/OPEN_AREA_RANGE_QUOTE_MODAL')
const closeAreaRangeQuoteModal = createAction('SCREENS/SITE_VIEW/CLOSE_AREA_RANGE_QUOTE_MODAL')

const defaultState = {
  show: false,
  id: null,
  squareMetres: null,
  siteRate: null,
  type: null,
  areaOrGroupId: null,
}

const reducer = handleActions(
  {
    [openAreaRangeQuoteModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: action.payload.id,
      squareMetres: action.payload.squareMetres,
      siteRate: action.payload.siteRate,
      type: action.payload.type,
      areaOrGroupId: action.payload.areaOrGroupId,
    }),
    [closeAreaRangeQuoteModal]: () => defaultState,
  },
  defaultState,
)

export { openAreaRangeQuoteModal, closeAreaRangeQuoteModal, reducer }
