import { initialize } from 'redux-form';
import { findInspector } from '../../../HttpRequests/inspectors';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { inspectorId } = getProps();
  
  if (inspectorId) {

    const response = await findInspector({ id: inspectorId });

    const inspector = response.data.data;

    dispatch(initialize(FORM_NAME, inspector))
    
  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}