import { createAction, handleActions } from 'redux-actions';

const loadFitters = createAction('SCREENS/FITTERS/LOAD_FITTERS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadFitters]: (state, action) => action.payload,
  },
  defaultState
);

export { loadFitters, reducer };