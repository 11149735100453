import { find, get } from 'lodash'
import guessDimensions from '../Utils/guessDimensions'
import guessSquareMetres from '../Utils/guessSquareMetres'
import { setCostSheetField } from '../../../State/order'

const modValues = (costSheetArea, field, value, state) => {

  let $costSheetArea = { ...costSheetArea, [field]: value }

  if (field === 'area') {
    const { areas } = state.screens.CostSheet.order.plot_type
    const area = find(areas, row => row.id === value[0])
    $costSheetArea.area_id = area.id
    $costSheetArea.area = area
    $costSheetArea = guessDimensions($costSheetArea, state)
    $costSheetArea = guessSquareMetres($costSheetArea)
  }

  if (field === 'material') {
    const { materialTypes, materialRanges } = state.core
    const materialType = find(materialTypes, row => row.id === value[0])
    const materialRange = find(materialRanges, row => row.id === value[2])
    const rangeVariant = find(materialRange.range_variants, row => row.id === value[3])
    $costSheetArea.material_type_id = materialType.id
    $costSheetArea.material_range_id = materialRange.id
    $costSheetArea.range_variant_id = get(rangeVariant, 'id')
    $costSheetArea.material_type = materialType
    $costSheetArea.material_range = materialRange
    $costSheetArea.range_variant = rangeVariant
    $costSheetArea = guessDimensions($costSheetArea, state)
    $costSheetArea = guessSquareMetres($costSheetArea)
  }

  if (field === 'dimensions') {
    $costSheetArea = guessSquareMetres($costSheetArea)
  }

  return $costSheetArea

}

export default (guid, field, value) => (dispatch, getState) => {

  const state = getState()

  const { cost_sheet_areas: costSheetAreas } = state.screens.CostSheet.order.cost_sheet

  const $costSheetAreas = []

  costSheetAreas.forEach(costSheetArea => {
    if (costSheetArea.guid !== guid) {
      $costSheetAreas.push({ ...costSheetArea })
    } else {
      const $costSheetArea = modValues(costSheetArea, field, value, state)
      $costSheetAreas.push($costSheetArea)
    }
  })

  dispatch(setCostSheetField({ field: 'cost_sheet_areas', value: $costSheetAreas }))

}
