import React from 'react';
import PropTypes from 'prop-types';
import { Select, Radio } from 'antd';

export default class extends React.PureComponent {

  static propTypes = {
    filters: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    onHoldReasons: PropTypes.array.isRequired,
    onChangeFilter: PropTypes.func.isRequired
  }

  render () {

    const {
      filters,
      users,
      onHoldReasons,
      onChangeFilter
    } = this.props;

    const defaultSelectProps = {
      style: { width: 210 },
      showArrow: true,
      showSearch: true,
      allowClear: true,
      notFoundContent: null,
      filterOption: (value, option) => option.props.children.toLowerCase().includes(value.toLowerCase())
    };

    const typeOptions = [
      { value: 'all', label: 'All' },
      { value: 'orders', label: 'Orders' },
      { value: 'remedials', label: 'Remedials' },
      { value: 'credits', label: 'Credits' }
    ];

    const statusOptions = [
      { value: '6', label: 'Not Called Off' },
      { value: '1', label: 'Not Fitted' },
      { value: '2', label: 'For Invoicing' },
      { value: '3', label: 'Invoiced' },
      { value: '4', label: 'Closed' },
      { value: '5', label: 'Cancelled' }
    ];

    const creditStatusOptions = [
      { value: 'To Be Applied For', label: 'To Be Applied For' },
      { value: 'Awaiting Credit', label: 'Awaiting Credit' },
      { value: 'Credit Received', label: 'Credit Received' },
    ];

    const managers = users.filter(user => user.site_count > 0)

    if (filters.type === 'credits') return (

      <div style={{ display: 'flex', marginBottom: 15 }}>

        <Radio.Group
          value={filters.type}
          onChange={(event) => onChangeFilter('type', event.target.value)}
        >
          {typeOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.value}
              key={option.value}
            >
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>

        <div style={{ width: 10 }} />

        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by Status"
          value={filters.credit_status}
          onChange={(value) => onChangeFilter('credit_status', value)}
        >

          {creditStatusOptions.map(creditStatus => (
            <Select.Option key={creditStatus.value}>
              {creditStatus.label}
            </Select.Option>
          ))}

        </Select>

      </div>

    )

    return (

      <div style={{ display: 'flex', marginBottom: 15 }}>
          

        <Radio.Group
          value={filters.type}
          onChange={(event) => onChangeFilter('type', event.target.value)}
        >
          {typeOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.value}
              key={option.value}
            >
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>


        <div style={{ width: 10 }} />


        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by Status"
          value={filters.status}
          onChange={(value) => onChangeFilter('status', value)}
        >
          
          {statusOptions.map(status => (
            <Select.Option key={status.value}>
              {status.label}
            </Select.Option>
          ))}

        </Select>


        <div style={{ width: 10 }} />


        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by Manager"
          value={filters.manager_id}
          onChange={(value) => onChangeFilter('manager_id', value)}
        >
          
          {managers.map(user => (
            <Select.Option key={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </Select.Option>
          ))}

        </Select>


        <div style={{ width: 10 }} />


        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by On Hold"
          value={filters.on_hold_reason_key}
          onChange={(value) => onChangeFilter('on_hold_reason_key', value)}
        >

          <Select.Option key="ANY">
            On Hold (Any Reason)
          </Select.Option>

          {onHoldReasons.map(reason => (
            <Select.Option key={reason.key}>
              {reason.name}
            </Select.Option>
          ))}

        </Select>


        <div style={{ width: 10 }} />


        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Survey Added?"
          value={filters.site_survey_added}
          style={{ width: 160 }}
          onChange={(value) => onChangeFilter('site_survey_added', value)}
        >

          <Select.Option key='yes' value={1}>Survey Added</Select.Option>
          <Select.Option key='no' value={0}>No Survey Added</Select.Option>

        </Select>


        <div style={{ width: 10 }} />


        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Photo Added?"
          value={filters.photo_added}
          style={{ width: 160 }}
          onChange={(value) => onChangeFilter('photo_added', value)}
        >

          <Select.Option key='yes' value={1}>Photo Added</Select.Option>
          <Select.Option key='no' value={0}>No Photo Added</Select.Option>

        </Select>


        {filters.type === 'remedials' && (

          <React.Fragment>

            <div style={{ width: 10 }} />

            <Select
              {...Object.assign({}, defaultSelectProps)}
              placeholder="Questionnaire Status"
              value={filters.questionnaire_status}
              onChange={(value) => onChangeFilter('questionnaire_status', value)}
            >

              <Select.Option key='not_sent' value='not_sent'>Not Sent</Select.Option>
              <Select.Option key='awaiting_response' value='awaiting_response'>Awaiting Response</Select.Option>
              <Select.Option key='awaiting_action' value='awaiting_action'>Awaiting Action</Select.Option>
              <Select.Option key='actioned' value='actioned'>Actioned</Select.Option>

            </Select>

          </React.Fragment>

        )}


      </div>

    )
  }
}