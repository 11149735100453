import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import SupplierModal from '../Supplier'
import SupplierRatesModal from '../SupplierRates'

import Controls from './Components/Controls'
import Table from './Components/Table'

const Spacer = () => <div style={{ height: 12 }} />

const Suppliers = props => {

  const {
    supplierModal,
    supplierRatesModal,
    componentDidMount,
    componentWillUnmount,
    onCloseSupplierModal,
    onCloseSupplierRatesModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Modal
      title='Suppliers'
      visible
      width={600}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >

      <Controls />

      <Spacer />

      <Table />

      {supplierModal.show && (
        <SupplierModal
          {...supplierModal}
          onClose={onCloseSupplierModal}
        />
      )}

      {supplierRatesModal.show && (
        <SupplierRatesModal
          {...supplierRatesModal}
          onClose={onCloseSupplierRatesModal}
        />
      )}

    </Modal>

  )

}

Suppliers.propTypes = {
  supplierModal: PropTypes.object.isRequired,
  supplierRatesModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseSupplierModal: PropTypes.func.isRequired,
  onCloseSupplierRatesModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Suppliers
