import chance from 'chance'
import { setCostSheetField } from '../../../../../State/order'

export default (guid, action) => (dispatch, getState) => {

  const { cost_sheet_expenses: costSheetExpenses } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetExpenses = []

  costSheetExpenses.forEach(costSheetExpense => {

    if (costSheetExpense.guid !== guid) {
      newCostSheetExpenses.push({ ...costSheetExpense })
      return
    }

    if (action === 'add') {
      newCostSheetExpenses.push({ ...costSheetExpense })
      newCostSheetExpenses.push({
        guid: chance().guid(),
      })
    }

    if (action === 'duplicate') {
      newCostSheetExpenses.push({ ...costSheetExpense })
      newCostSheetExpenses.push({
        ...costSheetExpense,
        id: undefined,
        guid: chance().guid(),
      })
    }

    if (action === 'remove') {
      // We just simply omit it
    }

  })

  dispatch(setCostSheetField({ field: 'cost_sheet_expenses', value: newCostSheetExpenses }))

}
