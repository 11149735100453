import { createAction, handleActions } from 'redux-actions';

const loadMaterials = createAction("CORE/MATERIALS/LOAD_MATERIALS");
const clearMaterials = createAction("CORE/MATERIALS/CLEAR_MATERIALS");

const defaultState = []

const reducer = handleActions(
  {
    [loadMaterials]: (state, action) => action.payload,
    [clearMaterials]: () => []
  },
  defaultState
);

export { loadMaterials, clearMaterials, reducer };