import { find, get } from 'lodash'

export default (values, materialTypes) => () => {

  const materialType = find(materialTypes, row => row.id === values.material_type_id)

  const errors = {}

  if (!values.title) errors.title = true

  if (!values.material_type_id) errors.material_type_id = true

  if (get(materialType, 'usage_key') === 'square-metres') {
    if (!values.material_range_id) errors.material_range_id = true
  }

  if (!values.rate) errors.rate = true
  
  return errors

}
