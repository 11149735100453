import { connect } from 'react-redux'
import Takeoff from './Takeoff'

import selectTakeoff from './Selectors/selectTakeoff'

const mapStateToProps = (state) => ({
  takeoff: selectTakeoff(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Takeoff)
