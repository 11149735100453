import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Popover } from 'antd'
import { get } from 'lodash'
import numeral from 'numeral'

const Table = props => {

  const {
    tableRows,
    fetching,
    onChangeTable,
    onClickRow,
  } = props

  return (

    <TableProvider
      dataSource={tableRows}
      rowKey='id'
      size='small'
      loading={fetching}
      onChange={onChangeTable}
      onRow={record => ({
        onClick: () => onClickRow(record),
        style: { cursor: 'pointer' },
      })}
    >

      <TableProvider.Column
        key='builder.name'
        title='Builder'
        sorter
        render={(text, record) => get(record, 'builder.name') || <span style={{ color: '#bfbfbf' }}>All</span>}
      />

      <TableProvider.Column
        key='material_type.name'
        title='Material'
        sorter
        render={(text, record) => record.material_type.name}
      />

      <TableProvider.Column
        key='material_range.name'
        title='Range'
        sorter
        render={(text, record) => {
          const content = (
            <div>
              {record.material_range.name}
              {record.format && ` / ${record.format}`}
            </div>
          )
          if (record.dimensions && record.dimensions.length > 0) return (
            <Popover
              trigger={['hover']}
              title='Sizes'
              content={(
                <React.Fragment>
                  {record.dimensions.map(({ length, width }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{`${length} x ${width}`}</div>
                  ))}
                </React.Fragment>
              )}
            >
              {content}
            </Popover>
          )
          return content
        }}
      />

      <TableProvider.Column
        key='unit'
        title='Unit'
        render={(text, record) => record.unit}
      />

      <TableProvider.Column
        key='rate'
        title='Rate'
        sorter
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => `£${numeral(record.rate).format('0,0.00')}`}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  tableRows: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Table
