import { createAction, handleActions } from 'redux-actions';

const loadLeftovers = createAction("SCREENS/ORDER_VIEW/LOAD_LEFTOVERS");
const clearLeftovers = createAction("SCREENS/ORDER_VIEW/CLEAR_LEFTOVERS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadLeftovers]: (state, action) => action.payload,
    [clearLeftovers]: () => null
  },
  defaultState
);

export { loadLeftovers, clearLeftovers, reducer };