import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteManufacturer } from '../../../HttpRequests/manufacturers'
import fetchManufacturers from '../../../Core/Services/fetchManufacturers'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { manufacturer } = modalProps()

  try {

    await deleteManufacturer({ id: manufacturer.id })

    message.success('Deleted Manufacturer')

    modalProps().onClose(true)

    dispatch(fetchManufacturers({ force: true }))

  } catch (error) {

    handleException(error)

  }

}
