import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'

export default () => (dispatch, getState) => {

  const { id } = modalProps()

  if (!id) return

  const { builderRate } = getState().modals.BuilderRate

  const initialValues = {
    title: builderRate.title,
    subtitle: builderRate.subtitle,
    material_type_id: builderRate.material_type_id,
    material_range_id: builderRate.material_range_id,
    rate: builderRate.rate,
    group_rate: builderRate.group_rate,
  }

  defer(() => formService().initialize(initialValues))

}
