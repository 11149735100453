import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'antd'
import styles from './styles.css'

const Text = props => {

  const {
    value,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <Icon type='info-circle' />

      &nbsp;

      <div>
        {value}
      </div>

    </div>
    
  )

}

Text.propTypes = {
  value: PropTypes.string,
}

Text.defaultProps = {
  value: null,
}

export default Text
