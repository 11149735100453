import { push } from 'connected-react-router';
import { getProps } from '../props';
import { duplicateOrder } from '../../../HttpRequests/orders';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch) => {
  
  try {

    const { id } = getProps().match.params;

    const response = await duplicateOrder({ id });
    
    dispatch(push(`/portal/orders/${response.data.data.id}`))

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}