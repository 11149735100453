import { createAction, handleActions } from 'redux-actions'

const openDimensionsModal = createAction('SCREENS/ORDER_VIEW/OPEN_DIMENSIONS_MODAL')
const closeDimensionsModal = createAction('SCREENS/ORDER_VIEW/CLOSE_DIMENSIONS_MODAL')

const defaultState = {
  show: false,
  index: null,
  dimensions: [],
  onSave: Function,
  onClose: Function,
}

const reducer = handleActions(
  {
    [openDimensionsModal]: (state, action) => Object.assign({}, state, {
      show: true,
      index: action.payload.index,
      dimensions: action.payload.dimensions,
      onSave: action.payload.onSave,
      onClose: action.payload.onClose,
    }),
    [closeDimensionsModal]: () => defaultState,
  },
  defaultState,
)

export { openDimensionsModal, closeDimensionsModal, reducer }
