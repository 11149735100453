import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, InputNumber, Switch, Icon } from 'antd'
import { ExposeFormToHelper } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  render() {

    const {
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        validate={validate}
        onSubmit={onSubmit}
      >

        {({ handleSubmit, values, form }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            colon={false}
            autoComplete='off'
          >

            <ExposeFormToHelper helper={formService} />

            <div className={styles.formWrapper}>

              <div className={styles.left}>

                <Field name='square_metres'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='M2'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        onChange={value => {
                          input.onChange(value)
                          if (values.use_rate) {
                            const priceNet = (value || 0) * (values.rate || 0)
                            const priceVat = (priceNet / 100) * (values.vat_perc || 0)
                            const priceGross = priceNet + priceVat
                            form.change('price_net', priceNet)
                            form.change('price_vat', priceVat)
                            form.change('price_gross', priceGross)
                          }
                        }}
                        min={0}
                        step={1}
                        precision={2}
                        style={{ width: '120px' }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='use_rate'>
                  {({ input }) => (
                    <Form.Item
                      label='Use Rate'
                    >
                      <Switch
                        {...input}
                        onChange={value => {
                          input.onChange(value)
                          if (!value) {
                            form.change('rate', null)
                          }
                        }}
                        checked={!!input.value}
                        checkedChildren={<Icon type='check' />}
                        style={{ marginTop: 4 }}
                      />
                    </Form.Item>
                  )}
                </Field>

                {!!values.use_rate && (

                  <Field name='rate'>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Rate'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <InputNumber
                          {...input}
                          onChange={value => {
                            input.onChange(value)
                            const priceNet = (values.square_metres || 0) * (value || 0)
                            const priceVat = (priceNet / 100) * (values.vat_perc || 0)
                            const priceGross = priceNet + priceVat
                            form.change('price_net', priceNet)
                            form.change('price_vat', priceVat)
                            form.change('price_gross', priceGross)
                          }}
                          min={0}
                          step={1}
                          precision={2}
                          style={{ width: '120px' }}
                        />
                      </Form.Item>
                    )}
                  </Field>

                )}

                <Field name='price_net'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Net'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        onChange={value => {
                          input.onChange(value)
                          const priceVat = ((value || 0) / 100) * (values.vat_perc || 0)
                          const priceGross = (value || 0) + priceVat
                          form.change('price_vat', priceVat)
                          form.change('price_gross', priceGross)
                        }}
                        min={0}
                        step={1}
                        precision={2}
                        disabled={!!values.use_rate}
                        style={{ width: '120px' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </div>

              <div className={styles.right}>

                <Field name='vat_perc'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='VAT %'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        onChange={value => {
                          input.onChange(value)
                          const priceVat = ((values.price_net || 0) / 100) * (value || 0)
                          const priceGross = (values.price_net || 0) + priceVat
                          form.change('price_vat', priceVat)
                          form.change('price_gross', priceGross)
                        }}
                        min={0}
                        max={100}
                        step={1}
                        precision={2}
                        disabled
                        style={{ width: '120px' }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='price_vat'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='VAT'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        min={0}
                        step={1}
                        precision={2}
                        disabled
                        style={{ width: '120px' }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='price_gross'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Total'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        min={0}
                        step={1}
                        precision={2}
                        disabled
                        style={{ width: '120px' }}
                      />
                    </Form.Item>
                  )}
                </Field>

              </div>

            </div>

          </Form>

        )}

      </FormProvider>

    )

  }

}
