import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { fitterId, onClose, didCreate, didUpdate } = getProps();
  
  if (!fitterId) {

    const fitter = await dispatch(create(values));

    if (fitter) {
      onClose();
      didCreate(fitter);
    }

  } else {

    const fitter = await dispatch(update(values));

    if (fitter) {
      onClose();
      didUpdate(fitter);
    }

  }

};
