import React from 'react'
import { Modal, Icon } from 'antd'
import unarchive from '../Services/unarchive'

export default () => (dispatch) => {

  Modal.confirm({
    title: 'Unarchive Range',
    content: 'Are you sure you want to unarchive this range?',
    icon: <Icon type="eye" />,
    okText: 'Unarchive',
    onOk: () => dispatch(unarchive()),
  })

}
