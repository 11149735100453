import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { order } = getProps();

  if (order) {

    dispatch(initialize(FORM_NAME, {
      id: order.id,
      plot_type_id: order.plot_type_id,
      material_type_id: order.material_type_id,
      order_number: order.order_number,
      plot_number: order.plot_number,
      date: order.date,
      delivery_date: order.delivery_date,
    }));
    
  } 
}