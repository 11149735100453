import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField, SelectField } from 'redux-form-antd-fields';
import Tags from '../../../../Core/Misc/tags';

const labelColSpan = 6;
const wrapperColSpan = 16;

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired
  }

  render () {

    const {
      submitting
    } = this.props;

    const tagOptions = Tags.map(item => ({ value: item.name, label: item.name }))

    return (
      
      <React.Fragment>

        <Field
          name="name"
          label="Name"
          disabled={submitting}
          component={InputField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="tags"
          label="Tags"
          component={SelectField}
          disabled={submitting}
          options={tagOptions}
          mode="multiple"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="account_ref"
          label="Account Ref"
          disabled={submitting}
          component={InputField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

      </React.Fragment>

    )
  }
}