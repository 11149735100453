import { createAction, handleActions } from 'redux-actions'

const loadManufacturers = createAction('CORE/MANUFACTURERS/LOAD_MANUFACTURERS')
const clearManufacturers = createAction('CORE/MANUFACTURERS/CLEAR_MANUFACTURERS')

const defaultState = []

const reducer = handleActions(
  {
    [loadManufacturers]: (state, action) => action.payload,
    [clearManufacturers]: () => [],
  },
  defaultState,
)

export { loadManufacturers, clearManufacturers, reducer }
