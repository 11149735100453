import modalProps from '../modalProps'
import { setSelectedRangeVariantIds } from '../State/selectedRangeVariantIds'

export default (props) => async (dispatch) => {

  modalProps(props)

  dispatch(setSelectedRangeVariantIds(props.rangeVariants.map(rangeVariant => rangeVariant.id)))
  
}
