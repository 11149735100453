import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteOrderNote } from '../../../HttpRequests/orderNotes'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  const { orderNote } = modalProps()

  try {

    await deleteOrderNote({ id: orderNote.id })

    message.success('Deleted Order Note')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
