import { setProps } from '../props';
import fetchOrder from '../Services/fetchOrder';
// import fetchLeftovers from '../Services/fetchLeftovers';

export default (props, prevProps) => async (dispatch) => {

  setProps(props)

  if (props.match.params.id !== prevProps.match.params.id) {

    // await dispatch(fetchLeftovers());
    await dispatch(fetchOrder());

  }

}