import { change, formValueSelector } from 'redux-form';
import { getOrder } from '../../../HttpRequests/orders';
import { FORM_NAME } from '../Form';

const selectValue = formValueSelector(FORM_NAME);

export default () => async (dispatch, getState) => {
  
  const id = selectValue(getState(), 'original_order_id');

  if (id) {

    const params = {
      include: 'site',
      'fields[site]': 'id,name'
    }

    const response = await getOrder({ id, params });

    const order = response.data.data;

    dispatch(change(FORM_NAME, 'order_category_id', order.order_category_id));
    dispatch(change(FORM_NAME, 'builder_id', order.builder_id));
    dispatch(change(FORM_NAME, 'site_id', order.site_id));
    dispatch(change(FORM_NAME, 'plot_number', order.plot_number));
    
  } else {

    dispatch(change(FORM_NAME, 'order_category_id', null));
    dispatch(change(FORM_NAME, 'builder_id', null));
    dispatch(change(FORM_NAME, 'site_id', null));
    dispatch(change(FORM_NAME, 'plot_number', null));

  }
}