import modalProps from '../modalProps'
import { getSupplierRates } from '../../../HttpRequests/supplierRates'
import { setSupplierRates } from '../State/supplierRates'
import { setFetching } from '../State/fetching'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { supplier } = modalProps()

  const { search, sort } = getState().modals.SupplierRates.controls

  const params = {
    search,
    'filter[supplier_id]': supplier.id,
    sort: sort || 'material_range.name',
    include: 'builder,material_type,material_range',
  }

  dispatch(setFetching(true))

  try {

    const response = await getSupplierRates({ params })

    dispatch(setSupplierRates(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
