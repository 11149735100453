import generate from '../../../Services/generate'
import addItem from '../../../Services/addItem'

export default () => (dispatch, getState) => {

  dispatch(generate())

  const { cost_sheet: costSheet } = getState().screens.CostSheet.order
  
  // Add an item if there wasn't anything to generate
  if (!costSheet.cost_sheet_expenses.length) {
    dispatch(addItem())
  }

}
