import { message } from 'antd'
import { deleteAreaMaterialType } from '../../../HttpRequests/areaMaterialTypes'
import { handleException } from '../../../../packages/sota'

export default (id) => async () => {

  try {

    await deleteAreaMaterialType({ id })

    message.success('Changes Saved')

  } catch (error) {

    handleException(error)

    throw error

  }

}
