export default (costSheetMaterial) => {

  const $costSheetMaterial = { ...costSheetMaterial }

  if ($costSheetMaterial.quantity) return $costSheetMaterial

  $costSheetMaterial.quantity = 1

  return $costSheetMaterial

}
