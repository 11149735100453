import fetchJobs from './fetchJobs'
import fetchHolidays from './fetchHolidays'
import fetchInspectorVisits from './fetchInspectorVisits'
import fetchInspections from './fetchInspections'
import fetchJobStats from './fetchJobStats'
import handleException from '../../../Core/Helpers/handleException'

export default ({ quiet } = { quiet: false }) => async (dispatch, getState) => {
  
  try {

    const { source } = getState().screens.Board

    if (source === 'JOBS') dispatch(fetchJobs({ quiet }))

    if (source === 'HOLIDAYS') dispatch(fetchHolidays({ quiet }))

    if (source === 'INSPECTOR_VISITS') dispatch(fetchInspectorVisits({ quiet }))

    if (source === 'INSPECTIONS') dispatch(fetchInspections({ quiet }))

    if (source === 'JOB_STATS') dispatch(fetchJobStats({ quiet }))

  } catch (error) {
    
    handleException(error)

  }

}