import modalProps from '../modalProps'
import { findSiteRate } from '../../../HttpRequests/siteRates'
import { setSiteRate } from '../State/siteRate'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = modalProps()

  if (!id) return

  const params = {
    include: 'material_type,material_ranges.range_variants,range_variants',
  }

  try {

    const response = await findSiteRate({ id, params })

    dispatch(setSiteRate(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
