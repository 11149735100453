import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Spin } from 'antd';
import JobItem from '../JobItem';
import HolidayItem from '../HolidayItem';
import InspectorVisitItem from '../InspectorVisitItem';
import InspectionItem from '../InspectionItem';
import styles from "./Component.css";

export default class extends React.PureComponent {

  static propTypes = {
    source: PropTypes.string.isRequired,
    jobs: PropTypes.array.isRequired,
    holidays: PropTypes.array.isRequired,
    inspectorVisits: PropTypes.array.isRequired,
    inspections: PropTypes.array.isRequired,
    dates: PropTypes.array.isRequired,
    showSpinner: PropTypes.bool.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired,
  }

  render () {

    const {
      source,
      jobs,
      holidays,
      inspectorVisits,
      inspections,
      dates,
      showSpinner,
      onDragOver,
      onDrop,
    } = this.props;

    return (

      <div className={styles.wrapper}>

        <div className={styles.board}>

          {dates.map((date, index) => (

            <div
              key={date}
              className={styles.dayColumn}
              onDragOver={onDragOver}
              onDrop={(event) => onDrop(event, date)}
            >

              <div className={styles.dayLabel}>
                {moment(date).format('ddd DD')}
              </div>

              {source === 'JOBS' && jobs[index].map(job => <JobItem key={job.id} data={job} />)}

              {source === 'HOLIDAYS' && holidays[index].map(holiday => <HolidayItem key={holiday.id} data={holiday} />)}

              {source === 'INSPECTOR_VISITS' && inspectorVisits[index].map(inspectorVisit => <InspectorVisitItem key={inspectorVisit.id} data={inspectorVisit} />)}

              {source === 'INSPECTIONS' && inspections[index].map(inspection => <InspectionItem key={inspection.id} data={inspection} />)}

            </div>

          ))}

        </div>
        
        {showSpinner && (
          <div className={styles.spin}>
            <Spin spinning />
          </div>
        )}

      </div>

    )
  }
}