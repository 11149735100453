import { createAction, handleActions } from 'redux-actions';

const loadInspectors = createAction("CORE/INSPECTORS/LOAD_INSPECTORS");
const clearInspectors = createAction("CORE/INSPECTORS/CLEAR_INSPECTORS");

const defaultState = []

const reducer = handleActions(
  {
    [loadInspectors]: (state, action) => action.payload,
    [clearInspectors]: () => []
  },
  defaultState
);

export { loadInspectors, clearInspectors, reducer };