import guessAreaPrice from '../Utils/guessAreaPrice'
import { setCostSheetField } from '../../../../../State/order'

export default () => (dispatch, getState) => {

  const state = getState()

  const { cost_sheet_quotes: costSheetQuotes } = state.screens.CostSheet.order.cost_sheet

  const $costSheetQuotes = []

  costSheetQuotes.forEach(costSheetQuote => {
    if (costSheetQuote.area_id) {
      $costSheetQuotes.push(guessAreaPrice(costSheetQuote, state))
    } else {
      $costSheetQuotes.push({ ...costSheetQuote })
    }
  })

  dispatch(setCostSheetField({ field: 'cost_sheet_quotes', value: $costSheetQuotes }))

}
