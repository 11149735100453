import * as React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Fields from './Components/Fields'
import Footer from './Components/Footer'

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    onClickCancel: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render () {

    const {
      submitting,
      onClickCancel,
    } = this.props

    return (
        
      <Modal
        visible  
        title="Upload File"
        onCancel={onClickCancel}
        footer={<Footer submitting={submitting}  />}
      >
        
        <Fields submitting={submitting} />

      </Modal>
    )
  }
}