import { connect } from 'react-redux'
import MaterialRange from './MaterialRange'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onSubmit from './Handlers/onSubmit'
import onDelete from './Handlers/onDelete'
import onArchive from './Handlers/onArchive'
import onUnarchive from './Handlers/onUnarchive'

const mapStateToProps = (state) => ({
  submitting: state.modals.MaterialRange.Form.formState.submitting,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onSubmit,
  onDelete,
  onArchive,
  onUnarchive,
}

export default connect(mapStateToProps, actionCreators)(MaterialRange)
