import { closeAddAreaMaterialTypeModal } from '../State/addAreaMaterialTypeModal'
import fetch from '../Components/AreaGroups/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeAddAreaMaterialTypeModal())

  if (reload) {
    dispatch(fetch())
  }

}
