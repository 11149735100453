export default (values) => {

  const errors = {};

  if (!values.fitter_id) errors.fitter_id = true;
  if (!values.amount) errors.amount = true;
  if (!values.date) errors.date = true;

  return errors;
  
}