import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectOrderNumbers = state => state.core.orderNumbers;
const selectToOrderOpen = state => state.modals.Leftover.toOrderOpen;
const selectToOrderSearchTerm = state => state.modals.Leftover.toOrderSearchTerm;
const selectToOrderId = state => formValueSelector(FORM_NAME)(state, 'to_order_id');

export default createSelector(
  selectOrderNumbers,
  selectToOrderOpen,
  selectToOrderSearchTerm,
  selectToOrderId,
  (orderNumbers, toOrderOpen, toOrderSearchTerm, toOrderId) => {

    if (toOrderOpen) {
      
      if (String(toOrderSearchTerm).length > 3) {
      
        return orderNumbers
          .filter(item => String(item.order_number).startsWith(toOrderSearchTerm))
          .map(item => ({ value: item.id, label: String(item.order_number) }));

      }
      
      return [];

    }

    if (toOrderId) {
    
      return orderNumbers
        .filter(item => item.id === toOrderId)
        .map(item => ({ value: item.id, label: String(item.order_number) }));
    
    }

    return [];
    
  }
)