import { initialize } from 'redux-form';
import { getFitterInvoice } from '../../../HttpRequests/fitterInvoices';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { fitterInvoiceId, orderId } = getProps();
  
  if (fitterInvoiceId) {

    const response = await getFitterInvoice({ id: fitterInvoiceId });

    const fitterInvoice = response.data.data;

    dispatch(initialize(FORM_NAME, fitterInvoice))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      order_id: orderId
    }))

  }
}