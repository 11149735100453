import { createAction, handleActions } from 'redux-actions'

const setSaving = createAction('MODALS/BUILDER_RATE/BUILDER_RATE_RANGE_VARIANTS_MODAL/SET_SAVING')
const resetSaving = createAction('MODALS/BUILDER_RATE/BUILDER_RATE_RANGE_VARIANTS_MODAL/RESET_SAVING')

const defaultState = false

const reducer = handleActions(
  {
    [setSaving]: (state, { payload }) => payload,
    [resetSaving]: () => defaultState,
  },
  defaultState,
)

export { setSaving, resetSaving, reducer }
