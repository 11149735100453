import { change } from 'redux-form'
import { pick } from 'lodash'
import moment from 'moment'
import { FORM_NAME } from '../Form'

export default () => (dispatch, getState) => {

  const { user } = getState()

  dispatch(change(FORM_NAME, 'inspection_set_at', moment().format('YYYY-MM-DD HH:mm:ss')))
  dispatch(change(FORM_NAME, 'inspection_set_by_user_id', user.id))
  dispatch(change(FORM_NAME, 'inspection_set_by_user', pick(user, ['id', 'first_name', 'last_name'])))

}
