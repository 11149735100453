import { createAction, handleActions } from 'redux-actions';

const setActiveTab = createAction('SCREENS/SITE_VIEW/SET_ACTIVE_TAB');

const defaultState = 'PLOT_TYPES';

const reducer = handleActions(
  {
    [setActiveTab]: (state, action) => action.payload
  },
  defaultState
);

export { setActiveTab, reducer };