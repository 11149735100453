import { connect } from 'react-redux';
import withSizes from 'react-sizes'

import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onCloseCompletedJobsModal from './Handlers/onCloseCompletedJobsModal';
import onCloseJobModal from './Handlers/onCloseJobModal';
import onCloseHolidayModal from './Handlers/onCloseHolidayModal';
import onCloseInspectorVisitModal from './Handlers/onCloseInspectorVisitModal';
import didCreateJob from './Handlers/didCreateJob';
import didUpdateJob from './Handlers/didUpdateJob';
import didDeleteJob from './Handlers/didDeleteJob';
import didCreateHoliday from './Handlers/didCreateHoliday';
import didUpdateHoliday from './Handlers/didUpdateHoliday';
import didDeleteHoliday from './Handlers/didDeleteHoliday';
import didCreateInspectorVisit from './Handlers/didCreateInspectorVisit';
import didUpdateInspectorVisit from './Handlers/didUpdateInspectorVisit';
import didDeleteInspectorVisit from './Handlers/didDeleteInspectorVisit';

const mapStateToProps = (state) => ({
  source: state.screens.Board.source,
  mode: state.screens.Board.mode,
  completedJobsModal: state.screens.Board.completedJobsModal,
  showJobModal: state.screens.Board.showJobModal,
  showHolidayModal: state.screens.Board.showHolidayModal,
  showInspectorVisitModal: state.screens.Board.showInspectorVisitModal,
  openedJobId: state.screens.Board.openedJobId,
  openedHolidayId: state.screens.Board.openedHolidayId,
  openedInspectorVisitId: state.screens.Board.openedInspectorVisitId,
  createLegacyJob: state.screens.Board.createLegacyJob,
});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  onCloseJobModal,
  onCloseHolidayModal,
  onCloseInspectorVisitModal,
  onCloseCompletedJobsModal,
  didCreateJob,
  didUpdateJob,
  didDeleteJob,
  didCreateHoliday,
  didUpdateHoliday,
  didDeleteHoliday,
  didCreateInspectorVisit,
  didUpdateInspectorVisit,
  didDeleteInspectorVisit
};

const mapSizesToProps = ({ height }) => ({
  screenHeight: height - 45
})

export default connect(mapStateToProps, actionCreators)(withSizes(mapSizesToProps)(Component));
