import * as React from 'react';
import PropTypes from 'prop-types';
import { Screen } from 'evolve-portal';

import Header from './Components/Header';
import Footer from './Components/Footer';
import WeekBoard from './Components/WeekBoard';
import MonthBoard from './Components/MonthBoard';
import JobStatsTable from './Components/JobStatsTable';

import CompletedJobs from '../../Modals/CompletedJobs';
import JobModal from '../../Modals/Job';
import HolidayModal from '../../Modals/Holiday';
import InspectorVisitModal from '../../Modals/InspectorVisit';

export default class extends React.PureComponent {

  static propTypes = {
    componentWillMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,

    screenHeight: PropTypes.number.isRequired,
    source: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    openedJobId: PropTypes.number,
    openedHolidayId: PropTypes.number,
    openedInspectorVisitId: PropTypes.number,
    createLegacyJob: PropTypes.bool,

    completedJobsModal: PropTypes.object.isRequired,
    showJobModal: PropTypes.bool.isRequired,
    showHolidayModal: PropTypes.bool.isRequired,
    showInspectorVisitModal: PropTypes.bool.isRequired,

    didCreateJob: PropTypes.func.isRequired,
    didUpdateJob: PropTypes.func.isRequired,
    didDeleteJob: PropTypes.func.isRequired,
    didCreateHoliday: PropTypes.func.isRequired,
    didUpdateHoliday: PropTypes.func.isRequired,
    didDeleteHoliday: PropTypes.func.isRequired,
    didCreateInspectorVisit: PropTypes.func.isRequired,
    didUpdateInspectorVisit: PropTypes.func.isRequired,
    didDeleteInspectorVisit: PropTypes.func.isRequired,
    
    onCloseCompletedJobsModal: PropTypes.func.isRequired,
    onCloseJobModal: PropTypes.func.isRequired,
    onCloseHolidayModal: PropTypes.func.isRequired,
    onCloseInspectorVisitModal: PropTypes.func.isRequired
  }

  static defaultProps = {
    openedJobId: null,
    openedHolidayId: null,
    openedInspectorVisitId: null
  }

  componentDidMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      screenHeight,
      source,
      mode,
      openedJobId,
      openedHolidayId,
      openedInspectorVisitId,
      createLegacyJob,
      completedJobsModal,
      showJobModal,
      showHolidayModal,
      showInspectorVisitModal,
      didCreateJob,
      didUpdateJob,
      didDeleteJob,
      didCreateHoliday,
      didUpdateHoliday,
      didDeleteHoliday,
      didCreateInspectorVisit,
      didUpdateInspectorVisit,
      didDeleteInspectorVisit,
      onCloseCompletedJobsModal,
      onCloseJobModal,
      onCloseHolidayModal,
      onCloseInspectorVisitModal
    } = this.props;

    return (

      <Screen
        style={{ height: screenHeight, padding: 30 }}
        innerStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >

        <Header />

        {source !== 'JOB_STATS' && mode === 'WEEK' && (
          <WeekBoard />
        )}

        {source !== 'JOB_STATS' && mode === 'MONTH' && (
          <MonthBoard />
        )}

        {source === 'JOB_STATS' && (
          <JobStatsTable />
        )}

        <Footer />

        {completedJobsModal.show && (
          <CompletedJobs
            onClose={onCloseCompletedJobsModal}
          />
        )}
        
        {showJobModal && (
          <JobModal
            jobId={openedJobId}
            legacy={createLegacyJob}
            onClose={onCloseJobModal}
            didCreate={didCreateJob}
            didUpdate={didUpdateJob}
            didDelete={didDeleteJob}
          />
        )}

        {showHolidayModal && (
          <HolidayModal
            holidayId={openedHolidayId}
            onClose={onCloseHolidayModal}
            didCreate={didCreateHoliday}
            didUpdate={didUpdateHoliday}
            didDelete={didDeleteHoliday}
          />
        )}

        {showInspectorVisitModal && (
          <InspectorVisitModal
            inspectorVisitId={openedInspectorVisitId}
            onClose={onCloseInspectorVisitModal}
            didCreate={didCreateInspectorVisit}
            didUpdate={didUpdateInspectorVisit}
            didDelete={didDeleteInspectorVisit}
          />
        )}

      </Screen>

    )
  }
}