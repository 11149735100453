export default () => async (dispatch, getState) => {

  const { token } = getState()
  const { searchTerm, filters, sorter } = getState().screens.Orders

  if (filters.type === 'all') return

  const params = {}

  if (searchTerm) {
    params.search = searchTerm;
  }

  if (filters.status) {
    params['filter[status]'] = filters.status;
  }

  if (filters.manager_id) {
    params['filter[site_manager_id]'] = filters.manager_id;
  }

  if (filters.on_hold_reason_key) {
    if (filters.on_hold_reason_key === 'ANY') {
      params['filter[on_hold]'] = 1
    } else {
      params['filter[on_hold_reason_key]'] = filters.on_hold_reason_key;
    }
  }

  if (filters.site_survey_added !== undefined) {
    params['filter[site_survey_added]'] = filters.site_survey_added;
  }

  if (filters.photo_added !== undefined) {
    params['filter[photo_added]'] = filters.photo_added;
  }

  if (filters.questionnaire_status !== undefined) {
    params['filter[questionnaire_status]'] = filters.questionnaire_status;
  }

  if (filters.credit_status !== undefined) {
    params['filter[credit_status]'] = filters.credit_status;
  }

  if (sorter.columnKey) {
    params.sort =  `${sorter.order === 'descend' ? '-' : '' }${sorter.columnKey}`;
  }

  const exportTypes = {
    'orders': 'export_non_remedials',
    'remedials': 'export_remedials',
    'credits': 'export_credits',
  }

  let url = `/api/orders/${exportTypes[filters.type]}?token=${token}`

  Object.keys(params).forEach(key => {
    if (params[key] !== undefined) url += `&${key}=${params[key]}`
  })

  window.location = url
  
}