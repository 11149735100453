import { assign, pick } from 'lodash';
import { push } from 'connected-react-router';
import { Modal } from 'antd'
import create from '../Services/create';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didCreate } = getProps();

  const data = {};

  assign(data, { ...pick(values, [

    'remedial',
    'credit',
    'custom_entry',
    'private',

    'credit_reference',
    'credit_status',

    'original_order_id',
    'order_category_id',
    'order_category_custom',
    'builder_id',
    'builder_custom',
    'site_id',
    'site_custom',
    'town_custom',
    'plot_type_id',
    'plot_number',
    'builder_po_number',

    'date',
    'delivery_date',

    'sale_price',
    'contract_works_price',
    'customer_extra_price',
    'matwell_price',
    'protection_price',

    'customer_email',
    'customer_phone_number',
    'remedial_overview',
    'custom_price_1',
    'custom_price_1_label',
    'custom_price_2',
    'custom_price_2_label',
    'custom_price_3',
    'custom_price_3_label',

  ])});
  
  const { order, shouldCreateAnother } = await dispatch(create(data));
  
  didCreate()

  if (shouldCreateAnother) {

    Modal.info({
      title: 'Order Created',
      content: `The order number is: ${order.order_number || 'N/A'}`,
    })

  } else {

    onClose()
    dispatch(push(`/portal/orders/${order.id}`))

  }
  
};
