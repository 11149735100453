import { setManager } from '../../../State/manager';
import fetchJobs from '../../../Services/fetchJobs';
import fetchInspections from '../../../Services/fetchInspections';
import fetchJobStats from '../../../Services/fetchJobStats';

export default (id) => async (dispatch) => {

  dispatch(setManager(id));
  dispatch(fetchJobs());
  dispatch(fetchInspections());
  dispatch(fetchJobStats());
  
}