import { connect } from 'react-redux'
import Item from './Item'

import selectFittingRates from './Selectors/selectFittingRates'

import onEditField from './Handlers/onEditField'
import onClickAction from './Handlers/onClickAction'

const mapStateToProps = (state) => ({
  fittingRates: selectFittingRates(state),
})

const actionCreators = {
  onEditField,
  onClickAction,
}

export default connect(mapStateToProps, actionCreators)(Item)
