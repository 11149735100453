import { message } from 'antd'
import { removeMaterialRangeFromBuilderRate } from '../../../HttpRequests/builderRates'
import fetch from './fetch'
import { handleException } from '../../../../packages/sota'

export default (materialRangeId) => async (dispatch, getState) => {

  const { builderRate: { id } } = getState().modals.BuilderRate

  try {

    await removeMaterialRangeFromBuilderRate({ id, materialRangeId })

    dispatch(fetch())

    message.success('Range Removed')

  } catch (error) {

    handleException(error)

    throw error

  }

}
