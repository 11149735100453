import React from 'react'
import { Modal, Icon } from 'antd'
import regenerate from '../../../Services/regenerate'

export default (plotTypeId) => (dispatch) => {

  Modal.confirm({
    title: 'Regenerate Plot Type Cost Matrix?',
    content: 'This action will automatically regenerate the plot type\'s cost matrix, using the site\'s rates and the plot type\'s take-off.',
    icon: <Icon type="reload" style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Regenerate',
    onOk: () => dispatch(regenerate('PlotType', plotTypeId)),
  })

}
