import { createSelector } from "reselect";
import moment from "moment";
import { flatten, find } from 'lodash';
import selectDates from './dates';
import tags from '../../../../../Core/Misc/tags'

const selectJobs = state => state.screens.Board.jobs;
const selectSelectedTags = state => state.screens.Board.selectedTags;

export default createSelector(
  selectJobs,
  selectDates,
  selectSelectedTags,
  (jobs, dates, selectedTags) => {

    const data = flatten(
      dates.map(week => week.map(date => date.date))
    ).reduce(
      (object, date) => Object.assign(object, { [date]: tags.map(tag => ({ tag: tag.name, count: 0 })) }),
      {}
    );

    jobs
      .filter(job => selectedTags.length === 0 || selectedTags.includes(job.tag))
      .forEach(job => {

        for (let date = moment(job.start_date); date.diff(job.end_date, 'days') <= 0; date.add(1, 'days')) {
          
          const key = moment(date).format('YYYY-MM-DD')

          if (data[key]) {
            
            const array = data[key]

            const item = find(array, { tag: job.tag })

            item.count += 1

          }

        }
      
      })

    return data
  
  }
)