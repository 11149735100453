import { change } from 'redux-form'
import moment from 'moment'
import { pick } from 'lodash'
import { FORM_NAME } from '../Form'

export default () => async (dispatch, getState) => {

  dispatch(change(FORM_NAME, 'fitter_set_at', moment().format('YYYY-MM-DD HH:mm:ss')))
  dispatch(change(FORM_NAME, 'fitter_set_by_user_id', getState().user.id))

  const info = {
    fitter_set_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    fitter_set_by_user_id: getState().user.id,
    fitter_set_by_user: pick(getState().user, ['id','first_name','last_name'])
  }

  dispatch(change(FORM_NAME, 'fitter_set_at', info.fitter_set_at))
  dispatch(change(FORM_NAME, 'fitter_set_by_user_id', info.fitter_set_by_user_id))
  dispatch(change(FORM_NAME, 'fitter_set_by_user', info.fitter_set_by_user))

}