import modalProps from '../modalProps'
import { resetFields } from '../State/fields'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetFields())

}
