import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool,
    siteId: PropTypes.number,
    formValues: PropTypes.object,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickArchive: PropTypes.func.isRequired,
    onClickUnarchive: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: null,
    siteId: null,
    formValues: {},
  }

  render () {

    const {
      submitting,
      siteId,
      formValues,
      onClickCancel,
      onClickSubmit,
      onClickDelete,
      onClickArchive,
      onClickUnarchive,
    } = this.props;

    return (
        
      <div className={styles.footer}>

        {!!siteId && (

          <React.Fragment>

            <Button
              type="danger"
              disabled={submitting}
              onClick={onClickDelete}
            >
              Delete
            </Button>

            {formValues.archived_at ? (

              <Button
                type="dashed"
                disabled={submitting}
                onClick={onClickUnarchive}
              >
                Unarchive
              </Button>

            ) : (

              <Button
                type="dashed"
                disabled={submitting}
                onClick={onClickArchive}
              >
                Archive
              </Button>

            )}

          </React.Fragment>

        )}

        <div style={{ margin: 'auto' }} />
      
        <Button
          type="default"
          disabled={submitting}
          onClick={onClickCancel}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          loading={submitting}
          onClick={onClickSubmit}
        >
          {siteId ? 'Update' : 'Create'}
        </Button>

      </div>

    )
  }
}