import { createSelector } from 'reselect';
import { findIndex } from 'lodash';

const selectMaterials = state => state.core.materials;

export default createSelector(
  selectMaterials,
  (materials) => materials.reduce((array, material) => {

    const materialTypeIndex = findIndex(array, { value: material.material_type.id });

    if (materialTypeIndex > -1) {

      array[materialTypeIndex].children.push({
        value: material.id,
        label: material.name,
        children: material.has_ranges ? material.material_ranges.map(materialRange => ({
          value: materialRange.id,
          label: materialRange.name
        })) : []
      })

    } else {

      array.push({
        value: material.material_type.id,
        label: material.material_type.name,
        children: [{
          value: material.id,
          label: material.name,
          children: material.has_ranges ? material.material_ranges.map(materialRange => ({
            value: materialRange.id,
            label: materialRange.name
          })) : []
        }]
      })

    }

    return array;

  }, [])
)