import chance from 'chance'
import { setCostSheetField } from '../../../../../State/order'

export default (values = {}) => (dispatch, getState) => {

  const { cost_sheet_expenses: costSheetExpenses } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetExpenses = [
    ...costSheetExpenses,
    { guid: chance().guid(), ...values },
  ]

  dispatch(setCostSheetField({ field: 'cost_sheet_expenses', value: newCostSheetExpenses }))

}
