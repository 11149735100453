import { createSelector } from 'reselect';

const selectBuilders = state => state.core.builders;

export default createSelector(
  selectBuilders,
  (builders) => builders.map(builder => ({
    label: builder.name,
    value: builder.id,
    protection: builder.protection
  }))
)