import { connect } from 'react-redux';

import Component from './Component';

import managers from './Selectors/managers';

import onChangeMode from './Handlers/onChangeMode';
import onChangeHideAllocated from './Handlers/onChangeHideAllocated';
import onChangeThetfordOnly from './Handlers/onChangeThetfordOnly';
import onChangeHideOrdered from './Handlers/onChangeHideOrdered';
import onChangeManager from './Handlers/onChangeManager';
import onChangeShowWeekends from './Handlers/onChangeShowWeekends';
import onClickTag from './Handlers/onClickTag';

const mapStateToProps = (state) => ({
  source: state.screens.Board.source,
  mode: state.screens.Board.mode,
  showWeekends: state.screens.Board.showWeekends,
  hideAllocated: state.screens.Board.hideAllocated,
  thetfordOnly: state.screens.Board.thetfordOnly,
  hideOrdered: state.screens.Board.hideOrdered,
  managers: managers(state),
  manager: state.screens.Board.manager,
  selectedTags: state.screens.Board.selectedTags
});

const actionCreators = {
  onChangeMode,
  onChangeHideAllocated,
  onChangeThetfordOnly,
  onChangeHideOrdered,
  onChangeManager,
  onChangeShowWeekends,
  onClickTag
};

export default connect(mapStateToProps, actionCreators)(Component);
