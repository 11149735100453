import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { builderId, onClose, didCreate, didUpdate } = getProps();
  
  if (!builderId) {

    const builder = await dispatch(create(values));

    if (builder) {
      onClose();
      didCreate(builder);
    }

  } else {

    const builder = await dispatch(update(values));

    if (builder) {
      onClose();
      didUpdate(builder);
    }

  }

};
