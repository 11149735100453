import { createAction, handleActions } from 'redux-actions';

const loadOrders = createAction('SCREENS/ORDERS/LOAD_ORDERS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadOrders]: (state, action) => action.payload,
  },
  defaultState
);

export { loadOrders, reducer };