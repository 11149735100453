import { createAction, handleActions } from 'redux-actions';

const setOpenedFitterInvoiceId = createAction("SCREENS/ORDER_VIEW/SET_OPENED_FITTER_INVOICE_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setOpenedFitterInvoiceId]: (state, action) => action.payload
  },
  defaultState
);

export { setOpenedFitterInvoiceId, reducer };
