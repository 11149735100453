import { createAction, handleActions } from 'redux-actions'

const setMaterialTypes = createAction('MODALS/MATERIAL_TYPES/SET_MATERIAL_TYPES')
const resetMaterialTypes = createAction('MODALS/MATERIAL_TYPES/RESET_MATERIAL_TYPES')

const defaultState = []

const reducer = handleActions(
  {
    [setMaterialTypes]: (state, { payload }) => payload,
    [resetMaterialTypes]: () => defaultState,
  },
  defaultState,
)

export { setMaterialTypes, resetMaterialTypes, reducer }
