import { createAction, handleActions } from 'redux-actions';

const loadMaterialTypes = createAction("MODALS/ORDERS/LOAD_MATERIAL_TYPES");

const defaultState = [];

const reducer = handleActions(
  {
    [loadMaterialTypes]: (state, action) => action.payload
  },
  defaultState
);

export { loadMaterialTypes, reducer };