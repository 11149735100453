import { initialize } from 'redux-form';
import { getHoliday } from '../../../HttpRequests/holidays';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { holidayId } = getProps();

  if (holidayId) {

    const response = await getHoliday({ id: holidayId });

    const holiday = response.data.data;

    dispatch(initialize(FORM_NAME, Object.assign({}, holiday, {
      dates: `${holiday.start_date},${holiday.end_date}`
    })));
    
  } else {

    dispatch(initialize(FORM_NAME, {
      
    }))

  }
}