import { createAction, handleActions } from 'redux-actions'

const setCostMatrixFormat = createAction('SCREENS/SITE_VIEW/SET_COST_MATRIX_FORMAT')
const resetCostMatrixFormat = createAction('SCREENS/SITE_VIEW/RESET_COST_MATRIX_FORMAT')

const defaultState = 'All'

const reducer = handleActions(
  {
    [setCostMatrixFormat]: (state, action) => action.payload,
    [resetCostMatrixFormat]: () => defaultState,
  },
  defaultState,
)

export { setCostMatrixFormat, resetCostMatrixFormat, reducer }
