import { pick } from 'lodash'
import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { jobId, onClose, didCreate, didUpdate } = getProps();

  const data = pick(values, [
    'id',
    'order_id',
    'legacy',
    'legacy_plot_number',
    'legacy_site',
    'start_date',
    'end_date',
    'tag',
    'fitter_id',
    'fitter_set_at',
    'fitter_set_by_user_id',
    'inspector_id',
    'inspection_date',
    'inspection_notes',
    'inspection_set_at',
    'inspection_set_by_user_id',
    'metres',
    'notes',
    'piggyback_order_id',
    'town_house',
    'via_thetford',
    'collected',
    'sent',
    'ordered',
  ])

  data.town_house = values.town_house ? 1 : 0
  data.via_thetford = values.via_thetford ? 1 : 0
  data.collected = values.collected ? 1 : 0
  data.sent = values.sent ? 1 : 0
  data.ordered = values.ordered ? 1 : 0

  if (!jobId) {

    const isCreated = await dispatch(create(data));

    if (isCreated) {

      onClose();
      didCreate();
      
    }
    
  } else {

    const isUpdated = await dispatch(update(data));

    if (isUpdated) {

      onClose();
      didUpdate();

    }
  }
};
