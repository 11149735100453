import { message } from 'antd'
import { updateJob } from '../../../HttpRequests/jobs';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {
  
  try {

    const data = { ...values };

    await updateJob({ id: values.id, data });
    
    message.success("Job Updated");

    return true;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}