import { Modal } from 'antd';
import deleteInspectorVisit from '../../../Services/delete';

export default () => (dispatch) => {

  Modal.confirm({
    title: `Are you sure to delete this inspection?`,
    content: 'Note: This action cannot be undone',
    okText: 'Yes, Delete it',
    width: 400,
    onOk: () => dispatch(deleteInspectorVisit())
  })

}