import { connect } from 'react-redux'
import Expenses from './Expenses'

import selectItems from './Selectors/selectItems'

const mapStateToProps = (state) => ({
  order: state.screens.CostSheet.order,
  items: selectItems(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Expenses)
