import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Input, Select, InputNumber } from 'antd'
import { get } from 'lodash'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    materialTypes: PropTypes.array.isRequired,
    manufacturers: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
    materialType: PropTypes.object,
    siblings: PropTypes.array.isRequired,
    onChangeFormState: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    materialType: null,
  }

  render() {

    const {
      materialTypes,
      manufacturers,
      initialValues,
      materialType,
      siblings,
      onChangeFormState,
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={values => validate(values, materialType)}
      >

        {({ handleSubmit, values, form }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ values: true, submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name='name'>
              {({ input, meta }) => (
                <Form.Item
                  label='Name'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Input
                    {...input}
                  />
                </Form.Item>
              )}
            </Field>

            <Field name='manufacturer_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Manufacturer'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    {manufacturers.map(manufacturer => (
                      <Select.Option key={manufacturer.id} value={manufacturer.id}>
                        {manufacturer.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='material_type_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Material'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    onChange={value => {
                      input.onChange(value)
                      form.change('categories', [])
                      form.change('siblings', [])
                      form.change('square_metres', null)
                    }}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    {materialTypes.map($materialType => (
                      <Select.Option key={$materialType.id} value={$materialType.id}>
                        {$materialType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            {get(materialType, 'is_flooring') === 0 && (

              <React.Fragment>

                <Field name='categories'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Categories'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        mode='multiple'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                      >
                        {materialTypes.filter($materialType => !!$materialType.is_flooring).map($materialType => (
                          <Select.Option key={$materialType.id} value={$materialType.id}>
                            {$materialType.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

                <Field name='siblings'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Siblings'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        mode='multiple'
                        allowClear
                        showSearch
                        optionFilterProp='children'
                      >
                        <Select.OptGroup label='Active'>
                          {siblings.filter(materialRange => !materialRange.archived_at).map(materialRange => (
                            <Select.Option key={materialRange.id} value={materialRange.id}>
                              {materialRange.name}
                              {' • '}
                              <span style={{ color: '#9e9e9e' }}>{materialRange.manufacturer.name}</span>
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                        <Select.OptGroup label='Archived'>
                          {siblings.filter(materialRange => !!materialRange.archived_at).map(materialRange => (
                            <Select.Option key={materialRange.id} value={materialRange.id} style={{ color: '#9e9e9e' }}>
                              {`${materialRange.name} • ${materialRange.manufacturer.name}`}
                            </Select.Option>
                          ))}
                        </Select.OptGroup>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

              </React.Fragment>

            )}

            {get(materialType, 'usage_key') === 'square-metres' && (

              <React.Fragment>

                <Field name='square_metres'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='M2'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

                <Field name='wastage_perc'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Waste %'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>
                
              </React.Fragment>

            )}

            {get(materialType, 'usage_key') === 'item' && (

              <React.Fragment>

                <Field name='target'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Covers M2'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        value={input.value || undefined}
                        onChange={value => {
                          input.onChange(value || null)
                          form.change('symbol', null)
                        }}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                      >
                        {['Net M2', 'Gross M2', 'Flooring M2'].map(target => (
                          <Select.Option key={target} value={target}>
                            {target}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Field>

                <Field name='coverage'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Coverage'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <InputNumber
                        {...input}
                        precision={2}
                        min={0}
                        style={{ width: 90 }}
                      />
                    </Form.Item>
                  )}
                </Field>

                {values.target !== 'Flooring M2' && (

                  <Field name='symbol'>
                    {({ input, meta }) => (
                      <Form.Item
                        label='Symbol'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                      >
                        <Input
                          {...input}
                          style={{ width: 90 }}
                        />
                      </Form.Item>
                    )}
                  </Field>

                )}
                
              </React.Fragment>

            )}

          </Form>

        )}

      </FormProvider>

    )

  }

}
