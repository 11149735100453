import { connect } from 'react-redux';

import Component from './Component';

import onClickAdd from './Handlers/onClickAdd';
import onClickFitterInvoice from './Handlers/onClickFitterInvoice';

const mapStateToProps = (state) => ({
  fitterInvoices: state.screens.OrderView.order ? state.screens.OrderView.order.fitter_invoices : []
});

const actionCreators = {
  onClickAdd,
  onClickFitterInvoice
};

export default connect(mapStateToProps, actionCreators)(Component);
