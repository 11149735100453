import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputNumberField, SelectField, DatePickerField } from 'redux-form-antd-fields';

const labelColSpan = 6;
const wrapperColSpan = 17;

export default class extends React.PureComponent{

  static propTypes = {
    plotTypes: PropTypes.array.isRequired,
    materialTypes: PropTypes.array.isRequired,
    submitting: PropTypes.bool.isRequired
  }

  render () {

    const {
      plotTypes,
      materialTypes,
      submitting
    } = this.props;

    const plotTypesOptions = plotTypes.map(item => ({
      label: item.name,
      value: item.id    
    }));

    const materialTypesOptions = materialTypes.map(item => ({
      label: item.name,
      value: item.key
    }))

    
    return (

      <div>

        <Field
          name="order_number"
          label="Order No"
          disabled
          component={InputNumberField}
          formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="plot_number"
          label="Plot No"
          disabled={submitting}
          component={InputNumberField}
          formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="plot_type_id"
          label="Plot Type"
          disabled={submitting}
          component={SelectField}
          options={plotTypesOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />


        <Field
          name="material_type_id"
          label="Material Type"
          disabled={submitting}
          component={SelectField}
          options={materialTypesOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <br />
        <br />

        <Field
          name="date"
          label="Order Date"
          disabled={submitting}
          component={DatePickerField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: 12 }}
          required
        />

        <Field
          name="delivery_date"
          label="Delivery Date"
          disabled={submitting}
          component={DatePickerField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: 12 }}
        />


      </div>

    )
  }
}