import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { plotTypeId, onClose, didCreate, didUpdate } = getProps();

  const data = {
    id: values.id,
    site_id: values.site_id,
    name: values.name,
    format: values.format.trim(),
    plots: values.plots.join(),
  }
  
  if (!plotTypeId) {

    const plotType = await dispatch(create(data));

    if (plotType) {
      onClose();
      didCreate(plotType);
    }

  } else {

    const plotType = await dispatch(update(data));

    if (plotType) {
      onClose();
      didUpdate(plotType);
    }

  }

};

