import { connect } from 'react-redux'
import Summary from './Summary'

import selectSummary from './Selectors/selectSummary'

const mapStateToProps = (state) => ({
  summary: selectSummary(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Summary)
