import { createAction, handleActions } from 'redux-actions';

const setShowWeekends = createAction("SCREENS/BOARD/SET_SHOW_WEEKENDS");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowWeekends]: (state, action) => action.payload
  },
  defaultState
);

export { setShowWeekends, reducer };
