import { createAction, handleActions } from 'redux-actions';

const setShowPlotTypeModal = createAction("SCREENS/SITE_VIEW/SET_SHOW_PLOT_TYPE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowPlotTypeModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowPlotTypeModal, reducer };