import React from 'react'
import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import styles from './styles.css'

const DragHandle = sortableHandle(() => (
  <Icon type='menu' style={{ cursor: 'pointer', color: '#999' }} />
))

const SortableItem = sortableElement(props => <tr {...props} />)
const SortableContainer = sortableContainer(props => <tbody {...props} />)

const DraggableBodyRow = (rowProps, tableRows) => {
  const index = tableRows.findIndex(tableRow => tableRow.id === rowProps['data-row-key'])
  return <SortableItem index={index} {...rowProps} />
}

const onSortEnd = ({ oldIndex, newIndex }, tableRows, onSort) => {
  if (oldIndex !== newIndex) {
    const newTableRows = arrayMove([].concat(tableRows), oldIndex, newIndex).filter(el => !!el)
    onSort(newTableRows)
  }
}

const DraggableContainer = (wrapperProps, tableRows, onSort) => (
  <SortableContainer
    useDragHandle
    helperClass={styles.rowDragging}
    onSortEnd={onSortEndProps => onSortEnd(onSortEndProps, tableRows, onSort)}
    {...wrapperProps}
  />
)

export default class extends React.PureComponent {
  
  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onClickRow: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
      onClickRow,
      onSort,
    } = this.props

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        className={styles.table}
        onRow={(record) => ({
          onClick: () => onClickRow(record),
        })}
        components={{
          body: {
            wrapper: wrapperProps => DraggableContainer(wrapperProps, data, onSort),
            row: rowProps => DraggableBodyRow(rowProps, data),
          },
        }}
      >

        <Table.Column
          key='sort'
          title=''
          width={30}
          className={styles.dragVisible}
          render={() => <DragHandle />}
        />

        <Table.Column
          key='title'
          title='Title'
          className={styles.dragVisible}
          render={(text, record) => record.title}
        />

        <Table.Column
          key='subtitle'
          title='Subtitle'
          render={(text, record) => record.subtitle}
        />

        <Table.Column
          key='material_type.name'
          title='Type'
          render={(text, record) => record.material_type.name}
        />

        <Table.Column
          key='rate'
          title='Rate'
          render={(text, record) => record.rate}
        />

        <Table.Column
          key='group_rate'
          title='TO Rate'
          render={(text, record) => record.group_rate}
        />

      </Table>

    )

  }

}
