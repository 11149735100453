import { closeOrderNoteModal } from '../State/orderNoteModal'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeOrderNoteModal())

  if (reload) {
    dispatch(fetch())
  }

}
