import axios from 'axios'

export const createBuilderRate = ({ data }) => axios.post(`builder_rates`, data)
export const deleteBuilderRate = ({ id }) => axios.delete(`builder_rates/${id}`)
export const findBuilderRate = ({ id, params } = {}) => axios.get(`builder_rates/${id}`, { params })
export const getBuilderRates = ({ params } = {}) => axios.get(`builder_rates`, { params })
export const updateBuilderRate = ({ id, data }) => axios.put(`builder_rates/${id}`, data)

export const reorderBuilderRates = ({ data }) => axios.put(`builder_rates/reorder`, data)
export const addMaterialRangeToBuilderRate = ({ id, materialRangeId }) => axios.post(`builder_rates/${id}/material_ranges/${materialRangeId}`)
export const removeMaterialRangeFromBuilderRate = ({ id, materialRangeId }) => axios.delete(`builder_rates/${id}/material_ranges/${materialRangeId}`)
export const syncBuilderRateRangeVariants = ({ id, data }) => axios.put(`builder_rates/${id}/range_variants`, data)
