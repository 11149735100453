import { createAction, handleActions } from 'redux-actions'

const setShowDropzone = createAction('SCREENS/ORDER_VIEW/SET_SHOW_DROPZONE')

const defaultState = false

const reducer = handleActions(
  {
    [setShowDropzone]: (state, action) => action.payload,
  },
  defaultState
)

export { setShowDropzone, reducer }