export default (values) => {

  const errors = {};

  if (!values.name) errors.name = 'Required field';
  if (!values.manager_id) errors.manager_id = 'Required field';
  if (!values.town) errors.town = 'Required field';
  if (!values.account_ref) errors.account_ref = 'Required field';

  return errors;
  
}