import modalProps from '../modalProps'
import { getRangeVariants } from '../../../HttpRequests/rangeVariants'
import { setRangeVariants } from '../State/rangeVariants'
import { setFetching } from '../State/fetching'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { materialRange } = modalProps()

  const { search, sort } = getState().modals.RangeVariants.controls

  const params = {
    search,
    'filter[material_range_id]': materialRange.id,
    sort: sort || 'name',
  }

  dispatch(setFetching(true))

  try {

    const response = await getRangeVariants({ params })

    dispatch(setRangeVariants(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
