export default (values) => {

  const errors = {};

  if (!values.tag) errors.tag = true;
  if (!values.dates || values.dates.length === 0) errors.dates = true;
  if (!values.legacy && !values.order_id) errors.order_id = true;
  if (values.legacy && !values.legacy_plot_number) errors.legacy_plot_number = true;
  if (values.legacy && !values.legacy_site) errors.legacy_site = true;

  return errors;
  
}