import { message } from 'antd'
import { find } from 'lodash'
import modalProps from '../modalProps'
import { createAreaMaterialTypes } from '../../../../../HttpRequests/areaMaterialTypes'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch, getState) => {

  const { areas, selectedRows } = getState().screens.PlotType.Areas

  const { areaGroups } = getState().screens.PlotType.AreaGroups

  const areaGroup = find(areaGroups, row => row.id === values.area_group_id)

  const areaMaterialTypes = []

  selectedRows.forEach(areaId => {

    const area = find(areas, row => row.id === areaId)

    const areaMaterialType = find(area.area_material_types.filter(row => !row.area_group_id), row => row.material_type_id === areaGroup.material_type_id)

    areaMaterialTypes.push({
      area_group_id: values.area_group_id,
      area_id: areaId,
      material_type_id: areaGroup.material_type_id,
      dimensions: areaMaterialType ? areaMaterialType.dimensions : [],
      square_metres: areaMaterialType ? areaMaterialType.square_metres : 0,
    })

  })

  const data ={
    area_material_types: areaMaterialTypes,
  } 

  try {

    await createAreaMaterialTypes({ data })

    modalProps().onClose(true)

    message.success('Areas Added to Group')

  } catch (error) {

    handleException(error)

    throw error

  }

}
