import { message } from 'antd'
import modalProps from '../modalProps'
import { deletePlotNote } from '../../../HttpRequests/plotNotes'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  const { plotNote } = modalProps()

  try {

    await deletePlotNote({ id: plotNote.id })

    message.success('Deleted Plot Note')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
