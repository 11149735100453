import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField, SelectField } from 'redux-form-antd-fields';

const labelColSpan = 6;
const wrapperColSpan = 16;

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired
  }

  render () {

    const {
      submitting
    } = this.props;

    return (
      
      <React.Fragment>

        <Field
          name="name"
          label="Name"
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="format"
          label="Format"
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="plots"
          label="Plots"
          component={SelectField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          mode="tags"
          tokenSeparators={[',', ' ']}
          notFoundContent=""
        />

      </React.Fragment>

    )
  }
}