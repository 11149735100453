/* eslint-disable camelcase */
import { Modal } from 'antd';
import { push } from 'connected-react-router'
import exportFile from '../../../Services/exportFile';
import updateOrderOnHoldReason from '../../../Services/updateOnHoldReason';
import markAsCancelled from '../../../Services/markAsCancelled';
import unmarkAsCancelled from '../../../Services/unmarkAsCancelled';
import duplicate from '../../../Services/duplicate';
import sendQuestionnaire from '../../../Services/sendQuestionnaire';

export default ({ key }) => async (dispatch, getState) => {

  const { order } = getState().screens.OrderView

  if (key.startsWith('export:')) {
    const type = key.split(':')[1];
    dispatch(exportFile(type));
  }

  if (key.startsWith('onholdreason:')) {
    const onHoldReasonKey = key.split(':')[1];
    if (onHoldReasonKey) {
      dispatch(updateOrderOnHoldReason(onHoldReasonKey));
    } else {
      dispatch(updateOrderOnHoldReason(null));
    }
  }

  if (key === 'cancel') {
    dispatch(markAsCancelled());
  }

  if (key === 'uncancel') {
    dispatch(unmarkAsCancelled());
  }

  if (key === 'duplicate') {
    Modal.confirm({
      title: `Are you sure to duplicate this order?`,
      okText: 'Yes, Duplicate',
      width: 400,
      onOk: () => dispatch(duplicate())
    })
  }

  if (key === 'builder') {
    const { builder_id, } = getState().screens.OrderView.order
    dispatch(push(`/portal/builders/${builder_id}`));
  }

  if (key === 'site') {
    const { builder_id, site_id, } = getState().screens.OrderView.order
    dispatch(push(`/portal/builders/${builder_id}/sites/${site_id}`));
  }

  if (key === 'plotType') {
    const { builder_id, site_id, plot_type_id, } = getState().screens.OrderView.order
    dispatch(push(`/portal/builders/${builder_id}/sites/${site_id}/plot_types/${plot_type_id}`));
  }

  if (key === 'send_questionnaire') {
    Modal.confirm({
      title: 'Send Questionnaire',
      content: `Are you sure you want to send a questionnaire to ${order.customer_email}?`,
      onOk: () => dispatch(sendQuestionnaire()),
    })
  }

}