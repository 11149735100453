import { find } from 'lodash'
import selectAreaGroups from '../../../../../Selectors/selectAreaGroups'
import selectSiteRates from '../../../../../Selectors/selectSiteRates'
import selectAreaSummary from '../../../../../Selectors/selectAreaSummary'

const findAreaRangeQuote = (siteRates, areaId, materialRangeId) => {

  const $siteRates = siteRates.filter(row => {
    const materialRangeIds = row.material_ranges.map($row => $row.id)
    const areaIds = row.area_range_quotes.map($row => $row.area_id)
    if (!materialRangeIds.includes(materialRangeId)) return false
    if (!areaIds.includes(areaId)) return false
    return true
  })

  if ($siteRates.length !== 1) return null

  return find($siteRates[0].area_range_quotes, row => row.area_id === areaId)

}

const findAreaGroupRangeQuote = (siteRates, areaGroupId, materialRangeId) => {

  const $siteRates = siteRates.filter(row => {
    const materialRangeIds = row.material_ranges.map($row => $row.id)
    const areaGroupIds = row.area_range_quotes.map($row => $row.area_group_id)
    if (!materialRangeIds.includes(materialRangeId)) return false
    if (!areaGroupIds.includes(areaGroupId)) return false
    return true
  })

  if ($siteRates.length !== 1) return null

  return find($siteRates[0].area_range_quotes, row => row.area_group_id === areaGroupId)

}

export default (costSheetQuote, state) => {

  const areaGroups = selectAreaGroups(state)
  const siteRates = selectSiteRates(state)
  const { materialRanges } = selectAreaSummary(state)

  const { cost_sheet: costSheet } = state.screens.CostSheet.order

  const $costSheetQuote = { ...costSheetQuote }

  const areaGroup = find(areaGroups, row => row.id === costSheet.area_group_id)

  // Group - not quotable

  if (areaGroup && areaGroup.is_custom) {
    
    const areaIds = areaGroup.areas.map(row => row.id)

    if (!areaIds.includes($costSheetQuote.area_id)) return $costSheetQuote

    if (materialRanges.length !== 1) return $costSheetQuote

    const { materialRangeId } = materialRanges[0]

    const areaRangeQuote = findAreaRangeQuote(siteRates, $costSheetQuote.area_id, materialRangeId)

    if (areaRangeQuote) {
      $costSheetQuote.price = areaRangeQuote.price_net
    }

    return $costSheetQuote

  }

  // Areas / group
  
  const areas = costSheet.cost_sheet_areas.filter(row => row.area_id === $costSheetQuote.area_id)

  if (areas.length !== 1) return $costSheetQuote

  const { area_id: areaId, material_range_id: materialRangeId, square_metres: squareMetres } = areas[0]

  if (!materialRangeId) return $costSheetQuote

  if (areaGroup && !costSheet.quote_areas) {

    const areaRangeQuote = findAreaGroupRangeQuote(siteRates, areaGroup.id, materialRangeId)

    if (areaRangeQuote) {
      $costSheetQuote.price = (areaRangeQuote.price_net / areaGroup.square_metres) * squareMetres
    }

  } else {

    const areaRangeQuote = findAreaRangeQuote(siteRates, areaId, materialRangeId)

    if (areaRangeQuote) {
      $costSheetQuote.price = areaRangeQuote.price_net
    }

  }

  return $costSheetQuote

}
