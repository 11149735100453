export default (values) => {

  const errors = {};

  if (!values.name) errors.name = 'This field is required';

  if (!values.colour) errors.colour = 'This field is required';

  return errors;
  
}