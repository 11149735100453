import modalProps from '../../../modalProps'
import { openMaterialRangeModal } from '../../../State/materialRangeModal'

export default () => (dispatch) => {

  const { manufacturer } = modalProps()

  dispatch(openMaterialRangeModal({ manufacturerId: manufacturer ? manufacturer.id : null }))

}
