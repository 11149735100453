import { createSelector } from 'reselect'
import numeral from 'numeral'

const selectAreas = state => state.screens.PlotType.Areas.areas

const selectTableRows = createSelector(
  selectAreas,
  (areas) => {

    const tableRows = []

    areas.forEach(area => {

      const areaMaterialTypes = area.area_material_types
        .filter(row => !row.area_group_id)
        .map(row => ({ ...row, square_metres: numeral(row.square_metres).format('0,0.00') }))

      const siteRateIds = area.area_site_rates.map(areaSiteRate => areaSiteRate.site_rate_id)

      tableRows.push({
        id: area.id,
        area,
        areaMaterialTypes,
        siteRateIds,
      })
      
    })
    
    return tableRows

  }

)

export default selectTableRows
