import { createSelector } from 'reselect'
import { get } from 'lodash'
import chance from 'chance'
import float from '../../../Utils/float'

const selectOrder = state => state.screens.CostSheet.order

const getMaterial = ({ material_range: materialRange, range_variant: rangeVariant }) => {
  if (materialRange && rangeVariant) {
    return `${materialRange.name} / ${[rangeVariant.name, rangeVariant.code, rangeVariant.design].filter(item => !!item).join(' • ')}`
  }
  if (materialRange) return materialRange.name
  return ''
}

const getDimensions = ({ dimensions }) => dimensions ? dimensions.map(row => ({
  ...row,
  guid: chance().guid(),
})) : null

export default createSelector(
  selectOrder,
  (order) => order.cost_sheet.cost_sheet_areas.map(costSheetArea => ({
    ...costSheetArea,
    area: get(costSheetArea, 'area.name', ''),
    custom_area: costSheetArea.custom_area || '',
    material: getMaterial(costSheetArea),
    custom_material: costSheetArea.custom_material || '',
    dimensions: getDimensions(costSheetArea),
    custom_dimensions: costSheetArea.custom_dimensions || '',
    square_metres: float(costSheetArea.square_metres),
    custom_square_metres: costSheetArea.custom_square_metres || '',
    notes: costSheetArea.notes || '',
  })),
)
