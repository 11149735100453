import { message } from 'antd'
import modalProps from '../modalProps'
import { createArea } from '../../../../../HttpRequests/areas'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { plotTypeId } = modalProps()

  const data = {
    plot_type_id: plotTypeId,
    name: values.name,
    type: values.type,
  }

  try {

    const response = await createArea({ data })

    modalProps().onClose(response.data.data)

    message.success(`Area Added`)

  } catch (error) {

    handleException(error)

    throw error

  }

}
