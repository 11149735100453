import { combineReducers } from "redux";

import { reducer as fitters } from "./State/fitters";
import { reducer as inspectors } from "./State/inspectors";
import { reducer as pagination } from "./State/pagination";
import { reducer as activeTab } from "./State/activeTab";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showFitterModal } from "./State/showFitterModal";
import { reducer as showInspectorModal } from "./State/showInspectorModal";
import { reducer as selectedFitterId } from "./State/selectedFitterId";
import { reducer as selectedInspectorId } from "./State/selectedInspectorId";


export default combineReducers({
  fitters,
  inspectors,
  pagination,
  activeTab,
  searchTerm,
  fetching,
  showFitterModal,
  showInspectorModal,
  selectedFitterId,
  selectedInspectorId
});
