import { createAction, handleActions } from 'redux-actions'

const setOrder = createAction('SCREENS/COST_SHEET/SET_ORDER')
const setCostSheet = createAction('SCREENS/COST_SHEET/SET_COST_SHEET')
const setCostSheetField = createAction('SCREENS/COST_SHEET/SET_COST_SHEET_FIELD')
const resetOrder = createAction('SCREENS/COST_SHEET/RESET_ORDER')

const defaultState = null

const reducer = handleActions(
  {
    [setOrder]: (state, { payload }) => payload,
    [setCostSheet]: (state, { payload }) => ({ ...state, cost_sheet: payload }),
    [setCostSheetField]: (state, { payload }) => ({
      ...state, cost_sheet: {
        ...state.cost_sheet,
        [payload.field]: payload.value,
      },
    }),
    [resetOrder]: () => defaultState,
  },
  defaultState,
)

export { setOrder, setCostSheet, setCostSheetField, resetOrder, reducer }
