export default (values) => {

  const errors = {};

  if (!values.order_number ) errors.order_number = true;
  if (!values.plot_number ) errors.plot_number = true;
  if (!values.plot_type_id ) errors.plot_type_id = true;
  if (!values.material_type_id ) errors.material_type_id = true;
  if (!values.date) errors.date = true;

  return errors;
  
};
