import { createAction, handleActions } from 'redux-actions'

const setControls = createAction('MODALS/MANUFACTURERS/SET_CONTROLS')
const setControl = createAction('MODALS/MANUFACTURERS/SET_CONTROL')
const resetControls = createAction('MODALS/MANUFACTURERS/RESET_CONTROLS')

const defaultState = {
  search: '',
  sort: null,
}

const reducer = handleActions(
  {
    [setControls]: (state, { payload }) => payload,
    [setControl]: (state, { payload }) => ({ ...state, [payload.control]: payload.value }),
    [resetControls]: () => defaultState,
  },
  defaultState,
)

export { setControls, setControl, resetControls, reducer }
