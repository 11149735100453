import { createAction, handleActions } from 'redux-actions'
import { concat, without } from 'lodash'

const setSelectedRowKeys = createAction('MODALS/COMPLETED_JOBS/SET_SELECTED_ROW_KEYS')
const addSelectedRowKeys = createAction('MODALS/COMPLETED_JOBS/ADD_SELECTED_ROW_KEYS')
const removeSelectedRowKeys = createAction('MODALS/COMPLETED_JOBS/REMOVE_SELECTED_ROW_KEYS')
const clearSelectedRowKeys = createAction('MODALS/COMPLETED_JOBS/CLEAR_SELECTED_ROW_KEYS')

const defaultState = []

const reducer = handleActions(
  {
    [setSelectedRowKeys]: (state, action) => action.payload,
    [addSelectedRowKeys]: (state, action) => concat(state, action.payload),
    [removeSelectedRowKeys]: (state, action) => without(state, ...action.payload),
    [clearSelectedRowKeys]: () => [],
  },
  defaultState
)

export { setSelectedRowKeys, addSelectedRowKeys, removeSelectedRowKeys, clearSelectedRowKeys, reducer }