import { createAction, handleActions } from 'redux-actions'

const setData = createAction('SCREENS/SITE_VIEW/COST_MATRIX/SET_DATA')
const resetData = createAction('SCREENS/SITE_VIEW/COST_MATRIX/RESET_DATA')

const defaultState = {
  columns: [],
  plotTypes: [],
  fetched: false,
}

const reducer = handleActions(
  {
    [setData]: (state, action) => action.payload,
    [resetData]: () => defaultState,
  },
  defaultState,
)

export { setData, resetData, reducer }
