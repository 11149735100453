import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createOrderNote, updateOrderNote } from '../../../../../HttpRequests/orderNotes'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async () => {

  const { orderNote, orderId } = modalProps()

  const data = {
    note: values.note,
  }

  let response

  try {

    if (orderNote) {

      response = await updateOrderNote({ id: orderNote.id, data })

      modalProps().onClose(true)

      message.success(`Order Note Updated`)

    } else {

      data.order_id = orderId

      response = await createOrderNote({ data })

      modalProps().onClose(response.data.data)

      message.success(`Order Note Added`)

    }

    return true

  } catch (error) {

    handleException(error)
    
    throw error

  }
  
}
