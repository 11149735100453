import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './AppBar.css'

import AppBarLeft from './AppBarLeft'
import AppBarRight from './AppBarRight'
import AppBarButton from './AppBarButton'

const AppBar = (props) => {

  const {
    children,
    className,
    style,
  } = props

  return (

    <div
      className={classNames(styles.appBar, className)}
      style={style}
    >
      {children}
    </div>

  )
}

AppBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

AppBar.defaultProps = {
  className: undefined,
  style: undefined,
}

AppBar.Left = AppBarLeft
AppBar.Right = AppBarRight
AppBar.Button = AppBarButton

export default AppBar