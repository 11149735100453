import { createSelector } from "reselect";
import { find, findIndex, slice } from 'lodash';
import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../../../Form';

const selectOrderNumbers = state => state.core.orderNumbers;
const selectOrderSearchTerm = state => state.modals.Job.orderSearchTerm;
const selectOrderId = state => formValueSelector(FORM_NAME)(state, 'order_id');

export default createSelector(
  selectOrderNumbers,
  selectOrderSearchTerm,
  selectOrderId,
  (orderNumbers, orderSearchTerm, orderId) => {

    if (orderSearchTerm.length > 3) {

      const position = findIndex(orderNumbers, item => String(item.order_number || item.legacy_order_number).startsWith(orderSearchTerm));

      if (position > -1) {
        return slice(orderNumbers, position, position+100)
      }
    }

    if (!orderSearchTerm && orderId) {
      
      const orderNumber = find(orderNumbers, item => item.id === orderId);

      if (orderNumber) {
        return [ orderNumber ];
      }
    }

    return [];
  }
)