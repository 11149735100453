import { message } from 'antd'
import { createOrder } from '../../../HttpRequests/orders';

import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {

  try {
    const data = { ...values }

    await createOrder({ data });

    message.success('Order Created');

    return true;
    
  }  catch(error) {
    
    handleException(error);

    return false;

  }
}
