import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Table, Typography, Tag, Empty } from 'antd'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './styles.css'

const AreaGroups = props => {

  const {
    areaGroups,
    selectedRows,
    onEditAreaGroup,
    onAddAreaMaterialType,
    onClickDimensions,
    onEditAreaMaterialType,
    onChangeSelectedRows,
  } = props

  if (!areaGroups.length) return <Empty />

  return (

    <div>

      {areaGroups.map(areaGroup => (

        <div key={areaGroup.id} className={styles.areaGroupWrapper}>

          <div className={styles.areaGroupHeader}>
            <div>
              <div className={styles.headerWrapper}>
                <div className={styles.header}>{areaGroup.name}</div>
                &nbsp;
                {!!areaGroup.is_custom && <Tag color='blue'>Custom</Tag>}
              </div>
              <h3 className={styles.subheader}>{areaGroup.material_type.name}</h3>
            </div>
            <div className={styles.headerButtons}>
              <Button onClick={() => onEditAreaGroup(areaGroup.id)}>
                <Icon type='edit' />
                Edit Group
              </Button>
              <div style={{ width: 10 }} />
              <Button type='primary' onClick={() => onAddAreaMaterialType(areaGroup.id, areaGroup.material_type_id)}>
                <Icon type='plus' />
                Add Line
              </Button>
            </div>
          </div>

          <Table
            dataSource={orderBy(areaGroup.area_material_types, 'area.order_no')}
            rowKey='id'
            bordered
            className={styles.table}
            pagination={false}
            rowSelection={{
              selectedRowKeys: selectedRows,
              onChange: onChangeSelectedRows,
            }}
          >

            <Table.Column
              key='area.name'
              title='Area'
              render={(text, record) => record.area.name}
            />

            <Table.Column
              key='square_metres'
              title='M2'
              width={150}
              onHeaderCell={() => ({
                className: classNames(styles.numeric),
              })}
              onCell={record => ({
                className: classNames(styles.numeric, styles.clickable),
                onClick: () => onClickDimensions(record.id, areaGroup.id, record.dimensions),
              })}
              render={(text, record) => (
                <div style={{ width: '100%', height: '100%' }}>
                  {record.dimensions.map((dimension, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{`${dimension.length} x ${dimension.width}`}</div>
                  ))}
                </div>
              )}
            />

            <Table.Column
              key='notes'
              title='Notes'
              width={600}
              onCell={() => ({
                className: classNames(styles.clickable),
              })}
              render={(text, record) => (
                <Typography.Text
                  editable={{ onChange: value => value !== record.notes && onEditAreaMaterialType(record.id, 'notes', value) }}
                >
                  {`${record.notes || ''}`}
                </Typography.Text>
              )}
            />

          </Table>

          <div className={styles.totals}>
            <div className={styles.total}>{numeral(areaGroup.area_material_types.reduce((total, row) => total + Number(row.square_metres), 0)).format('0,0.00')}</div>
          </div>

        </div>

      ))}

    </div>

  )

}

AreaGroups.propTypes = {
  areaGroups: PropTypes.array.isRequired,
  selectedRows: PropTypes.array.isRequired,
  onEditAreaGroup: PropTypes.func.isRequired,
  onAddAreaMaterialType: PropTypes.func.isRequired,
  onClickDimensions: PropTypes.func.isRequired,
  onEditAreaMaterialType: PropTypes.func.isRequired,
  onChangeSelectedRows: PropTypes.func.isRequired,
}

export default AreaGroups
