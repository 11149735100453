import { connect } from 'react-redux'
import SiteMaterialRanges from './SiteMaterialRanges'

import componentDidMount from './Handlers/componentDidMount'

const mapStateToProps = () => ({
})

const actionCreators = {
  componentDidMount,
}

export default connect(mapStateToProps, actionCreators)(SiteMaterialRanges)
