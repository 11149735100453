import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import DimensionsModal from '../Dimensions'

import Form from './Components/Form'

const SupplierRate = props => {

  const {
    supplierRate,
    submitting,
    dimensionsModal,
    componentDidMount,
    componentWillUnmount,
    onSubmit,
    onDelete,
    onCloseDimensionsModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  const footer = []

  if (supplierRate) footer.push(
    <Button key='delete' type='danger' onClick={onDelete} style={{ float: 'left' }}>
      Delete
    </Button>,
  )

  footer.push(
    <Button key='cancel' onClick={() => onClose()}>
      Cancel
    </Button>,
    <Button key='save' type='primary' loading={submitting} onClick={onSubmit}>
      {supplierRate ? 'Update' : 'Create'}
    </Button>,
  )

  return (

    <Modal
      visible
      title={supplierRate ? 'Supplier Rate' : 'New Supplier Rate'}
      width={500}
      onCancel={() => onClose()}
      maskClosable={false}
      footer={footer}
    >

      <Form />

      {dimensionsModal.show && (
        <DimensionsModal
          {...dimensionsModal}
          showTotal={false}
          onClose={onCloseDimensionsModal}
        />
      )}

    </Modal>

  )

}

SupplierRate.propTypes = {
  supplierRate: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  dimensionsModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCloseDimensionsModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

SupplierRate.defaultProps = {
  supplierRate: null,
}

export default SupplierRate
