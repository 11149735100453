import loadingIndicator from '../../resources/images/loading.gif';
import loginLogo from '../../resources/images/login-logo.png';

const lightgrey = '#FAFAFA';
const darkgrey = '#666';
const red = '#CF1437';

export default {

  header: {
    barColor: darkgrey
  },

  startup: {
    loadingIndicator
  },

  login: {
    brandingPanelColor: lightgrey,
    brandingPanelLogo: loginLogo,
    inputBorderColorFocused: red,
    submitButtonColor: red
  }

}