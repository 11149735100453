import { setCostSheetField } from '../../../State/order'

export default () => (dispatch) => {

  dispatch(setCostSheetField({ field: 'anc_cost', value: null }))
  dispatch(setCostSheetField({ field: 'rebate_cost', value: null }))

  dispatch(setCostSheetField({ field: 'cost_sheet_materials', value: [] }))

}
