import { find } from 'lodash'
import { resetData as resetManufacturerData } from '../Components/Manufacturers/State/data'
import { resetSearchTerm as resetManufacturerSearchTerm } from '../Components/Manufacturers/State/searchTerm'
import { resetSorting as resetManufacturerSorting } from '../Components/Manufacturers/State/sorting'
import { resetPagination as resetManufacturerPagination } from '../Components/Manufacturers/State/pagination'
import { resetData as resetMaterialRangeData } from '../Components/MaterialRanges/State/data'
import { setMaterialType } from '../Components/MaterialRanges/State/materialType'
import { resetSearchTerm as resetMaterialRangeSearchTerm } from '../Components/MaterialRanges/State/searchTerm'
import { resetSorting as resetMaterialRangeSorting } from '../Components/MaterialRanges/State/sorting'
import { resetPagination as resetMaterialRangePagination } from '../Components/MaterialRanges/State/pagination'
import { setActiveTab } from '../State/activeTab'
import fetchMaterialRanges from '../Components/MaterialRanges/Services/fetch'

export default (key) => (dispatch, getState) => {

  dispatch(resetManufacturerData())
  dispatch(resetManufacturerSearchTerm())
  dispatch(resetManufacturerSorting())
  dispatch(resetManufacturerPagination())

  dispatch(resetMaterialRangeData())
  dispatch(resetMaterialRangeSearchTerm())
  dispatch(resetMaterialRangeSorting())
  dispatch(resetMaterialRangePagination())

  dispatch(setActiveTab(key))

  if (key === 'manufacturers' || key === 'suppliers' || key === 'material-types') return

  const { materialTypes } = getState().core

  // eslint-disable-next-line eqeqeq
  const materialType = find(materialTypes, row => row.id == key)

  dispatch(setMaterialType(materialType))

  dispatch(fetchMaterialRanges())

}
