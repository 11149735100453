import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './Heading.css'

const Heading = (props) => {

  const {
    level,
    children,
    className,
    style,
  } = props

  const HeadingTag = `h${level}`

  return (

    <span className={styles.heading}>

      <HeadingTag
        className={classNames(styles.heading, className)}
        style={style}
      >
        {children}
      </HeadingTag>
      
    </span>

  )
}

Heading.propTypes = {
  level: PropTypes.oneOf([1,2,3,4,5,6]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

Heading.defaultProps = {
  level: 1,
  className: undefined,
  style: undefined,
}

export default Heading