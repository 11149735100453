import handleException from '../../../Core/Helpers/handleException';
import { getMaterialTypes } from '../../../HttpRequests/materialTypes';
import { loadMaterialTypes } from '../State/materialTypes';

export default () => async (dispatch) => {

  try {

    const params = {
      pagination: 0
    }

    const response = await getMaterialTypes({ params });

    dispatch(loadMaterialTypes(response.data.data));

  } catch(error) {

    handleException(error);

  }
}