import React from 'react'
import PropTypes from 'prop-types'
import HeightSpacer from '../../../HeightSpacer'
import float from '../../../../Utils/float'

import Controls from './Components/Controls'
import Headings from './Components/Headings'
import Item from './Components/Item'
import Static from './Components/Static'
import Placeholder from './Components/Placeholder'

const Expenses = props => {

  const {
    order,
    items,
  } = props

  return (
    
    <React.Fragment>

      <Controls />

      <HeightSpacer type='controls' />

      {!!items.length && <Headings />}

      {items.map(item => <Item key={item.guid} item={item} />)}

      {!!items.length && <Static item={{ title: 'Travel', cost: float(order.cost_sheet.travel_cost), field: 'travel_cost' }} />}

      {!items.length && <Placeholder />}

    </React.Fragment>
    
  )

}

Expenses.propTypes = {
  order: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
}

export default Expenses
