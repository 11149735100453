import { setPagination } from '../../../State/pagination';
import fetchFitters from '../../../Services/fetchFitters';
import fetchInspectors from '../../../Services/fetchInspectors';

export default () => async (dispatch, getState) => {

  const { activeTab } = getState().screens.Fitters

  dispatch(setPagination({ current: 1 }));

  if (activeTab === 'fitters') {
    dispatch(fetchFitters());
  }

  if (activeTab === 'inspectors') {
    dispatch(fetchInspectors());
  }
  
}