import { setPagination } from '../../../State/pagination';
import fetchInspectors from '../../../Services/fetchInspectors';

export default (pagination) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  await dispatch(fetchInspectors());

}