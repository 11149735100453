import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field } from 'redux-form';
import { Comment, Avatar, Typography, Button } from 'antd';
import { TextAreaField } from 'redux-form-antd-fields';
import Panel from '../Panel';
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    user: PropTypes.object.isRequired,
    orderLogs: PropTypes.array.isRequired,
    hasEnteredContent: PropTypes.bool.isRequired,
    submitting: PropTypes.bool,
    onEditContent: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: null
  }
  
  render () {

    const {
      user,
      orderLogs,
      hasEnteredContent,
      submitting,
      onEditContent,
      onClickSubmit,
    } = this.props;

    return (

      <React.Fragment>

        <br />

        <Panel
          title="Logs and Comments"
        >

          <div className={styles.wrapper}>

            {orderLogs.map(orderLog => (

              <Comment
                key={orderLog.id}
                author={`${orderLog.user.first_name} ${orderLog.user.last_name}`}
                avatar={(
                  orderLog.system_generated ? (
                    <Avatar
                      icon="rocket"
                      size="large"
                    />
                  ) : (
                    <Avatar
                      className={styles.userAvatar}
                      size="large"
                    >
                      {`${orderLog.user.first_name.charAt(0)}${orderLog.user.last_name.charAt(0)}`}
                    </Avatar>
                  )
                )}
                content={(!orderLog.system_generated && orderLog.user_id === user.id) ? (
                  <Typography.Text
                    editable={{ onChange: value => value !== orderLog.content && onEditContent(orderLog.id, value) }}
                  >
                    {orderLog.content}
                  </Typography.Text>
                ) : orderLog.content}
                datetime={(
                  <span>{`${moment(orderLog.created_at).format('ddd Do MMM YYYY [at] h:mma')} (${moment(orderLog.created_at).fromNow()})`}</span>
                )}
              />

            ))}

            <Comment
              avatar={(

                <Avatar
                  className={styles.userAvatar}
                  size="large"
                >
                  {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
                </Avatar>

              )}
              content={(

                <React.Fragment>

                  <Field
                    name="content"
                    disabled={submitting}
                    component={TextAreaField}
                    rows={4}
                    placeholder="Write a message ..."
                  />
                  
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={onClickSubmit}
                    loading={submitting}
                    disabled={!hasEnteredContent || submitting}
                    className={styles.submitButton}
                  >
                    Add Comment
                  </Button>

                </React.Fragment>

              )}
            />

          </div>
        
        </Panel>

      </React.Fragment>

    )
  }
}