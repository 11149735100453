export default (values) => {

  const errors = {};

  if (!values.name) errors.name = 'Required field';

  if (!values.format) errors.format = 'Required field';

  return errors;
  
}