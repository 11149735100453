import { message } from 'antd';
import { getProps } from '../props';
import { deleteBuilder } from '../../../HttpRequests/builders';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { builderId, onClose, didDelete } = getProps();

    await deleteBuilder({ id: builderId });
    
    message.success("Builder Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}