import { createAction, handleActions } from 'redux-actions';

const setSelectedRowKeys = createAction('SCREENS/ORDERS/SET_SELECTED_ROW_KEYS');

const defaultState = [];

const reducer = handleActions(
  {
    [setSelectedRowKeys]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedRowKeys, reducer };
