import { message } from 'antd'
import { createJob } from '../../../HttpRequests/jobs';
import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {

  try {
    
    const data = { ...values }

    await createJob({ data });

    message.success('Job Created');

    return true;
    
  } catch (error) {
    
    handleException(error);

    return false;

  }
}
