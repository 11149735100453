import { closeAddMaterialRangeModal } from '../State/addMaterialRangeModal'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeAddMaterialRangeModal())

  if (reload) {
    dispatch(fetch())
  }

}
