import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import tagColorMap from "../../../../Core/Misc/tagColorMap";
import styles from "./Component.css";

export default class extends React.PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render () {

    const {
      data: { tag, count, },
    } = this.props;

    return (

      <div
        role="presentation"
        className={classnames({
          [styles.wrapper]: true,
          [styles[`tag${tagColorMap[tag]}`]]: true
        })}
      >
        {`${count}x ${tag}`}
      </div>

    )
  }
}