import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteAreaGroup } from '../../../HttpRequests/areaGroups'
import { handleException } from '../../../../packages/sota'

export default () => async () => {

  const { id } = modalProps()

  try {

    await deleteAreaGroup({ id })

    message.success('Deleted Group')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
