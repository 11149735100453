import * as React from "react";
import PropTypes from "prop-types";
import { Screen } from "evolve-portal";

import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import Table from "./Components/Table";
import BuilderModal from "../../Modals/Builder";

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    showBuilderModal: PropTypes.bool.isRequired,
    onCloseBuilderModal: PropTypes.func.isRequired,
    didCreateBuilder: PropTypes.func.isRequired
  };

  static defaultProps = {

  };

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      showBuilderModal,
      onCloseBuilderModal,
      didCreateBuilder
    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header />

        <Table />

        {showBuilderModal && (
          <BuilderModal
            onClose={onCloseBuilderModal}
            didCreate={didCreateBuilder}
          />
        )}

      </Screen>

    )
  }
}
