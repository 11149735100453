import axios from 'axios'

export const createSite = ({ data }) => axios.post(`sites`, data)
export const deleteSite = ({ id }) => axios.delete(`sites/${id}`)
export const findSite = ({ id, params } = {}) => axios.get(`sites/${id}`, { params })
export const getSites = ({ params } = {}) => axios.get(`sites`, { params })
export const updateSite = ({ id, data }) => axios.put(`sites/${id}`, data)

export const archiveSite = ({ id, data }) => axios.put(`sites/${id}/archive`, data)
export const unarchiveSite = ({ id, data }) => axios.put(`sites/${id}/unarchive`, data)
export const regenerateSiteCostMatrix = ({ id } = {}) => axios.put(`sites/${id}/cost_matrix`)
export const getSiteCostMatrix = ({ id, params } = {}) => axios.get(`sites/${id}/cost_matrix`, { params })
