import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Beforeunload from 'react-beforeunload'
import { Prompt } from 'react-router-dom'
import { Button, Popconfirm } from 'antd'
import { Screen } from 'evolve-portal'
import MaterialTypesModal from '../../Modals/MaterialTypes'
import ManufacturersModal from '../../Modals/Manufacturers'
import MaterialRangesModal from '../../Modals/MaterialRanges'
import SuppliersModal from '../../Modals/Suppliers'
import FittingRatesModal from '../../Modals/FittingRates'
import styles from './styles.css'

import Initializing from './Components/Initializing'
import Banner from './Components/Banner'
import Header from './Components/Header'
import Areas from './Components/Areas'
import Materials from './Components/Materials'
import Footer from './Components/Footer'
import Notes from './Components/Notes'
import Controls from './Components/Controls'
import HeightSpacer from './Components/HeightSpacer'

const CostSheet = props => {

  const {
    order,
    editing,
    materialTypesModal,
    manufacturersModal,
    materialRangesModal,
    suppliersModal,
    fittingRatesModal,
    componentDidMount,
    componentWillUnmount,
    onClickBack,
    onCloseMaterialTypesModal,
    onCloseManufacturersModal,
    onCloseMaterialRangesModal,
    onCloseSuppliersModal,
    onCloseFittingRatesModal,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  if (!order) return <Initializing />

  const confirmationText = 'You have unsaved changes. Are you sure you want to continue?'

  return (

    <Screen
      maxWidth={920}
      style={{ backgroundColor: '#eeeeee' }}
      innerStyle={{ minHeight: window.innerHeight, marginBottom: 40 }}
    >

      {editing && <Beforeunload onBeforeunload={() => confirmationText} />}

      <Prompt
        when={editing}
        message={() => confirmationText}
      />

      {editing ? (
        <Popconfirm title={confirmationText} onConfirm={onClickBack}>
          <Button icon='caret-left'>Order</Button>
        </Popconfirm>
      ) : (
        <Button icon='caret-left' onClick={onClickBack}>Order</Button>
      )}

      <HeightSpacer type='block' />

      <div className={styles.sheet} style={{ pointerEvents: editing ? 'all' : 'none' }}>

        <Banner />

        <HeightSpacer type='block' />

        <Header />

        <HeightSpacer type='block' />

        <Areas />

        {!!order.cost_sheet.material_type_id && (

          <React.Fragment>

            <HeightSpacer type='block' />

            <Materials />

            <HeightSpacer type='block' />

            <Footer />

            <HeightSpacer type='block' />

            <Notes />

          </React.Fragment>

        )}

      </div>

      <Controls />

      {materialTypesModal.show && (
        <MaterialTypesModal
          {...materialTypesModal}
          onClose={onCloseMaterialTypesModal}
        />
      )}

      {manufacturersModal.show && (
        <ManufacturersModal
          {...manufacturersModal}
          onClose={onCloseManufacturersModal}
        />
      )}

      {materialRangesModal.show && (
        <MaterialRangesModal
          {...materialRangesModal}
          onClose={onCloseMaterialRangesModal}
        />
      )}

      {suppliersModal.show && (
        <SuppliersModal
          {...suppliersModal}
          onClose={onCloseSuppliersModal}
        />
      )}

      {fittingRatesModal.show && (
        <FittingRatesModal
          {...fittingRatesModal}
          onClose={onCloseFittingRatesModal}
        />
      )}

    </Screen>

  )

}

CostSheet.propTypes = {
  order: PropTypes.object,
  editing: PropTypes.bool.isRequired,
  materialTypesModal: PropTypes.object.isRequired,
  manufacturersModal: PropTypes.object.isRequired,
  materialRangesModal: PropTypes.object.isRequired,
  suppliersModal: PropTypes.object.isRequired,
  fittingRatesModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onCloseMaterialTypesModal: PropTypes.func.isRequired,
  onCloseManufacturersModal: PropTypes.func.isRequired,
  onCloseMaterialRangesModal: PropTypes.func.isRequired,
  onCloseSuppliersModal: PropTypes.func.isRequired,
  onCloseFittingRatesModal: PropTypes.func.isRequired,
}

CostSheet.defaultProps = {
  order: null,
}

export default CostSheet
