import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.css'

const Placeholder = props => {

  const {
    editing,
    onClickGenerate,
  } = props

  return (
    
    <div className={styles.wrapper}>

      {editing && (

        <div>
          Generate
          {' '}
          <a onClick={onClickGenerate}>fitting</a>
          {' '}
          automatically
        </div>

      )}

    </div>
    
  )

}

Placeholder.propTypes = {
  editing: PropTypes.bool.isRequired,
  onClickGenerate: PropTypes.func.isRequired,
}

export default Placeholder
