import { connect } from 'react-redux'
import BuilderRateRangeVariantsModal from './BuilderRateRangeVariantsModal'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onToggleRangeVariant from './Handlers/onToggleRangeVariant'
import onClickSave from './Handlers/onClickSave'

const mapStateToProps = (state, { materialRange }) => ({
  materialRange,
  selectedRangeVariantIds: state.modals.BuilderRate.BuilderRateRangeVariantsModal.selectedRangeVariantIds,
  saving: state.modals.BuilderRate.BuilderRateRangeVariantsModal.saving,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onToggleRangeVariant,
  onClickSave,
}

export default connect(mapStateToProps, actionCreators)(BuilderRateRangeVariantsModal)
