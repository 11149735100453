import * as React from "react";
import PropTypes from "prop-types";
import { Button, Tabs } from "antd";
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    site: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onChangeTab: PropTypes.func.isRequired
  };

  render () {

    const {
      site,
      activeTab,
      onClickEdit,
      onChangeTab
    } = this.props;

    return (

      <div>

        <div className={styles.upper}>

          <h1>
            {site.name}
          </h1>

          <div className={styles.buttons}>

            <Button
              type="default"
              onClick={onClickEdit}
              icon="edit"
            >
              Edit Site
            </Button>

          </div>

        </div>

        <div className={styles.lower}>

          <Tabs onChange={onChangeTab} activeKey={activeTab}>

            <Tabs.TabPane
              key="PLOT_TYPES"
              tab="Plot Types"
            />

            <Tabs.TabPane
              key="SITE_RATES"
              tab="Rates"
            />

            <Tabs.TabPane
              key="SITE_MATERIAL_RANGES"
              tab="Materials"
            />

            <Tabs.TabPane
              key="COST_MATRIX"
              tab="Cost Matrix"
            />

          </Tabs>

        </div>

      </div>

    )
  }
}
