import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openAvailableFittersModal = createAction('MODALS/JOB/OPEN_AVAILABLE_FITTERS_MODAL')
const closeAvailableFittersModal = createAction('MODALS/JOB/CLOSE_AVAILABLE_FITTERS_MODAL')

const defaultState = {
  show: false,
  tag: null,
  dates: null,
}

const reducer = handleActions(
  {
    [openAvailableFittersModal]: (state, action) => Object.assign({}, state, {
      show: true,
      tag: get(action.payload, 'tag', null),
      dates: get(action.payload, 'dates', null),
    }),
    [closeAvailableFittersModal]: () => Object.assign({}, defaultState),
  },
  defaultState
)

export { openAvailableFittersModal, closeAvailableFittersModal, reducer }