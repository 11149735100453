import React from 'react'
import PropTypes from 'prop-types'
import HeightSpacer from '../../../HeightSpacer'
import styles from './styles.css'

import Controls from './Components/Controls'

const Summary = props => {

  const {
    summary,
  } = props

  return (

    <React.Fragment>

      <Controls />

      <HeightSpacer type='controls' />

      <div className={styles.section}>

        {summary.materialTypes.map(({ key, label, value }) => (
          <div key={key} className={styles.detail}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value}</div>
          </div>
        ))}

        {!!summary.sale && (
          <div className={styles.detail}>
            <div className={styles.label}>Sale</div>
            <div className={styles.value}>{summary.sale}</div>
          </div>
        )}

        {!!summary.contractWorks && (
          <div className={styles.detail}>
            <div className={styles.label}>C/W</div>
            <div className={styles.value}>{summary.contractWorks}</div>
          </div>
        )}

        {!!summary.customerExtra && (
          <div className={styles.detail}>
            <div className={styles.label}>C/E</div>
            <div className={styles.value}>{summary.customerExtra}</div>
          </div>
        )}

        <div className={styles.detail}>
          <div className={styles.label}>Cost</div>
          <div className={styles.value}>{summary.cost}</div>
        </div>

        <div className={styles.detail}>
          <div className={styles.label}>Profit</div>
          <div className={styles.value}>{summary.profit}</div>
        </div>

        <div className={styles.detail}>
          <div className={styles.label}>Markup</div>
          <div className={styles.value}>{summary.markup}%</div>
        </div>

      </div>

    </React.Fragment>

  )

}

Summary.propTypes = {
  summary: PropTypes.object.isRequired,
}

export default Summary
