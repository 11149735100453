import chance from 'chance'
import { setCostSheetField } from '../../../../../State/order'

export default (values = {}) => (dispatch, getState) => {

  const { cost_sheet_quotes: costSheetQuotes } = getState().screens.CostSheet.order.cost_sheet

  const newCostSheetQuotes = [
    ...costSheetQuotes,
    { guid: chance().guid(), ...values },
  ]

  dispatch(setCostSheetField({ field: 'cost_sheet_quotes', value: newCostSheetQuotes }))

}
