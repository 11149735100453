import { message } from 'antd'
import { getProps } from '../props'
import { updateOrder } from '../../../HttpRequests/orders'
import fetchOrder from './fetchOrder'
import handleException from '../../../Core/Helpers/handleException'

export default (orderValues) => async (dispatch) => {

  const { id } = getProps().match.params
  
  try {

    await updateOrder({ id, data: orderValues })
    
    message.success('Order Updated')

    await dispatch(fetchOrder())

    return true

  } catch (error) {
    
    handleException(error);

    return false

  }

}
