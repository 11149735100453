import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import { Table, Button, Icon } from 'antd';
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    fitterInvoices: PropTypes.array.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickFitterInvoice: PropTypes.func.isRequired
  }

  renderHeader = () => (

    <div className={styles.header}>

      <div className={styles.title}>
        Fitter Invoices
      </div>

      <Button onClick={this.props.onClickAdd}>
        <Icon type="plus-circle" />
        Add Invoice
      </Button>

    </div>

  )
  
  render () {

    const {
      fitterInvoices,
      onClickFitterInvoice
    } = this.props;

    const columns = [

      {
        title: 'Fitter',
        dataIndex: 'fitter.name',
        width: 170,
        render: (text, record) => (
          <div onClick={() => onClickFitterInvoice(record.id)} role="presentation" className={styles.link}>
            {record.fitter ? record.fitter.name : 'Unallocated'}
          </div>
        )
      },
    
      { 
        title: 'Date', 
        dataIndex: 'date',
        width: 100,
        render: (text, record) => moment(record.date).format('D MMM YYYY')
      },
    
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: 100,
        render: (text, record) => numeral(record.amount).format('0,0.00')
      },
      
      {
        title: 'Notes',
        dataIndex: 'notes',
        width: 100,
        render: (text, record) => record.notes
      }
      
    ];

    return (

      <Table
        dataSource={fitterInvoices}
        columns={columns} 
        rowKey={record => record.id}
        pagination={false}
        bordered
        title={this.renderHeader}
        locale={{ emptyText: <b>There are no fitter invoices for this order yet</b>}}
      />

    )
  }
}