import { createAction, handleActions } from 'redux-actions';

const loadPlotTypes = createAction("MODALS/ORDERS/LOAD_PLOT_TYPES");

const defaultState = [];

const reducer = handleActions(
  {
    [loadPlotTypes]: (state, action) => action.payload
  },
  defaultState
);

export { loadPlotTypes, reducer };