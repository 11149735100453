import { connect } from 'react-redux'
import Component from './Component'

import onClickCancel from './Handlers/onClickCancel'
import onClickSubmit from './Handlers/onClickSubmit'

const mapStateToProps = () => ({})

const actionCreators = {
  onClickCancel,
  onClickSubmit,
}

export default connect(mapStateToProps, actionCreators)(Component)