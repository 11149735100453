import modalProps from '../modalProps'
import fetchMaterialTypes from '../../../Core/Services/fetchMaterialTypes'
import fetchManufacturers from '../../../Core/Services/fetchManufacturers'
import initializeForm from '../Components/Form/Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchMaterialTypes())
  dispatch(fetchManufacturers())
  
  dispatch(initializeForm())
  
}
