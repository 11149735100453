import { find } from 'lodash'
import selectAreaGroups from '../../../../../Selectors/selectAreaGroups'
import guessAreaPrice from '../Utils/guessAreaPrice'
import addItem from './addItem'

export default () => (dispatch, getState) => {

  const state = getState()

  const areaGroups = selectAreaGroups(state)

  const { cost_sheet: costSheet } = state.screens.CostSheet.order

  const areaGroup = find(areaGroups, row => row.id === costSheet.area_group_id)

  if (!areaGroup || !areaGroup.is_custom) {

    costSheet.cost_sheet_areas.forEach(({ area_id: areaId, area }) => {
  
      if (!areaId) return
      
      let costSheetQuote = {
        area_id: areaId,
        area,
      }
  
      costSheetQuote = guessAreaPrice(costSheetQuote, state)
  
      dispatch(addItem(costSheetQuote))
  
    })

    return

  }

  areaGroup.areas.forEach(area => {
    
    let costSheetQuote = {
      area_id: area.id,
      area,
    }

    costSheetQuote = guessAreaPrice(costSheetQuote, state)

    dispatch(addItem(costSheetQuote))

  })

}
