import { combineReducers } from 'redux'

import { reducer as dimensionsModal } from './State/dimensionsModal'

import Form from './Components/Form/reducer'

export default combineReducers({
  dimensionsModal,
  
  Form,
})
