import { message } from 'antd'
import modalProps from '../../../modalProps'
import { createMaterialType, updateMaterialType } from '../../../../../HttpRequests/materialTypes'
import fetchMaterialTypes from '../../../../../Core/Services/fetchMaterialTypes'
import { handleException } from '../../../../../../packages/sota'

export default (values) => async (dispatch) => {

  const { materialType } = modalProps()

  const data = {
    name: values.name,
    usage_key: values.usage_key,
    is_flooring: values.is_flooring || false,
    is_quotable: values.is_quotable || false,
    vat_perc: values.vat_perc,
    anc_coverage: values.anc_coverage,
    anc_rate: values.anc_rate,
    anc_min_amount: values.anc_min_amount,
    rebate_rate: values.rebate_rate,
  }

  try {

    if (materialType) {
      await updateMaterialType({ id: materialType.id, data })
      message.success('Updated Material')
    } else {
      await createMaterialType({ data })
      message.success('Created Material')
    }

    modalProps().onClose(true)

    dispatch(fetchMaterialTypes({ force: true }))

  } catch (error) {

    handleException(error)

    throw error

  }

}
