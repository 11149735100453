import { connect } from 'react-redux'
import RangeVariants from './RangeVariants'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseRangeVariantModal from './Handlers/onCloseRangeVariantModal'

const mapStateToProps = (state) => ({
  rangeVariantModal: state.modals.RangeVariants.rangeVariantModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseRangeVariantModal,
}

export default connect(mapStateToProps, actionCreators)(RangeVariants)
