import { connect } from 'react-redux';
import Component from './Component';

import onChangeTable from './Handlers/onChangeTable';
import onClickItem from './Handlers/onClickItem';

const mapStateToProps = (state) => ({
  inspectors: state.screens.Fitters.inspectors,
  pagination: state.screens.Fitters.pagination,
  fetching: state.screens.Fitters.fetching
});

const actionCreators = {
  onChangeTable,
  onClickItem
};

export default connect(mapStateToProps, actionCreators)(Component);
