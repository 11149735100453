import { setData } from '../../../State/data'
import reorder from '../../../Services/reorder'

export default (data) => (dispatch) => {

  dispatch(setData(data))

  dispatch(reorder(data.map(row => row.id)))

}
