import modalProps from '../modalProps'
import { findAreaGroup } from '../../../HttpRequests/areaGroups'
import { setAreaGroup } from '../State/areaGroup'
import { setSelectedAreaIds } from '../State/selectedAreaIds'
import { handleException } from '../../../../packages/sota'

export default () => async (dispatch) => {

  const { id } = modalProps()

  if (!id) return

  const params = {
    include: 'areas',
  }

  try {

    const response = await findAreaGroup({ id, params })

    const areaGroup = response.data.data

    dispatch(setAreaGroup(areaGroup))

    dispatch(setSelectedAreaIds(areaGroup.areas.map(area => area.id)))

  } catch (error) {

    handleException(error)

  }

}
