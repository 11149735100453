import { initialize } from 'redux-form';
import moment from 'moment';
import { findInspectorVisit } from '../../../HttpRequests/inspectorVisits';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { inspectorVisitId } = getProps();

  if (inspectorVisitId) {

    const params = {
      include: 'site',
    }

    const response = await findInspectorVisit({ id: inspectorVisitId, params });

    const inspectorVisit = response.data.data;

    dispatch(initialize(FORM_NAME, {
      ...inspectorVisit,
      builder_id: inspectorVisit.site.builder_id,
    }));
    
  } else {

    dispatch(initialize(FORM_NAME, {
      
    }))

  }
}