import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import numeral from 'numeral'
import styles from './styles.css'

const formats = {
  'dimensions': 'Dimensions (Length X Width)',
  'square-metres': 'Square Metres (M2)',
  'item': 'Item (None)',
}

export default class extends React.PureComponent {
  
  static propTypes = {
    data: PropTypes.array.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClickRow: PropTypes.func.isRequired,
  }

  render() {

    const {
      data,
      isFetching,
      onChange,
      onClickRow,
    } = this.props

    return (

      <Table
        dataSource={data}
        loading={isFetching}
        rowKey='id'
        pagination={false}
        onChange={onChange}
        className={styles.table}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id),
        })}
      >

        <Table.Column
          key='name'
          title='Name'
          sorter
          render={(text, record) => record.name}
        />

        <Table.Column
          key='usage_key'
          title='Unit'
          sorter
          render={(text, record) => formats[record.usage_key]}
        />

        <Table.Column
          key='is_flooring'
          title='Flooring'
          sorter
          render={(text, record) => record.is_flooring ? 'Yes' : null}
        />

        <Table.Column
          key='vat_perc'
          title='VAT'
          sorter
          render={(text, record) => `${numeral(record.vat_perc).format('0,0.[00]')}%`}
        />

      </Table>

    )

  }

}
