import modalProps from '../modalProps'
import { resetSelectedRangeVariantIds } from '../State/selectedRangeVariantIds'

export default () => (dispatch) => {

  modalProps.reset()

  dispatch(resetSelectedRangeVariantIds())
  
}
