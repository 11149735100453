import { createAction, handleActions } from 'redux-actions';

const loadSites = createAction("CORE/SITES/LOAD_SITES");
const clearSites = createAction("CORE/SITES/CLEAR_SITES");

const defaultState = []

const reducer = handleActions(
  {
    [loadSites]: (state, action) => action.payload,
    [clearSites]: () => []
  },
  defaultState
);

export { loadSites, clearSites, reducer };