import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider } from 'antd'

const Table = props => {

  const {
    rangeVariants,
    fetching,
    onChangeTable,
    onClickRow,
  } = props

  return (

    <TableProvider
      dataSource={rangeVariants}
      rowKey='id'
      size='small'
      loading={fetching}
      onChange={onChangeTable}
      onRow={record => ({
        onClick: () => onClickRow(record),
        style: { cursor: 'pointer' },
      })}
    >

      <TableProvider.Column
        key='name'
        title='Name'
        sorter
        render={(text, record) => record.name}
      />

      <TableProvider.Column
        key='code'
        title='Code'
        sorter
        render={(text, record) => record.code}
      />

      <TableProvider.Column
        key='design'
        title='Design'
        sorter
        render={(text, record) => record.design}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  rangeVariants: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Table
