import React from 'react'
import PropTypes from 'prop-types'
import { Tabs, Affix, Button, Icon } from 'antd'
import { Screen } from 'evolve-portal'
import styles from './styles.css'

import Breadcrumbs from './Components/Breadcrumbs'
import Areas from './Components/Areas'
import AreaGroups from './Components/AreaGroups'
import PlotType from '../../Modals/PlotType'
import AddArea from './Components/AddArea'
import GroupAreas from './Components/GroupAreas'
import Dimensions from '../../Modals/PrevDimensions'
import AreaGroup from '../../Modals/AreaGroup'
import AddAreaMaterialType from './Components/AddAreaMaterialType'

export default class extends React.PureComponent {

  static propTypes = {
    plotType: PropTypes.object,
    activeTab: PropTypes.string.isRequired,
    plotTypeModal: PropTypes.object.isRequired,
    addAreaModal: PropTypes.object.isRequired,
    groupAreasModal: PropTypes.object.isRequired,
    dimensionsModal: PropTypes.object.isRequired,
    areaGroupModal: PropTypes.object.isRequired,
    addAreaMaterialTypeModal: PropTypes.object.isRequired,
    areas: PropTypes.array.isRequired,
    selectedAreas: PropTypes.array.isRequired,
    selectedAreaMaterialTypes: PropTypes.array.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onChangeActiveTab: PropTypes.func.isRequired,
    onEditPlotType: PropTypes.func.isRequired,
    onDeletePlotType: PropTypes.func.isRequired,
    onUpdatePlotType: PropTypes.func.isRequired,
    onClosePlotTypeModal: PropTypes.func.isRequired,
    onAddArea: PropTypes.func.isRequired,
    onGroupAreas: PropTypes.func.isRequired,
    onDeleteAreas: PropTypes.func.isRequired,
    onAddAreaGroup: PropTypes.func.isRequired,
    onDeleteAreaMaterialTypes: PropTypes.func.isRequired,
    onCloseAddAreaModal: PropTypes.func.isRequired,
    onCloseGroupAreasModal: PropTypes.func.isRequired,
    onCloseAreaGroupModal: PropTypes.func.isRequired,
    onCloseAddAreaMaterialTypeModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    plotType: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      plotType,
      activeTab,
      plotTypeModal,
      addAreaModal,
      groupAreasModal,
      dimensionsModal,
      areaGroupModal,
      addAreaMaterialTypeModal,
      areas,
      selectedAreas,
      selectedAreaMaterialTypes,
      onChangeActiveTab,
      onEditPlotType,
      onDeletePlotType,
      onUpdatePlotType,
      onClosePlotTypeModal,
      onAddArea,
      onGroupAreas,
      onDeleteAreas,
      onAddAreaGroup,
      onDeleteAreaMaterialTypes,
      onCloseAddAreaModal,
      onCloseGroupAreasModal,
      onCloseAreaGroupModal,
      onCloseAddAreaMaterialTypeModal,
    } = this.props

    if (!plotType) return false

    const getButtons = () => {
      const buttons = []
      if (activeTab === '1') {
        if (selectedAreas.length) {
          buttons.push(
            <Button key='link' onClick={() => onGroupAreas()} style={{ boxShadow: '4px 4px #DDD' }}>
              <Icon type='arrow-right' />
              Group Areas
            </Button>,
            <div key='spacer-1' style={{ width: 10 }} />,
            <Button key='delete' type='danger' onClick={() => onDeleteAreas()} style={{ boxShadow: '4px 4px #DDD' }}>
              <Icon type='delete' />
              Delete Areas
            </Button>,
          )
        }
        buttons.push(
          <div key='spacer-2' style={{ width: 10 }} />,
          <Button key='add' type='primary' onClick={onAddArea} style={{ boxShadow: '4px 4px #DDD' }}>
            <Icon type='plus' />
            Add Area
          </Button>,
        )
      }
      if (activeTab === '2') {
        if (selectedAreaMaterialTypes.length) {
          buttons.push(
            <Button key='delete' type='danger' onClick={() => onDeleteAreaMaterialTypes()} style={{ boxShadow: '4px 4px #DDD' }}>
              <Icon type='delete' />
              Delete Lines
            </Button>,
          )
        }
        buttons.push(
          <div key='spacer' style={{ width: 10 }} />,
          <Button key='add' type='primary' onClick={onAddAreaGroup} style={{ boxShadow: '4px 4px #DDD' }}>
            <Icon type='plus' />
            Add Group
          </Button>,
        )
      }
      return buttons
    }

    return (

      <Screen>

        <Breadcrumbs plotType={plotType} />

        <div className={styles.header}>

          <h1>{plotType.name}</h1>

          <Button
            type='default'
            onClick={onEditPlotType}
            icon='edit'
          >
            Edit Plot Type
          </Button>

        </div>

        <Tabs
          activeKey={activeTab}
          onChange={onChangeActiveTab}
          style={{ paddingBottom: 10 }}
        >
          <Tabs.TabPane key='1' tab='Areas' />
          <Tabs.TabPane key='2' tab='Groups' />
        </Tabs>

        {activeTab === '1' && <Areas />}

        {activeTab === '2' && <AreaGroups />}

        {plotTypeModal.show && (

          <PlotType
            plotTypeId={plotType.id}
            didDelete={onDeletePlotType}
            didUpdate={onUpdatePlotType}
            onClose={onClosePlotTypeModal}
          />

        )}

        {addAreaModal.show && (

          <AddArea
            plotTypeId={plotType.id}
            onClose={onCloseAddAreaModal}
          />

        )}

        {groupAreasModal.show && (

          <GroupAreas
            onClose={onCloseGroupAreasModal}
          />

        )}

        {dimensionsModal.show && (

          <Dimensions
            {...dimensionsModal}
            isNew={!dimensionsModal.id}
          />

        )}

        {areaGroupModal.show && (

          <AreaGroup
            {...areaGroupModal}
            areas={areas}
            onClose={onCloseAreaGroupModal}
          />

        )}

        {addAreaMaterialTypeModal.show && (

          <AddAreaMaterialType
            {...addAreaMaterialTypeModal}
            onClose={onCloseAddAreaMaterialTypeModal}
          />

        )}

        <Affix offsetBottom>
          <div className={styles.footerButtons}>{getButtons()}</div>
        </Affix>

      </Screen>

    )

  }
  
}
