import { createSelector } from 'reselect'
import modalProps from '../../../modalProps'

const selectMaterialRanges = state => state.core.materialRanges
const selectValues = state => state.modals.MaterialRange.Form.formState.values

export default createSelector(
  selectMaterialRanges,
  selectValues,
  (materialRanges, values) => {

    if (!values.material_type_id) return []

    const { materialRange } = modalProps()
    
    return materialRanges.filter(row => {
      if (materialRange && row.id === materialRange.id) return false
      return row.material_type_id === values.material_type_id
    })
    
  },
)
