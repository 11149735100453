import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Icon } from 'antd'
import numeral from 'numeral'

const usageKeys = {
  'dimensions': 'Length X Width',
  'square-metres': 'M2',
  'item': 'Each',
}

const rightAlign = {
  onHeaderCell: () => ({
    style: { textAlign: 'right' },
  }),
  onCell: () => ({
    style: { textAlign: 'right' },
  }),
}

const Table = props => {

  const {
    materialTypes,
    fetching,
    onChangeTable,
    onClickRow,
  } = props

  return (

    <TableProvider
      dataSource={materialTypes}
      rowKey='id'
      size='small'
      loading={fetching}
      onChange={onChangeTable}
      onRow={record => ({
        onClick: () => onClickRow(record),
        style: { cursor: 'pointer' },
      })}
    >

      <TableProvider.Column
        key='name'
        title='Name'
        sorter
        render={(text, record) => record.name}
      />

      <TableProvider.Column
        key='usage_key'
        title='Unit'
        sorter
        render={(text, record) => usageKeys[record.usage_key]}
      />

      <TableProvider.Column
        key='is_flooring'
        title='Flooring'
        sorter
        render={(text, record) => !!record.is_flooring && <Icon type='check' />}
      />

      <TableProvider.Column
        key='vat_perc'
        title='VAT'
        sorter
        {...rightAlign}
        render={(text, record) => record.vat_perc !== null && `${numeral(record.vat_perc).format('0,0')}%`}
      />

      <TableProvider.Column
        key='rebate_rate'
        title='Rebate'
        sorter
        {...rightAlign}
        render={(text, record) => !!record.rebate_rate && `£${numeral(record.rebate_rate).format('0,0.00')}`}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  materialTypes: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default Table
