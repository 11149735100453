import { change } from 'redux-form'
import { FORM_NAME } from '../Form'
import { closeNotesModal } from '../State/notesModal'
import logInspectionChange from '../Services/logInspectionChange'

export default (notes = false) => (dispatch) => {

  dispatch(closeNotesModal())

  if (notes !== false) {

    dispatch(change(FORM_NAME, 'inspection_notes', notes))

    dispatch(logInspectionChange())

  }
  
}