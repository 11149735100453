import { closeBuilderMaterialRangeModal } from '../State/builderMaterialRangeModal'
import fetch from '../Components/BuilderMaterialRanges/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeBuilderMaterialRangeModal())

  if (reload) {
    dispatch(fetch())
  }

}
