import { regenerateSiteCostMatrix } from '../../../HttpRequests/sites'
import { regeneratePlotTypeCostMatrix } from '../../../HttpRequests/plotTypes'
import fetch from '../Components/CostMatrix/Services/fetch'
import { handleException } from '../../../../packages/sota'

export default (type, id) => async (dispatch) => {

  try {

    if (type === 'Site') {
      await regenerateSiteCostMatrix({ id })
    }

    if (type === 'PlotType') {
      await regeneratePlotTypeCostMatrix({ id })
    }

    dispatch(fetch())

  } catch (error) {

    handleException(error)

    throw error

  }

}
