import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchPlotTypes from '../Services/fetchPlotTypes';
import fetchMaterialTypes from '../Services/fetchMaterialTypes';

export default (props) => (dispatch) => {

  setProps(props);
  dispatch(fetchPlotTypes());
  dispatch(fetchMaterialTypes());
  dispatch(initialize());
  
}