import { createAction, handleActions } from 'redux-actions';

const loadMaterialUnits = createAction("CORE/MATERIAL_UNITS/LOAD_MATERIAL_UNITS");
const clearMaterialUnits = createAction("CORE/MATERIAL_UNITS/CLEAR_MATERIAL_UNITS");

const defaultState = []

const reducer = handleActions(
  {
    [loadMaterialUnits]: (state, action) => action.payload,
    [clearMaterialUnits]: () => []
  },
  defaultState
);

export { loadMaterialUnits, clearMaterialUnits, reducer };