import { createAction, handleActions } from 'redux-actions';

const loadMaterialVariantUnits = createAction("CORE/MATERIAL_VARIANT_UNITS/LOAD_MATERIAL_VARIANT_UNITS");
const clearMaterialVariantUnits = createAction("CORE/MATERIAL_VARIANT_UNITS/CLEAR_MATERIAL_VARIANT_UNITS");

const defaultState = []

const reducer = handleActions(
  {
    [loadMaterialVariantUnits]: (state, action) => action.payload,
    [clearMaterialVariantUnits]: () => []
  },
  defaultState
);

export { loadMaterialVariantUnits, clearMaterialVariantUnits, reducer };