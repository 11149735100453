import upload from '../../../Core/Services/upload'
import { getProps } from '../props'
import onUploadSuccess from './onUploadSuccess'
import onUploadFailure from './onUploadFailure'

export default (files) => (dispatch) => {

  const uploads = files.map(file => ({
    file,
    modelType: 'Order',
    modelId: getProps().match.params.id,
    onSuccess: onUploadSuccess,
    onFailure: onUploadFailure,
  }))

  dispatch(upload(uploads))

}