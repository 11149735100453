import { change } from 'redux-form';
import { defer } from 'lodash'
import { FORM_NAME } from '../../../Form';
import checkFitterAvailability from '../../../Services/checkFitterAvailability'

export default (event, value) => (dispatch) => {

  dispatch(change(FORM_NAME, 'start_date', value ? value.split(',')[0] : null))
  dispatch(change(FORM_NAME, 'end_date', value ? value.split(',')[1] : null))
  
  defer(() => dispatch(checkFitterAvailability()))
  
}