const createFormHelper = () => {

  let form = {}

  const formHelper = (newForm) => {
    
    if (newForm !== undefined) {
      form = newForm
    }

    return form
  }

  return formHelper

}

export default createFormHelper