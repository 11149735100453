import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openPlotTypeModal = createAction('SCREENS/PLOT_TYPES/OPEN_PLOT_TYPE_MODAL')
const closePlotTypeModal = createAction('SCREENS/PLOT_TYPES/CLOSE_PLOT_TYPE_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openPlotTypeModal]: (state, action) => Object.assign({}, state, {
      show: true,
      id: get(action, 'payload.id'),
    }),
    [closePlotTypeModal]: () => defaultState,
  },
  defaultState,
)

export { openPlotTypeModal, closePlotTypeModal, reducer }
