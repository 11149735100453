import { createAction, handleActions } from 'redux-actions'

const openNotesModal = createAction('SCREENS/COST_SHEET/AREAS/OPEN_NOTES_MODAL')
const closeNotesModal = createAction('SCREENS/COST_SHEET/AREAS/CLOSE_NOTES_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openNotesModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeNotesModal]: () => defaultState,
  },
  defaultState,
)

export { openNotesModal, closeNotesModal, reducer }
