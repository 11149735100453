import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Form, Select, InputNumber, Button } from 'antd'
import { get } from 'lodash'
import { FormInterceptor } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    builders: PropTypes.array.isRequired,
    materialTypes: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
    materialRanges: PropTypes.array.isRequired,
    materialType: PropTypes.object,
    format: PropTypes.string,
    dimensions: PropTypes.array,
    unit: PropTypes.string,
    onChangeFormState: PropTypes.func.isRequired,
    onClickDimensions: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    materialType: null,
    format: null,
    dimensions: null,
    unit: null,
  }

  render() {

    const {
      builders,
      materialTypes,
      initialValues,
      materialRanges,
      materialType,
      format,
      dimensions,
      unit,
      onChangeFormState,
      onClickDimensions,
      onSubmit,
      validate,
    } = this.props

    return (

      <FormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={values => validate(values, materialType)}
      >

        {({ handleSubmit, form }) => (

          <Form
            onSubmit={handleSubmit}
            className={styles.form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            autoComplete='off'
          >

            <FormInterceptor
              ref={formService}
              subscription={{ values: true, submitting: true }}
              onChange={onChangeFormState}
            />

            <Field name='builder_id'>
              {({ input }) => (
                <Form.Item
                  label='Builder'
                >
                  <Select
                    {...input}
                    value={input.value || undefined}
                    onChange={value => input.onChange(value || null)}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    placeholder='All'
                  >
                    {builders.map(builder => (
                      <Select.Option key={builder.id} value={builder.id}>
                        {builder.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='material_type_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Material'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    onChange={value => {
                      input.onChange(value)
                      form.change('material_range_id', null)
                      form.change('format', null)
                      form.change('dimensions', null)
                    }}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    {materialTypes.map($materialType => (
                      <Select.Option key={$materialType.id} value={$materialType.id}>
                        {$materialType.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Field>

            <Field name='material_range_id'>
              {({ input, meta }) => (
                <Form.Item
                  label='Range'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <Select
                    {...input}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                  >
                    <Select.OptGroup label='Active'>
                      {materialRanges.filter(materialRange => !materialRange.archived_at).map(materialRange => (
                        <Select.Option key={materialRange.id} value={materialRange.id}>
                          {materialRange.name}
                          {' • '}
                          <span style={{ color: '#9e9e9e' }}>{materialRange.manufacturer.name}</span>
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                    <Select.OptGroup label='Archived'>
                      {materialRanges.filter(materialRange => !!materialRange.archived_at).map(materialRange => (
                        <Select.Option key={materialRange.id} value={materialRange.id} style={{ color: '#9e9e9e' }}>
                          {`${materialRange.name} • ${materialRange.manufacturer.name}`}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Form.Item>
              )}
            </Field>

            {get(materialType, 'usage_key') === 'dimensions' && (

              <React.Fragment>

                <Field name='format'>
                  {({ input, meta }) => (
                    <Form.Item
                      label='Format'
                      validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                    >
                      <Select
                        {...input}
                        onChange={value => {
                          input.onChange(value)
                          form.change('dimensions', null)
                        }}
                        allowClear
                        showSearch
                        optionFilterProp='label'
                        style={{ width: 90 }}
                      >
                        <Select.Option key='roll' value='roll'>Roll</Select.Option>
                        <Select.Option key='cut' value='cut'>Cut</Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                </Field>

                {format === 'roll' && (

                  <Field name='dimensions'>
                    {({ meta }) => (
                      <Form.Item
                        label='Sizes'
                        validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                        help={(meta.touched && meta.error) ? 'At least 1 size must be added' : undefined}
                      >
                        <Button
                          type='primary'
                          onClick={() => onClickDimensions(dimensions)}
                          style={{ width: 90 }}
                        >
                          {`Add (${dimensions ? dimensions.length : 0})`}
                        </Button>
                      </Form.Item>
                    )}
                  </Field>

                )}

              </React.Fragment>

            )}

            <Field name='rate'>
              {({ input, meta }) => (
                <Form.Item
                  label='Rate'
                  validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                >
                  <InputNumber
                    {...input}
                    precision={2}
                    min={0}
                    style={{ width: 90 }}
                  />
                  {unit && <span style={{ marginLeft: 8 }}>{unit}</span>}
                </Form.Item>
              )}
            </Field>

          </Form>

        )}

      </FormProvider>

    )

  }

}
