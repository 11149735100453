import React from 'react'
import PropTypes from 'prop-types'
import HeightSpacer from '../HeightSpacer'
import DimensionsModal from '../../../../Modals/Dimensions'
import Text from '../Text'
import NotesModal from '../NotesModal'

import Controls from './Components/Controls'
import Headings from './Components/Headings'
import Item from './Components/Item'
import Custom from './Components/Custom'
import CustomArea from './Components/CustomArea'
import Totals from './Components/Totals'
import Placeholder from './Components/Placeholder'
import SetupModal from './Components/SetupModal'
import AddModal from './Components/AddModal'

const Areas = props => {

  const {
    items,
    setupModal,
    addModal,
    dimensionsModal,
    notesModal,
    onCloseSetupModal,
    onCloseAddModal,
    onCloseDimensionsModal,
    onCloseNotesModal,
  } = props

  return (
    
    <React.Fragment>

      <Controls />

      <HeightSpacer type='controls' />

      {!!items.length && <Headings />}

      {items.map(item => {

        let component = <Item key={item.guid} item={item} />

        if (item.type === 'Custom') component = <Custom key={item.guid} item={item} />

        if (item.type === 'Custom Area') component = <CustomArea key={item.guid} item={item} />

        return (
          <React.Fragment key={item.guid}>
            {component}
            {item.supply_sheet_notes && <Text value={item.supply_sheet_notes} />}
          </React.Fragment>
        )
        
      })}

      {!!items.length && <Totals />}

      {!items.length && <Placeholder />}

      {setupModal.show && (
        <SetupModal
          {...setupModal}
          onClose={onCloseSetupModal}
        />
      )}

      {addModal.show && (
        <AddModal
          {...addModal}
          onClose={onCloseAddModal}
        />
      )}

      {dimensionsModal.show && (
        <DimensionsModal
          {...dimensionsModal}
          onClose={onCloseDimensionsModal}
        />
      )}

      {notesModal.show && (
        <NotesModal
          {...notesModal}
          onClose={onCloseNotesModal}
        />
      )}

    </React.Fragment>
    
  )

}

Areas.propTypes = {
  items: PropTypes.array.isRequired,
  setupModal: PropTypes.object.isRequired,
  addModal: PropTypes.object.isRequired,
  dimensionsModal: PropTypes.object.isRequired,
  notesModal: PropTypes.object.isRequired,
  onCloseSetupModal: PropTypes.func.isRequired,
  onCloseAddModal: PropTypes.func.isRequired,
  onCloseDimensionsModal: PropTypes.func.isRequired,
  onCloseNotesModal: PropTypes.func.isRequired,
}

export default Areas
