import { connect } from 'react-redux'
import Component from './Component'

import onClickApprove from './Handlers/onClickApprove'

const mapStateToProps = () => ({})

const actionCreators = {
  onClickApprove,
}

export default connect(mapStateToProps, actionCreators)(Component)