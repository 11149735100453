import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Cascader, Dropdown, Menu, Icon } from 'antd'
import classNames from 'classnames'
import styles from './styles.css'

const Item = props => {

  const {
    materials,
    suppliers,
    item,
    onEditField,
    onClickDimensions,
    onClickAction,
    onClickOnJobSheet,
    onClickSupplySheetNotes,
  } = props

  const [squareMetres, setSquareMetres] = useState(undefined)
  const [quantity, setQuantity] = useState(undefined)
  const [cost, setCost] = useState(undefined)

  const onBlurNumber = (field, value) => {
    const $value = (value || value === '0') ? parseFloat(value.replace(',', '')) : null
    onEditField(item.guid, field, $value)
  }

  const actions = (
    <Menu>
      <Menu.Item key='add' onClick={() => onClickAction(item.guid, 'add')}>
        <Icon type='plus' />
        Add
      </Menu.Item>
      <Menu.Item key='duplicate' onClick={() => onClickAction(item.guid, 'duplicate')}>
        <Icon type='copy' />
        Duplicate
      </Menu.Item>
      <Menu.Item key='on_job_sheet' onClick={() => onClickOnJobSheet(item.guid, item.on_job_sheet)}>
        <Icon type={item.on_job_sheet ? 'eye-invisible' : 'eye'} />
        {`${item.on_job_sheet ? 'Hide' : 'Show'} on Job Sheet`}
      </Menu.Item>
      <Menu.Item key='supply-sheet-notes' onClick={() => onClickSupplySheetNotes(item.guid, item.supply_sheet_notes)}>
        <Icon type='message' />
        Supply Sheet Notes
      </Menu.Item>
      <Menu.Item key='remove' onClick={() => onClickAction(item.guid, 'remove')}>
        <Icon type='delete' />
        Remove
      </Menu.Item>
    </Menu>
  )

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.material)}>
        <Cascader
          options={materials}
          onChange={value => onEditField(item.guid, 'material', value)}
          expandTrigger='hover'
        >
          <div className={styles.content}>{item.material}</div>
        </Cascader>
      </div>

      <div className={classNames(styles.cell, styles.supplier)}>
        <Cascader
          options={suppliers}
          onChange={value => onEditField(item.guid, 'supplier', value)}
          expandTrigger='hover'
        >
          <div className={styles.content}>{item.supplier}</div>
        </Cascader>
      </div>

      <div className={classNames(styles.cell, styles.quantity)}>
        <input
          value={quantity !== undefined ? quantity : item.quantity}
          onChange={e => setQuantity(e.target.value)}
          onBlur={e => {
            onBlurNumber('quantity', e.target.value)
            setQuantity(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
      </div>

      <div className={classNames(styles.cell, styles.dimensions)}>
        <div
          onClick={() => onClickDimensions(item.guid, item.dimensions)}
          className={classNames(styles.content, styles.number)}
        >
          {!!item.dimensions && item.dimensions.map(({ guid, length, width }) => (
            <div key={guid}>{`${length} x ${width}`}</div>
          ))}
        </div>
      </div>

      <div className={classNames(styles.cell, styles.squareMetres)}>
        <input
          value={squareMetres !== undefined ? squareMetres : item.square_metres}
          onChange={e => setSquareMetres(e.target.value)}
          onBlur={e => {
            onBlurNumber('square_metres', e.target.value)
            setSquareMetres(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
      </div>

      <div className={classNames(styles.cell, styles.totalSquareMetres)}>
        <input
          value={item.total_square_metres}
          readOnly
          className={classNames(styles.input, styles.number)}
        />
      </div>

      <div className={classNames(styles.cell, styles.cost)}>
        <input
          value={cost !== undefined ? cost : item.cost}
          onChange={e => setCost(e.target.value)}
          onBlur={e => {
            onBlurNumber('cost', e.target.value)
            setCost(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
        <div className={styles.actions}>
          <Dropdown
            trigger={['click']}
            overlay={actions}
          >
            <Icon type='caret-down' />
          </Dropdown>
        </div>
      </div>

    </div>
    
  )

}

Item.propTypes = {
  materials: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  onEditField: PropTypes.func.isRequired,
  onClickDimensions: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
  onClickOnJobSheet: PropTypes.func.isRequired,
  onClickSupplySheetNotes: PropTypes.func.isRequired,
}

export default Item
