import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Checkbox, Button } from 'antd'
import { orderBy } from 'lodash'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    areas: PropTypes.array.isRequired,
    onAddAreas: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    handleAddAreas: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
  }

  state = {
    areaIds: [],
  }

  render() {

    const {
      areas,
      onAddAreas,
      onClose,
      handleAddAreas,
      handleClose,
    } = this.props

    const close = (reload = false) => handleClose(onClose, reload)

    const add = () => {
      handleAddAreas(onAddAreas, this.state.areaIds)
      close(true)
    }

    return (

      <Modal
        visible
        title='Add Areas'
        width={500}
        footer={[
          <Button key='cancel' onClick={() => close()}>Cancel</Button>,
          <Button key='add' type='primary' onClick={() => add()}>Add</Button>,
        ]}
        onCancel={() => close()}
      >

        <div className={styles.wrapper}>
          <Checkbox.Group
            value={this.state.areaIds}
            onChange={areaIds => this.setState({ areaIds })}
            options={orderBy(areas, 'order_no').map(area => ({ label: area.name, value: area.id }))}
            className={styles.areaCheckboxes}
          />
        </div>

      </Modal>

    )

  }

}
