import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table, Popover, Button, Icon, Spin } from 'antd'
import { get } from 'lodash'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './styles.css'

const CostMatrix = props => {

  const {
    columns,
    plotTypes,
    fetched,
    isFetching,
    componentDidMount,
    onClickRegenerate,
    onGoToPlotType,
    onClickPriceCell,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  const formatFloat = (float) => float ? numeral(float).format('0,0.00') : null

  const getScrollX = () => {
    const siteRateColumns = columns.filter(column => column.type === 'SITE_RATE')
    const nonSiteRateColumns = columns.filter(column => column.type !== 'SITE_RATE')
    const vatColumns = siteRateColumns.filter(siteRateColumn => siteRateColumn.site_rate.material_type.vat_perc > 0)
    const nonVatColumns = siteRateColumns.filter(siteRateColumn => siteRateColumn.site_rate.material_type.vat_perc == 0)
    return 300 + (nonSiteRateColumns.length * 100) + (vatColumns.length * 300) + (nonVatColumns.length * 100)
  }

  if (!fetched) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    )
  }

  return (

    <div>

      {plotTypes.map(plotType => (

        <div key={plotType.id} className={styles.plotTypeWrapper}>

          <div className={styles.plotTypeHeader}>
            <div>
              <h2>{plotType.name}</h2>
              <h3 className={styles.subheader}>{plotType.plots_formatted}</h3>
            </div>
            <div className={styles.headerButtons}>
              <Button type='primary' onClick={() => onClickRegenerate(plotType.id)}>
                <Icon type='reload' />
                Regenerate Plot Type
              </Button>
              <div style={{ width: 10 }} />
              <Button onClick={() => onGoToPlotType(plotType.id)}>
                <Icon type='arrow-right' />
                Go to Plot Type
              </Button>
            </div>
          </div>

          <Table
            dataSource={plotType.areas_and_groups}
            rowKey='key'
            bordered
            size='small'
            className={styles.table}
            pagination={false}
            loading={isFetching}
            scroll={{ x: getScrollX() }}
          >

            <Table.Column
              key='name'
              title='Area'
              width={300}
              fixed='left'
              render={(text, record) => (
                <Popover
                  title={record.name}
                  content={(
                    <div>
                      <div>{`Net M2: ${record.square_metres || 'N/A'}`}</div>
                    </div>
                  )}
                >
                  {record.type === 'AreaGroup' ? <b>{record.name}</b> : record.name}
                </Popover>
              )}
            />

            {columns.map((column, index) => {

              if (column.type === 'dimensions') {

                return (

                  <Table.Column
                    key={column.key}
                    title={`M2 ${column.material_type.name.charAt(0)}`}
                    width={100}
                    onHeaderCell={() => ({
                      className: classNames(styles.m2Column, styles.numeric),
                    })}
                    onCell={() => ({
                      className: classNames(styles.m2Column, styles.numeric),
                    })}
                    render={(text, record) => formatFloat(record.columns[index].square_metres)}
                  />

                )
                
              }

              if (column.type === 'square-metres') {

                return (

                  <Table.Column
                    key={column.key}
                    title={`M2 ${column.material_range.name.charAt(0)}`}
                    width={100}
                    onHeaderCell={() => ({
                      className: classNames(styles.m2Column, styles.numeric),
                    })}
                    onCell={() => ({
                      className: classNames(styles.m2Column, styles.numeric),
                    })}
                    render={(text, record) => formatFloat(record.columns[index].square_metres)}
                  />

                )
                
              }

              if (column.type === 'SITE_RATE') {

                return (

                  <Table.ColumnGroup
                    key={column.key}
                    title={(
                      <div>
                        <div style={{ fontSize: '15px' }}>{column.site_rate.title}</div>
                        {column.site_rate.subtitle && <div style={{ fontSize: '13px', fontWeight: 400 }}>{column.site_rate.subtitle}</div>}
                      </div>
                    )}
                  >

                    {column.site_rate.material_type.vat_perc > 0 && (

                      <Table.Column
                        key={`price_net-${column.site_rate.id}`}
                        title='Net'
                        width={100}
                        onHeaderCell={() => ({
                          className: classNames(styles.numeric),
                        })}
                        onCell={record => ({
                          className: classNames(styles.numeric, styles.clickable),
                          onClick: () => onClickPriceCell(column, record, index),
                        })}
                        render={(text, record) => formatFloat(get(record.columns[index], 'area_range_quote.price_net'))}
                      />

                    )}

                    {column.site_rate.material_type.vat_perc > 0 && (

                      <Table.Column
                        key={`price_vat-${column.site_rate.id}`}
                        title='VAT'
                        width={100}
                        onHeaderCell={() => ({
                          className: classNames(styles.numeric),
                        })}
                        onCell={record => ({
                          className: classNames(styles.numeric, styles.clickable),
                          style: { fontWeight: 300 },
                          onClick: () => onClickPriceCell(column, record, index),
                        })}
                        render={(text, record) => formatFloat(get(record.columns[index], 'area_range_quote.price_vat'))}
                      />

                    )}

                    <Table.Column
                      key={`price_gross-${column.site_rate.id}`}
                      title='Total'
                      width={100}
                      onHeaderCell={() => ({
                        className: classNames(styles.numeric),
                      })}
                      onCell={record => ({
                        className: classNames(styles.numeric, styles.clickable),
                        style: { fontWeight: 500 },
                        onClick: () => onClickPriceCell(column, record, index),
                      })}
                      render={(text, record) => formatFloat(get(record.columns[index], 'area_range_quote.price_gross'))}
                    />

                  </Table.ColumnGroup>

                )
                
              }

              return false

            })}

          </Table>

        </div>

      ))}

    </div>

  )

}

CostMatrix.propTypes = {
  columns: PropTypes.array.isRequired,
  plotTypes: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onClickRegenerate: PropTypes.func.isRequired,
  onGoToPlotType: PropTypes.func.isRequired,
  onClickPriceCell: PropTypes.func.isRequired,
}

export default CostMatrix
