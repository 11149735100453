import React from 'react'
import { notification } from 'antd'
import moment from 'moment'
import { getPlotNotes } from '../../../HttpRequests/plotNotes'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { searchTerm } = getState().screens.Orders

  if (!searchTerm) return

  const params = {
    'filter[order_search]': searchTerm,
    'sort': '-created_at',
    'include': 'order.site,user',
  }

  try {

    const { data: { data: plotNotes } } = await getPlotNotes({ params })

    plotNotes.forEach(plotNote => {
      const { order } = plotNote
      notification.warning({
        key: plotNote.id,
        message: `${order.plot_number} ${order.site.name}`,
        description: (
          <div>
            <a
              onClick={() => window.open(`/portal/orders/${plotNote.order_id}`)}
              style={{ fontWeight: 500 }}
            >
              {`${order.remedial ? 'Remedial' : 'Order'}: ${order.order_number}`}
            </a>
            <div style={{ marginTop: 5, marginBottom: 5 }}>{plotNote.note}</div>
            <div style={{ fontSize: 13, fontStyle: 'italic' }}>
              {`${plotNote.user.name} / ${moment(plotNote.created_at).format('DD/MM/YYYY HH:mm')}`}
            </div>
          </div>
        ),
        duration: 0,
      })
    })

  } catch (error) {

    handleException(error)

  }

}
