import { createSelector } from 'reselect'
import { find, get } from 'lodash'
import selectMaterialRanges from '../../../../../Selectors/selectMaterialRanges'

const selectFields = state => state.screens.CostSheet.Areas.SetupModal.fields

export default createSelector(
  selectMaterialRanges,
  selectFields,
  (materialRanges, fields) => get(find(materialRanges, row => row.id === fields.materialRangeId), 'range_variants', []),
)
