import React from 'react'
import styles from './styles.css'

const Controls = () => (

  <div className={styles.wrapper}>

    <div className={styles.title}>Summary</div>

  </div>

)

export default Controls
