import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import FittingRateModal from '../FittingRate'

import Controls from './Components/Controls'
import Table from './Components/Table'

const Spacer = () => <div style={{ height: 12 }} />

const FittingRates = props => {

  const {
    fittingRateModal,
    componentDidMount,
    componentWillUnmount,
    onCloseFittingRateModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Modal
      title='Fitting Rates'
      visible
      width={600}
      onCancel={onClose}
      maskClosable={false}
      footer={false}
    >

      <Controls />

      <Spacer />

      <Table />

      {fittingRateModal.show && (
        <FittingRateModal
          {...fittingRateModal}
          onClose={onCloseFittingRateModal}
        />
      )}

    </Modal>

  )

}

FittingRates.propTypes = {
  fittingRateModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseFittingRateModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FittingRates
