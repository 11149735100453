import { createSelector } from 'reselect'
import { flatten } from 'lodash'
import selectAreas from './selectAreas'

const selectOrder = state => state.screens.CostSheet.order

export default createSelector(
  selectOrder,
  selectAreas,
  (order, areas) => {

    const { area_group_id: areaGroupId } = order.cost_sheet

    const areaMaterialTypes = flatten(areas.map(area => area.area_material_types))
    
    return areaMaterialTypes.filter(areaMaterialType => areaMaterialType.area_group_id === areaGroupId)

  },
)
