import { createSelector } from "reselect";
import moment from "moment";
import { flatten } from 'lodash';
import selectDates from './dates';

const selectInspections = state => state.screens.Board.inspections;

export default createSelector(
  selectInspections,
  selectDates,
  (inspections, dates) => {

    const data = flatten(
      dates.map(week => week.map(date => date.date))
    ).reduce(
      (object, date) => Object.assign(object, { [date]: [] }),
      {}
    );

    inspections.forEach(inspection => {

      const key = moment(inspection.inspection_date).format('YYYY-MM-DD');

      if (data[key]) data[key].push(inspection);
    
    });
    
    return data;
  
  }
)