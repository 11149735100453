import { message } from 'antd';
import { getProps } from '../props';
import { deleteJob } from '../../../HttpRequests/jobs';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { jobId, onClose, didDelete } = getProps();

    await deleteJob({ id: jobId });
    
    message.success("Job Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}