import React from 'react'
import { Modal, Icon } from 'antd'
import deleteBuilderMaterialRange from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: 'Remove Material',
    content: 'Are you sure you want to remove this material?',
    icon: <Icon type='delete' style={{ color: '#ff4d4f' }} />,
    okType: 'danger',
    okText: 'Remove',
    onOk: () => dispatch(deleteBuilderMaterialRange()),
  })

}
