import { initialize } from 'redux-form';
import moment from 'moment';
import { FORM_NAME } from '../Form';

export default () => async (dispatch) => {

  dispatch(initialize(FORM_NAME, {
    order_type: 'order',
    remedial: false,
    date: moment().format('YYYY-MM-DD')
  }))

}