import { createSelector } from 'reselect';

const selectPermissions = () => [];
const selectPathname = state => state.router.location.pathname;

export default createSelector(
  selectPermissions,
  selectPathname,
  (permissions, pathname) => {

    const left = [];
    const right = [];

    left.push({
      key: "orders",
      title: 'Orders',
      href: '/portal/orders',
      selected: pathname && (pathname.startsWith('/portal/orders') || pathname.startsWith('/portal/cost_sheets'))
    });
    
    left.push({
      key: "board",
      title: 'Board',
      href: '/portal/board',
      selected: pathname && pathname.startsWith('/portal/board')
    });

    left.push({
      key: "builders",
      title: 'Builders',
      href: '/portal/builders',
      selected: pathname && pathname.startsWith('/portal/builders')
    });

    /*

    left.push({
      key: "suppliers",
      title: 'Suppliers',
      href: '/portal/suppliers',
      selected: pathname && pathname.startsWith('/portal/suppliers')
    });

    */

    left.push({
      key: "fitters",
      title: 'Fitters',
      href: '/portal/fitters',
      selected: pathname && pathname.startsWith('/portal/fitters')
    });
    
    left.push({
      key: "materials",
      title: 'Materials',
      href: '/portal/materials',
      selected: pathname && pathname.startsWith('/portal/materials')
    });

    /*

    left.push({
      key: "leftovers",
      title: 'Leftovers',
      href: '/portal/leftovers',
      selected: pathname && pathname.startsWith('/portal/leftover')
    });

    */

    right.push({
      key: "logout",
      title: 'Logout',
      href: '/portal/logout'
    });

    return { left, right };
  }
)