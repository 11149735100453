import React from 'react'
import PropTypes from 'prop-types'
import { Form as FormProvider, Field } from 'react-final-form'
import { Modal, Button, Form, Input, Select } from 'antd'
import { ExposeFormToHelper } from '../../../../../packages/sota'
import formService from './formService'
import styles from './styles.css'

export default class extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render() {

    const {
      onSubmit,
      validate,
      onClose,
    } = this.props

    const footer = [
      <Button key='cancel' onClick={() => onClose()}>Cancel</Button>,
      <Button key='save' type='primary' onClick={() => formService().submit()}>Add</Button>,
    ]

    return (

      <Modal
        visible
        title='Add Area'
        width={500}
        footer={footer}
        onCancel={() => onClose()}
      >

        <FormProvider
          initialValues={{ type: 'C/E' }}
          validate={validate}
          onSubmit={onSubmit}
        >

          {({ handleSubmit }) => (

            <Form
              onSubmit={handleSubmit}
              className={styles.form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              colon={false}
              autoComplete='off'
            >

              <ExposeFormToHelper helper={formService} />

              <Field name='name'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Name'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Input
                      {...input}
                    />
                  </Form.Item>
                )}
              </Field>

              <Field name='type'>
                {({ input, meta }) => (
                  <Form.Item
                    label='Type'
                    validateStatus={(meta.touched && meta.error) ? 'error' : undefined}
                  >
                    <Select
                      {...input}
                      className={styles.antInput}
                    >
                      {/* <Select.Option key='Sale' value='Sale'>Sale</Select.Option> */}
                      <Select.Option key='C/W' value='C/W'>C/W</Select.Option>
                      <Select.Option key='C/E' value='C/E'>C/E</Select.Option>
                    </Select>
                  </Form.Item>
                )}
              </Field>

            </Form>

          )}

        </FormProvider>

      </Modal>

    )

  }

}
