import { defer } from 'lodash'
import modalProps from '../../../modalProps'
import formService from '../formService'

export default () => (dispatch, getState) => {

  const { id, squareMetres, siteRate, type } = modalProps()

  if (!id) {

    const { rate, group_rate: groupRate } = siteRate
    const { vat_perc: vatPerc } = siteRate.material_type

    const priceNet = (squareMetres || 0) * rate
    const priceVat = (priceNet / 100) * vatPerc
    const priceGross = priceNet + priceVat

    const initialValues = {
      square_metres: squareMetres,
      use_rate: true,
      rate: type === 'AreaGroup' && groupRate ? groupRate : rate,
      price_net: priceNet,
      vat_perc: vatPerc,
      price_vat: priceVat,
      price_gross: priceGross,
    }

    defer(() => formService().initialize(initialValues))

    return
  }

  const { areaRangeQuote } = getState().modals.AreaRangeQuote

  const initialValues = {
    square_metres: areaRangeQuote.square_metres,
    use_rate: !!areaRangeQuote.rate,
    rate: areaRangeQuote.rate,
    price_net: areaRangeQuote.price_net,
    vat_perc: areaRangeQuote.vat_perc,
    price_vat: areaRangeQuote.price_vat,
    price_gross: areaRangeQuote.price_gross,
  }

  defer(() => formService().initialize(initialValues))

}
