import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchSites from '../../../Core/Services/fetchSites';

export default (props) => (dispatch) => {

  setProps(props);

  dispatch(initialize());
  dispatch(fetchUsers());
  dispatch(fetchSites());
  
}