import { createAction, handleActions } from 'redux-actions'

const openSuppliersModal = createAction('SCREENS/COST_SHEET/OPEN_SUPPLIERS_MODAL')
const closeSuppliersModal = createAction('SCREENS/COST_SHEET/CLOSE_SUPPLIERS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSuppliersModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeSuppliersModal]: () => defaultState,
  },
  defaultState,
)

export { openSuppliersModal, closeSuppliersModal, reducer }
