import modalProps from '../modalProps'
import { syncBuilderRateRangeVariants } from '../../../../../HttpRequests/builderRates'
import { setSaving } from '../State/saving'
import { handleException } from '../../../../../../packages/sota'

export default () => async (dispatch, getState) => {

  const { builderRateId, materialRange, onClose } = modalProps()
  
  const { selectedRangeVariantIds } = getState().modals.BuilderRate.BuilderRateRangeVariantsModal

  const data = {
    material_range_id: materialRange.id,
    range_variant_ids: selectedRangeVariantIds,
  }

  dispatch(setSaving(true))

  try {

    await syncBuilderRateRangeVariants({ id: builderRateId, data })

    onClose()

  } catch (error) {

    handleException(error)
    
    throw error

  } finally {

    dispatch(setSaving(false))

  }
  
}
