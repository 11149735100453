import { reset } from 'redux-form';
import createOrderLog from '../../../Services/createOrderLog';
import { FORM_NAME } from './index';

export default async (values, dispatch) => {
  
  const didCreate = await dispatch(createOrderLog(values));

  if (didCreate) {
    dispatch(reset(FORM_NAME));
  }

};
