import { createAction, handleActions } from 'redux-actions';

const setSorter = createAction("SCREENS/ORDERS/SET_SORTER");
const setDefaultSorter = createAction("SCREENS/ORDERS/SET_DEFAULT_SORTER");

const defaultState = {
  columnKey: 'id',
  order: 'descend'
};

const reducer = handleActions(
  {
    [setSorter]: (state, action) => Object.assign({}, state, action.payload),
    [setDefaultSorter]: () =>  Object.assign({}, defaultState),
  },
  defaultState
);

export { setSorter, setDefaultSorter, reducer };