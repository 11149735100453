import { message } from 'antd'
import { createAreaSiteRate } from '../../../../../HttpRequests/areaSiteRates'
import fetch from './fetch'
import { handleException } from '../../../../../../packages/sota'

export default (id, siteRateId) => async (dispatch) => {

  const data = {
    area_id: id,
    site_rate_id: siteRateId,
  }

  try {
    
    await createAreaSiteRate({ data })

    dispatch(fetch())

    message.success('Site Rate Added')

  } catch (error) {

    handleException(error)

    throw error

  }

}
