import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as rangeVariants } from './State/rangeVariants'
import { reducer as fetching } from './State/fetching'
import { reducer as rangeVariantModal } from './State/rangeVariantModal'

export default combineReducers({
  controls,
  rangeVariants,
  fetching,
  rangeVariantModal,
})
