import { createAction, handleActions } from 'redux-actions';

const loadMaterialTypes = createAction("CORE/MATERIAL_TYPES/LOAD_MATERIAL_TYPES");
const clearMaterialTypes = createAction("CORE/MATERIAL_TYPES/CLEAR_MATERIAL_TYPES");

const defaultState = []

const reducer = handleActions(
  {
    [loadMaterialTypes]: (state, action) => action.payload,
    [clearMaterialTypes]: () => []
  },
  defaultState
);

export { loadMaterialTypes, clearMaterialTypes, reducer };