import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.css'

const Static = props => {

  const {
    item,
    onEditField,
  } = props

  const [cost, setCost] = useState(undefined)

  const onBlurNumber = (value) => {
    const $value = (value || value === '0') ? parseFloat(value.replace(',', '')) : null
    onEditField(item.field, $value)
  }

  return (
    
    <div className={styles.wrapper}>

      <div className={classNames(styles.cell, styles.title)}>
        <div className={styles.content}>{item.title}</div>
      </div>

      <div className={classNames(styles.cell, styles.cost)}>
        <input
          value={cost !== undefined ? cost : item.cost}
          onChange={e => setCost(e.target.value)}
          onBlur={e => {
            onBlurNumber(e.target.value)
            setCost(undefined)
          }}
          className={classNames(styles.input, styles.number)}
        />
      </div>

    </div>
    
  )

}

Static.propTypes = {
  item: PropTypes.object.isRequired,
  onEditField: PropTypes.func.isRequired,
}

export default Static
