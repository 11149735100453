import handleException from '../../../../../Core/Helpers/handleException';

export default (errors, dispatch, submitError) => {

  if (submitError) {

    handleException(submitError);

  }

}