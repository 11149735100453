import { createAction, handleActions } from 'redux-actions'

const setAreaGroup = createAction('MODALS/AREA_GROUP/SET_AREA_GROUP')
const resetAreaGroup = createAction('MODALS/AREA_GROUP/RESET_AREA_GROUP')

const defaultState = null

const reducer = handleActions(
  {
    [setAreaGroup]: (state, action) => action.payload,
    [resetAreaGroup]: () => defaultState,
  },
  defaultState,
)

export { setAreaGroup, resetAreaGroup, reducer }
