import { closeMaterialTypeModal } from '../State/materialTypeModal'
import fetch from '../Components/MaterialTypes/Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeMaterialTypeModal())

  if (reload) {
    dispatch(fetch())
  }

}
