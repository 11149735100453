import { connect } from "react-redux";

import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import onCloseLeftoverModal from "./Handlers/onCloseLeftoverModal";
import didCreateLeftover from "./Handlers/didCreateLeftover";
import didUpdateLeftover from "./Handlers/didUpdateLeftover";
import didDeleteLeftover from "./Handlers/didDeleteLeftover";

const mapStateToProps = state => ({
  showLeftoverModal: state.screens.Leftovers.showLeftoverModal,
  selectedLeftoverId: state.screens.Leftovers.selectedLeftoverId
});

const actionCreators = {
  componentDidMount,
  onCloseLeftoverModal,
  didCreateLeftover,
  didUpdateLeftover,
  didDeleteLeftover
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
